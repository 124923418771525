import { combineReducers } from 'redux';
import {
    carMakeModalVariant,
    carMakeReducer,
    bikeMakeReducer,
    carModelsReducer,
    carPolicyTypesReducer,
    bikeModelsReducer,
    rtoReducer,
    authReducer,
    messageReducer,
    bikePolicyTypesReducer,
    bikeMakeModalVariant,
    posCityReducer,
    posPinReducer
} from './reducers';

import { stateReducer } from './reducers/state';
import { cityReducer } from './reducers/city';
import { pinReducer } from './reducers/pinCode';




const rootReducer = combineReducers({
    carMake: carMakeReducer,
    bikeMake: bikeMakeReducer,
    carModels: carModelsReducer,
    bikeModels: bikeModelsReducer,
    rto: rtoReducer,
    policyTypes: carPolicyTypesReducer,
    bikePolicyTypes: bikePolicyTypesReducer,
    makeModalVaraint: carMakeModalVariant,
    bikeMakeModalVaraint: bikeMakeModalVariant,
    auth: authReducer,
    message: messageReducer,
    posState: stateReducer,
    posCity: cityReducer,
    posPinCode: pinReducer,
});

export default rootReducer;