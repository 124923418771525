import React, { useState, Fragment } from "react";
import './HRDashboard.css';
import { nanoid } from "nanoid";
import data from "./mock-data.json";
import ReadOnlyRow from "./ReadOnlyRow";
import EditableRow from "./EditableRow";
const HRDashboard = () => {
    const [contacts, setContacts] = useState(data);
    const [addFormData, setAddFormData] = useState({
        code: "",
        namee: "",
        email: "",
        date: "",
        designation: "",
        status1: "", status2: ""
    });
    const [editFormData, setEditFormData] = useState({
        code: "",
        namee: "",
        email: "",
        date: "",
        designation: "",
        status1: "", status2: ""

    });
    const [editContactId, setEditContactId] = useState(null);

    const handleAddFormChange = (event) => {
        event.preventDefault();

        const fieldName = event.target.getAttribute("name");
        const fieldValue = event.target.value;

        const newFormData = { ...addFormData };
        newFormData[fieldName] = fieldValue;

        setAddFormData(newFormData);
    };
    const handleEditFormChange = (event) => {
        event.preventDefault();

        const fieldName = event.target.getAttribute("name");
        const fieldValue = event.target.value;

        const newFormData = { ...editFormData };
        newFormData[fieldName] = fieldValue;

        setEditFormData(newFormData);
    };
    const handleAddFormSubmit = (event) => {
        event.preventDefault();
        const newContact = {
            id: nanoid(),
            code: addFormData.code,
            namee: addFormData.namee,
            email: addFormData.email,
            date: addFormData.date,
            designation: addFormData.designation,
            status1: addFormData.status1,
            status2: addFormData.status2

        };

        const newContacts = [...contacts, newContact];

        setContacts(newContacts);
    };
    const handleEditFormSubmit = (event) => {
        event.preventDefault();

        const editedContact = {
            id: editContactId,
            code: editFormData.code,
            namee: editFormData.namee,
            email: editFormData.email,
            date: editFormData.date,
            designation: editFormData.designation,
            staus1: editFormData.status1,
            status2: editFormData.status2

        };
        const newContacts = [...contacts];

        const index = contacts.findIndex((contact) => contact.id === editContactId);

        newContacts[index] = editedContact;

        setContacts(newContacts);
        setEditContactId(null);
    };

    const handleEditClick = (event, contact) => {
        event.preventDefault();
        setEditContactId(contact.id);

        const formValues = {
            code: contact.code,
            namee: contact.namee,
            email: contact.email,
            date: contact.date,
            designation: contact.designation,
            status1: contact.status1,
            status2: contact.status2
        };

        setEditFormData(formValues);
    };
    const handleCancelClick = () => {
        setEditContactId(null);
    };

    const handleDeleteClick = (contactId) => {
        const newContacts = [...contacts];

        const index = contacts.findIndex((contact) => contact.id === contactId);

        newContacts.splice(index, 1);

        setContacts(newContacts);
    };

    return (<div className="Hrdashboard" id="Hrdashboard">
        {/* <!-- Top Bar --> */}

        <div class="container-fluid1 py-2 icueTopBarContainer bg-white">
            <div class="row">
                <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                    <div class="topBarLfContainer d-flex">
                        <div class="icueLogoContainer">
                            <img src="images/logo.png" alt="ICue Logo" class="icueLogo" />
                        </div>
                        <div class="icueSearchBox w-75 mx-3">
                            <div class="input-group ">
                                <span class="input-group-text" id="basic-addon1">
                                    <i class="bi bi-search"></i>
                                </span>
                                <input type="search" id="text-search" class="form-control icueSearchBoxControl" placeholder="Enter keywords..." aria-label="Username" aria-describedby="basic-addon1" />
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                    <div class="d-flex flex-row-reverse">
                        <div class="icueProfile px-2">
                            <img src="images/avatar.png" alt="icueProfilePic" class="icueProfilePic border border-dark rounded-circle" />
                        </div>
                        <div class="position-relative px-2">
                            <a href="#" id="icueNotifyBoxHandler">
                                <i class="bi bi-bell">
                                    <span class="position-absolute icueNofIndic"></span>
                                </i>
                            </a>
                            <div class="icueNotifyBox" id="icueNotifyBox">
                                Notifications
                            </div>
                        </div>

                        <div class="icueUserName fs-6 px-2">
                            Viral Patel
                        </div>

                        <div class="icueMail px-2">
                            <i class="bi bi-headset"></i> &nbsp;
                            adminhelpdesk@gmail.com
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* <!-- /Top Bar -->

    <!-- Main Body --> */}
        <div class="container-fluid my-2">
            <div class="row">
                <div class="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12 bg-white">
                    <div class="icueRespSideBar d-xxl-none d-xl-none d-lg-none d-md-block d-sm-block d-block" id="icueRespSideBar">
                        <button type="button" class="btn btn-primary" id="icueRespHumburger">
                            <i class="bi bi-grid-3x3-gap"></i>
                        </button>
                        <div class="icueSideBar" id="icueSideBar" >
                            <nav class="navbar icueSideNavContainer" >
                                <ul class="icueNavBarList">
                                    <li class="icueNavBarItem active">
                                        <a href="/Hrdashboard" class="icueNavBarLink" >Dashboard</a>
                                    </li>
                                    <li class="icueNavBarItem border-bottom-0">
                                        <a href="#" class="icueNavBarLink" >User Management</a>
                                    </li>

                                    <li>
                                        <ul class="icueSubNavBar">
                                            <li class="icueSubNavItem">
                                                {/* <!-- <a href="#" data-bs-toggle="modal" data-bs-target="#addid" data-bs-whatever="@getbootstrap" class="icueSubNavLink  text-capitalize">Add Member</a> --> */}
                                                <a href="#" class="icueSubNavLink  text-capitalize">Add Members</a>
                                            </li>
                                            <li class="icueSubNavItem">
                                                <a href="/ListMembers" class="icueSubNavLink  text-capitalize">List of Members</a>
                                            </li>
                                        </ul>
                                    </li>
                                    <hr></hr>

                                    <li class="icueNavBarItem border-bottom-0">
                                        <a href="#" class="icueNavBarLink" id="">Leave Management</a>
                                    </li>

                                    <li>
                                        <ul class="icueSubNavBar">
                                            <li class="icueSubNavItem">
                                                {/* <!-- <a href="#" data-bs-toggle="modal" data-bs-target="#addid" data-bs-whatever="@getbootstrap" class="icueSubNavLink  text-capitalize">Add Member</a> --> */}
                                                <a href="/Leaves" class="icueSubNavLink  text-capitalize">Leaves</a>
                                            </li>
                                            <li class="icueSubNavItem">
                                                <a href="/LeaveType" class="icueSubNavLink  text-capitalize">Leave Type</a>
                                            </li>
                                        </ul>
                                    </li>
                                    <hr></hr>
                                    <li class="icueNavBarItem border-bottom-1">
                                        <a href="/Holidays" class="icueNavBarLink" id="">Holidays</a>
                                    </li>
                                    <li class="icueNavBarItem border-bottom-1">
                                        <a href="/Attendance" class="icueNavBarLink" id="">Attendance</a>
                                    </li>



                                    <li class="icueNavBarItem">
                                        <a href="/Department" class="icueNavBarLink" id="">Department</a>
                                    </li>

                                    <li class="icueNavBarItem">
                                        <a href="/AddRoles" class="icueNavBarLink" id="">Roles</a>
                                    </li>

                                    <li class="icueNavBarItem border-bottom-0">
                                        <a href="#" class="icueNavBarLink" id="">Payroll</a>
                                    </li>
                                    <li>
                                        <ul class="icueSubNavBar">
                                            <li class="icueSubNavItem">
                                                <a href="/EmpSalary" class="icueSubNavLink  text-capitalize">Employee Salary</a>
                                            </li>
                                            <li class="icueSubNavItem">
                                                <a href="/Payitems" class="icueSubNavLink  text-capitalize">Payroll Items</a>
                                            </li>
                                        </ul>
                                        <hr></hr>
                                    </li>

                                    <li class="icueNavBarItem border-bottom-0" style={{ marginTop: "-18px" }}>
                                        <a href="#" class="icueNavBarLink" id="">Settings</a>
                                    </li>
                                    <li>
                                        <ul class="icueSubNavBar">
                                            <li class="icueSubNavItem">
                                                <a href="#" class="icueSubNavLink  text-capitalize">Change Password</a>
                                            </li>
                                            <li class="icueSubNavItem">
                                                <a href="#" class="icueSubNavLink  text-capitalize">Profile</a>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>

                    <div class="icueNavSideBar d-xxl-block d-xl-block d-lg-block d-md-none d-sm-none d-none" id="icueNavSideBar">
                        <nav>
                            <ul class="icueNavBarList">
                                <li class="icueNavBarItem active">
                                    <a href="/Hrdashboard" class="icueNavBarLink" id="">Dashboard</a>
                                </li>

                                <li class="icueNavBarItem border-bottom-0">
                                    <a href="#" class="icueNavBarLink" id="">User Management</a>
                                </li>

                                <li>
                                    <ul class="icueSubNavBar">
                                        <li class="icueSubNavItem" style={{ marginTop: "-8px" }}>
                                            {/* <!-- <a href="#" data-bs-toggle="modal" data-bs-target="#addid" data-bs-whatever="@getbootstrap" class="icueSubNavLink  text-capitalize">Add Member</a> --> */}
                                            <a href="#" class="icueSubNavLink  text-capitalize">Add Member</a>
                                        </li>
                                        <li class="icueSubNavItem">
                                            <a href="/ListMembers" class="icueSubNavLink  text-capitalize">List of Members</a>
                                        </li>
                                    </ul>
                                </li>
                                <hr></hr>

                                <li class="icueNavBarItem border-bottom-0" style={{ marginTop: "-18px" }}>
                                    <a href="#" class="icueNavBarLink" id="" >Leave Management</a>
                                </li>

                                <li>
                                    <ul class="icueSubNavBar ">
                                        <li class="icueSubNavItem" style={{ marginTop: "-8px" }}>
                                            {/* <!-- <a href="#" data-bs-toggle="modal" data-bs-target="#addid" data-bs-whatever="@getbootstrap" class="icueSubNavLink  text-capitalize">Add Member</a> --> */}
                                            <a href="/Leaves" class="icueSubNavLink  text-capitalize">Leaves</a>
                                        </li>
                                        <li class="icueSubNavItem">
                                            <a href="/LeaveType" class="icueSubNavLink  text-capitalize">Leave Type</a>
                                        </li>
                                    </ul>
                                    <hr></hr>
                                </li>

                                <li class="icueNavBarItem " style={{ marginTop: "-18px" }}>
                                    <a href="/Holidays" class="icueNavBarLink" id="">Holidays</a>
                                </li>
                                <li class="icueNavBarItem ">
                                    <a href="/Attendance" class="icueNavBarLink" id="">Attendance</a>
                                </li>




                                <li class="icueNavBarItem" >
                                    <a href="/Department" class="icueNavBarLink" id="">Department</a>
                                </li>

                                <li class="icueNavBarItem">
                                    <a href="/AddRoles" class="icueNavBarLink" id="">Roles</a>
                                </li>


                                <li class="icueNavBarItem border-bottom-0">
                                    <a href="#" class="icueNavBarLink" id="">Payroll</a>
                                </li>
                                <li>
                                    <ul class="icueSubNavBar">
                                        <li class="icueSubNavItem">
                                            <a href="/EmpSalary" class="icueSubNavLink  text-capitalize">Employee Salary</a>
                                        </li>
                                        <li class="icueSubNavItem">
                                            <a href="/Payitems" class="icueSubNavLink  text-capitalize">Payroll Items</a>
                                        </li>
                                    </ul>
                                    <hr></hr>
                                </li>

                                <li class="icueNavBarItem border-bottom-0" style={{ marginTop: "-18px" }}>
                                    <a href="#" class="icueNavBarLink" id="">Settings</a>
                                </li>
                                <li>
                                    <ul class="icueSubNavBar">
                                        <li class="icueSubNavItem">
                                            <a href="#" class="icueSubNavLink  text-capitalize">Change Password</a>
                                        </li>
                                        <li class="icueSubNavItem">
                                            <a href="#" class="icueSubNavLink  text-capitalize">Profile</a>
                                        </li>
                                    </ul>
                                    <hr></hr>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>

                <div class="col-xxl-10 col-xl-10 col-lg-10 col-md-12 col-sm-12 col-12 ">
                    <main class="mainBodyContainer">
                        <div class="row my-2">
                            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 py-2 px-3">
                                <div class="bg-transparent p-2  rounded-3">
                                    <nav style={{ bsbreadcrumbdivider: 'url("data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="8" height="8"%3E%3Cpath d="M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z" fill="currentColor"/%3E%3C/svg%3E")' }} aria-label="breadcrumb">
                                        <ol class="breadcrumb mb-0 justify-content-end bg-transparent">
                                            <li class="breadcrumb-item bg-transparent"><a href="/Hrdashboard" class="text-decoration-none " style={{ fontFamily: 'Calibri', color: "black" }}>Dashboard</a></li>
                                            {/* <li class="breadcrumb-item text-muted" aria-current="page">LMS</li> */}
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>

                        <div class="row my-2">
                            <div class="col-xxl-4 col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 py-2 px-3">
                                <div class="bg-white p-1 shadow icueBorBottomPrimary" style={{ marginLeft: "0.8rem" }}>
                                    <div class="row py-2">
                                        <div class="col-7">
                                            <div class="ps-2">
                                                <h2 class="fs-1 pt-1 icueTextPrimary" style={{ fontFamily: "ErasDemiITC", fontSize: "30px" }}>218</h2><br />
                                                <h4 class="fs-5 mb-0 text-capitalize icueTextLight" style={{ fontFamily: "ErasDemiITC" }}>total employees</h4>
                                            </div>
                                        </div>
                                        <div class="col-5 text-end">
                                            <div class="pe-2">
                                                <div class="icueBgLightGrey icueTextPrimary rounded-circle fs-1 d-inline-block p-3 text-center ">
                                                    <i class="fas fa-users "></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xxl-4 col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 py-2 px-3">
                                <div class="bg-white p-1 shadow icueBorBottomLightInfo">
                                    <div class="row py-2">
                                        <div class="col-7">
                                            <div class="ps-2">
                                                <h2 class="fs-1 pt-1 icueTextInfoLight" style={{ fontFamily: "ErasDemiITC", fontSize: "30px" }}>30</h2> <br />
                                                <h4 class="fs-5 mb-0 text-capitalize icueTextLight" style={{ fontFamily: "ErasDemiITC" }}>New employees</h4>
                                            </div>
                                        </div>
                                        <div class="col-5 text-end">
                                            <div class="pe-2">
                                                <div class="icueBgLightGrey icueTextInfoLight rounded-circle fs-1 d-inline-block p-3 text-center ">
                                                    <i class="fas fa-users "></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xxl-4 col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 py-2 px-3">
                                <div class="bg-white p-1 shadow icueBorBottomLightDanger" >
                                    <div class="row py-2">
                                        <div class="col-7">
                                            <div class="ps-2">
                                                <h2 class="fs-1 pt-1 icueTextLightDanger" style={{ fontFamily: "ErasDemiITC", fontSize: "30px" }}>15</h2> <br />
                                                <h4 class="fs-5 mb-0 text-capitalize icueTextLight" style={{ fontFamily: "ErasDemiITC" }}>unplanned leaves</h4>
                                            </div>
                                        </div>
                                        <div class="col-5 text-end">
                                            <div class="pe-2">
                                                <div class="icueBgLightGrey icueTextLightDanger rounded-circle fs-1 d-inline-block p-3 text-center ">
                                                    <i class="fas fa-users "></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="row my-2">
                            <div className="col-xxl-8 col-xl-8 col-lg-12 col-md-12 col-sm-12 col-12 py-2 px-2">
                                <div className="bg-white p-2  rounded-3 shadow-sm" style={{ marginLeft: "0.8rem", marginRight: "0.8rem" }}>
                                    <div className="px-1 py-3">
                                        <div class="container1">
                                            <div className="row">
                                                <div class=" col-xs-12 bg-white ">
                                                    <div class="col-xl-9 col-lg-12 col-md-12 col-sm-12">
                                                        <div class="icueSearchBox w-85 mx-3" style={{ marginTop: "-20px" }} >
                                                            <div class="input-group " style={{ marginLeft: "-20px" }}>
                                                                <input type="search" class="search form-control icueSearchBoxControl" placeholder="Search Users by Name, Email or Date" aria-label="Username" aria-describedby="basic-addon1" style={{ backgroundColor: "#F4F7FC" }} />
                                                                <div class="col-xl-3 col-lg-12 col-md-12 col-sm-12">
                                                                    <a href="" type="button" class=" icueBgPrimary text-white pt-2 " data-bs-toggle="modal" data-bs-target="#addid" style={{ marginLeft: "12.5rem", width: "7.5rem", textAlign: "center", fontSize: "0.75rem", marginTop: "-0.063rem", display: "inline-block", height: "35px" }}><i class="bi bi-plus-circle"></i> &nbsp;Add Employee</a>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <br />
                                            <form id="icueHrInfoForm" onSubmit={handleEditFormSubmit}>
                                                <table id="icueHrTable" className="table results table-striped table-hover" style={{ width: "100%", border: "0", marginLeft: "-12px" }}>
                                                    <thead>
                                                        <tr className="text-uppercase" style={{ lineHeight: "15px", fontFamily: "Calibri", fontSize: "12px", backgroundColor: "#F4F7FC" }}>
                                                            <th >Emp code</th>
                                                            <th class="text-center py-3" > name</th>
                                                            <th class="text-center py-3">Email id</th>
                                                            <th class="text-center py-3" >Joined on</th>
                                                            <th class="text-center py-3">Designation</th>
                                                            <th class="text-center py-3">status</th>

                                                        </tr>
                                                    </thead>
                                                    <tbody style={{ lineHeight: "15px", fontFamily: "Calibri", fontSize: "12px", textAlign: "center" }}>

                                                        {contacts.map((contact) => (
                                                            <Fragment>
                                                                {editContactId === contact.id ? (
                                                                    <EditableRow
                                                                        editFormData={editFormData}
                                                                        handleEditFormChange={handleEditFormChange}
                                                                        handleCancelClick={handleCancelClick}
                                                                    />
                                                                ) : (
                                                                    <ReadOnlyRow
                                                                        contact={contact}
                                                                        handleEditClick={handleEditClick}
                                                                        handleDeleteClick={handleDeleteClick}
                                                                    />
                                                                )}
                                                            </Fragment>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 py-2 px-3">
                                <div className="bg-white pt-4 pb-2 px-4 rounded-3 shadow-sm border-bottom" style={{ marginRight: "0.8rem" }}>
                                    <div className="d-flex justify-content-between">
                                        <h4 className="icueTextPrimary pb-2" style={{ fontFamily: "ErasDemiITC", fontSize: "28px", fontWeight: "15px" }}>Requested Leaves</h4>
                                        <div>
                                            <a href="/Followup" type="text" className="" style={{ fontSize: "28px", fontFamily: "ErasDemiITC" }}>
                                                15</a>
                                        </div>
                                    </div>

                                    <a href="#"> <button type="button" className="btn  text-start text-black w-100 mb-2  " style={{ backgroundColor: "#EFF7FD", boxShadow: " 0px 0px 5px rgba(0, 0, 0, 0.2)" }} >
                                        <div className="text-uppercase " style={{ fontFamily: "Calibri", fontSize: "20px", fontSize: "bold", color: "black", lineHeight: "0px" }}>Martin Lewis
                                            <i class="bi bi-check-circle fa-lg" style={{ marginLeft: "79px", color: "#29CC97", fontSize: "bold" }}></i>
                                            <i class="bi bi-x-circle fa-lg" style={{ marginLeft: "19px", color: "#D64343", fontSize: "bold" }}></i>

                                        </div>
                                        <small> <small className="text-capitalize" style={{ color: "#242424", fontSize: "12px" }}>4th Sep 2021</small> </small></button> </a>


                                    <a href="#"> <button type="button" className="btn  text-start text-black w-100 mb-2  " style={{ backgroundColor: "#EFF7FD", boxShadow: " 0px 0px 5px rgba(0, 0, 0, 0.2)" }} >
                                        <div className="" style={{ fontFamily: "Calibri", fontSize: "20px", fontSize: "bold", color: "black" }}>John Doe
                                            <i class="bi bi-check-circle fa-lg" style={{ marginLeft: "110px", color: "#29CC97", fontSize: "bold" }}></i>
                                            <i class="bi bi-x-circle fa-lg" style={{ marginLeft: "20px", color: "#D64343", fontSize: "bold" }}></i>
                                        </div>
                                        <small> <small className="text-capitalize" style={{ color: "#242424", fontSize: "12px" }}>4th Sep 2021</small> </small></button> </a>

                                    <a href="#"> <button type="button" className="btn  text-start text-black w-100 mb-2  " style={{ backgroundColor: "#EFF7FD", boxShadow: " 0px 0px 5px rgba(0, 0, 0, 0.2)" }} >
                                        <div className="" style={{ fontFamily: "Calibri", fontSize: "20px", fontSize: "bold", color: "black" }}>Zaire Saris
                                            <i class="bi bi-check-circle fa-lg" style={{ marginLeft: "105px", color: "#29CC97", fontSize: "bold" }}></i>
                                            <i class="bi bi-x-circle fa-lg" style={{ marginLeft: "20px", color: "#D64343", fontSize: "bold" }}></i>
                                        </div>
                                        <small> <small className="text-capitalize" style={{ color: "#242424", fontSize: "12px" }}>4th Sep 2021</small> </small></button> </a>

                                    <a href="#"> <button type="button" className="btn  text-start text-black w-100 mb-2  " style={{ backgroundColor: "#EFF7FD", boxShadow: " 0px 0px 5px rgba(0, 0, 0, 0.2)" }} >
                                        <div className="" style={{ fontFamily: "Calibri", fontSize: "20px", fontSize: "bold", color: "black" }}>Kadin Lubim
                                            <i class="bi bi-check-circle fa-lg" style={{ marginLeft: "95px", color: "#29CC97", fontSize: "bold" }}></i>
                                            <i class="bi bi-x-circle fa-lg" style={{ marginLeft: "20px", color: "#D64343", fontSize: "bold" }}></i>

                                        </div>
                                        <small> <small className="text-capitalize" style={{ color: "#242424", fontSize: "12px" }}>4th Sep 2021</small> </small></button> </a>
                                    <a href="#"> <button type="button" className="btn  text-start text-black w-100 mb-2  " style={{ backgroundColor: "#EFF7FD", boxShadow: " 0px 0px 5px rgba(0, 0, 0, 0.2)" }} >
                                        <div className="" style={{ fontFamily: "Calibri", fontSize: "20px", fontSize: "bold", color: "black" }}>Omar Vetrovs
                                            <i class="bi bi-check-circle fa-lg" style={{ marginLeft: "85px", color: "#29CC97", fontSize: "bold" }}></i>
                                            <i class="bi bi-x-circle fa-lg" style={{ marginLeft: "19px", color: "#D64343", fontSize: "bold" }}></i>

                                        </div>
                                        <small> <small className="text-capitalize" style={{ color: "#242424", fontSize: "12px" }}>4th Sep 2021</small> </small></button> </a>
                                    <a href="#"> <button type="button" className="btn  text-start text-black w-100 mb-2  " style={{ backgroundColor: "#EFF7FD", boxShadow: " 0px 0px 5px rgba(0, 0, 0, 0.2)" }} >
                                        <div className="" style={{ fontFamily: "Calibri", fontSize: "20px", fontSize: "bold", color: "black" }}>Justin korsgaard
                                            <i class="bi bi-check-circle fa-lg" style={{ marginLeft: "75px", color: "#29CC97", fontSize: "bold" }}></i>
                                            <i class="bi bi-x-circle fa-lg" style={{ marginLeft: "19px", color: "#D64343", fontSize: "bold" }}></i>

                                        </div>
                                        <small> <small className="text-capitalize" style={{ color: "#242424", fontSize: "12px" }}>4th Sep 2021</small> </small></button> </a>
                                    <button type="button" class="btn btn-outline-primary  btn-small" style={{ marginLeft: "180px", width: "40%", marginTop: "14px" }}>View All <i class="bi bi-chevron-right" style={{ marginLeft: "10px", marginTop: "20px" }}></i></button>

                                </div>

                            </div>
                        </div>

                        {/* <!-- Assign Lead To Modal --> */}
                        <div class="modal fade" id="addid" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div class="modal-dialog  modal-dialog-centered">
                                <div class="modal-content rounded-3">
                                    <div class="modal-header d-flex flex-column" id="addModalHeader">
                                        <div class="text-end w-100" >
                                            <button type="button" class="btn text-muted " data-bs-dismiss="modal" ><i class="bi bi-x-circle" style={{ color: "#8692A6" }}></i></button>
                                        </div>
                                        <div class="text-center">
                                            <h4 class="modal-title">Add Member</h4>
                                            <p class="text-muted">Please add a valid email</p>
                                        </div>
                                    </div>
                                    <div class="modal-body" id="addModalBody">
                                        <form class="">
                                            <div class="row">
                                                <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                                                    <div class="form-group pb-2">
                                                        <input class="form-control" type="text" name="code" placeholder="Employee Code" onChange={handleAddFormChange} />
                                                    </div>
                                                </div>
                                                <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                                                    <div class="form-group pb-2">
                                                        <input class="form-control" type="text" name="namee" onChange={handleAddFormChange} placeholder="Employee Name" />
                                                    </div>
                                                </div>
                                                <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                                                    <div class="form-group pb-2">
                                                        <input class="form-control" type="text" name="email" onChange={handleAddFormChange} placeholder="Enter Email" />
                                                    </div>
                                                </div>
                                                <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                                                    <div class="form-group pb-2">
                                                        <input class="form-control" type="date" name="date" onChange={handleAddFormChange} />
                                                    </div>
                                                </div>
                                                <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                                                    <div class="form-group pb-2">
                                                        <select class="form-select text-muted" aria-label="icueAddDepartment" name="designation" onChange={handleAddFormChange} >
                                                            <option>Designation</option>
                                                            <option>Executive</option>
                                                            <option>Manager</option>
                                                            <option>Sales Rep.</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                                                    <div class="form-group pb-2">
                                                        <select class="form-select text-muted" aria-label="icueAddRole" name="status1" onChange={handleAddFormChange} >
                                                            <option>Status</option>
                                                            <option value="Active">Active</option>
                                                            <option value="Inactive" >Inactive</option>
                                                        </select>
                                                    </div>
                                                </div>


                                                <div class="col-12 py-3">
                                                    <div class="d-grid gap-2">
                                                        <button type="button" data-bs-dismiss="modal" onClick={handleAddFormSubmit} class="btn text-white " style={{ backgroundColor: "#3A3285" }}>
                                                            Add Memeber
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- /Assign Lead To Modal -->

<!-- Send RFQ Modal --> */}
                        <div className="modal fade" id="sendRfqModal" tabindex="-1" aria-labelledby="sendRfqModalLabel" aria-hidden="true">
                            {/* <!-- <div className="modal-dialog modal-dialog-centered"> --> */}
                            <div className="modal-dialog">
                                <div className="modal-content">
                                    <div className="modal-header py-1 shadow-sm">
                                        <h5 className="modal-title" id="sendModalLabel" style={{ fontFamily: "ErasDemiITC" }}>RFQ-Marine Insurance</h5>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div className="modal-body" style={{ backgroundColor: "#edf5fb" }}>
                                        <form className="">
                                            <div className="row">
                                                <div className="col-lg-9 col-md-9 col-sm-12 col-12 mx-auto">
                                                    <div className="form-group pb-2" style={{ fontStyle: "bold" }}>
                                                        {/* <!-- <input className="form-control" type="text" name="icueMemEmail" placeholder="Enter Name/Code" value="" /> --> */}
                                                        {/* <select id="icueRfqSelBox" name="icueRfqSelBox" className="form-select text-capitalize" >
                                    <option value="hdfc ergo">HDFC ERGO</option>
                                    <option value="ICICI Term Life">ICICI Term Life</option>
                                    <option value="royal sundaram">royal sundaram</option>
                                    <option value="sompa">sompa</option>
                                    <option value="SBI general">SBI general</option>
                                </select> */}
                                                        Insurance Company
                                                        {/* <Select isMulti options={Companyname}>
                                 
                                </Select> */}



                                                    </div>
                                                </div>
                                                <div className="col-lg-9 col-md-9 col-sm-12 col-12 mx-auto">
                                                    <div className="form-group pb-2">
                                                        <textarea className="form-control" id="icueRFQDesc" rows="3" placeholder="Description of quotation" style={{ fontFamily: "Calibri" }}></textarea>
                                                    </div>
                                                </div>
                                                <div className="col-lg-9 col-md-9 col-sm-12 col-12 mx-auto">
                                                    <div className="mb-3">
                                                        <div className="d-flex justify-content-between">
                                                            <label for="choose-file" className="custom-file-upload bg-primary text-white mt-4 mb-5 " id="choose-file-label" style={{ fontFamily: "Calibri" }}>
                                                                Upload Document
                                                            </label>
                                                            <input name="uploadDocument" type="file" id="choose-file"
                                                                accept=".jpg,.jpeg,.pdf,doc,docx,application/msword,.png" style={{ display: "none" }} />
                                                            <div style={{ width: "55%", marginTop: "27px" }}>
                                                                <input type="text" readonly id="icueRfqUploadedDoc" value="document.pdf" style={{ fontFamily: "Calibri", fontSize: "16px", color: "#494949" }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-9 col-md-9 col-sm-12 col-12 py-3 mx-auto">
                                                    <div className="d-grid gap-2">
                                                        <button type="button" className="btn btn-primary " style={{ marginTop: "-50px" }}>
                                                            Send
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>


















                        <div class="row my-2">
                            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 py-2 px-3">
                                <div class="bg-white p-2 rounded-3 shadow-sm">

                                </div>
                            </div>
                        </div>


                        {/* <!-- Add Details Modal --> */}
                        <div class="modal" id="addid" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div class="modal-dialog  modal-dialog-centered">
                                <div class="modal-content rounded-3">
                                    <div class="modal-header d-flex flex-column" id="addModalHeader">
                                        <div class="text-end w-100">
                                            <button type="button" class="btn text-muted" data-bs-dismiss="modal"><i class="bi bi-x-circle"></i></button>
                                        </div>
                                        <div class="text-center">
                                            <h4 class="modal-title">Add Member</h4>
                                            <p class="text-muted">Please add a valid email</p>
                                        </div>
                                    </div>
                                    <div class="modal-body" id="addModalBody">
                                        <form class="">
                                            <div class="row">
                                                <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                                                    <div class="form-group pb-2">
                                                        <input class="form-control" type="text" name="icueMemEmail" placeholder="Enter Email" value="" />
                                                    </div>
                                                </div>
                                                <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                                                    <div class="form-group pb-2">
                                                        <input class="form-control" type="text" name="icueMemEmpCode" placeholder="Employee Code" value="" />
                                                    </div>
                                                </div>
                                                <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                                                    <div class="form-group pb-2">
                                                        <select class="form-select text-muted" aria-label="Default select example">
                                                            <option>Department</option>
                                                            <option value="1">Development</option>
                                                            <option value="2">Designing</option>
                                                            <option value="3">QA</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                                                    <div class="form-group pb-2">
                                                        <select class="form-select text-muted" aria-label="Default select example">
                                                            <option>Role</option>
                                                            <option value="1">Member</option>
                                                            <option value="2">Team Lead</option>
                                                            <option value="3">Manager</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                                                    <span class="text-muted">Permissions: </span>
                                                </div>
                                                <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                                                    <div class="form-check pb-2">
                                                        <input class="form-check-input" type="checkbox" value="" id="icueNotifyNewUserByMail" checked />
                                                        <label class="form-check-label text-muted" for="icueNotifyNewUserByMail">
                                                            Notify new users by email
                                                        </label>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                                                    <div class="form-check pb-2">
                                                        <input class="form-check-input" type="checkbox" value="" id="icueCanManageUsers" />
                                                        <label class="form-check-label text-muted" for="icueCanManageUsers">
                                                            Can manage users
                                                        </label>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                                                    <div class="form-check pb-2">
                                                        <input class="form-check-input" type="checkbox" value="" id="icueClaimManag" />
                                                        <label class="form-check-label text-muted" for="icueClaimManag">
                                                            Claim management
                                                        </label>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                                                    <div class="form-check pb-2">
                                                        <input class="form-check-input" type="checkbox" value="" id="icuePolicyManag" />
                                                        <label class="form-check-label text-muted" for="icuePolicyManag">
                                                            Policy management
                                                        </label>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                                                    <div class="form-check pb-2">
                                                        <input class="form-check-input" type="checkbox" value="" id="icueAccManag" />
                                                        <label class="form-check-label text-muted" for="icueAccManag">
                                                            Account management
                                                        </label>
                                                    </div>
                                                </div>

                                                <div class="col-12 py-3">
                                                    <div class="d-grid gap-2">
                                                        <button type="button" class="btn btn-primary btnIcon">
                                                            Send Invite
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- /Add Details Modal --> */}



                    </main>
                </div>
            </div>
        </div>


    </div>)
}
export default HRDashboard;
