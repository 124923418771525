import React, { useState, Fragment } from "react";
import { nanoid } from "nanoid";
import data from "./mock-data.json";
import ReadOnlyRow from "./ReadOnlyRow";
import EditableRow from "./EditableRow";
import './Leaves.css';

const Leaves=()=>{
    const [contacts, setContacts] = useState(data);
  const [addFormData, setAddFormData] = useState({
   employee:"",
   leavetype:"",
   from:"",
   to:"",
   days:"",
   manager:""
    
  });
  const [editFormData, setEditFormData] = useState({
    employee:"",
   leavetype:"",
   from:"",
   to:"",
   days:"",
   manager:""
    
  });
  const [editContactId, setEditContactId] = useState(null);

  const handleAddFormChange = (event) => {
    event.preventDefault();

    const fieldName = event.target.getAttribute("name");
    const fieldValue = event.target.value;

    const newFormData = { ...addFormData };
    newFormData[fieldName] = fieldValue;

    setAddFormData(newFormData);
  };
  const handleEditFormChange = (event) => {
    event.preventDefault();

    const fieldName = event.target.getAttribute("name");
    const fieldValue = event.target.value;

    const newFormData = { ...editFormData };
    newFormData[fieldName] = fieldValue;

    setEditFormData(newFormData);
  };
  const handleAddFormSubmit = (event) => {
    event.preventDefault();
    const newContact = {
        id: nanoid(),
        employee: addFormData.employee,
        leavetype: addFormData.leavetype,
        from: addFormData.from,
       to:addFormData.to,
       days:addFormData.days,
        reason:addFormData.reason,
        manager:addFormData.manager
       
      };
      const newContacts = [...contacts, newContact];
    
      setContacts(newContacts);
    };
    const handleEditFormSubmit = (event) => {
        event.preventDefault();
    
        const editedContact = {
          id: editContactId,
         employee: editFormData.employee,
        leavetype: editFormData.leavetype,
        from: editFormData.from,
       to:editFormData.to,
        days:editFormData.days,
        reason:editFormData.reason,
        manager:editFormData.manager
         
        };
    
        const newContacts = [...contacts];
    
        const index = contacts.findIndex((contact) => contact.id === editContactId);
    
        newContacts[index] = editedContact;
    
        setContacts(newContacts);
        setEditContactId(null);
      };
      const handleEditClick = (event, contact) => {
        event.preventDefault();
        setEditContactId(contact.id);
    
        const formValues = {
          employee: contact.employee,
          leavetype: contact.leavetype,
         from: contact.from,
          to:contact.to,
         days:contact.days,
         reason:contact.reason,
         manager:contact.manager
         
        };
    
        setEditFormData(formValues);
      };
    
      const handleCancelClick = () => {
        setEditContactId(null);
      };
      const handleDeleteClick = (contactId) => {
        const newContacts = [...contacts];
    
        const index = contacts.findIndex((contact) => contact.id === contactId);
    
        newContacts.splice(index, 1);
    
        setContacts(newContacts);
      };
    return(<div className="Leaves" id="Leaves">
        {/* <!-- Top Bar --> */}
    <div class="container-fluid1 py-2 icueTopBarContainer bg-white">
        <div class="row">
            <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                <div class="topBarLfContainer d-flex">
                    <div class="icueLogoContainer">
                        <img src="images/logo.png" alt="ICue Logo" class="icueLogo" />
                    </div>
                    <div class="icueSearchBox w-75 mx-3">
                        <div class="input-group ">
                            <span class="input-group-text" id="basic-addon1">
                                <i class="bi bi-search"></i>
                            </span>
                            <input type="search" id="text-search" class="form-control icueSearchBoxControl" placeholder="Enter keywords..." aria-label="Username" aria-describedby="basic-addon1"/>
                          </div>
                    </div>
                </div>
            </div>

            <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                <div class="d-flex flex-row-reverse">
                    <div class="icueProfile px-2">
                        <img src="images/avatar.png" alt="icueProfilePic" class="icueProfilePic border border-dark rounded-circle" />
                    </div>
                    <div class="position-relative px-2">
                        <a href="#" id="icueNotifyBoxHandler">
                            <i class="bi bi-bell">
                                <span class="position-absolute icueNofIndic"></span>
                            </i>
                        </a>
                        <div class="icueNotifyBox" id="icueNotifyBox">
                            Notifications
                        </div>
                    </div>

                    <div class="icueUserName fs-6 px-2">
                        Viral Patel
                    </div>

                    <div class="icueMail px-2">
                        <i class="bi bi-headset"></i> &nbsp;
                        adminhelpdesk@gmail.com
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* <!-- /Top Bar -->

    <!-- Main Body --> */}
    <div class="container-fluid my-2">
        <div class="row">
            <div class="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12 bg-white">
                <div class="icueRespSideBar d-xxl-none d-xl-none d-lg-none d-md-block d-sm-block d-block" id="icueRespSideBar">
                    <button type="button" class="btn btn-primary" id="icueRespHumburger">
                        <i class="bi bi-grid-3x3-gap"></i>
                    </button>
                    <div class="icueSideBar" id="icueSideBar">
                        <nav class="navbar icueSideNavContainer">
                            <ul class="icueNavBarList">
                                <li class="icueNavBarItem ">
                                    <a href="/Hrdashboard" class="icueNavBarLink" id="">Dashboard</a>
                                </li>
                                <li class="icueNavBarItem border-bottom-0">
                                <a href="#" class="icueNavBarLink" id="">User Management</a>
                            </li>

                            <li>
                                <ul class="icueSubNavBar">
                                    <li class="icueSubNavItem">
                                        {/* <!-- <a href="#" data-bs-toggle="modal" data-bs-target="#addid" data-bs-whatever="@getbootstrap" class="icueSubNavLink  text-capitalize">Add Member</a> --> */}
                                        <a href="#" class="icueSubNavLink  text-capitalize">Add Members</a>
                                    </li>
                                    <li class="icueSubNavItem">
                                        <a href="/ListMembers" class="icueSubNavLink  text-capitalize">List of Members</a>
                                    </li>
                                </ul>
                            </li>
                            <hr></hr>

                                <li class="icueNavBarItem border-bottom-0">
                                <a href="#" class="icueNavBarLink" id="">Leave Management</a>
                            </li>

                            <li className="active">
                                <ul class="icueSubNavBar ">
                                    <li class="icueSubNavItem" style={{marginTop:"-8px", marginLeft:"-20px" }}>
                                       
                                        <a href="/Leaves" class="icueSubNavLink  text-capitalize">Leaves</a>
                                    </li>                                   
                                </ul>                                
                            </li> 
                            <li>
                                <ul>
                                <li class="icueSubNavItem">
                                        <a href="/LeaveType" class="icueSubNavLink  text-capitalize">Leave Type</a>
                                    </li>
                                </ul>
                                </li>             
                             <hr/>
                            <li class="icueNavBarItem border-bottom-1">
                                <a href="/Holidays" class="icueNavBarLink" id="">Holidays</a>
                            </li>                            
                            <li class="icueNavBarItem border-bottom-1">
                                <a href="/Attendance" class="icueNavBarLink" id="">Attendance</a>
                            </li>                       
    
                                 
    
                                <li class="icueNavBarItem">
                                    <a href="/Department" class="icueNavBarLink" id="">Department</a>
                                </li>
    
                                <li class="icueNavBarItem">
                                    <a href="/AddRoles" class="icueNavBarLink" id="">Roles</a>
                                </li>
                                
                                <li class="icueNavBarItem border-bottom-0">
                                <a href="#" class="icueNavBarLink" id="">Payroll</a>
                            </li>
                            <li>
                                <ul class="icueSubNavBar">
                                    <li class="icueSubNavItem">
                                        <a href="/EmpSalary" class="icueSubNavLink  text-capitalize">Employee Salary</a>
                                    </li>
                                    <li class="icueSubNavItem">
                                        <a href="/Payitems" class="icueSubNavLink  text-capitalize">Payroll Items</a>
                                    </li>
                                </ul>
                                <hr></hr>
                            </li>
                                
                                <li class="icueNavBarItem border-bottom-0" style={{marginTop:"-18px"}}>
                                    <a href="#" class="icueNavBarLink" id="">Settings</a>
                                </li>
                                <li>
                                    <ul class="icueSubNavBar">
                                        <li class="icueSubNavItem">
                                            <a href="#" class="icueSubNavLink  text-capitalize">Change Password</a>
                                        </li>
                                        <li class="icueSubNavItem">
                                            <a href="#" class="icueSubNavLink  text-capitalize">Profile</a>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>

                <div class="icueNavSideBar d-xxl-block d-xl-block d-lg-block d-md-none d-sm-none d-none" id="icueNavSideBar">
                    <nav>
                        <ul class="icueNavBarList">
                            <li class="icueNavBarItem ">
                                <a href="/Hrdashboard" class="icueNavBarLink" id="">Dashboard</a>
                            </li>

                            <li class="icueNavBarItem border-bottom-0">
                                <a href="#" class="icueNavBarLink" id="">User Management</a>
                            </li>

                            <li>
                                <ul class="icueSubNavBar">
                                    <li class="icueSubNavItem"  style={{marginTop:"-8px"}}>
                                        {/* <!-- <a href="#" data-bs-toggle="modal" data-bs-target="#addid" data-bs-whatever="@getbootstrap" class="icueSubNavLink  text-capitalize">Add Member</a> --> */}
                                        <a href="#" class="icueSubNavLink  text-capitalize">Add Member</a>
                                    </li>
                                    <li class="icueSubNavItem">
                                        <a href="/ListMembers" class="icueSubNavLink  text-capitalize">List of Members</a>
                                    </li>
                                </ul>
                            </li>
                            <hr></hr>

                            <li class="icueNavBarItem border-bottom-0" style={{marginTop:"-18px"}}>
                                <a href="#" class="icueNavBarLink" id="" >Leave Management</a>
                            </li>

                            <li className="active">
                                <ul class="icueSubNavBar ">
                                    <li class="icueSubNavItem" style={{marginTop:"-8px", marginLeft:"-20px" }}>
                                       
                                        <a href="/Leaves" class="icueSubNavLink  text-capitalize">Leaves</a>
                                    </li>                                   
                                </ul>                                
                            </li> 
                            
                            <li>
                                <ul>
                                <li class="icueSubNavItem">
                                        <a href="/LeaveType" class="icueSubNavLink  text-capitalize">Leave Type</a>
                                    </li>
                                </ul>
                                </li>             
                             <hr/>
                            <li class="icueNavBarItem "style={{marginTop:"-18px"}}>
                                <a href="/Holidays" class="icueNavBarLink" id="">Holidays</a>
                            </li>   
                            <li class="icueNavBarItem ">
                                <a href="/Attendance" class="icueNavBarLink" id="">Attendance</a>
                            </li>   
                            
                            

                            
                            <li class="icueNavBarItem" >
                                <a href="/Department" class="icueNavBarLink" id="">Department</a>
                            </li>

                            <li class="icueNavBarItem">
                                <a href="/AddRoles" class="icueNavBarLink" id="">Roles</a>
                            </li>
                            

                            <li class="icueNavBarItem border-bottom-0">
                                <a href="#" class="icueNavBarLink" id="">Payroll</a>
                            </li>
                            <li>
                                <ul class="icueSubNavBar">
                                    <li class="icueSubNavItem">
                                        <a href="/EmpSalary" class="icueSubNavLink  text-capitalize">Employee Salary</a>
                                    </li>
                                    <li class="icueSubNavItem">
                                        <a href="/Payitems" class="icueSubNavLink  text-capitalize">Payroll Items</a>
                                    </li>
                                </ul>
                                <hr></hr>
                            </li>
                           
                            <li class="icueNavBarItem border-bottom-0"  style={{marginTop:"-18px"}}>
                                <a href="#" class="icueNavBarLink" id="">Settings</a>
                            </li>
                            <li>
                                <ul class="icueSubNavBar">
                                    <li class="icueSubNavItem">
                                        <a href="#" class="icueSubNavLink  text-capitalize">Change Password</a>
                                    </li>
                                    <li class="icueSubNavItem">
                                        <a href="#" class="icueSubNavLink  text-capitalize">Profile</a>
                                    </li>
                                </ul>
                                <hr></hr>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>

            <div class="col-xxl-10 col-xl-10 col-lg-10 col-md-12 col-sm-12 col-12 ">
                <main class="mainBodyContainer">
                    <div class="row my-2">
                        <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 py-2 px-3">
                            <div class="d-flex justify-content-between">
                                <h4 class="text-captalize"style={{fontFamily:"ErasDemiITC", fontWeight:"bold", marginTop:"1rem", marginLeft:"12px"}}>Leaves</h4>
                                <div class="bg-transparent p-2  rounded-3">
                                <nav style={{bsbreadcrumbdivider: 'url(&#34;"data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="8" height="8"%3E%3Cpath d="M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z" fill="currentColor"/%3E%3C/svg%3E&#34")'}} aria-label="breadcrumb">
                                    <ol class="breadcrumb mb-0 justify-content-end">
                                        <li class="breadcrumb-item bg-transparent" style={{fontFamily:"Calibri", fontSize:"bold"}}><a href="/Hrdashboard" class="text-decoration-none text-info">Dashboard</a></li>
                                        <li class="breadcrumb-item bg-transparent" style={{fontFamily:"Calibri"}}><a href="#" class="text-decoration-none text-info">Leave Management</a></li>
                                        <li class="breadcrumb-item text-muted text-capitalize bg-transparent" aria-current="page" style={{  fontSize:"bold"}}>Leaves</li>
                                    </ol>
                                </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row my-2">
                        <div class="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12 py-2 px-3">
                            <div class="bg-white p-1 shadow ">
                                <div class="row py-2">
                                    <div class="col-7">
                                        <div class="ps-2">
                                            <h3 class="fs-2 pt-1 icueTextPrimary" style={{fontFamily:"ErasDemiITC", fontSize:"12px"}}>12/60</h3> 
                                            <h5 class="fs-6 mb-0 text-capitalize icueTextPrimary" style={{fontFamily:"ErasDemiITC", fontSize:"10px"}}>today presents</h5>
                                        </div>
                                    </div>
                                    <div className="col-5 text-end">
                                        <div className="pe-2">
                                        <small><img src="images/today-presents-icon.svg " alt="today-presents" className="w-80 rounded-circle "/></small>
                                        </div>                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12 py-2 px-3">
                            <div class="bg-white p-1 shadow ">
                                <div class="row py-2">
                                    <div class="col-7">
                                        <div class="ps-2">
                                            <h3 class="fs-2 pt-1 icueTextPrimary" style={{fontFamily:"ErasDemiITC", fontSize:"12px"}}>8</h3>
                                            <h5 class="fs-6 mb-0 text-capitalize icueTextPrimary" style={{fontFamily:"ErasDemiITC", fontSize:"10px"}}>planned leaves</h5>
                                        </div>
                                    </div>
                                    <div className="col-5 text-end">
                                        <div className="pe-2">
                                        <small><img src="images/planned-leaves-icon.svg " alt="planned-leaves" className="w-80 rounded-circle "/></small>
                                        </div>                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12 py-2 px-3">
                            <div class="bg-white p-1 shadow ">
                                <div class="row py-2">
                                    <div class="col-8">
                                        <div class="ps-2">
                                            <h3 class="fs-2 pt-1 icueTextPrimary" style={{fontFamily:"ErasDemiITC", fontSize:"12px"}}>0</h3>
                                            <h5 class="fs-6 mb-0 pb-0 text-capitalize icueTextPrimary" style={{fontFamily:"ErasDemiITC", fontSize:"10px"}}>unplanned leaves</h5>
                                        </div>
                                    </div>
                                    <div className="col-4 text-end">
                                        <div className="pe-2">
                                        <small><img src="images/unplanned-leaves-icon.svg " alt="unplanned-leaves" className="w-60 rounded-circle "/></small>
                                        </div>                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12 py-2 px-3">
                            <div class="bg-white p-1 shadow ">
                                <div class="row py-2">
                                    <div class="col-8">
                                        <div class="ps-2">
                                            <h3 class="fs-2 pt-1 icueTextPrimary" style={{fontFamily:"ErasDemiITC", fontSize:"12px"}}>12</h3>
                                            <h5 class="fs-6 mb-1 text-capitalize icueTextPrimary" style={{fontFamily:"ErasDemiITC", fontSize:"10px", lineHeight:"12px"}}>pending leave requests</h5>
                                        </div>
                                    </div>
                                    <div className="col-4 text-end">
                                        <div className="pe-2">
                                        <small><img src="images/pending-leaves-icons.svg " alt="pending-leaves" className="w-60 rounded-circle "/></small>
                                        </div>                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                   
                    <div class="row my-2">
                        <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 py-1 px-3" >
                            <div class="bg-white p-2  rounded-3 shadow-sm">
                                <div class="px-2 py-3">                  
                                      <div class="container1">
                                      <div class="row">
                                             <div class=" col-xs-12 bg-white ">
                                                
                                             <div class="col-xl-9 col-lg-12 col-md-12 col-sm-12">
               
                                             <div class="icueSearchBox w-75 mx-2" >
                                                  <div class="input-group " style={{ marginLeft:"-20px"}}>
                                                      
                                                 <input type="search" class="search form-control icueSearchBoxControl" placeholder="Search Users by Name, Email or Date" aria-label="Username" aria-describedby="basic-addon1" style={{backgroundColor:"#F4F7FC", marginTop:"-15px"}} />
                                                 <div class="col-xl-3 col-lg-12 col-md-12 col-sm-12">
                                                    <a href="#" type="button" class="btn icueBgPrimary text-white pt-2  "  style={{fontFamily:"Calibri", color:"white", fontSize:"bold", height:"35px", lineHeight:"10px", marginTop:"-15px", width:"95px", marginLeft:"10px", textAlign:"center"}}>Search</a> 
                                                 </div>
                                                 
                                              </div>
                                                
                                              </div>
                                              </div>
                                              </div>
                                              </div>
                                             <br/>
                                               <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12  bg-white">
                                                    <div class="tab-content py-1 px-3 px-sm-0" id="myTabContent">
                                                        <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                                          <div class="row">              
                                                               <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12  bg-white">              
                                                               <form onSubmit={handleEditFormSubmit}>
                                            <table id="payrollTable" class="table table-hover table-striped results " width="102%" >
                                                <thead>
                                                    <tr class="text-uppercase" style={{lineHeight:"15px", fontFamily:"Calibri", fontStyle:"normal",  fontSize:"12px", backgroundColor:"#F4F7FC", textAlign:"center", marginTop:"-30px"}}>
                                                        <th class="py-3" >Employee</th>
                                                        <th  class="text-center py-3" >Leave Type</th>
                                                        <th  class="text-center py-3" >From</th>
                                                        <th  class="text-center py-3" >To</th>
                                                        <th  class="text-center py-3" >No. of Days</th>
                                                        <th  class="text-center py-3" >Reason</th>
                                                        <th  class="text-center py-3" >Manager</th>
                                                        <th  class="text-center py-3" >Leave Status</th>                                                        
                                                        <th  class="text-center py-3">Actions</th>
                                                                                                               
                                                    </tr>
                                                </thead>
                                                <tbody style={{lineHeight:"15px", fontFamily:"Calibri", fontSize:"16px"}}>
                                                    {/* <tr  style={{ border:"0"}}> */}
                                                   
                                                        {/* <td >
                                                        <img src="images/avatar.png" alt="icueProfilePic" class="icueProfilePic border border-dark rounded-circle" /> &nbsp;
                                                            Shane Law <br></br><div style={{fontSize:"12px", color:" #7D90B2", marginLeft:"38px", marginTop:"-10px"}}> Emp Code: 100001  </div></td>
                                                         <td class="text-center"> Medical Leave </td>
                                                        <td class="text-center">01-Jan-2021</td>
                                                        <td class="text-center">03-Jan-2021</td>
                                                        <td class="text-center">3 days</td>
                                                        <td class="text-center">Going to Hospital</td>
                                                        <td class="text-center">Jerry Helfer</td> 
                                                        <td >
                                                            <div class="selectWrapper">
                                                                
                                                                <select class="selectBox far">
                                                           <option value="far fa-scrubber" class="far"  >&#xf192; Declined </option>
                                                            <option  value="far fa-scrubber" class="" >&#xf192; New</option>
                                                            <option value="far fa-scrubber" class="">&#xf192; Pending</option>
                                                             <option value="far fa-scrubber" class="">&#xf192; Approved</option>                                                            
                                                        </select></div>  
                                                         
                                                         </td>                                                       
                                                        
                                                        <td class="text-center  py-0" >  <button type='button'class='btn btn-sm btn-transparent editModal text-muted' id='editMod_1'> <a href="#"><i class='bi bi-pencil'></i></a></button> 
                                                        <button type='button' class='btn btn-sm btn-transparent icueDelBtnRow ' style={{marginLeft:"-30px"}}><i class='bi bi-trash'></i></button> </td>
                                                                                                            
                                                    </tr>
                                                    <tr  style={{ border:"0", backgroundColor:"#F4F7FC"}}>
                                                        <td >
                                                        <img src="images/avatar.png" alt="icueProfilePic" class="icueProfilePic border border-dark rounded-circle" />
                                                            Nicholas Motley<br/> <div style={{fontSize:"12px", color:" #7D90B2", marginLeft:"40px", marginTop:"-10px"}}> Emp Code: 100002  </div></td>
                                                         <td class="text-center"> Hospitalization </td>
                                                        <td class="text-center">01-Feb-2021</td>
                                                        <td class="text-center">04-Feb-2021</td>
                                                        <td class="text-center">4 days</td>
                                                        <td class="text-center">Going to Hospital</td>
                                                        <td class="text-center">Iva Ryan</td>
                                                        <td >
                                                    
                                                        <div class="selectWrapper ">
                                                        <select class="selectBox far">
                                                           <option value="far fa-scrubber" class="far">&#xf192; New</option>
                                                            <option value="far fa-scrubber" class="far">&#xf192; Declined</option>
                                                            <option  value="far fa-scrubber" class="far">&#xf192; Pending</option>
                                                             <option  value="far fa-scrubber" class="far">&#xf192; Approved</option>                                                            
                                                        </select></div>   
                                                            
                                                               </td>                                                       
                                                        
                                                        <td class="text-center  py-0" >  <button type='button'class='btn btn-sm btn-transparent editModal text-muted' id='editMod_1'> <a href="#"><i class='bi bi-pencil'></i></a></button> 
                                                        <button type='button' class='btn btn-sm btn-transparent icueDelBtnRow ' style={{marginLeft:"-30px"}}><i class='bi bi-trash'></i></button> </td>
                                                                                                            
                                                    </tr>
                                                    <tr  style={{ border:"0"}}>
                                                        <td >
                                                        <img src="images/avatar.png" alt="icueProfilePic" class="icueProfilePic border border-dark rounded-circle" /> &nbsp;
                                                            Drake Erickson<div style={{fontSize:"12px", color:" #7D90B2", marginLeft:"38px", marginTop:"-10px"}}> Emp Code: 100003  </div></td>
                                                         <td class="text-center"> Maternity Leave </td>
                                                        <td class="text-center">01-Mar-2021</td>
                                                        <td class="text-center">02-Mar-2021</td>
                                                        <td class="text-center">2 days</td>
                                                        <td class="text-center">Injury</td>
                                                        <td class="text-center">Kurt Bates</td> 
                                                        <td >
                                                            <div class="selectWrapper ">
                                                        <select class="selectBox far">
                                                        <option  value="far fa-scrubber" class="far">&#xf192; Pending</option>
                                                           <option  value="far fa-scrubber" class="far">&#xf192; Declined</option>
                                                            <option  value="far fa-scrubber" class="far">&#xf192; New</option>                                                           
                                                             <option  value="far fa-scrubber" class="far">&#xf192; Approved</option>                                                            
                                                        </select></div>    </td>                                                       
                                                        
                                                        <td class="text-center  py-0" >  <button type='button'class='btn btn-sm btn-transparent editModal text-muted' id='editMod_1'> <a href="#"><i class='bi bi-pencil'></i></a></button> 
                                                        <button type='button' class='btn btn-sm btn-transparent icueDelBtnRow text-muted' id='remMod_1'style={{marginLeft:"-30px"}}><i class='bi bi-trash'></i></button> </td>
                                                                                                            
                                                    </tr>
                                                    <tr  style={{ border:"0", backgroundColor:"#F4F7FC"}}>
                                                        <td >
                                                        <img src="images/avatar.png" alt="icueProfilePic" class="icueProfilePic border border-dark rounded-circle" /> &nbsp;
                                                            Wally Mann <div style={{fontSize:"12px", color:" #7D90B2", marginLeft:"40px", marginTop:"-10px"}}> Emp Code: 100004  </div></td>
                                                         <td class="text-center"> Casual Leave </td>
                                                        <td class="text-center">01-Apr-2021</td>
                                                        <td class="text-center">10-Apr-2021</td>
                                                        <td class="text-center">10 days</td>
                                                        <td class="text-center">Vaccation</td>
                                                        <td class="text-center">David Elson</td>
                                                        <td >
                                                    
                                                        <div class="selectWrapper ">
                                                        <select class="selectBox far">
                                                        <option  value="far fa-scrubber" class="far">&#xf192; Approved</option>
                                                           <option  value="far fa-scrubber" class="far">&#xf192; New</option>
                                                            <option  value="far fa-scrubber" class="far">&#xf192; Declined</option>
                                                            <option  value="far fa-scrubber" class="far">&#xf192; Pending</option>
                                                                                                                        
                                                        </select></div>   
                                                            
                                                               </td>                                                       
                                                        
                                                        <td class="text-center  py-0" >  <button type='button'class='btn btn-sm btn-transparent editModal text-muted' id='editMod_1'> <a href="#"><i class='bi bi-pencil'></i></a></button> 
                                                        <button type='button' class='btn btn-sm btn-transparent icueDelBtnRow text-muted' id='remMod_1'style={{marginLeft:"-30px"}}><i class='bi bi-trash'></i></button> </td>
                                                                                                            
                                                    </tr>
                                                    <tr  style={{ border:"0"}}>
                                                        <td >
                                                        <img src="images/avatar.png" alt="icueProfilePic" class="icueProfilePic border border-dark rounded-circle" /> 
                                                           Howard Savage<div style={{fontSize:"12px", color:" #7D90B2", marginLeft:"38px", marginTop:"-10px"}}> Emp Code: 100005  </div></td>
                                                         <td class="text-center"> Casual Leave </td>
                                                        <td class="text-center">01-May-2021</td>
                                                        <td class="text-center">08-May-2021</td>
                                                        <td class="text-center">8 days</td>
                                                        <td class="text-center">Pets emergencies</td>
                                                        <td class="text-center">Lorri Warf</td> 
                                                        <td >
                                                            <div class="selectWrapper ">
                                                        <select class="selectBox far ">
                                                        <option  value="far fa-scrubber" class="far">&#xf192; New</option>
                                                        <option  value="far fa-scrubber" class="far">&#xf192; Pending</option>
                                                           <option  value="far fa-scrubber" class="far">&#xf192; Declined</option>                                                            
                                                             <option  value="far fa-scrubber" class="far">&#xf192; Approved</option>                                                            
                                                        </select></div>    </td>                                                       
                                                        
                                                        <td class="text-center  py-0" >  <button type='button'class='btn btn-sm btn-transparent editModal text-muted' id='editMod_1'> <a href="#"><i class='bi bi-pencil'></i></a></button> 
                                                        <button type='button' class='btn btn-sm btn-transparent icueDelBtnRow text-muted' id='remMod_1'style={{marginLeft:"-30px"}}><i class='bi bi-trash'></i></button> </td>
                                                                                                            
                                                    </tr>
                                                    <tr  style={{ border:"0", backgroundColor:"#F4F7FC"}}>
                                                        <td >
                                                        <img src="images/avatar.png" alt="icueProfilePic" class="icueProfilePic border border-dark rounded-circle" /> &nbsp;
                                                            Ford Becker <div style={{fontSize:"12px", color:" #7D90B2", marginLeft:"40px", marginTop:"-10px"}}> Emp Code: 100006  </div></td>
                                                         <td class="text-center"> Paternity Leave </td>
                                                        <td class="text-center">01-Jun-2021</td>
                                                        <td class="text-center">05-Jun-2021</td>
                                                        <td class="text-center">5 days</td>
                                                        <td class="text-center">Deaths and Funerals</td>
                                                        <td class="text-center">Paula Mora</td>
                                                        <td >
                                                    
                                                        <div class="selectWrapper ">
                                                        <select class="selectBox far">
                                                        <option  value="far fa-scrubber" class="far">&#xf192; New</option>
                                                        <option  value="far fa-scrubber" class="far">&#xf192; Approved</option>                                                          
                                                            <option  value="far fa-scrubber" class="far">&#xf192; Declined</option>
                                                            <option  value="far fa-scrubber" class="far">&#xf192;Pending</option>
                                                                                                                        
                                                        </select></div>   
                                                            
                                                               </td>                                                       
                                                        
                                                        <td class="text-center  py-0" >  <button type='button'class='btn btn-sm btn-transparent editModal text-muted' id='editMod_1'> <a href="#"><i class='bi bi-pencil'></i></a></button> 
                                                        <button type='button' class='btn btn-sm btn-transparent icueDelBtnRow text-muted' id='remMod_1'style={{marginLeft:"-30px"}}><i class='bi bi-trash'></i></button> </td>
                                                                                                            
                                                    </tr>
                                                     <tr  style={{ border:"0"}}>
                                                        <td >
                                                        <img src="images/avatar.png" alt="icueProfilePic" class="icueProfilePic border border-dark rounded-circle" /> &nbsp;
                                                           Byron  <br/><div style={{fontSize:"12px", color:" #7D90B2", marginLeft:"38px", marginTop:"-10px"}}> Emp Code: 100007  </div></td>
                                                         <td class="text-center"> Casual Leave </td>
                                                        <td class="text-center">01-Jul-2021</td>
                                                        <td class="text-center">03-Jul-2021</td>
                                                        <td class="text-center">3 days</td>
                                                        <td class="text-center">Tragedy at home</td>
                                                        <td class="text-center">John Dukes</td> 
                                                        <td >
                                                            <div class="selectWrapper ">
                                                        <select class="selectBox far">
                                                        <option  value="far fa-scrubber" class="far">&#xf192; New</option>
                                                        <option  value="far fa-scrubber" class="far">&#xf192; Pending</option>
                                                           <option  value="far fa-scrubber" class="far">&#xf192; Declined</option>                                                                                   
                                                             <option  value="far fa-scrubber" class="far">&#xf192; Approved</option>                                                            
                                                        </select></div>    </td>                                                       
                                                        
                                                        <td class="text-center  py-0" >  <button type='button'class='btn btn-sm btn-transparent editModal text-muted' id='editMod_1'> <a href="#"><i class='bi bi-pencil'></i></a></button> 
                                                        <button type='button' class='btn btn-sm btn-transparent icueDelBtnRow text-muted' id='remMod_1'style={{marginLeft:"-30px"}}><i class='bi bi-trash'></i></button> </td>
                                                                                                          
                                                    </tr> 
                                                    <tr  style={{ border:"0", backgroundColor:"#F4F7FC"}}>
                                                        <td >
                                                        <img src="images/avatar.png" alt="icueProfilePic" class="icueProfilePic border border-dark rounded-circle" /> &nbsp;
                                                           Shane Law <div style={{fontSize:"12px", color:" #7D90B2", marginLeft:"40px", marginTop:"-10px"}}> Emp Code: 100008  </div></td>
                                                         <td class="text-center"> Loss of Pay </td>
                                                        <td class="text-center">01-Jan-2021</td>
                                                        <td class="text-center">03-Jan-2021</td>
                                                        <td class="text-center">3 days</td>
                                                        <td class="text-center">Personal reasons</td>
                                                        <td class="text-center">Kathy Pacheco</td>
                                                        <td >
                                                    
                                                        <div class="selectWrapper ">
                                                        <select class="selectBox far">
                                                        <option  value="far fa-scrubber" class="far">&#xf192; Approved</option>
                                                        <option  value="far fa-scrubber" class="far">&#xf192; New</option>                                                                
                                                            <option  value="far fa-scrubber" class="far">&#xf192; Declined</option>
                                                            <option  value="far fa-scrubber" class="far">&#xf192; Pending</option>
                                                                                                                        
                                                        </select></div>   
                                                            
                                                               </td>                                                       
                                                        
                                                        <td class="text-center  py-0" >  <button type='button'class='btn btn-sm btn-transparent editModal text-muted' id='editMod_1'> <a href="#"><i class='bi bi-pencil'></i></a></button> 
                                                        <button type='button' class='btn btn-sm btn-transparent icueDelBtnRow text-muted' id='remMod_1'style={{marginLeft:"-30px"}}><i class='bi bi-trash'></i></button> </td>
                                                                                                            
                                                    </tr> 
                                                    <tr  style={{ border:"0"}}>
                                                        <td >
                                                        <img src="images/avatar.png" alt="icueProfilePic" class="icueProfilePic border border-dark rounded-circle" />
                                                           Nicholas Motley <br/><div style={{fontSize:"12px", color:" #7D90B2", marginLeft:"38px", marginTop:"-10px"}}> Emp Code: 100009  </div></td>
                                                         <td class="text-center"> Casual Leave </td>
                                                        <td class="text-center">01-Feb-2021</td>
                                                        <td class="text-center">05-Feb-2021</td>
                                                        <td class="text-center">5 days</td>
                                                        <td class="text-center">Personal reasons</td>
                                                        <td class="text-center">James Hall</td> 
                                                        <td >
                                                            <div class="selectWrapper ">
                                                        <select class="selectBox far ">
                                                        <option  value="far fa-scrubber" class="far">&#xf192; Approved</option> 
                                                        <option  value="far fa-scrubber" class="far">&#xf192; New</option>
                                                        <option  value="far fa-scrubber" class="far">&#xf192; Pending</option>
                                                           <option  value="far fa-scrubber" class="far">&#xf192; Declined</option>                                                                                                                      
                                                                                                                       
                                                        </select></div>    </td>                                                       
                                                        
                                                        <td class="text-center  py-0" >  <button type='button'class='btn btn-sm btn-transparent editModal text-muted' id='editMod_1'> <a href="#"><i class='bi bi-pencil'></i></a></button> 
                                                        <button type='button' class='btn btn-sm btn-transparent icueDelBtnRow text-muted' id='remMod_1'style={{marginLeft:"-30px"}}><i class='bi bi-trash'></i></button> </td>
                                                                                                          
                                                    </tr> 
                                                    <tr  style={{ border:"0", backgroundColor:"#F4F7FC"}}>
                                                        <td >
                                                        <img src="images/avatar.png" alt="icueProfilePic" class="icueProfilePic border border-dark rounded-circle" />
                                                          Dracke Erickson <div style={{fontSize:"12px", color:" #7D90B2", marginLeft:"40px", marginTop:"-10px"}}> Emp Code: 100010  </div></td>
                                                         <td class="text-center">Casual Leave </td>
                                                        <td class="text-center">01-Mar-2021</td>
                                                        <td class="text-center">01-Mar-2021</td>
                                                        <td class="text-center">1 days</td>
                                                        <td class="text-center">For unforeseen reaons</td>
                                                        <td class="text-center">Cornico McCOy</td>
                                                        <td >
                                                    
                                                        <div class="selectWrapper ">
                                                        <select class="selectBox far">
                                                        <option  value="far fa-scrubber" class="far">&#xf192; Approved</option>
                                                        <option  value="far fa-scrubber" class="far">&#xf192; New</option>                                                                                                                  
                                                            <option  value="far fa-scrubber" class="far">&#xf192; Declined</option>
                                                            <option  value="far fa-scrubber" class="far">&#xf192; Pending</option>
                                                                                                                        
                                                        </select></div>   
                                                            
                                                               </td>                                                       
                                                        
                                                        <td class="text-center  py-0" >  <button type='button'class='btn btn-sm btn-transparent editModal text-muted' id='editMod_1'> <a href="#"><i class='bi bi-pencil'></i></a></button> 
                                                        <button type='button' class='btn btn-sm btn-transparent icueDelBtnRow text-muted' id='remMod_1'style={{marginLeft:"-30px"}}><i class='bi bi-trash'></i></button> </td>
                                                                                                            
                                                    </tr>  */}
                                                    

{contacts.map((contact) => (
              <Fragment>
                  
                {editContactId === contact.id ? (
                  <EditableRow
                    editFormData={editFormData}
                    handleEditFormChange={handleEditFormChange}
                    handleCancelClick={handleCancelClick}
                  />
                ) : (
                  <ReadOnlyRow
                    contact={contact}
                    handleEditClick={handleEditClick}
                    handleDeleteClick={handleDeleteClick}
                  />
                )}
              </Fragment>
              
            ))}
            
                                                  
                                                   
                                                </tbody>
                                            </table>
                                            </form>
                                        </div>
                                    </div>   
                                     
                                   </div>
                                   

</div>
</div>
</div>
</div>

       
    </div>
</div>
</div>
</main>
</div>
        </div>
    </div>
</div>
    )}
    export default Leaves;