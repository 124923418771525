import React, { useRef } from 'react'
import './ComingSoon.css'
import emailjs from 'emailjs-com'

//Components
import Input from './Components/Input'
import NavBar from './Components/NavBar'

function ComingSoon() {

    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_9lujfud', 'template_oa292ja', e.target, 'user_OnZwHe45Mq6N7juZxG8HY')
            .then((result) => {
                alert('Email Send Successfully')
            }, (error) => {
                console.log(error);
            });

        e.target.reset()
    };




    return (
        <form ref={form} onSubmit={sendEmail}>
            <NavBar />
            <div className="container header-img" style={{ marginTop: '9rem', marginLeft: '8rem' }}>
                <div className="row">

                    <div className="col-lg-6">
                        <div className="text-container">
                            <h1 className="h1-heading">We're Coming Soon...</h1>
                            <p className="sub-heading">We'er working hard to give you the best<br /> experience and service!</p>

                            <div style={{ marginTop: '4rem' }}>
                                <Input label={"Enter Your Name"} type="text" name='name' />
                                <Input label={"Enter Your Contact"} type="text" name='contact' />
                                <Input label={"Enter Your Email id"} type="email" name='user_email' />
                            </div>
                        </div>

                        <div class='notifybtn'>
                            <input type="submit" value="Get Notify" />
                        </div>
                    </div>

                    <div className="col-lg-6">
                        <div className="image-container">
                            <img className="img-fluid img-place" src="images/Group 309.png" alt="alternative" />
                        </div>
                    </div>
                </div>

            </div>
            <img className='footer-image' src="./images/Banner.png" alt='' />
            <div className='address'>
                <p className='paragraph'>Address: </p>
                <p className='subParagraph'>1851 Lynch Street, New Berlin, WI 53151</p>
            </div>
        </form>
    )
}

export default ComingSoon
