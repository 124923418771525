import logo from './logo.svg';
import './App.css';
import AppRoutes from './components/Routes/AppRoutes';
import { ToastContainer } from 'react-toastify';
import "react-datepicker/dist/react-datepicker.css";
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <div className="Appp">
     <AppRoutes/>
     <ToastContainer />
    </div>
  );
}

export default App;
