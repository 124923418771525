import React,{useEffect, useState} from 'react';
import axios from 'axios'
import './SuperDashboard.css';
//import { makeStyles } from '@material-ui/core';

export default function SuperDashboard(){
    const [carleads, setCarLeads] = useState([]);
    const[bikeleads, setBikeLeads]=useState([]);
    const[total, setTotal]=useState([]);   
    useEffect(() => {
        axios.get('https://policiecue-api.mobileprogramming.net/motor/carleadsCount')
            .then(res => {
                console.log(res)
                setCarLeads(res.data.data)
            })
            axios.get('https://policiecue-api.mobileprogramming.net/motor/bikeleadsCount')
            .then(res=>{
                console.log(res)
                setBikeLeads(res.data.data)
            })
            axios.get('https://policiecue-api.mobileprogramming.net/lead/leadsCount')
            .then(res=>{
                console.log(res)
                setTotal(res.data.data)
            })  
            .catch(err=>{
                console.log(err)
            })
    }, {})

    return(<div className="Superdashboard" id="Superdashboard">
        {/* <!-- Top Bar --> */}
    <div class="container-fluid1 py-2 icueTopBarContainer bg-white">
        <div class="row">
            <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                <div class="topBarLfContainer d-flex">
                    <div class="icueLogoContainer">
                        <img src="images/logo.png" alt="ICue Logo" class="icueLogo" />
                    </div>
                    <div class="icueSearchBox w-75 mx-3">
                        <div class="input-group ">
                            <span class="input-group-text" id="basic-addon1">
                                <i class="bi bi-search"></i>
                            </span>
                            <input type="search" id="text-search" class="form-control icueSearchBoxControl" placeholder="Enter keywords..." aria-label="Username" aria-describedby="basic-addon1"/>
                          </div>
                    </div>
                </div>
            </div>

            <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                <div class="d-flex flex-row-reverse">
                    <div class="icueProfile px-2">
                        <img src="images/avatar.png" alt="icueProfilePic" class="icueProfilePic border border-dark rounded-circle" />
                    </div>
                    <div class="position-relative px-2">
                        <a href="#" id="icueNotifyBoxHandler">
                            <i class="bi bi-bell">
                                <span class="position-absolute icueNofIndic"></span>
                            </i>
                        </a>
                        <div class="icueNotifyBox" id="icueNotifyBox">
                            Notifications
                        </div>
                    </div>

                    <div class="icueUserName fs-6 px-2">
                        Viral Patel
                    </div>

                    <div class="icueMail px-2">
                        <i class="bi bi-headset"></i> &nbsp;
                        adminhelpdesk@gmail.com
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* <!-- /Top Bar -->

    <!-- Main Body --> */}
    <div class="container-fluid my-2">
        <div class="row">
            <div class="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12 bg-white">
                <div class="icueRespSideBar d-xxl-none d-xl-none d-lg-none d-md-block d-sm-block d-block" id="icueRespSideBar">
                    <button type="button" class="btn btn-primary" id="icueRespHumburger">
                        <i class="bi bi-grid-3x3-gap"></i>
                    </button>
                    <div class="icueSideBar" id="icueSideBar">
                        <nav class="navbar icueSideNavContainer">
                            <ul class="icueNavBarList">
                                <li class="icueNavBarItem active">
                                    <a href="/Superdashboard" class="icueNavBarLink" id="">Dashboard</a>
                                </li>

                                <li class="icueNavBarItem">
                                    <a href="#" class="icueNavBarLink" id="">User Management</a>
                                </li>

                                <li class="icueNavBarItem">
                                    <a href="/Hrdashboard" class="icueNavBarLink" id="">HRMS</a>
                                </li>
    
                                {/* <!-- <li class="icueNavBarItem icueNavDot">
                                    <a href="userManagement.html" class="icueNavBarLink" id="">LMS</a>
                                    <ul class="icueSubNavBar">
                                        <li class="icueSubNavItem">
                                            <a href="#" class="icueSubNavLink  text-capitalize">Retail</a>
                                        </li>
                                        <li class="icueSubNavItem">
                                            <a href="#" class="icueSubNavLink  text-capitalize">Corporate</a>
                                        </li>
                                    </ul>
                                </li> --> */}

                                <li class="icueNavBarItem border-bottom-0">
                                    <a href="/lms" class="icueNavBarLink" id="">LMS</a>
                                </li>

                                <li>
                                    <ul class="icueSubNavBar">
                                        <li class="icueSubNavItem">
                                            {/* <!-- <a href="#" data-bs-toggle="modal" data-bs-target="#addid" data-bs-whatever="@getbootstrap" class="icueSubNavLink  text-capitalize">Retail</a> --> */}
                                            <a href="#" class="icueSubNavLink  text-capitalize">Retail</a>
                                        </li>
                                        <li class="icueSubNavItem">
                                            <a href="/leads" class="icueSubNavLink  text-capitalize">Corporate</a>
                                        </li>
                                    </ul>
                                </li>
                                <hr></hr>
                              

                                <li class="icueNavBarItem" style={{marginTop:"-0.625rem"}}>
                                    <a href="#" class="icueNavBarLink text-capitalize" id="" >Claim Management</a>
                                </li>
                                <li>
                                    <ul class="icueSubNavBar">
                                        <li class="icueSubNavItem">
                                            <a href="#" class="icueSubNavLink  text-capitalize">Retail</a>
                                        </li>
                                        <li class="icueSubNavItem">
                                            <a href="#" class="icueSubNavLink  text-capitalize">Corporate</a>
                                        </li>
                                        <li class="icueSubNavItem">
                                            <a href="#" class="icueSubNavLink  text-capitalize">Corporate</a>
                                        </li>
                                        <li class="icueSubNavItem">
                                            <a href="#" class="icueSubNavLink  text-capitalize">Corporate</a>
                                        </li>
                                    </ul>
                                </li>
    
                                <li class="icueNavBarItem">
                                    <a href="/Department" class="icueNavBarLink" id="">Department</a>
                                </li>
    
                                <li class="icueNavBarItem">
                                    <a href="/AddRoles" class="icueNavBarLink" id="">Roles</a>
                                </li>
    
                              
                                <li class="icueNavBarItem border-bottom-0">
                                    <a href="#" class="icueNavBarLink" id="">Settings</a>
                                </li>
                                <li>
                                    <ul class="icueSubNavBar">
                                        <li class="icueSubNavItem">
                                            <a href="#" class="icueSubNavLink  text-capitalize">Change Password</a>
                                        </li>
                                        <li class="icueSubNavItem">
                                            <a href="#" class="icueSubNavLink  text-capitalize">Profile</a>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>

                <div class="icueNavSideBar d-xxl-block d-xl-block d-lg-block d-md-none d-sm-none d-none" id="icueNavSideBar">
                    <nav>
                        <ul class="icueNavBarList">
                            <li class="icueNavBarItem active">
                                <a href="/Superdashboard" class="icueNavBarLink" id="">Dashboard</a>
                            </li>

                            <li class="icueNavBarItem">
                                <a href="#" class="icueNavBarLink" id="">User Management</a>
                            </li>

                            <li class="icueNavBarItem">
                                <a href="/Hrdashboard" class="icueNavBarLink" id="">HRMS</a>
                            </li>

                            
                            <li class="icueNavBarItem border-bottom-0">
                                <a href="/lms" class="icueNavBarLink" id="">LMS</a>
                            </li>

                            <li>
                                <ul class="icueSubNavBar">
                                    <li class="icueSubNavItem">
                                        {/* <!-- <a href="#" data-bs-toggle="modal" data-bs-target="#addid" data-bs-whatever="@getbootstrap" class="icueSubNavLink  text-capitalize">Add Member</a> --> */}
                                        <a href="#" class="icueSubNavLink  text-capitalize">Retail</a>
                                    </li>
                                    <li class="icueSubNavItem">
                                        <a href="/leads" class="icueSubNavLink  text-capitalize">Corporate</a>
                                    </li>
                                </ul>
                            </li>
                            <hr></hr>
                           
                            <li class="icueNavBarItem border-bottom-0" style={{marginTop:"-0.625rem"}}>
                                <a href="#" class="icueNavBarLink" id="">Claim Management</a>
                            </li>

                            <li>
                                <ul class="icueSubNavBar">
                                    <li class="icueSubNavItem">
                                        <a href="#" class="icueSubNavLink  text-capitalize">Life Insurance</a>
                                    </li>
                                    <li class="icueSubNavItem">
                                        <a href="#" class="icueSubNavLink  text-capitalize">Car Insurance</a>
                                    </li>
                                    <li class="icueSubNavItem">
                                        <a href="#" class="icueSubNavLink  text-capitalize">Two-wheeler Insurance</a>
                                    </li>
                                    <li class="icueSubNavItem">
                                        <a href="#" class="icueSubNavLink  text-capitalize">Marine Insurance</a>
                                    </li>
                                </ul>
                            </li>
                            <hr></hr>
                            <li class="icueNavBarItem" style={{marginTop:"-0.813rem"}}>
                                <a href="/Department" class="icueNavBarLink" id="">Department</a>
                            </li>

                            <li class="icueNavBarItem">
                                <a href="/AddRoles" class="icueNavBarLink" id="">Roles</a>
                            </li>


                            <li class="icueNavBarItem border-bottom-0">
                                <a href="#" class="icueNavBarLink" id="">Settings</a>
                            </li>
                            <li>
                                <ul class="icueSubNavBar">
                                    <li class="icueSubNavItem">
                                        <a href="#" class="icueSubNavLink  text-capitalize">Change Password</a>
                                    </li>
                                    <li class="icueSubNavItem">
                                        <a href="#" class="icueSubNavLink  text-capitalize">Profile</a>
                                    </li>
                                </ul>
                                <hr></hr>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>

            <div class="col-xxl-10 col-xl-10 col-lg-10 col-md-12 col-sm-12 col-12 ">
                <main class="mainBodyContainer">
                    <div class="row my-2">
                        <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 py-2 px-3">
                            <div class="bg-transparent p-2  rounded-3">
                            <nav style={{bsbreadcrumbdivider: 'url("data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="8" height="8"%3E%3Cpath d="M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z" fill="currentColor"/%3E%3C/svg%3E")'}} aria-label="breadcrumb">
                                <ol class="breadcrumb mb-0 justify-content-end bg-transparent">
                                    <li class="breadcrumb-item bg-transparent"><a href="/Superdashboard" class="text-decoration-none text-info" style={{fontFamily:'Calibri'}}>Dashboard</a></li>
                                    {/* <li class="breadcrumb-item text-muted" aria-current="page">LMS</li> */}
                                </ol>
                              </nav>
                            </div>
                        </div>
                    </div>

                    <div class="row my-2">
                        <div class="col-xxl-4 col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 py-2 px-3">
                            <div class="bg-white p-1 shadow icueBorBottomPrimary"style={{ marginLeft:"0.8rem"}}>
                                <div class="row py-2">
                                    <div class="col-7">
                                        <div class="ps-2">
                                            <h2 class="fs-1 pt-1 icueTextPrimary" style={{fontFamily:"ErasDemiITC", fontSize:"30px"}}>1500</h2><br/>
                                            <h4 class="fs-5 mb-0 text-capitalize icueTextLight" style={{fontFamily:"ErasDemiITC"}}>total employees</h4>
                                        </div>
                                    </div>
                                    <div class="col-5 text-end">
                                        <div class="pe-2">
                                            <div class="icueBgLightGrey icueTextPrimary rounded-circle fs-1 d-inline-block p-3 text-center ">
                                                <i class="fas fa-users "></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xxl-4 col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 py-2 px-3">
                            <div class="bg-white p-1 shadow icueBorBottomLightInfo">
                                <div class="row py-2">
                                    <div class="col-7">
                                        <div class="ps-2">
                                            <h2 class="fs-1 pt-1 icueTextInfoLight" style={{fontFamily:"ErasDemiITC", fontSize:"30px"}}>120</h2><br/>
                                            <h4 class="fs-5 mb-0 text-capitalize icueTextLight" style={{fontFamily:"ErasDemiITC"}}>active employees</h4>
                                        </div>
                                    </div>
                                    <div class="col-5 text-end">
                                        <div class="pe-2">
                                            <div class="icueBgLightGrey icueTextInfoLight rounded-circle fs-1 d-inline-block p-3 text-center ">
                                                <i class="fas fa-users "></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>  
                        <div class="col-xxl-4 col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 py-2 px-3">
                            <div class="bg-transparent p-1"  >
                                <div class="row py-2">
                                    {/* <div class="col-7">
                                        <div class="ps-2">
                                            <h2 class="fs-1 pt-1 icueTextInfoLight" style={{fontFamily:"ErasDemiITC", fontSize:"30px"}}>120</h2>
                                            <h4 class="fs-5 mb-2 text-capitalize icueTextLight" style={{fontFamily:"ErasDemiITC"}}>active employees</h4>
                                        </div>
                                    </div> */}
                                    {/* <div class="col-5 text-end">
                                        <div class="pe-2">
                                            <div class="icueBgLightGrey icueTextInfoLight rounded-circle fs-1 d-inline-block p-3 text-center ">
                                                <i class="fas fa-users "></i>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>  
                                              
                    </div>

                    <div class="row my-2">
                        <div class="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12 py-2 px-3" >
                            <div class="icueBgPrimary p-1 rounded-3 shadow text-white" style={{ marginLeft:"0.8rem"}}>
                                <div class="row py-2">
                                    <div class="col-7">
                                        <div class="ps-2">
                                            <h2 class="fs-1" style={{color:"white", fontFamily:"ErasDemiITC"}}>{total.totalallLeads}</h2><br/>
                                            <h4 class="fs-5 mb-0 text-capitalize" style={{color:"white", fontFamily:"ErasDemiITC" }}>total leads</h4>
                                        </div>
                                    </div>
                                    <div class="col-5 text-end">
                                        <div class="pe-2">
                                            <div class="bg-white icon1 rounded-circle fs-1 d-inline-block p-3 text-center">
                                                <i class="fas fa-chart-bar"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12 py-2 px-3">
                            <div class="icueBgPrimary p-1 rounded-3 shadow text-white">
                                <div class="row py-2">
                                    <div class="col-7">
                                        <div class="ps-2">
                                            <h2 class="fs-1" style={{color:"white", fontFamily:"ErasDemiITC"}}>{total.wonallLeads}</h2><br/>
                                            {/* {carleads.wonCarLeads + bikeleads.wonBikeLeads} */}
                                            <h4 class="fs-5 mb-0 text-capitalize" style={{color:"white", fontFamily:"ErasDemiITC"}}>won leads</h4>
                                        </div>
                                    </div>
                                    <div class="col-5 text-end">
                                        <div class="pe-2">
                                            <div class="bg-white icon2 rounded-circle fs-1 d-inline-block p-3 text-center">
                                                <i class="fas fa-chart-bar"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12 py-2 px-3">
                            <div class="icueBgPrimary p-1 rounded-3 shadow text-white">
                                <div class="row py-2">
                                    <div class="col-7">
                                        <div class="ps-2">
                                            <h2 class="fs-1" style={{color:"white",fontFamily:"ErasDemiITC"}}>{total.lostallLeads}</h2><br/>
                                            <h4 class="fs-5 mb-0 text-capitalize"style={{color:"white", fontFamily:"ErasDemiITC"}}>lost leads</h4>
                                        </div>
                                    </div>
                                    <div class="col-5 text-end">
                                        <div class="pe-2">
                                            <div class="bg-white icon3 rounded-circle fs-1 d-inline-block p-3 text-center">
                                                <i class="fas fa-chart-bar"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12 py-2 px-3">
                            <div class="icueBgPrimary p-1 rounded-3 shadow text-white" style={{ marginRight:"0.8rem"}}>
                                <div class="row py-2">
                                    <div class="col-7">
                                        <div class="ps-2">
                                            <h2 class="fs-1"style={{color:"white",  fontFamily:"ErasDemiITC"}}>{total.inProgressallLeads}</h2><br/>
                                            <h4 class="fs-5 mb-0 text-capitalize"style={{color:"white", fontFamily:"ErasDemiITC"}}>in progress</h4>
                                        </div>
                                    </div>
                                    <div class="col-5 text-end">
                                        <div class="pe-2">
                                            <div class="bg-white icon4 rounded-circle fs-1 d-inline-block p-3 text-center">
                                                <i class="fas fa-chart-bar"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row my-2">
                        <div class="col-xxl-4 col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 py-2 px-3">
                            <div class="bg-white p-1 shadow icueBorBottomPrimary" style={{ marginLeft:"0.8rem"}}>
                                <div class="row py-2">
                                    <div class="col-7">
                                        <div class="ps-2">
                                            <h2 class="fs-1 icueTextPrimary"style={{fontFamily:"ErasDemiITC"}}>1200</h2><br/>
                                            <h4 class="fs-5 mb-0 text-capitalize icueTextLight"style={{fontFamily:"ErasDemiITC"}}>life insurance</h4>
                                        </div>
                                    </div>
                                    <div class="col-5 text-end">
                                        <div class="pe-2">
                                            <div class="icueBgLightGrey icueTextPrimary rounded-circle fs-1 d-inline-block p-3 text-center ">
                                                <i class="fas fa-hand-holding-heart "></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-xxl-4 col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 py-2 px-3">
                            <div class="bg-white p-1 shadow icueBorBottomPrimary">
                                <div class="row py-2">
                                    <div class="col-7">
                                        <div class="ps-2">
                                            <h2 class="fs-1 icueTextPrimary"style={{fontFamily:"ErasDemiITC"}}>{carleads.totalCarLeads}</h2><br/>
                                            
                                            {/* <ul>{carleads.map(car => <li key={car.id}>{car.totalCarLeads}</li>)}</ul> */}
                                            <h4 class="fs-5 mb-0 text-capitalize icueTextLight" style={{fontFamily:"ErasDemiITC"}}>car insurance</h4>
                                        </div>
                                    </div>
                                    <div class="col-5 text-end">
                                        <div class="pe-2">
                                            <div class="icueBgLightGrey icueTextPrimary rounded-circle fs-1 d-inline-block p-3 text-center ">
                                                <i class="fas fa-car"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-xxl-4 col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 py-2 px-3">
                            <div class="bg-white p-1 shadow icueBorBottomPrimary" style={{ marginRight:"0.8rem"}}>
                                <div class="row py-2">
                                    <div class="col-7">
                                        <div class="ps-2">
                                            <h2 class="fs-1 icueTextPrimary" style={{fontFamily:"ErasDemiITC"}}>{bikeleads.totalBikeLeads}</h2><br/>
                                            <h4 class="fs-5 mb-0 text-capitalize icueTextLight" style={{fontFamily:"ErasDemiITC"}}>bike insurance</h4>
                                        </div>
                                    </div>
                                    <div class="col-5 text-end">
                                        <div class="pe-2">
                                            <div class="icueBgLightGrey icueTextPrimary rounded-circle fs-1 d-inline-block p-3 text-center ">
                                                <i class="fas fa-biking "></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row my-2">
                        <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 py-2 px-3">
                            <div class="bg-white p-4 rounded-3 shadow-sm" id="icueRfqTable" style={{ marginLeft:"0.8rem", marginRight:"0.8rem"}}>
                            <h4 class="icueTextPrimary pb-2" style={{fontFamily:"ErasDemiITC", fontWeight:"bold", fontSize:"28px"}}>Today's RFQ</h4>
                                <table class="table table-striped table-hover" id="superadmintable" style={{fontFamily:"Inter", borderLeft:"transparent"}}>
                                    <thead >
                                        <tr class="text-uppercase" style={{fontFamily:"Calibri", border:"0", fontSize:"18px"}}>
                                            <th class="py-3">insurance company name</th>
                                            <th class="text-center py-3">total rfq sent</th>
                                            <th class="text-center py-3">total response</th>
                                            <th class="text-center py-3">awaiting response</th>
                                        </tr>
                                    </thead>
                                    <tbody class="text-capitalize">
                                        <tr style={{ border:"0"}} >
                                            <td>HDFC Agro</td>
                                            <td class="text-center">50</td>
                                            <td class="text-center">20</td>
                                            <td class="text-center">30</td>
                                        </tr>
                                        <tr style={{ border:"0"}}>
                                            <td>ICICI Term Life</td>
                                            <td class="text-center">70</td>
                                            <td class="text-center">40</td>
                                            <td class="text-center">30</td>
                                        </tr>
                                        <tr style={{ border:"0"}}>
                                            <td>royal sundaram</td>
                                            <td class="text-center">35</td>
                                            <td class="text-center">10</td>
                                            <td class="text-center">25</td>
                                        </tr>
                                        <tr style={{ border:"0"}}>
                                            <td>sompa</td>
                                            <td class="text-center">25</td>
                                            <td class="text-center">18</td>
                                            <td class="text-center">7</td>
                                        </tr>
                                        <tr style={{ border:"0"}}>
                                            <td>SBI general</td>
                                            <td class="text-center">80</td>
                                            <td class="text-center">50</td>
                                            <td class="text-center">30</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    

                    <div class="row my-2">
                        <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 py-2 px-3">
                            <div class="bg-white p-2 rounded-3 shadow-sm">
                                
                            </div>
                        </div>
                    </div>


{/* <!-- Add Details Modal --> */}
<div class="modal" id="addid" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog  modal-dialog-centered">
        <div class="modal-content rounded-3">
            <div class="modal-header d-flex flex-column" id="addModalHeader">
                <div class="text-end w-100">
                    <button type="button" class="btn text-muted" data-bs-dismiss="modal"><i class="bi bi-x-circle"></i></button>
                </div>
                <div class="text-center">
                    <h4 class="modal-title">Add Member</h4>
                    <p class="text-muted">Please add a valid email</p>
                </div>
            </div>
            <div class="modal-body" id="addModalBody">
                <form class="">
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                            <div class="form-group pb-2">
                                <input class="form-control" type="text" name="icueMemEmail" placeholder="Enter Email" value="" />
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                            <div class="form-group pb-2">
                                <input class="form-control" type="text" name="icueMemEmpCode" placeholder="Employee Code" value="" />
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                            <div class="form-group pb-2">
                                <select class="form-select text-muted" aria-label="Default select example">
                                    <option>Department</option>
                                    <option value="1">Development</option>
                                    <option value="2">Designing</option>
                                    <option value="3">QA</option>
                                  </select>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                            <div class="form-group pb-2">
                                <select class="form-select text-muted" aria-label="Default select example">
                                    <option>Role</option>
                                    <option value="1">Member</option>
                                    <option value="2">Team Lead</option>
                                    <option value="3">Manager</option>
                                  </select>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                            <span class="text-muted">Permissions: </span>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                            <div class="form-check pb-2">
                                <input class="form-check-input" type="checkbox" value="" id="icueNotifyNewUserByMail" checked/>
                                <label class="form-check-label text-muted" for="icueNotifyNewUserByMail">
                                    Notify new users by email
                                </label>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                            <div class="form-check pb-2">
                                <input class="form-check-input" type="checkbox" value="" id="icueCanManageUsers"/>
                                <label class="form-check-label text-muted" for="icueCanManageUsers">
                                    Can manage users
                                </label>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                            <div class="form-check pb-2">
                                <input class="form-check-input" type="checkbox" value="" id="icueClaimManag"/>
                                <label class="form-check-label text-muted" for="icueClaimManag">
                                    Claim management
                                </label>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                            <div class="form-check pb-2">
                                <input class="form-check-input" type="checkbox" value="" id="icuePolicyManag"/>
                                <label class="form-check-label text-muted" for="icuePolicyManag">
                                    Policy management
                                </label>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                            <div class="form-check pb-2">
                                <input class="form-check-input" type="checkbox" value="" id="icueAccManag"/>
                                <label class="form-check-label text-muted" for="icueAccManag">
                                    Account management
                                </label>
                            </div>
                        </div>  
                        
                        <div class="col-12 py-3">
                            <div class="d-grid gap-2">
                                <button type="button" class="btn btn-primary btnIcon">
                                    Send Invite
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
{/* <!-- /Add Details Modal --> */}



                </main>
            </div>
        </div>
    </div>
    {/* <!-- /Main Body --> */}

    {/* <!-- <div class="container-fluid border border-danger my-5">
        
    </div> --> */}
   
    </div>)
}