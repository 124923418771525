import React from 'react'
import './HealthCover.css'
import NavBar from '../ReusableComponents/NavBar'
import Footer from '../ReusableComponents/Footer'
import { useHistory } from 'react-router-dom';
import { makeStyles, MenuItem } from '@material-ui/core';
import { InputLabel, Select, FormControl } from '@material-ui/core';
import Stepper from '../ReusableComponents/Stepper';

import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

const healthDiseaseSchema = yup.object({
    diseases: yup.string().required(),

})

const useStyles = makeStyles({
    medication: {
        width: '280px',
        marginTop: '20px',
        // outline:'red',
        [`& fieldset`]: {
            borderRadius: '10px',
            // borderColor:'red',
            // paddingBottom:'25px
        },

    }
})


export default function HealthDisease() {
    const history = useHistory();
    const classes = useStyles()

    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(healthDiseaseSchema)
    });
    const onSubmit = data => {
        if (data) {
            history.push("/HealthSaveQuotes")
        }
    }
    return (
        <>
            <div style={{ background: '#F2F6FF' }}>
                <NavBar />
                <br /><br />
                <div class='step4-car-insurance'>
                    <div class="container-fluid ">
                        <div style={{ display: 'flex', justifyContent: 'center' }}><div class='H-step1-topimg'><img src='images/Component 17.png' ></img></div>
                        </div>
                        <div class="row" style={{ marginTop: '50px' }}>
                            <div class='col-lg-1 col-md-0 ' >
                                <Stepper current={3} thirdStep={true} fouthStep={true} src="images/healthActive.png" />
                            </div>
                            <div class="H-step1-content col-lg-6 col-md-6">

                                <h4>Does any of the member have any disease ?</h4>

                                <div id='ck-button' style={{ marginBottom: '30px' }}>
                                    <label >
                                        <input type='radio' name='disease' checked /><span class='span'>Yes</span>
                                    </label>
                                </div>
                                <div id='ck-button' style={{ marginBottom: '30px' }}>
                                    <label >
                                        <input type='radio' name='disease' /><span class='span' >No</span>
                                    </label>
                                </div>
                                <br /><br /><br />
                                <FormControl variant="outlined" className={classes.medication} >
                                    <InputLabel id="demo-simple-select-outlined-label">Existing Diseases</InputLabel>
                                    <Select
                                        // labelId="demo-simple-select-outlined-label"
                                        // id="demo-simple-select-outlined"
                                        // value={age}
                                        // onChange={handleChange}
                                        label="Relation"
                                        {...register("diseases")}
                                    >
                                        <MenuItem value="x">x</MenuItem>
                                        <MenuItem value="y">y</MenuItem>
                                    </Select>
                                    <p class="text-danger">{errors.diseases?.message}</p>
                                </FormControl>

                                <div>
                                    <div class='H-step1-nextbtn2'><button style={{ marginTop: '2rem' }} onClick={handleSubmit(onSubmit)} >Next</button></div>
                                </div>
                            </div>

                            <div class=" H-step1-rightimg col-lg-5 col-md-6">
                                <img src='images/im2 4.png'></img>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>


    )
}