
import React from 'react'
import { useHistory } from 'react-router-dom'
import './product.css'
export default function Products() {

    const history=useHistory();
    return (
        <div className='products-card'>
            <div className='products'>
                <div className='item' onClick={() => history.push("/HealthInsurance")}>
                    <img src="images/landing/health.png" class="img-mail" />
                    <span className='span-text' >Health Insurance</span>
                </div>
                <div className='item' onClick={() => history.push("/carstep1")}>
                    <img src="images/landing/car.png" class="img-mail" />
                    <span className='span-text' >Car Insurance</span>
                </div>
                <div className='item' onClick={() => history.push("/Two-wheeler")}>
                    <img src="images/landing/bike.png" class="img-mail" />
                    <span className='span-text' >Two-wheeler Insurance</span>
                </div>
                <div className='item' onClick={() => history.push("/LifeInsurance")}>
                    <img src="images/landing/term.png" class="img-mail" />
                    <span className='span-text' >Term Insurance</span>
                </div>
                <div className='item' onClick={() => history.push("/cattleInsurance")}>
                    <img src="images/landing/cattle-img.svg" class="img-mail" />
                    <span className='span-text' >Cattle Insurance</span>
                </div>
                <div className='item' onClick={() => history.push("/OtherInsurance")}>
                    <img src="images/landing/others.png" class="img-mail" />
                    <span className='span-text' >Other Insurance</span>
                </div>
                {/* <div>
<ul>
<h4>Motor Insurance</h4>

<li className='products-li'><a href='/carstep1'>Car Insurance</a></li>
<li className='products-li'><a href='/BikeInsurance'>Two Wheelar Insurance</a></li>
<li className='products-li'><a href='#'>Commercial Vehicle Insurance</a></li>
<li className='products-li'><a href='#'>Used Car Insurance</a></li>
</ul>
</div>
<div>
<ul>
<h4>Health Insurance</h4>

<li className='products-li'><a href='#'>Base Idemnity</a></li>
<li className='products-li'><a href='#'>Critical Illness</a></li>
<li className='products-li'><a href='#'>Super Top-Up</a></li>
<li className='products-li'><a href='#'>Sanjivini</a></li>
</ul>
</div>
<div>
<ul>
<h4>Life Insurance</h4>

<li className='products-li'><a href='/LifeStep1'>Term Insurance</a></li>
</ul>
</div>
<div>
<ul>
<h4>Other Insurance</h4>

<li className='products-li'><a href='#'>Marine Insurance</a></li>
<li className='products-li'><a href='#'>Fire Insurance</a></li>
<li className='products-li'><a href='#'>Liability Insurance</a></li>
</ul>
</div> */}
            </div>
        </div>
    )
}