import React from 'react'
import '../../css/styles.css'
import Footer from '../ReusableComponents/Footer'
import NavBar from '../ReusableComponents/NavBar'
import { useHistory } from 'react-router-dom';
import './vision.css';
import Accordion from 'react-bootstrap/Accordion'
export default function HomePage() {
  const history = useHistory();

  return (
    <div className='main-div'>
      <NavBar />
      <header id="header" className="header">
        <div className="container header-img">
          <div className="row">
            <div className="col-lg-6 col-sm-12">
              <div className="text-container">
                <h1 className="h1-large">Lets Find You</h1>
                <h1 className="h1-too-large">The Best Insurance Plans</h1>
                {/* <p className="p-large">JOIN US AS A PARTNER <u className="underline">REGISTER NOW</u></p> */}
              </div>
            </div>
            <div className="col-lg-6 col-sm-12" >
              <div className="image-container">
                <img className="img-fluid img-placing" src="images/Homepage_Grouped.png" alt="alternative" />
              </div>
            </div>
          </div>


          {/* <!--Cards--> */}
          <div className="row" id='four' style={{ cursor: "pointer" }}>
            <div className="col-md-4 col-xl-3 col-sm-6">
              <div className="card border-0 text-center card-features bg-c-white order-card">
                <a onClick={() => history.push("/carstep1")} style={{ textDecoration: "none" }}><br />
                  <div className="card-block1" style={{ alignItems: "center" }}>
                    <h4 className="text-left car-insurance"><img className="f-left img-width-car-insurance" src="images/Group 107.svg" style={{ width: "40" }} />
                    </h4>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', margin: '2px', alignItems: 'start' }}>
                      <span>Car</span>
                      <span>Insurance</span>
                    </div>
                    {/* <p className="m-b-0">Compare&nbsp;&nbsp;&nbsp;<img src="./images/compare.svg" style={{width: "5%"}}/><span className="f-right">learn more&nbsp;&nbsp;<img src="./images/arrow-right.svg" style={{width: "18%"}}/></span></p> */}
                  </div>
                </a>
              </div>
            </div>

            <div className="col-md-4 col-xl-3 col-sm-6">
              <div className="card border-0 text-center card-features bg-c-white order-card">
                <a onClick={() => history.push("/Two-wheeler")} style={{ textDecoration: "none" }}><br />
                  <div className="card-block1" style={{ alignItems: "center" }}>
                    <h4 className="text-left bike-insurance"><img className="f-left img-width-bike-insurance" src="images/Group 108.svg" style={{ width: "40" }} />

                    </h4>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', margin: '2px', alignItems: 'start' }}>
                      <span>Two-wheeler</span>
                      <span>Insurance</span>
                    </div>

                    {/* <p className="m-b-0">Compare&nbsp;&nbsp;&nbsp;<img src="./images/compare.svg" style={{width: "5%"}}/><span className="f-right">learn more&nbsp;&nbsp;<img src="./images/arrow-right.svg" style={{width: "18%"}}/></span></p> */}
                  </div>
                </a>
              </div>
            </div>

            <div className="col-md-4 col-xl-3 col-sm-6">
              <div className="card border-0 text-center card-features bg-c-white order-card">
                <a onClick={() => history.push("/HealthInsurance")} style={{ textDecoration: "none" }}><br />
                  <div className="card-block1">
                    <h4 className="text-left health-insurance"><img className="f-left img-width-Health-insurance" src="images/Group 109.svg" />

                    </h4>
                    <div style={{ display: 'flex', flexDirection: 'column',justifyContent:'flex-start',margin: '2px', alignItems: 'start' }}>
                      <span>Health</span>
                      <span>Insurance</span>
                    </div>
                    {/* <p className="m-b-0">Compare&nbsp;&nbsp;&nbsp;<img src="./images/compare.svg" style={{width: "5%"}}/><span className="f-right">learn more&nbsp;&nbsp;<img src="./images/arrow-right.svg" style={{width: "18%"}}/></span></p> */}
                  </div>
                </a>
              </div>
            </div>

            <div className="col-md-4 col-xl-3 col-sm-6">
              <div className="card border-0 text-center card-features bg-c-white order-card">
                <a onClick={() => history.push("/LifeInsurance")} style={{ textDecoration: "none" }}><br />
                  <div className="card-block1">
                    <h4 className="text-left life-insurance"><img className="f-left img-width-life-insurance" src="images/Component 3.png" style={{ width: "40" }} />

                    </h4>
                    <div style={{ display: 'flex', flexDirection: 'column',justifyContent:'flex-start',margin: '2px', alignItems: 'start'  }}>
                      <span>Life</span>
                      <span>Insurance</span>
                    </div>
                    {/* <p className="m-b-0">Compare&nbsp;&nbsp;&nbsp;<img src="./images/compare.svg" style={{width: "5%"}}/><span className="f-right">learn more&nbsp;&nbsp;<img src="./images/arrow-right.svg" style={{width: "18%"}}/></span></p> */}
                  </div>
                </a>
              </div>
            </div>
          </div>

        </div>
      </header>
      <div className="basic-3">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 why-block" >
              <div className="image-container">
                <img className="img-fluid img-details" src="images/Illustration1.jpg" alt="alternative" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="text-container">
                {/* <img className="edit_icon" src="images/landing/edit_icon.png" alt="" /> */}
                <h2 className="h2-header">Why PoliciCue?</h2>
                <p className="paragraph-header" style={{ fontFamily: "Calibri", fontSize: "1rem" }}>
                  Insurance is not an option anymore but has become a need in the current scenario. Hence, we at PoliciCue believe it’s important to empower our customers with the necessary set of knowledge that enables them to accurately pick up a policy based on their needs.
                  <br />
                  <br />

                  We believe in providing insurance, dismissing ignorance. It was with this vision that PoliciCue was formed to simplify the process of comparing and buying best-fit insurance policies for our customers through our seamless offline and digital models. More than just financial safety, we promise you a friend who helps you fully understand the benefits of the policy as per your needs.
                  <br />
                  <br />

                  PoliciCue is your one-stop solution where we don’t sell policies but connect you with the best policy as per your needs.
                  <br />
                  <br />

                  Got questions? We offer candid advice by qualified aficionados, tell us about your needs and our group of experts will recommend you the best match policy/plans.
                  <br />
                  <br />

                  Our friendly customer support team is your friend in need. Speak your heart out. We listen with undivided attention and will resolve your concerns promptly.

                </p>

                {/* <a href="#your-link">
                  <button className="bootstrap-button" style={{ marginLeft: '6.5rem' }}>Read more</button>
                </a> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* vision block */}

      <div className="basic-3">
        <div className="row">
          <div className="col-lg-6 col-sm-12 col-xs-12">
            <div className="left-container">
              <div className='left-block'>
                <h4 className='h4-text'>Vision</h4>
                <span className='span-texts'>
                  PoliciCue is committed to be most trusted and technology driven insurance distributor,
                  pledging to contribute towards a financially inclusive society.
                </span>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-sm-12 col-xs-12">
            <div className="right-container">
              <img className='img-mission' src="images/landing/mission_bg.png" alt="alternative" />
              <div className='right-inner-block'>
                <div className='right-block'>
                  <h4 className='h4-text'>Mission</h4>
                  <span className='span-texts'>
                    Knowledge based technology driven Innovative team, which wish to serve it's stakeholders with utmost empathy and professional
                    acumen as a Trusted partner.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* About Team Block */}
      <div className="basic-3">
        <div className="row team-block">
          <div className='col-4 text-center'>
            <div style={{ marginBottom: '4rem' }}>

            </div>
          </div>
          <div className='col-4 text-center' style={{ marginBottom: '4rem' }}>
            <h4 className='leader-ship'>
              Leadership
            </h4>
            {/* <h4>
                Shareholder and Directors
              </h4> */}
          </div>
          <div className='col-4 text-center'>
            <div style={{ marginBottom: '4rem' }}>

            </div>
          </div>
          {/* <div className="col-md-4 col-xl-3" onClick={() => history.push("/LeadersProfile")} title='Click to view profile'> */}
          <div className="col-md-4 col-xl-3" onClick={() =>
            history.push(
              {
                pathname: "/LeadersProfile",
                state: {
                  val: 1,
                }
              }

            )} title='Click to view profile'>


            <div className='person-block'>
              <img className='img-person' src="images/landing/p1.png" alt="Sanjay Vats" /> <br />
              <h4 className='h4-name' >
                Sanjay Vats
              </h4>
              <span className='span-desc' style={{ marginTop: "-7px" }}>
                Has 24+ years of experience in Non-Life insurance industry with expertise in Claims, Underwriting,
                Legal Operations, Team Management, MIS and Trainings etc. Has qualified BQP for Life &
                Non-Life insurance sectors
              </span>
            </div>
          </div>
          <div className="col-md-4 col-xl-3" onClick={() =>
            history.push(
              {
                pathname: "/LeadersProfile",
                state: {
                  val: 2,
                }
              }

            )} title='Click to view profile'>
            <div className='person-block'>
              <img className='img-person' src="images/landing/p2.png" alt="Vikas Mathur" /> <br />
              <h4 className='h4-name' >
                Vikas Mathur
              </h4>
              <span className='span-desc' style={{ marginTop: "-7px" }}>
                Has 24+ years of experience in Non-Life insurance industry with expertise in Sales & Marketing, Health Insurance, Digital Partnership,
                Channel Management etc. Has qualified BQP for Life, Non-Life & Re-insurance sectors
              </span>
            </div>
          </div>
          <div className="col-md-4 col-xl-3" onClick={() =>
            history.push(
              {
                pathname: "/LeadersProfile",
                state: {
                  val: 3,
                }
              }

            )} title='Click to view profile'>
            <div className='person-block'>
              <img className='img-person' src="images/landing/p3.png" alt="Vishwa B. Rao" /> <br />
              <h4 className='h4-name'>
                Vishwa B. Rao
              </h4>
              <span className='span-desc' style={{ marginTop: "-7px" }}>
                Has 18+ years of experience in Non-Life insurance industry with expertise in Underwriting, Risk Management, Operations, Key Account Management,
                MIS and Trainings etc. Has qualified BQP for Life & Non-Life insurance sectors
              </span>
            </div>
          </div>
        </div>
      </div>


      {/* testimonials block */}

      {/* <div className="basic-3">
        <div className="row testimonials-container ">
          <div className='col-12 text-center layer'>
            <div style={{ marginBottom: '4rem' }}>
              <h4 className='header-testimonial'>
                Testimonials
              </h4>
              <h5 className='header-testimonial'>
                See What Our Existing Clients To Say About Us
              </h5>
            </div>
          </div>
          <div className='col-md-4 col-xl-3'>
            <div className='testimonial-card'>
              data
            </div>
          </div>
        </div>
      </div> */}

      {/* faqs */}
      <div className="faqs">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h2 className="h2-heading-faqs">Insurance FAQ’s</h2>
              <p className="p-heading-faqs">If You Are Looking To Buy Insurance You  Ought to Check Below Frequently Asked Questions</p>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row">
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header className='accordion-header-1'>
                  <img src="./images/Rectangle 22.svg" alt="" />&nbsp;&nbsp;&nbsp;&nbsp;
                  <p className='faqText'>What is insurance?</p>
                  </Accordion.Header>
                <Accordion.Body className='accordion-header-1'>
                  <p class="p-faqs-para">
                    It's a financial safety net and a protective measure i.e. to protect yourself from losing money.
                    By acquiring an insurance policy, you won’t have to pay large expenses when the unexpected arises, like auto accidents, health complications, natural disasters, and so on.
                    In this direction only, we are present who can answer your questions, and provide you with sound advice for the policies that are best for you and your unique needs.
                  </p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header className='accordion-header-2'> <img src="./images/Rectangle 22.svg" alt="" />&nbsp;&nbsp;&nbsp;&nbsp;
                <p className='faqText'>Why should I buy insurance?</p></Accordion.Header>
                <Accordion.Body className='accordion-header-2'>
                  <p class="p-faqs-para">
                    When you buy insurance, you transfer the cost of a potential loss to the insurance company in exchange for a fee, known as the premium. </p>
                  <p class="p-faqs-para">
                    e.g. Most of us could not afford to pay such large sums if we get sick, especially since we don't know when we might become ill or injured or how much care we might need. Health insurance offers a way to reduce such costs to more reasonable amounts. </p>
                  <p class="p-faqs-para">
                    Without insurance, many aspects of modern societies and economies could not function. Insurance provides the cover against unforeseen events that enables individuals and businesses to carry out their daily activities.
                    Buying an insurance policy for a smaller, known premium removes the possibility of a larger, potentially unaffordable loss, bringing peace of mind and security. These small premiums are collected in a pool, which allows the losses of the few to be spread among the many. </p>
                  <p class="p-faqs-para">
                    Indian Non-Life insurers pay out almost Rs.300 Crores a day in claims to help businesses and individuals through difficult events and in benefits to long-term savers.
                    * IRDA Handbook </p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header className='accordion-header-1'> <img src="./images/Rectangle 22.svg" alt="" />&nbsp;&nbsp;&nbsp;&nbsp;  <p className='faqText' >How will insurance pay for my emergency expenses?</p></Accordion.Header>
                <Accordion.Body className='accordion-header-1'>
                  <p class="p-faqs-para">
                    Companies get revenue through premiums which are paid in a central fund by every person in the risk pool to cover the losses of the few who need to use their coverage. </p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header className='accordion-header-2'> <img src="./images/Rectangle 22.svg" alt="" />&nbsp;&nbsp;&nbsp;&nbsp; <p className='faqText'>What is the eligible age to buy insurance?</p> </Accordion.Header>
                <Accordion.Body className='accordion-header-2'>
                  <p class="p-faqs-para">
                    Any person who has an insurable interest in the property is eligible to buy the insurance for the same. </p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header className='accordion-header-1'> <img src="./images/Rectangle 22.svg" alt="" />&nbsp;&nbsp;&nbsp;&nbsp; <p className='faqText'>Will I be allowed to cover my family under my insurance?</p></Accordion.Header>
                <Accordion.Body className='accordion-header-1'>
                <p class="p-faqs-para">
                      Yes.  You may cover your family in your insurance policy. </p>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            {/* <div className="accordion" id="accordionExample">
              <div className="card border-0 card-faqs card-bg-1" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                <div className="card-header" id="headingOne">
                  <h5 className="mb-0">
                    <img src="./images/Rectangle 22.svg" alt="" />
                    <button className="btn btn-link btn-faqs" type="button" >
                      What is insurance?
                    </button>
                    <img src="images/chevron-bottom.svg" className="img-chevron-down-faqs float-right" />
                  </h5>
                </div>

                <div id="collapseOne" className="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
                  <div className="card-body content">
                    <p class="p-faqs-para">
                      It's a financial safety net and a protective measure i.e. to protect yourself from losing money.
                      By acquiring an insurance policy, you won’t have to pay large expenses when the unexpected arises, like auto accidents, health complications, natural disasters, and so on.
                      In this direction only, we are present who can answer your questions, and provide you with sound advice for the policies that are best for you and your unique needs. </p>
                  </div>
                </div>
              </div>
              <div className="card border-0 card-faqs card-bg-2" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                <div className="card-header" id="headingTwo">
                  <h5 className="mb-0">
                    <img src="./images/Rectangle 22.svg" alt="" />
                    <button className="btn btn-link btn-faqs collapsed" type="button" >
                      Why should I buy insurance?
                    </button>
                    <img src="images/chevron-bottom.svg" className="img-chevron-down-faqs float-right" />
                  </h5>
                </div>
                <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                  <div className="card-body content">
                    <p class="p-faqs-para">
                      When you buy insurance, you transfer the cost of a potential loss to the insurance company in exchange for a fee, known as the premium. </p>
                    <p class="p-faqs-para">
                      e.g. Most of us could not afford to pay such large sums if we get sick, especially since we don't know when we might become ill or injured or how much care we might need. Health insurance offers a way to reduce such costs to more reasonable amounts. </p>
                    <p class="p-faqs-para">
                      Without insurance, many aspects of modern societies and economies could not function. Insurance provides the cover against unforeseen events that enables individuals and businesses to carry out their daily activities.
                      Buying an insurance policy for a smaller, known premium removes the possibility of a larger, potentially unaffordable loss, bringing peace of mind and security. These small premiums are collected in a pool, which allows the losses of the few to be spread among the many. </p>
                    <p class="p-faqs-para">
                      Indian Non-Life insurers pay out almost Rs.300 Crores a day in claims to help businesses and individuals through difficult events and in benefits to long-term savers.
                      * IRDA Handbook </p>
                  </div>
                </div>
              </div>
              <div className="card border-0 card-faqs card-bg-1" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                <div className="card-header" id="headingThree">
                  <h5 className="mb-0">
                    <img src="./images/Rectangle 22.svg" alt="" />
                    <button className="btn btn-link btn-faqs collapsed" type="button" >
                      How will insurance pay for my emergency expenses?
                    </button>
                    <img src="images/chevron-bottom.svg" className="img-chevron-down-faqs float-right" />
                  </h5>
                </div>
                <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                  <div className="card-body content">
                    <p class="p-faqs-para">
                      Companies get revenue through premiums which are paid in a central fund by every person in the risk pool to cover the losses of the few who need to use their coverage. </p>
                  </div>
                </div>
              </div>
              <div className="card border-0 card-faqs card-bg-2" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                <div className="card-header" id="headingFour">
                  <h5 className="mb-0">
                    <img src="./images/Rectangle 22.svg" alt="" />
                    <button className="btn btn-link btn-faqs collapsed" type="button" >
                      What is the eligible age to buy insurance?
                    </button>
                    <img src="images/chevron-bottom.svg" className="img-chevron-down-faqs float-right" />
                  </h5>
                </div>
                <div id="collapseFour" className="collapse" aria-labelledby="headingFour" data-parent="#accordionExample">
                  <div className="card-body content">
                    <p class="p-faqs-para">
                      Any person who has an insurable interest in the property is eligible to buy the insurance for the same. </p>
                  </div>
                </div>
              </div>
              <div className="card border-0 card-faqs card-bg-1" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                <div className="card-header" id="headingFive">
                  <h5 className="mb-0">
                    <img src="./images/Rectangle 22.svg" alt="" />
                    <button className="btn btn-link btn-faqs collapsed" type="button" >
                      Will I be allowed to cover my family under my insurance?
                    </button>
                    <img src="images/chevron-bottom.svg" className="img-chevron-down-faqs float-right" />
                  </h5>
                </div>
                <div id="collapseFive" className="collapse" aria-labelledby="headingFive" data-parent="#accordionExample">
                  <div className="card-body content">
                    <p class="p-faqs-para">
                      Yes.  You may cover your family in your insurance policy. </p>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>

      {/*  */}
      {/* <!-- Details 3 --> */}

      {/* <!-- end of details 3 --> */}


      {/*  */}

      {/*  */}

      <div className="related-articles">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h2 className="h2-heading-related-articles" >Related Articles</h2>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row related-articles-size">
            <div className="col-lg-4 mb-4" data-toggle="modal" data-target="#modal1" style={{ cursor: 'pointer' }} title='click to view the blog'>
              <div className="card border-0 card-faqs">
                <img src="images/landing/article-img1.png" alt="" className="card-img-top img-faqs" />
                <div className="card-body card-body-related-articles">
                  <h5 className="card-title">If Health is Wealth, Insurance makes you Healthier</h5>
                  <p className="card-text p-related-articles">If Health is wealth your Lifestyle determines the wealth you posses in near future.
                    Indians has a very rich history of having...</p>

                </div>
              </div>
            </div>
            <div className="col-lg-4 mb-4" data-toggle="modal" data-target="#modal2" style={{ cursor: 'pointer' }} title='click to view the blog'>
              <div className="card border-0 card-faqs">
                <img src="images/landing/article-img2.png" alt="" className="card-img-top img-faqs" />
                <div className="card-body card-body-related-articles">
                  <h5 className="card-title">Eyeing Health Insurance – Check Must Have First</h5>
                  <p className="card-text p-related-articles">You have been planning your financials and concluded Health Insurance is a must have product to you kitty...</p>

                </div>
              </div>
            </div>
            <div className="col-lg-4 mb-4" data-toggle="modal" data-target="#modal3" style={{ cursor: 'pointer' }} title='click to view the blog'>
              <div className="card border-0 card-faqs">
                <img src="images/landing/article-img3.png" alt="" className="card-img-top img-faqs" />
                <div className="card-body card-body-related-articles">
                  <h5 className="card-title">Health Insurance Optimization – Mediclaim Add-On</h5>
                  <p className="card-text p-related-articles">
                    Once I was looking to buy a Laptop along with some other peripheral items which was usually required for a house hold...
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="container">
          <div className="row">
            <div className="col-lg-12 related-articles-find-more" style={{ left: "auto", right: "55px" }}>
              <a href="#your-link">
                <button className="bootstrap-button" >Find out more</button>
              </a>
            </div>
          </div>
        </div> */}

      </div>

      {/* <div className="partners">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h2 className="h2-heading-partners">Our Partners</h2>
              <p className="p-heading-partners">Leading Insurers  For Your Financial Freedom</p>
            </div>
          </div>
          <div className="row partner-set-1">
            <div className="col-xl-3 col-sm-6 col-12 partners-content">
              <div className="card card-partners">
                <div className="card-content">
                  <div className="card-body">
                    <div className="media d-flex">
                      <div className="text-center">
                        <img className="img-fluid img-partners" src="images/1200px-AA_Insurance_logo.png" alt="alternative" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-sm-6 col-12 partners-content">
              <div className="card card-partners">
                <div className="card-content">
                  <div className="card-body">
                    <div className="media d-flex">
                      <div className="text-center">
                        <img className="img-fluid img-partners" src="images/Kotak-Life-Insurance-Logo.png" alt="alternative" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-sm-6 col-12 partners-content">
              <div className="card card-partners">
                <div className="card-content">
                  <div className="card-body">
                    <div className="media d-flex">
                      <div className="text-center">
                        <img className="img-fluid img-partners" src="images/142-1420852_life-insurance-logos-car-insurance-company-logo 2.png" alt="alternative" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-sm-6 col-12 partners-content">
              <div className="card card-partners">
                <div className="card-content">
                  <div className="card-body">
                    <div className="media d-flex">
                      <div className="text-center">
                        <img className="img-fluid img-partners" src="images/142-1420852_life-insurance-logos-car-insurance-company-logo 3.png" alt="alternative" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row partner-set-1">
            <div className="col-xl-3 col-sm-6 col-12 partners-content">
              <div className="card card-partners">
                <div className="card-content">
                  <div className="card-body">
                    <div className="media d-flex">
                      <div className="text-center">
                        <img className="img-fluid img-partners" src="images/142-1420852_life-insurance-logos-car-insurance-company-logo 7.png" alt="alternative" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-sm-6 col-12 partners-content">
              <div className="card card-partners">
                <div className="card-content">
                  <div className="card-body">
                    <div className="media d-flex">
                      <div className="text-center">
                        <img className="img-fluid img-partners" src="images/142-1420852_life-insurance-logos-car-insurance-company-logo 6.png" alt="alternative" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-sm-6 col-12 partners-content">
              <div className="card card-partners">
                <div className="card-content">
                  <div className="card-body">
                    <div className="media d-flex">
                      <div className="text-center">
                        <img className="img-fluid img-partners" src="images/142-1420852_life-insurance-logos-car-insurance-company-logo 5.png" alt="alternative" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-sm-6 col-12 partners-content">
              <div className="card card-partners">
                <div className="card-content">
                  <div className="card-body">
                    <div className="media d-flex">
                      <div className="text-center">
                        <img className="img-fluid img-partners" src="images/142-1420852_life-insurance-logos-car-insurance-company-logo 4.png" alt="alternative" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <Footer />
      <div id="modal1" class="modal fade bd-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <iframe src="https://insurancejourno.wordpress.com/2020/04/26/if-health-is-wealth-insurance-makes-you-healthier/" width="100%" height="650"></iframe>
          </div>
        </div>
      </div>
      <div id="modal2" class="modal fade bd-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <iframe src="https://insurancejourno.wordpress.com/2020/05/15/eyeing-health-insurance-check-must-have-first/" width="100%" height="650"></iframe>
          </div>
        </div>
      </div>
      <div id="modal3" class="modal fade bd-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <iframe src="https://insurancejourno.wordpress.com/2020/05/24/health-insurance-optimization-mediclaim-add-on/" width="100%" height="650"></iframe>
          </div>
        </div>
      </div>
    </div>
  )
}