import React from 'react';
import './Lead.css';
export default function Lead(){
    return(<div className="leads" id="leads">
          <div class="container-fluid1 py-2 icueTopBarContainer bg-white">
        <div class="row">
            <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                <div class="topBarLfContainer d-flex">
                    <div class="icueLogoContainer">
                        <img src="images/logo.png" alt="ICue Logo" class="icueLogo" />
                    </div>
                    <div class="icueSearchBox w-75 mx-3">
                        <div class="input-group ">
                            <span class="input-group-text" id="basic-addon1">
                                <i class="bi bi-search"></i>
                            </span>
                            <input type="search" id="text-search" class="search form-control icueSearchBoxControl" placeholder="Enter keywords..." aria-label="Username" aria-describedby="basic-addon1"/>
                          </div>
                    </div>
                </div>
            </div>

            <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                <div class="d-flex flex-row-reverse">
                    <div class="icueProfile px-2">
                        <img src="images/avatar.png" alt="icueProfilePic" class="icueProfilePic border border-dark rounded-circle" />
                    </div>
                    <div class="position-relative px-2">
                        <a href="#" id="icueNotifyBoxHandler">
                            <i class="bi bi-bell">
                                <span class="position-absolute icueNofIndic"></span>
                            </i>
                        </a>
                        <div class="icueNotifyBox" id="icueNotifyBox">
                            Notifications
                        </div>
                    </div>

                    <div class="icueUserName fs-6 px-2">
                        Viral Patel
                    </div>

                    <div class="icueMail px-2">
                        <i class="bi bi-headset"></i>&nbsp;
                        adminhelpdesk@gmail.com
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container-fluid my-2">
        <div class="row">
            <div class="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12 bg-white">
                <div class="icueRespSideBar d-xxl-none d-xl-none d-lg-none d-md-block d-sm-block d-block" id="icueRespSideBar">
                    <button type="button" class="btn btn-primary" id="icueRespHumburger">
                        <i class="bi bi-grid-3x3-gap" id="icueOpenIcon"></i>
                        <i class="bi bi-x-lg" id="icueCloseIcon"></i>
                    </button>
                    <div class="icueSideBar" id="icueSideBar">
                        <nav class="navbar icueSideNavContainer">
                            <ul class="icueNavBarList">
                                <li class="icueNavBarItem">
                                    <a href="/Superdashboard" class="icueNavBarLink" id="">Dashboard</a>
                                </li>

                                <li class="icueNavBarItem">
                                    <a href="#" class="icueNavBarLink" id="">User Management</a>
                                </li>

                                <li class="icueNavBarItem">
                                    <a href="/Hrdashboard" class="icueNavBarLink" id="">HRMS</a>
                                </li>
    
                               

                                <li class="icueNavBarItem border-bottom-0 ">
                                    <a href="/lms" class="icueNavBarLink" id="">LMS</a>
                                </li>

                                <li>
                                    <ul class="icueSubNavBar">
                                        <li class="icueSubNavItem">
                                            {/* <!-- <a href="#" data-bs-toggle="modal" data-bs-target="#addid" data-bs-whatever="@getbootstrap" class="icueSubNavLink  text-capitalize">Retail</a> --> */}
                                            <a href="#" class="icueSubNavLink  text-capitalize">Retail</a>
                                        </li>
                                        </ul>
                                </li>
                                <li className="active">
                                    <ul>
                                        <li class="icueSubNavItem">
                                            <a href="/leads" class="icueSubNavLink  text-capitalize">Corporate</a>
                                        </li>
                                        </ul>
                                </li>
                                   
                                <hr></hr>
                               

                                <li class="icueNavBarItem" style={{marginTop:"-0.625rem"}}>
                                    <a href="claim-management.html" class="icueNavBarLink text-capitalize" id="">Claim Management</a>
                                </li>
                                <li>
                                    <ul class="icueSubNavBar">
                                        <li class="icueSubNavItem">
                                            <a href="#" class="icueSubNavLink  text-capitalize">Retail</a>
                                        </li>
                                        <li class="icueSubNavItem">
                                            <a href="#" class="icueSubNavLink  text-capitalize">Corporate</a>
                                        </li>
                                        <li class="icueSubNavItem">
                                            <a href="#" class="icueSubNavLink  text-capitalize">Corporate</a>
                                        </li>
                                        <li class="icueSubNavItem">
                                            <a href="#" class="icueSubNavLink  text-capitalize">Corporate</a>
                                        </li>
                                    </ul>
                                </li>
                                 <hr></hr>
                                <li class="icueNavBarItem">
                                    <a href="/Department" class="icueNavBarLink" id="">Department</a>
                                </li>
    
                                <li class="icueNavBarItem">
                                    <a href="/AddRoles" class="icueNavBarLink" id="">Roles</a>
                                </li>
    
                                {/* <!-- <li class="icueNavBarItem">
                                    <a href="#" class="icueNavBarLink" id="">Settings</a>
                                    <ul class="icueSubNavBar">
                                        <li class="icueSubNavItem">
                                            <a href="#" class="icueSubNavLink  text-capitalize">Change Password</a>
                                        </li>
                                        <li class="icueSubNavItem">
                                            <a href="#" class="icueSubNavLink  text-capitalize">Profile</a>
                                        </li>
                                    </ul>
                                </li> --> */}
                                <li class="icueNavBarItem border-bottom-0">
                                    <a href="#" class="icueNavBarLink" id="">Settings</a>
                                </li>
                                <li>
                                    <ul class="icueSubNavBar">
                                        <li class="icueSubNavItem">
                                            <a href="#" class="icueSubNavLink  text-capitalize">Change Password</a>
                                        </li>
                                        <li class="icueSubNavItem">
                                            <a href="#" class="icueSubNavLink  text-capitalize">Profile</a>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                            <hr></hr>
                        </nav>
                    </div>
                </div>

                <div class="icueNavSideBar d-xxl-block d-xl-block d-lg-block d-md-none d-sm-none d-none" id="icueNavSideBar">
                    <nav>
                        <ul class="icueNavBarList">
                            <li class="icueNavBarItem">
                                <a href="/Superdashboard" class="icueNavBarLink" id="">Dashboard</a>
                            </li>

                            <li class="icueNavBarItem">
                                <a href="#" class="icueNavBarLink" id="">User Management</a>
                            </li>

                            <li class="icueNavBarItem">
                                <a href="/Hrdashboard" class="icueNavBarLink" id="">HRMS</a>
                            </li>

                            {/* <!-- <li class="icueNavBarItem icueNavDot active">
                                <a href="lms-dashboard-1.html" class="icueNavBarLink" id="">LMS</a>
                                <ul class="icueSubNavBar">
                                    <li class="icueSubNavItem">
                                        <a href="#" class="icueSubNavLink  text-capitalize">Retail</a>
                                    </li>
                                    <li class="icueSubNavItem">
                                        <a href="#" class="icueSubNavLink  text-capitalize">Corporate</a>
                                    </li>
                                </ul>
                            </li> --> */}

                            <li class="icueNavBarItem  border-bottom-0">
                                <a href="/lms" class="icueNavBarLink" id="">LMS</a>
                            </li>

                            <li>
                                <ul class="icueSubNavBar">
                                    <li class="icueSubNavItem">
                                        {/* <!-- <a href="#" data-bs-toggle="modal" data-bs-target="#addid" data-bs-whatever="@getbootstrap" class="icueSubNavLink  text-capitalize">Add Member</a> --> */}
                                        <a href="#" class="icueSubNavLink  text-capitalize">Retail</a>
                                    </li>
                                   
                                </ul>
                            </li>
                               <li className="active">
                                   <ul>
                                    <li class="icueSubNavItem " style={{ marginLeft:"-20px" }}>
                                        <a href="/leads" class="icueSubNavLink  text-capitalize">Corporate</a>
                                    </li>
                                    </ul>
                                </li>
                             <hr></hr>
                         

                            <li class="icueNavBarItem border-bottom-0" style={{marginTop:"-0.625rem"}}>
                                <a href="#" class="icueNavBarLink" id="">Claim Management</a>
                            </li>

                            <li>
                                <ul class="icueSubNavBar">
                                    <li class="icueSubNavItem">
                                        <a href="#" class="icueSubNavLink  text-capitalize">Life Insurance</a>
                                    </li>
                                    <li class="icueSubNavItem">
                                        <a href="#" class="icueSubNavLink  text-capitalize">Car Insurance</a>
                                    </li>
                                    <li class="icueSubNavItem">
                                        <a href="#" class="icueSubNavLink  text-capitalize">Two-wheeler Insurance</a>
                                    </li>
                                    <li class="icueSubNavItem">
                                        <a href="#" class="icueSubNavLink  text-capitalize">Marine Insurance</a>
                                    </li>
                                </ul>
                            </li>
                            <hr></hr>
                            <li class="icueNavBarItem" style={{marginTop:"-0.813rem"}}>
                                <a href="/Department" class="icueNavBarLink" id="">Department</a>
                            </li>

                            <li class="icueNavBarItem">
                                <a href="/AddRoles" class="icueNavBarLink" id="">Roles</a>
                            </li>

                            {/* <!-- <li class="icueNavBarItem icueNavDot">
                                <a href="#" class="icueNavBarLink" id="">Settings</a>
                                <ul class="icueSubNavBar">
                                    <li class="icueSubNavItem">
                                        <a href="#" class="icueSubNavLink  text-capitalize">Change Password</a>
                                    </li>
                                    <li class="icueSubNavItem">
                                        <a href="#" class="icueSubNavLink  text-capitalize">Profile</a>
                                    </li>
                                </ul>
                            </li> --> */}

                            <li class="icueNavBarItem border-bottom-0">
                                <a href="#" class="icueNavBarLink" id="">Settings</a>
                            </li>
                            <li>
                                <ul class="icueSubNavBar">
                                    <li class="icueSubNavItem">
                                        <a href="#" class="icueSubNavLink  text-capitalize">Change Password</a>
                                    </li>
                                    <li class="icueSubNavItem">
                                        <a href="#" class="icueSubNavLink  text-capitalize">Profile</a>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                        <hr></hr>
                    </nav>
                </div>
            </div>

            <div class="col-xxl-10 col-xl-10 col-lg-10 col-md-12 col-sm-12 col-12 ">
                <main class="mainBodyContainer">
                    <div class="row my-2">
                        <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 py-2 px-3">
                            <div class="d-flex justify-content-between">
                                <h4 class="text-uppercase"style={{fontFamily:"ErasDemiITC", fontWeight:"bold", marginTop:"1rem", marginLeft:"12px"}}>leads generated</h4>
                                <div class="bg-transparent p-2  rounded-3">
                                <nav style={{bsbreadcrumbdivider: 'url(&#34;"data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="8" height="8"%3E%3Cpath d="M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z" fill="currentColor"/%3E%3C/svg%3E&#34")'}} aria-label="breadcrumb">
                                    <ol class="breadcrumb mb-0 justify-content-end">
                                        <li class="breadcrumb-item bg-transparent" style={{fontFamily:"Calibri"}}><a href="/Superdashboard" class="text-decoration-none text-info">Dashboard</a></li>
                                        <li class="breadcrumb-item bg-transparent" style={{fontFamily:"Calibri"}}><a href="/lms" class="text-decoration-none text-info">LMS</a></li>
                                        <li class="breadcrumb-item text-muted text-capitalize bg-transparent" aria-current="page">leads generated</li>
                                    </ol>
                                </nav>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row my-2">
                        <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 py-2 px-3" >
                            <div class="bg-white p-2  rounded-3 shadow-sm">
                                <div class="px-4 py-3">
                                    <div class="row">
                                        <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 ">
                                            {/* <!-- <h4>Leads Generated</h4> --> */}

                                            <table id="lmsDataTable" class="table results " style={{width:"100%", border: "none"}}>
                                                <thead>
                                                    <tr class="text-uppercase" style={{lineHeight:"15px", fontFamily:"Calibri", fontStyle:"normal"}}>
                                                        <th style={{columnGap:"15px", width:"35px"}}>ID</th>
                                                        <th style={{width:"105px",columnGap:"15px" }}>customer name</th>
                                                        <th style={{width:"115px", columnGap:"1px"}} >location</th>
                                                        <th style={{columnGap:"7px", width:"75px"}}>policy type</th>
                                                        <th style={{columnGap:"5px",width:"50px"}}>lead status</th>
                                                        <th style={{width:"130px"}}>lead date</th>
                                                        <th >assign to</th>
                                                        <th>lead from</th>
                                                        <th>rfq status</th>
                                                        <th class="text-center" >rfq</th>
                                                        <th >follow up</th>
                                                        <th>actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody style={{lineHeight:"15px", fontFamily:"Calibri"}}>
                                                    <tr>
                                                        <td >100001</td>
                                                         <td> 
                                                             <a href="/Customer" >Annie Rose</a>
                                                             </td>
                                                        <td class="wrap">Block no. 9/c, Dwaraka, Delhi</td>
                                                        <td  >car insurance</td>
                                                        <td class="text-center">
                                                            <span class="badge rounded-pill bg-success text-uppercase px-2 py-2 w-75"  >cold</span>
                                                        </td>
                                                        <td  >15-feb-2021</td>
                                                        <td>
                                                            <button type="button" class="btn btn-sm text-capitalize assignModal" data-bs-toggle="modal" data-bs-target="#assignModal" id="assignLeadTo_1" >assign</button>
                                                            <div class="icueAssChgBox" id="icueAssChgBox_1">
                                                                <div class="form-group input-group">
                                                                    <input type="text" class="form-control form-control-sm icueAssLeadTo border-end-0 text-capitalize" name="icueAssLeadTo" id="icueAssLeadTo" value="Jhon" />
                                                                    <span class="input-group-text bg-transparent border-start-0" onclick="changeAssLeadTo();">
                                                                        <span id="icueChgAssLeadTo"><i class="bi bi-x text-danger"  ></i></span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td class="text-center">
                                                            website
                                                        </td>
                                                        <td class="textColor text-danger">not sent</td>
                                                        <td>
                                                            <button type="button" class="btn btn-sm text-white rounded-3 w-100 icueFeedBackBtn text-capitalize " id="sendBtn_1" data-bs-toggle="modal" data-bs-target="#sendRfqModal" style={{lineHeight:"10px" , backgroundColor:"#3A3285"}} >send</button>
                                                        </td>
                                                        <td>
                                                            <button type="button" class="btn btn-sm btn-toggle active" data-toggle="button" aria-pressed="false" autocomplete="off" id="toggBtn_1"><div class="handle"></div></button>
                                                        </td>
                                                        <td class="text-center">
                                                            <button type="button" class="btn btn-sm btn-transparent icueDelBtnRow text-muted" id="remMod_1"><i style={{color:"#7D90B2",  width:"0.875rem"}} class="bi bi-trash"></i></button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td >100002</td>
                                                        <td>
                                                            <a href="/Customer" >harry lewis </a></td>
                                                        <td >Flat No. 928/A, Santacruz, Mumbai</td>
                                                        <td >health insurance</td>
                                                        <td class="text-center" style={{width:"8rem"}}>
                                                            <span class="badge rounded-pill bg-warning text-uppercase px-2 py-2 w-75" >warm</span>
                                                        </td>
                                                        <td >20-jun-2021</td>
                                                        <td>
                                                            <button type="button" class="btn btn-sm text-capitalize assignModal" data-bs-toggle="modal" data-bs-target="#assignModal" id="assignLeadTo_2">assign</button>
                                                            <div class="icueAssChgBox" id="icueAssChgBox_2">
                                                                <div class="form-group input-group">
                                                                    <input type="text" class="form-control form-control-sm icueAssLeadTo border-end-0 text-capitalize" name="icueAssLeadTo" id="icueAssLeadTo" value="Jhon" />
                                                                    <span class="input-group-text bg-transparent border-start-0" onclick="changeAssLeadTo();">
                                                                        <span id="icueChgAssLeadTo"><i class="bi bi-x text-danger"></i></span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td class="text-center" >
                                                            website
                                                        </td>
                                                        <td class="textColor text-dark" >waiting</td>
                                                        <td>
                                                            <button type="button" class="btn btn-sm text-white rounded-3 w-100 icueFeedBackBtn text-capitalize" id="sendBtn_2" data-bs-toggle="modal" data-bs-target="#sendRfqModal" style={{lineHeight:"10px" , backgroundColor:"#3A3285"}} >send</button>
                                                        </td>
                                                        <td>
                                                            <button type='button' class='btn btn-sm btn-toggle' data-toggle='button' aria-pressed='false' autocomplete='off' id='toggBtn_2'><div class='handle'></div></button>
                                                        </td>
                                                        <td class="text-center">
                                                            <button type="button" class="btn btn-sm btn-transparent icueDelBtnRow text-muted" id="remMod_2"><i class="bi bi-trash"></i></button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>100003</td>
                                                        <td>
                                                        <a href="/Customer" >amelia davies</a> </td>
                                                        <td >HIG-277, Shivaji Nagar, indore</td>
                                                        <td >life insurance</td>
                                                        <td class="text-center">
                                                            <span class="badge rounded-pill bg-danger text-uppercase px-2 py-2 w-75">hot</span>
                                                        </td>
                                                        <td>25-aug-2021</td>
                                                        <td>
                                                            <button type="button" class="btn btn-sm text-capitalize assignModal" data-bs-toggle="modal" data-bs-target="#assignModal" id="assignLeadTo_3" >assign</button>
                                                            <div class="icueAssChgBox" id="icueAssChgBox_3">
                                                                <div class="form-group input-group">
                                                                    <input type="text" class="form-control form-control-sm icueAssLeadTo border-end-0 text-capitalize" name="icueAssLeadTo" id="icueAssLeadTo" value="Jhon" />
                                                                    <span class="input-group-text bg-transparent border-start-0" onclick="changeAssLeadTo();">
                                                                        <span id="icueChgAssLeadTo"><i class="bi bi-x text-danger"></i></span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td class="text-center" >
                                                            website
                                                        </td>
                                                        <td class="textColor text-primary">more info</td>
                                                        <td>
                                                            <button type="button" class="btn btn-sm text-white rounded-3 w-100 icueFeedBackBtn text-capitalize" id="sendBtn_3" data-bs-toggle="modal" data-bs-target="#sendRfqModal" style={{lineHeight:"10px", backgroundColor:"#3A3285"}} >send</button>
                                                        </td>
                                                        <td>
                                                            <button type='button' class='btn btn-sm btn-toggle' data-toggle='button' aria-pressed='false' autocomplete='off' id='toggBtn_3'><div class='handle'></div></button>
                                                        </td>
                                                        <td class="text-center">
                                                            <button type="button" class="btn btn-sm btn-transparent icueDelBtnRow text-muted" id="remMod_3"><i class="bi bi-trash"></i></button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td >100004</td>
                                                        <td>
                                                        <a href="/Customer" >emily taylor</a> </td>
                                                        <td>Flat No. 412, Green Acres, Chennai</td>
                                                        <td >marine insurance</td>
                                                        <td class="text-center">
                                                            <span class="badge rounded-pill bg-success text-uppercase px-2 py-2 w-75">cold</span>
                                                        </td>
                                                        <td >15-feb-2021</td>
                                                        <td>
                                                            <button type="button" class="btn btn-sm text-capitalize assignModal" data-bs-toggle="modal" data-bs-target="#assignModal" id="assignLeadTo_4" >assign</button>
                                                            <div class="icueAssChgBox" id="icueAssChgBox_4">
                                                                <div class="form-group input-group">
                                                                    <input type="text" class="form-control form-control-sm icueAssLeadTo border-end-0 text-capitalize" name="icueAssLeadTo" id="icueAssLeadTo" value="Jhon" />
                                                                    <span class="input-group-text bg-transparent border-start-0" onclick="changeAssLeadTo();">
                                                                        <span id="icueChgAssLeadTo"><i class="bi bi-x text-danger"></i></span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td class="text-center">
                                                            website
                                                        </td>
                                                        <td class="textColor text-primary">more info</td>
                                                        <td>
                                                            <button type="button" class="btn btn-sm text-white rounded-3 w-100 icueFeedBackBtn text-capitalize" id="sendBtn_4" data-bs-toggle="modal" data-bs-target="#sendRfqModal" style={{lineHeight:"10px", backgroundColor:"#3A3285"}} >send</button>
                                                        </td>
                                                        <td>
                                                            <button type='button' class='btn btn-sm btn-toggle' data-toggle='button' aria-pressed='false' autocomplete='off' id='toggBtn_4'><div class='handle'></div></button>
                                                        </td>
                                                        <td class="text-center">
                                                            <button type="button" class="btn btn-sm btn-transparent icueDelBtnRow text-muted" id="remMod_4"><i class="bi bi-trash"></i></button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td >100005</td>
                                                        <td>
                                                        <a href="/Customer" >alfie smith</a></td>
                                                        <td>H.NO. 78, Plot No. 405, Burail, Chandigarh</td>
                                                        <td >fire insurance</td>
                                                        <td class="text-center">
                                                            <span class="badge rounded-pill bg-warning text-uppercase px-2 py-2 w-75" >warm</span>
                                                        </td>
                                                        <td >20-jun-2021</td>
                                                        <td>
                                                            <button type="button" class="btn btn-sm text-capitalize assignModal" data-bs-toggle="modal" data-bs-target="#assignModal" id="assignLeadTo_5" >assign</button>
                                                            <div class="icueAssChgBox" id="icueAssChgBox_5">
                                                                <div class="form-group input-group">
                                                                    <input type="text" class="form-control form-control-sm icueAssLeadTo border-end-0 text-capitalize" name="icueAssLeadTo" id="icueAssLeadTo" value="Jhon" />
                                                                    <span class="input-group-text bg-transparent border-start-0" onclick="changeAssLeadTo();">
                                                                        <span id="icueChgAssLeadTo"><i class="bi bi-x text-danger"></i></span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td class="text-center" >
                                                            website
                                                        </td>
                                                        <td class="textColor text-info" style={{ color:"#29CC97"}}>received</td>
                                                        <td>
                                                            <button type="button" class="btn btn-sm text-white rounded-3 w-100 icueFeedBackBtn text-capitalize" id="sendBtn_5" data-bs-toggle="modal" data-bs-target="#sendRfqModal" style={{lineHeight:"10px", backgroundColor:"#3A3285"}} >send</button>
                                                        </td>
                                                        <td>
                                                            <button type='button' class='btn btn-sm btn-toggle' data-toggle='button' aria-pressed='false' autocomplete='off' id='toggBtn_5'><div class='handle'></div></button>
                                                        </td>
                                                        <td class="text-center">
                                                            <button type="button" class="btn btn-sm btn-transparent icueDelBtnRow text-muted" id="remMod_5"><i class="bi bi-trash"></i></button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td >100006</td>
                                                        <td>
                                                        <a href="/Customer" >mia evans</a></td>
                                                        <td >H.No. 508, KPHB Colony, Kukatpally, Hyderabad</td>
                                                        <td>car insurance</td>
                                                        <td class="text-center">
                                                            <span class="badge rounded-pill bg-danger text-uppercase px-2 py-2 w-75" >hot</span>
                                                        </td>
                                                        <td >25-aug-2021</td>
                                                        <td>
                                                            <button type="button" class="btn btn-sm text-capitalize assignModal" data-bs-toggle="modal" data-bs-target="#assignModal" id="assignLeadTo_6">assign</button>
                                                            <div class="icueAssChgBox" id="icueAssChgBox_6">
                                                                <div class="form-group input-group">
                                                                    <input type="text" class="form-control form-control-sm icueAssLeadTo border-end-0 text-capitalize" name="icueAssLeadTo" id="icueAssLeadTo" value="Jhon" />
                                                                    <span class="input-group-text bg-transparent border-start-0" onclick="changeAssLeadTo();">
                                                                        <span id="icueChgAssLeadTo"><i class="bi bi-x text-danger"></i></span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td class="text-center" >
                                                            website
                                                        </td>
                                                        <td class="textColor text-info" >received</td>
                                                        <td>
                                                            <button type="button" class="btn btn-sm text-white rounded-3 w-100 icueFeedBackBtn text-capitalize" id="sendBtn_6" data-bs-toggle="modal" data-bs-target="#sendRfqModal" style={{lineHeight:"10px", backgroundColor:"#3A3285"}}>send</button>
                                                        </td>
                                                        <td>
                                                            <button type='button' class='btn btn-sm btn-toggle' data-toggle='button' aria-pressed='false' autocomplete='off' id='toggBtn_6'><div class='handle'></div></button>
                                                        </td>
                                                        <td class="text-center">
                                                            <button type="button" class="btn btn-sm btn-transparent icueDelBtnRow text-muted" id="remMod_6"><i class="bi bi-trash"></i></button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>100007</td>
                                                        <td>
                                                        <a href="/Customer" >ava brown</a></td>
                                                        <td >Flat No. 214/B, Waghoil, Pune</td>
                                                        <td >health insurance</td>
                                                        <td class="text-center">
                                                            <span class="badge rounded-pill bg-success text-uppercase px-2 py-2 w-75" >cold</span>
                                                        </td>
                                                        <td >15-feb-2021</td>
                                                        <td>
                                                            <button type="button" class="btn btn-sm text-capitalize assignModal" data-bs-toggle="modal" data-bs-target="#assignModal" id="assignLeadTo_7">assign</button>
                                                            <div class="icueAssChgBox" id="icueAssChgBox_7">
                                                                <div class="form-group input-group">
                                                                    <input type="text" class="form-control form-control-sm icueAssLeadTo border-end-0 text-capitalize" name="icueAssLeadTo" id="icueAssLeadTo" value="Jhon" />
                                                                    <span class="input-group-text bg-transparent border-start-0" onclick="changeAssLeadTo();">
                                                                        <span id="icueChgAssLeadTo"><i class="bi bi-x text-danger"></i></span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td class="text-center" >
                                                            website
                                                        </td>
                                                        <td class="textColor text-info" >received</td>
                                                        <td>
                                                            <button type="button" class="btn btn-sm text-white rounded-3 w-100 icueFeedBackBtn text-capitalize" id="sendBtn_7" data-bs-toggle="modal" data-bs-target="#sendRfqModal" style={{lineHeight:"10px", backgroundColor:"#3A3285"}}>send</button>
                                                        </td>
                                                        <td>
                                                            <button type='button' class='btn btn-sm btn-toggle' data-toggle='button' aria-pressed='false' autocomplete='off' id='toggBtn_7'><div class='handle'></div></button>
                                                        </td>
                                                        <td class="text-center">
                                                            <button type="button" class="btn btn-sm btn-transparent icueDelBtnRow text-muted" id="remMod_7"><i class="bi bi-trash"></i></button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td >100008</td>
                                                        <td>
                                                        <a href="/Customer" >johnathan fowler</a></td>
                                                        <td >Flat.No. 401, Dayai Bagh, Agra</td>
                                                        <td>life insurance</td>
                                                        <td class="text-center">
                                                            <span class="badge rounded-pill bg-warning text-uppercase px-2 py-2 w-75" >warm</span>
                                                        </td>
                                                        <td >20-jun-2021</td>
                                                        <td>
                                                            <button type="button" class="btn btn-sm text-capitalize assignModal" data-bs-toggle="modal" data-bs-target="#assignModal" id="assignLeadTo_8" >assign</button>
                                                            <div class="icueAssChgBox" id="icueAssChgBox_8">
                                                                <div class="form-group input-group">
                                                                    <input type="text" class="form-control form-control-sm icueAssLeadTo border-end-0 text-capitalize" name="icueAssLeadTo" id="icueAssLeadTo" value="Jhon" />
                                                                    <span class="input-group-text bg-transparent border-start-0" onclick="changeAssLeadTo();">
                                                                        <span id="icueChgAssLeadTo"><i class="bi bi-x text-danger"></i></span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td class="text-center">
                                                            website
                                                        </td>
                                                        <td class="textColor text-danger" >not sent</td>
                                                        <td>
                                                            <button type="button" class="btn btn-sm text-white rounded-3 w-100 icueFeedBackBtn text-capitalize" id="sendBtn_8" data-bs-toggle="modal" data-bs-target="#sendRfqModal" style={{lineHeight:"10px", backgroundColor:"#3A3285"}}>send</button>
                                                        </td>
                                                        <td>
                                                            <button type='button' class='btn btn-sm btn-toggle' data-toggle='button' aria-pressed='false' autocomplete='off' id='toggBtn_8'><div class='handle'></div></button>
                                                        </td>
                                                        <td class="text-center">
                                                            <button type="button" class="btn btn-sm btn-transparent icueDelBtnRow text-muted" id="remMod_8"><i class="bi bi-trash"></i></button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>100009</td>
                                                        <td>
                                                        <a href="/Customer" >isla roberts</a></td>
                                                        <td >Flat No. 302, Indira Nagar, Nashik</td>
                                                        <td >marine insurance</td>
                                                        <td class="text-center">
                                                            <span class="badge rounded-pill bg-danger text-uppercase px-2 py-2 w-75" >hot</span>
                                                        </td>
                                                        <td>25-aug-2021</td>
                                                        <td>
                                                            <button type="button" class="btn btn-sm text-capitalize assignModal" data-bs-toggle="modal" data-bs-target="#assignModal" id="assignLeadTo_9" >assign</button>
                                                            <div class="icueAssChgBox" id="icueAssChgBox_9">
                                                                <div class="form-group input-group">
                                                                    <input type="text" class="form-control form-control-sm icueAssLeadTo border-end-0 text-capitalize" name="icueAssLeadTo" id="icueAssLeadTo" value="Jhon" />
                                                                    <span class="input-group-text bg-transparent border-start-0" onclick="changeAssLeadTo();">
                                                                        <span id="icueChgAssLeadTo"><i class="bi bi-x text-danger"></i></span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td class="text-center"> 
                                                            website
                                                        </td>
                                                        <td class="textColor icueTextPrimary" > sent</td>
                                                        <td>
                                                            <button type="button" class="btn btn-sm text-white rounded-3 w-100 icueFeedBackBtn text-capitalize" id="sendBtn_9" data-bs-toggle="modal" data-bs-target="#sendRfqModal"style={{lineHeight:"10px", backgroundColor:"#3A3285"}}>send</button>
                                                        </td>
                                                        <td>
                                                            <button type='button' class='btn btn-sm btn-toggle' data-toggle='button' aria-pressed='false' autocomplete='off' id='toggBtn_9'><div class='handle'></div></button>
                                                        </td>
                                                        <td class="text-center">
                                                            <button type="button" class="btn btn-sm btn-transparent icueDelBtnRow text-muted" id="remMod_9"><i class="bi bi-trash"></i></button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td >100010</td>
                                                        <td>
                                                        <a href="/Customer" >poppy williams</a></td>
                                                        <td >H.NO. 500/3, Rathinapuri, Coimbatore</td>
                                                        <td> fire insurance</td>
                                                        <td class="text-center">
                                                            <span class="badge rounded-pill bg-success text-uppercase px-2 py-2 w-75">cold</span>
                                                        </td>
                                                        <td >15-feb-2021</td>
                                                        <td>
                                                            <button type="button" class="btn btn-sm text-capitalize assignModal" data-bs-toggle="modal" data-bs-target="#assignModal" id="assignLeadTo_10" >assign</button>
                                                            <div class="icueAssChgBox" id="icueAssChgBox_10">
                                                                <div class="form-group input-group">
                                                                    <input type="text" class="form-control form-control-sm icueAssLeadTo border-end-0 text-capitalize" name="icueAssLeadTo" id="icueAssLeadTo" value="Jhon" />
                                                                    <span class="input-group-text bg-transparent border-start-0" onclick="changeAssLeadTo();">
                                                                        <span id="icueChgAssLeadTo"><i class="bi bi-x text-danger"></i></span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td class="text-center">
                                                            website
                                                        </td>
                                                        <td class="textColor icueTextPrimary" >sent</td>
                                                        <td>
                                                            <button type="button" class="btn btn-sm text-white rounded-3 w-100 icueFeedBackBtn text-capitalize" id="sendBtn_10" data-bs-toggle="modal" data-bs-target="#sendRfqModal" style={{lineHeight:"10px", backgroundColor:"#3A3285"}}>send</button>
                                                        </td>
                                                        <td>
                                                            <button type='button' class='btn btn-sm btn-toggle' data-toggle='button' aria-pressed='false' autocomplete='off' id='toggBtn_10'><div class='handle'></div></button>
                                                        </td>
                                                        <td class="text-center">
                                                            <button type="button" class="btn btn-sm btn-transparent icueDelBtnRow text-muted" id="remMod_10"><i class="bi bi-trash"></i></button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{fontFamily:"poppins"}}>100011</td>
                                                        <td style={{fontFamily:"poppins"}}>annie rose</td>
                                                        <td style={{fontFamily:"Inter"}}>Block No. 9/C, Dwaraka, Delhi</td>
                                                        <td style={{fontFamily:"Inter"}}>car insurance</td>
                                                        <td class="text-center" style={{width:"8rem"}}>
                                                            <span class="badge rounded-pill bg-success text-uppercase px-2 py-2 w-75" style={{fontFamily:"Mulish"}}>cold</span>
                                                        </td>
                                                        <td style={{fontFamily:"Inter"}}>15-feb-2021</td>
                                                        <td>
                                                            <button type="button" class="btn btn-sm text-capitalize assignModal" data-bs-toggle="modal" data-bs-target="#assignModal" id="assignLeadTo_11" style={{fontFamily:"Inter"}}>assign</button>
                                                            <div class="icueAssChgBox" id="icueAssChgBox_11">
                                                                <div class="form-group input-group">
                                                                    <input type="text" class="form-control form-control-sm icueAssLeadTo border-end-0 text-capitalize" name="icueAssLeadTo" id="icueAssLeadTo" value="Jhon" />
                                                                    <span class="input-group-text bg-transparent border-start-0" onclick="changeAssLeadTo();">
                                                                        <span id="icueChgAssLeadTo"><i class="bi bi-x text-danger"></i></span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td class="text-center" style={{fontFamily:"Inter"}}>
                                                            website
                                                        </td>
                                                        <td style={{fontFamily:"Inter"}}>
                                                            not sent
                                                        </td>
                                                        <td>
                                                            <button type="button" class="btn btn-sm text-white rounded-3 w-100 icueFeedBackBtn text-capitalize" id="sendBtn_11" data-bs-toggle="modal" data-bs-target="#sendRfqModal" style={{lineHeight:"10px", backgroundColor:"#3A3285"}}>send</button>
                                                        </td>
                                                        <td>
                                                            <button type='button' class='btn btn-sm btn-toggle' data-toggle='button' aria-pressed='false' autocomplete='off' id='toggBtn_11'><div class='handle'></div></button>
                                                        </td>
                                                        <td class="text-center">
                                                            <button type="button" class="btn btn-sm btn-transparent icueDelBtnRow text-muted" id="remMod_11"><i class="bi bi-trash"></i></button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{fontFamily:"poppins"}}>100012</td>
                                                        <td style={{fontFamily:"poppins"}}>harry lewis</td>
                                                        <td style={{fontFamily:"Inter"}}>Flat No. 928/A, Santacruz, Mumbai</td>
                                                        <td style={{fontFamily:"Inter"}}>health insurance</td>
                                                        <td class="text-center">
                                                            <span class="badge rounded-pill bg-warning text-uppercase px-2 py-2 w-75" style={{fontFamily:"Mulish"}}>warm</span>
                                                        </td>
                                                        <td style={{fontFamily:"Inter"}}>20-jun-2021</td>
                                                        <td>
                                                            <button type="button" class="btn btn-sm text-capitalize assignModal" data-bs-toggle="modal" data-bs-target="#assignModal" id="assignLeadTo_12" style={{fontFamily:"Inter"}}>assign</button>
                                                            <div class="icueAssChgBox" id="icueAssChgBox_12">
                                                                <div class="form-group input-group">
                                                                    <input type="text" class="form-control form-control-sm icueAssLeadTo border-end-0 text-capitalize" name="icueAssLeadTo" id="icueAssLeadTo" value="Jhon" />
                                                                    <span class="input-group-text bg-transparent border-start-0" onclick="changeAssLeadTo();">
                                                                        <span id="icueChgAssLeadTo"><i class="bi bi-x text-danger"></i></span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td class="text-center" style={{fontFamily:"Inter"}}>
                                                            website
                                                        </td>
                                                        <td style={{fontFamily:"Inter"}}>
                                                            not sent
                                                        </td>
                                                        <td>
                                                            <button type="button" class="btn btn-sm text-white rounded-3 w-100 icueFeedBackBtn text-capitalize" id="sendBtn_12" data-bs-toggle="modal" data-bs-target="#sendRfqModal" style={{lineHeight:"10px", backgroundColor:"#3A3285"}}>send</button>
                                                        </td>
                                                        <td>
                                                            <button type='button' class='btn btn-sm btn-toggle' data-toggle='button' aria-pressed='false' autocomplete='off' id='toggBtn_12'><div class='handle'></div></button>
                                                        </td>
                                                        <td class="text-center">
                                                            <button type="button" class="btn btn-sm btn-transparent icueDelBtnRow text-muted" id="remMod_12"><i class="bi bi-trash"></i></button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{fontFamily:"poppins"}}>100013</td>
                                                        <td style={{fontFamily:"poppins"}}>amelia davies</td>
                                                        <td style={{fontFamily:"Inter"}}>HIG-277, Shivaji Nagar, Indore</td>
                                                        <td style={{fontFamily:"Inter"}}>life insurance</td>
                                                        <td class="text-center">
                                                            <span class="badge rounded-pill bg-danger text-uppercase px-2 py-2 w-75" style={{fontFamily:"Mulish"}}>hot</span>
                                                        </td>
                                                        <td style={{fontFamily:"Inter"}}>25-aug-2021</td>
                                                        <td>
                                                            <button type="button" class="btn btn-sm text-capitalize assignModal" data-bs-toggle="modal" data-bs-target="#assignModal" id="assignLeadTo_13" style={{fontFamily:"Inter"}}>assign</button>
                                                            <div class="icueAssChgBox" id="icueAssChgBox_13">
                                                                <div class="form-group input-group">
                                                                    <input type="text" class="form-control form-control-sm icueAssLeadTo border-end-0 text-capitalize" name="icueAssLeadTo" id="icueAssLeadTo" value="Jhon" />
                                                                    <span class="input-group-text bg-transparent border-start-0" onclick="changeAssLeadTo();">
                                                                        <span id="icueChgAssLeadTo"><i class="bi bi-x text-danger"></i></span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td class="text-center" style={{fontFamily:"Inter"}}>
                                                            website
                                                        </td>
                                                        <td style={{fontFamily:"Inter"}}>
                                                            not sent
                                                        </td>
                                                        <td>
                                                            <button type="button" class="btn btn-sm text-white rounded-3 w-100 icueFeedBackBtn text-capitalize" id="sendBtn_13" data-bs-toggle="modal" data-bs-target="#sendRfqModal" style={{lineHeight:"10px", backgroundColor:"#3A3285"}}>send</button>
                                                        </td>
                                                        <td>
                                                            <button type='button' class='btn btn-sm btn-toggle' data-toggle='button' aria-pressed='false' autocomplete='off' id='toggBtn_13'><div class='handle'></div></button>
                                                        </td>
                                                        <td class="text-center">
                                                            <button type="button" class="btn btn-sm btn-transparent icueDelBtnRow text-muted" id="remMod_13"><i class="bi bi-trash"></i></button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{fontFamily:"poppins"}}>100014</td>
                                                        <td style={{fontFamily:"poppins"}}>emily taylor</td>
                                                        <td style={{fontFamily:"Inter"}}>Flat No. 412, Green Acres, Chennai</td>
                                                        <td style={{fontFamily:"Inter"}}>marine insurance</td>
                                                        <td class="text-center">
                                                            <span class="badge rounded-pill bg-success text-uppercase px-2 py-2 w-75" style={{fontFamily:"Mulish"}}>cold</span>
                                                        </td>
                                                        <td style={{fontFamily:"Inter"}}>15-feb-2021</td>
                                                        <td>
                                                            <button type="button" class="btn btn-sm text-capitalize assignModal" data-bs-toggle="modal" data-bs-target="#assignModal" id="assignLeadTo_14" style={{fontFamily:"Inter"}}>assign</button>
                                                            <div class="icueAssChgBox" id="icueAssChgBox_14">
                                                                <div class="form-group input-group">
                                                                    <input type="text" class="form-control form-control-sm icueAssLeadTo border-end-0 text-capitalize" name="icueAssLeadTo" id="icueAssLeadTo" value="Jhon" />
                                                                    <span class="input-group-text bg-transparent border-start-0" onclick="changeAssLeadTo();">
                                                                        <span id="icueChgAssLeadTo"><i class="bi bi-x text-danger"></i></span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td class="text-center" style={{fontFamily:"Inter"}}>
                                                            website
                                                        </td>
                                                        <td style={{fontFamily:"Inter"}}>
                                                            not sent
                                                        </td>
                                                        <td>
                                                            <button type="button" class="btn btn-sm text-white rounded-3 w-100 icueFeedBackBtn text-capitalize" id="sendBtn_14" data-bs-toggle="modal" data-bs-target="#sendRfqModal" style={{lineHeight:"10px", backgroundColor:"#3A3285"}}>send</button>
                                                        </td>
                                                        <td>
                                                            <button type='button' class='btn btn-sm btn-toggle' data-toggle='button' aria-pressed='false' autocomplete='off' id='toggBtn_14'><div class='handle'></div></button>
                                                        </td>
                                                        <td class="text-center">
                                                            <button type="button" class="btn btn-sm btn-transparent icueDelBtnRow text-muted" id="remMod_14"><i class="bi bi-trash"></i></button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{fontFamily:"poppins"}}>100015</td>
                                                        <td style={{fontFamily:"poppins"}}>alfie smith</td>
                                                        <td style={{fontFamily:"Inter"}}>H.NO. 78, Plot No. 405, Burail, Chandigarh</td>
                                                        <td style={{fontFamily:"Inter"}}>fire insurance</td>
                                                        <td class="text-center">
                                                            <span class="badge rounded-pill bg-warning text-uppercase px-2 py-2 w-75" style={{fontFamily:"Mulish"}}>warm</span>
                                                        </td>
                                                        <td style={{fontFamily:"Inter"}}>20-jun-2021</td>
                                                        <td>
                                                            <button type="button" class="btn btn-sm text-capitalize assignModal" data-bs-toggle="modal" data-bs-target="#assignModal" id="assignLeadTo_15"style={{fontFamily:"Inter"}}>assign</button>
                                                            <div class="icueAssChgBox" id="icueAssChgBox_15">
                                                                <div class="form-group input-group">
                                                                    <input type="text" class="form-control form-control-sm icueAssLeadTo border-end-0 text-capitalize" name="icueAssLeadTo" id="icueAssLeadTo" value="Jhon" />
                                                                    <span class="input-group-text bg-transparent border-start-0" onclick="changeAssLeadTo();">
                                                                        <span id="icueChgAssLeadTo"><i class="bi bi-x text-danger"></i></span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td class="text-center"style={{fontFamily:"Inter"}}>
                                                            website
                                                        </td>
                                                        <td style={{fontFamily:"Inter"}}>
                                                            not sent
                                                        </td>
                                                        <td>
                                                            <button type="button" class="btn btn-sm text-white rounded-3 w-100 icueFeedBackBtn text-capitalize" id="sendBtn_15" data-bs-toggle="modal" data-bs-target="#sendRfqModal" style={{lineHeight:"10px", backgroundColor:"#3A3285"}}>send</button>
                                                        </td>
                                                        <td>
                                                            <button type='button' class='btn btn-sm btn-toggle' data-toggle='button' aria-pressed='false' autocomplete='off' id='toggBtn_15'><div class='handle'></div></button>
                                                        </td>
                                                        <td class="text-center">
                                                            <button type="button" class="btn btn-sm btn-transparent icueDelBtnRow text-muted" id="remMod_15"><i class="bi bi-trash"></i></button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{fontFamily:"poppins"}}>100016</td>
                                                        <td style={{fontFamily:"poppins"}}>mia evans</td>
                                                        <td style={{fontFamily:"Inter"}}>H.No. 508, KPHB Colony, Kukatpally, Hyderabad</td>
                                                        <td style={{fontFamily:"Inter"}}>car insurance</td>
                                                        <td class="text-center">
                                                            <span class="badge rounded-pill bg-danger text-uppercase px-2 py-2 w-75" style={{fontFamily:"Mulish"}}>hot</span>
                                                        </td>
                                                        <td style={{fontFamily:"Inter"}}>25-aug-2021</td>
                                                        <td>
                                                            <button type="button" class="btn btn-sm text-capitalize assignModal" data-bs-toggle="modal" data-bs-target="#assignModal" id="assignLeadTo_16" style={{fontFamily:"Inter"}}>assign</button>
                                                            <div class="icueAssChgBox" id="icueAssChgBox_16">
                                                                <div class="form-group input-group">
                                                                    <input type="text" class="form-control form-control-sm icueAssLeadTo border-end-0 text-capitalize" name="icueAssLeadTo" id="icueAssLeadTo" value="Jhon" />
                                                                    <span class="input-group-text bg-transparent border-start-0" onclick="changeAssLeadTo();">
                                                                        <span id="icueChgAssLeadTo"><i class="bi bi-x text-danger"></i></span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td class="text-center" style={{fontFamily:"Inter"}}>
                                                            website
                                                        </td>
                                                        <td style={{fontFamily:"Inter"}}>
                                                            not sent
                                                        </td>
                                                        <td>
                                                            <button type="button" class="btn btn-sm text-white rounded-3 w-100 icueFeedBackBtn text-capitalize" id="sendBtn_16" data-bs-toggle="modal" data-bs-target="#sendRfqModal"style={{lineHeight:"10px", backgroundColor:"#3A3285"}}>send</button>
                                                        </td>
                                                        <td>
                                                            <button type='button' class='btn btn-sm btn-toggle' data-toggle='button' aria-pressed='false' autocomplete='off' id='toggBtn_16'><div class='handle'></div></button>
                                                        </td>
                                                        <td class="text-center">
                                                            <button type="button" class="btn btn-sm btn-transparent icueDelBtnRow text-muted" id="remMod_16"><i class="bi bi-trash"></i></button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{fontFamily:"poppins"}}>100017</td>
                                                        <td style={{fontFamily:"poppins"}}>ava brown</td>
                                                        <td style={{fontFamily:"Inter"}}>Flat No. 214/B, Waghoil, Pune</td>
                                                        <td style={{fontFamily:"Inter"}}>health insurance</td>
                                                        <td class="text-center">
                                                            <span class="badge rounded-pill bg-success text-uppercase px-2 py-2 w-75" style={{fontFamily:"Mulish"}}>cold</span>
                                                        </td>
                                                        <td style={{fontFamily:"Inter"}}>15-feb-2021</td>
                                                        <td>
                                                            <button type="button" class="btn btn-sm text-capitalize assignModal" data-bs-toggle="modal" data-bs-target="#assignModal" id="assignLeadTo_17" style={{fontFamily:"Inter"}}>assign</button>
                                                            <div class="icueAssChgBox" id="icueAssChgBox_17">
                                                                <div class="form-group input-group">
                                                                    <input type="text" class="form-control form-control-sm icueAssLeadTo border-end-0 text-capitalize" name="icueAssLeadTo" id="icueAssLeadTo" value="Jhon" />
                                                                    <span class="input-group-text bg-transparent border-start-0" onclick="changeAssLeadTo();">
                                                                        <span id="icueChgAssLeadTo"><i class="bi bi-x text-danger"></i></span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td class="text-center"style={{fontFamily:"Inter"}}>
                                                            website
                                                        </td>
                                                        <td style={{fontFamily:"Inter"}}>
                                                            not sent
                                                        </td>
                                                        <td>
                                                            <button type="button" class="btn btn-sm text-white rounded-3 w-100 icueFeedBackBtn text-capitalize" id="sendBtn_17" data-bs-toggle="modal" data-bs-target="#sendRfqModal" style={{lineHeight:"10px", backgroundColor:"#3A3285"}}>send</button>
                                                        </td>
                                                        <td>
                                                            <button type='button' class='btn btn-sm btn-toggle' data-toggle='button' aria-pressed='false' autocomplete='off' id='toggBtn_17'><div class='handle'></div></button>
                                                        </td>
                                                        <td class="text-center">
                                                            <button type="button" class="btn btn-sm btn-transparent icueDelBtnRow text-muted" id="remMod_17"><i class="bi bi-trash"></i></button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{fontFamily:"poppins"}}>100018</td>
                                                        <td style={{fontFamily:"poppins"}}>johnathan fowler</td>
                                                        <td style={{fontFamily:"Inter"}}>Flat.No. 401, Dayai Bagh, Agra</td>
                                                        <td style={{fontFamily:"Inter"}}>life insurance</td>
                                                        <td class="text-center">
                                                            <span class="badge rounded-pill bg-warning text-uppercase px-2 py-2 w-75"style={{fontFamily:"Mulish"}}>warm</span>
                                                        </td>
                                                        <td style={{fontFamily:"Inter"}}>20-jun-2021</td>
                                                        <td>
                                                            <button type="button" class="btn btn-sm text-capitalize assignModal" data-bs-toggle="modal" data-bs-target="#assignModal" id="assignLeadTo_18" style={{fontFamily:"Inter"}}>assign</button>
                                                            <div class="icueAssChgBox" id="icueAssChgBox_18">
                                                                <div class="form-group input-group">
                                                                    <input type="text" class="form-control form-control-sm icueAssLeadTo border-end-0 text-capitalize" name="icueAssLeadTo" id="icueAssLeadTo" value="Jhon" />
                                                                    <span class="input-group-text bg-transparent border-start-0" onclick="changeAssLeadTo();">
                                                                        <span id="icueChgAssLeadTo"><i class="bi bi-x text-danger"></i></span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td class="text-center" style={{fontFamily:"Inter"}}>
                                                            website
                                                        </td>
                                                        <td style={{fontFamily:"Inter"}}>
                                                            not sent
                                                        </td>
                                                        <td>
                                                            <button type="button" class="btn btn-sm text-white rounded-3 w-100 icueFeedBackBtn text-capitalize" id="sendBtn_18" data-bs-toggle="modal" data-bs-target="#sendRfqModal" style={{lineHeight:"10px", backgroundColor:"#3A3285"}}>send</button>
                                                        </td>
                                                        <td>
                                                            <button type='button' class='btn btn-sm btn-toggle' data-toggle='button' aria-pressed='false' autocomplete='off' id='toggBtn_18'><div class='handle'></div></button>
                                                        </td>
                                                        <td class="text-center">
                                                            <button type="button" class="btn btn-sm btn-transparent icueDelBtnRow text-muted" id="remMod_18"><i class="bi bi-trash"></i></button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{fontFamily:"poppins"}}>100019</td>
                                                        <td style={{fontFamily:"poppins"}}>isla roberts</td>
                                                        <td style={{fontFamily:"Inter"}}>Flat No. 302, Indira Nagar, Nashik</td>
                                                        <td style={{fontFamily:"Inter"}}>marine insurance</td>
                                                        <td class="text-center">
                                                            <span class="badge rounded-pill bg-danger text-uppercase px-2 py-2 w-75"style={{fontFamily:"Mulish"}}>hot</span>
                                                        </td>
                                                        <td style={{fontFamily:"Inter"}}>25-aug-2021</td>
                                                        <td>
                                                            <button type="button" class="btn btn-sm text-capitalize assignModal" data-bs-toggle="modal" data-bs-target="#assignModal" id="assignLeadTo_19" style={{fontFamily:"Inter"}}>assign</button>
                                                            <div class="icueAssChgBox" id="icueAssChgBox_19">
                                                                <div class="form-group input-group">
                                                                    <input type="text" class="form-control form-control-sm icueAssLeadTo border-end-0 text-capitalize" name="icueAssLeadTo" id="icueAssLeadTo" value="Jhon" />
                                                                    <span class="input-group-text bg-transparent border-start-0" onclick="changeAssLeadTo();">
                                                                        <span id="icueChgAssLeadTo"><i class="bi bi-x text-danger"></i></span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td class="text-center" style={{fontFamily:"Inter"}}>
                                                            website
                                                        </td>
                                                        <td style={{fontFamily:"Inter"}}>
                                                            not sent
                                                        </td>
                                                        <td>
                                                            <button type="button" class="btn btn-sm text-white rounded-3 w-100 icueFeedBackBtn text-capitalize" id="sendBtn_19" data-bs-toggle="modal" data-bs-target="#sendRfqModal" style={{lineHeight:"10px", backgroundColor:"#3A3285"}}>send</button>
                                                        </td>
                                                        <td>
                                                            <button type='button' class='btn btn-sm btn-toggle' data-toggle='button' aria-pressed='false' autocomplete='off' id='toggBtn_19'><div class='handle'></div></button>
                                                        </td>
                                                        <td class="text-center">
                                                            <button type="button" class="btn btn-sm btn-transparent icueDelBtnRow text-muted" id="remMod_19"><i class="bi bi-trash"></i></button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{fontFamily:"poppins"}}>100020</td>
                                                        <td style={{fontFamily:"poppins"}}>poppy williams</td>
                                                        <td style={{fontFamily:"Inter"}}>H.NO. 500/3, Rathinapuri, Coimbatore</td>
                                                        <td style={{fontFamily:"Inter"}}>fire insurance</td>
                                                        <td class="text-center">
                                                            <span class="badge rounded-pill bg-success text-uppercase px-2 py-2 w-75" style={{fontFamily:"Mulish"}}>cold</span>
                                                        </td>
                                                        <td style={{fontFamily:"Inter"}}>15-feb-2021</td>
                                                        <td>
                                                            <button type="button" class="btn btn-sm text-capitalize assignModal" data-bs-toggle="modal" data-bs-target="#assignModal" id="assignLeadTo_20" style={{fontFamily:"Inter"}}>assign</button>
                                                            <div class="icueAssChgBox" id="icueAssChgBox_20">
                                                                <div class="form-group input-group">
                                                                    <input type="text" class="form-control form-control-sm icueAssLeadTo border-end-0 text-capitalize" name="icueAssLeadTo" id="icueAssLeadTo" value="Jhon" />
                                                                    <span class="input-group-text bg-transparent border-start-0" onclick="changeAssLeadTo();">
                                                                        <span id="icueChgAssLeadTo"><i class="bi bi-x text-danger"></i></span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td class="text-center" style={{fontFamily:"Inter"}}>
                                                            website
                                                        </td >
                                                        <td style={{fontFamily:"Inter"}}>
                                                            not sent
                                                        </td>
                                                        <td>
                                                            <button type="button" class="btn btn-sm text-white rounded-3 w-100 icueFeedBackBtn text-capitalize" id="sendBtn_20" data-bs-toggle="modal" data-bs-target="#sendRfqModal" style={{lineHeight:"10px", backgroundColor:"#3A3285"}}>send</button>
                                                        </td>
                                                        <td>
                                                            <button type='button' class='btn btn-sm btn-toggle' data-toggle='button' aria-pressed='false' autocomplete='off' id='toggBtn_20'><div class='handle'></div></button>
                                                        </td>
                                                        <td class="text-center">
                                                            <button type="button" class="btn btn-sm btn-transparent icueDelBtnRow text-muted" id="remMod_20"><i class="bi bi-trash"></i></button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{fontFamily:"poppins"}}>100021</td>
                                                        <td style={{fontFamily:"poppins"}}>annie rose</td>
                                                        <td style={{fontFamily:"Inter"}}>Block No. 9/C, Dwaraka, Delhi</td>
                                                        <td style={{fontFamily:"Inter"}}>car insurance</td>
                                                        <td class="text-center" style={{width:"8rem"}}>
                                                            <span class="badge rounded-pill bg-success text-uppercase px-2 py-2 w-75" style={{fontFamily:"Mulish"}}>cold</span>
                                                        </td>
                                                        <td style={{fontFamily:"Inter"}}>15-feb-2021</td>
                                                        <td>
                                                            <button type="button" class="btn btn-sm text-capitalize assignModal" data-bs-toggle="modal" data-bs-target="#assignModal" id="assignLeadTo_21" style={{fontFamily:"Inter"}}>assign</button>
                                                            <div class="icueAssChgBox" id="icueAssChgBox_21">
                                                                <div class="form-group input-group">
                                                                    <input type="text" class="form-control form-control-sm icueAssLeadTo border-end-0 text-capitalize" name="icueAssLeadTo" id="icueAssLeadTo" value="Jhon" />
                                                                    <span class="input-group-text bg-transparent border-start-0" onclick="changeAssLeadTo();">
                                                                        <span id="icueChgAssLeadTo"><i class="bi bi-x text-danger"></i></span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td class="text-center" style={{fontFamily:"Inter"}}>
                                                            website
                                                        </td>
                                                        <td style={{fontFamily:"Inter"}}>
                                                            not sent
                                                        </td>
                                                        <td>
                                                            <button type="button" class="btn btn-sm text-white rounded-3 w-100 icueFeedBackBtn text-capitalize" id="sendBtn_21" data-bs-toggle="modal" data-bs-target="#sendRfqModal" style={{lineHeight:"10px", backgroundColor:"#3A3285"}}>send</button>
                                                        </td>
                                                        <td>
                                                            <button type='button' class='btn btn-sm btn-toggle' data-toggle='button' aria-pressed='false' autocomplete='off' id='toggBtn_21'><div class='handle'></div></button>
                                                        </td>
                                                        <td class="text-center">
                                                            <button type="button" class="btn btn-sm btn-transparent icueDelBtnRow text-muted" id="remMod_21"><i class="bi bi-trash"></i></button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{fontFamily:"poppins"}}>100022</td>
                                                        <td style={{fontFamily:"poppins"}}>harry lewis</td>
                                                        <td style={{fontFamily:"Inter"}}>Flat No. 928/A, Santacruz, Mumbai</td>
                                                        <td style={{fontFamily:"Inter"}}>health insurance</td>
                                                        <td class="text-center">
                                                            <span class="badge rounded-pill bg-warning text-uppercase px-2 py-2 w-75" style={{fontFamily:"Mulish"}}>warm</span>
                                                        </td>
                                                        <td style={{fontFamily:"Inter"}}>20-jun-2021</td>
                                                        <td>
                                                            <button type="button" class="btn btn-sm text-capitalize assignModal" data-bs-toggle="modal" data-bs-target="#assignModal" id="assignLeadTo_22"style={{fontFamily:"Inter"}}>assign</button>
                                                            <div class="icueAssChgBox" id="icueAssChgBox_22">
                                                                <div class="form-group input-group">
                                                                    <input type="text" class="form-control form-control-sm icueAssLeadTo border-end-0 text-capitalize" name="icueAssLeadTo" id="icueAssLeadTo" value="Jhon" />
                                                                    <span class="input-group-text bg-transparent border-start-0" onclick="changeAssLeadTo();">
                                                                        <span id="icueChgAssLeadTo"><i class="bi bi-x text-danger"></i></span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td class="text-center" style={{fontFamily:"Inter"}}>
                                                            website
                                                        </td>
                                                        <td style={{fontFamily:"Inter"}}>
                                                            not sent
                                                        </td>
                                                        <td>
                                                            <button type="button" class="btn btn-sm text-white rounded-3 w-100 icueFeedBackBtn text-capitalize" id="sendBtn_22" data-bs-toggle="modal" data-bs-target="#sendRfqModal" style={{lineHeight:"10px", backgroundColor:"#3A3285"}}>send</button>
                                                        </td>
                                                        <td>
                                                            <button type='button' class='btn btn-sm btn-toggle' data-toggle='button' aria-pressed='false' autocomplete='off' id='toggBtn_22'><div class='handle'></div></button>
                                                        </td>
                                                        <td class="text-center">
                                                            <button type="button" class="btn btn-sm btn-transparent icueDelBtnRow text-muted" id="remMod_22"><i class="bi bi-trash"></i></button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{fontFamily:"poppins"}}>100023</td>
                                                        <td style={{fontFamily:"poppins"}}>amelia davies</td>
                                                        <td style={{fontFamily:"Inter"}}>HIG-277, Shivaji Nagar, Indore</td>
                                                        <td style={{fontFamily:"Inter"}}>life insurance</td>
                                                        <td class="text-center">
                                                            <span class="badge rounded-pill bg-danger text-uppercase px-2 py-2 w-75" style={{fontFamily:"Mulish"}}>hot</span>
                                                        </td>
                                                        <td style={{fontFamily:"Inter"}}>25-aug-2021</td>
                                                        <td>
                                                            <button type="button" class="btn btn-sm text-capitalize assignModal" data-bs-toggle="modal" data-bs-target="#assignModal" id="assignLeadTo_23"style={{fontFamily:"Inter"}}>assign</button>
                                                            <div class="icueAssChgBox" id="icueAssChgBox_23">
                                                                <div class="form-group input-group">
                                                                    <input type="text" class="form-control form-control-sm icueAssLeadTo border-end-0 text-capitalize" name="icueAssLeadTo" id="icueAssLeadTo" value="Jhon" />
                                                                    <span class="input-group-text bg-transparent border-start-0" onclick="changeAssLeadTo();">
                                                                        <span id="icueChgAssLeadTo"><i class="bi bi-x text-danger"></i></span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td class="text-center"style={{fontFamily:"Inter"}}>
                                                            website
                                                        </td>
                                                        <td style={{fontFamily:"Inter"}}>
                                                            not sent
                                                        </td>
                                                        <td>
                                                            <button type="button" class="btn btn-sm text-white rounded-3 w-100 icueFeedBackBtn text-capitalize" id="sendBtn_23" data-bs-toggle="modal" data-bs-target="#sendRfqModal"style={{lineHeight:"10px", backgroundColor:"#3A3285"}}>send</button>
                                                        </td>
                                                        <td>
                                                            <button type='button' class='btn btn-sm btn-toggle' data-toggle='button' aria-pressed='false' autocomplete='off' id='toggBtn_23'><div class='handle'></div></button>
                                                        </td>
                                                        <td class="text-center">
                                                            <button type="button" class="btn btn-sm btn-transparent icueDelBtnRow text-muted" id="remMod_23"><i class="bi bi-trash"></i></button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{fontFamily:"poppins"}}>100024</td>
                                                        <td style={{fontFamily:"poppins"}}>emily taylor</td>
                                                        <td style={{fontFamily:"Inter"}}>Flat No. 412, Green Acres, Chennai</td>
                                                        <td style={{fontFamily:"Inter"}}>marine insurance</td>
                                                        <td class="text-center">
                                                            <span class="badge rounded-pill bg-success text-uppercase px-2 py-2 w-75" style={{fontFamily:"Mulish"}}>cold</span>
                                                        </td>
                                                        <td style={{fontFamily:"Inter"}}>15-feb-2021</td>
                                                        <td>
                                                            <button type="button" class="btn btn-sm text-capitalize assignModal" data-bs-toggle="modal" data-bs-target="#assignModal" id="assignLeadTo_24"style={{fontFamily:"Inter"}}>assign</button>
                                                            <div class="icueAssChgBox" id="icueAssChgBox_24">
                                                                <div class="form-group input-group">
                                                                    <input type="text" class="form-control form-control-sm icueAssLeadTo border-end-0 text-capitalize" name="icueAssLeadTo" id="icueAssLeadTo" value="Jhon" />
                                                                    <span class="input-group-text bg-transparent border-start-0" onclick="changeAssLeadTo();">
                                                                        <span id="icueChgAssLeadTo"><i class="bi bi-x text-danger"></i></span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td class="text-center"style={{fontFamily:"Inter"}}>
                                                            website
                                                        </td>
                                                        <td style={{fontFamily:"Inter"}}>
                                                            not sent
                                                        </td>
                                                        <td>
                                                            <button type="button" class="btn btn-sm text-white rounded-3 w-100 icueFeedBackBtn text-capitalize" id="sendBtn_24" data-bs-toggle="modal" data-bs-target="#sendRfqModal" style={{lineHeight:"10px", backgroundColor:"#3A3285"}}>send</button>
                                                        </td>
                                                        <td>
                                                            <button type='button' class='btn btn-sm btn-toggle' data-toggle='button' aria-pressed='false' autocomplete='off' id='toggBtn_24'><div class='handle'></div></button>
                                                        </td>
                                                        <td class="text-center">
                                                            <button type="button" class="btn btn-sm btn-transparent icueDelBtnRow text-muted" id="remMod_24"><i class="bi bi-trash"></i></button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{fontFamily:"poppins"}}>100025</td>
                                                        <td style={{fontFamily:"poppins"}}>alfie smith</td>
                                                        <td style={{fontFamily:"Inter"}}>H.NO. 78, Plot No. 405, Burail, Chandigarh</td>
                                                        <td style={{fontFamily:"Inter"}}>fire insurance</td>
                                                        <td class="text-center">
                                                            <span class="badge rounded-pill bg-warning text-uppercase px-2 py-2 w-75" style={{fontFamily:"Mulish"}}>warm</span>
                                                        </td>
                                                        <td style={{fontFamily:"Inter"}}>20-jun-2021</td>
                                                        <td>
                                                            <button type="button" class="btn btn-sm text-capitalize assignModal" data-bs-toggle="modal" data-bs-target="#assignModal" id="assignLeadTo_25" style={{fontFamily:"Inter"}}>assign</button>
                                                            <div class="icueAssChgBox" id="icueAssChgBox_25">
                                                                <div class="form-group input-group">
                                                                    <input type="text" class="form-control form-control-sm icueAssLeadTo border-end-0 text-capitalize" name="icueAssLeadTo" id="icueAssLeadTo" value="Jhon" />
                                                                    <span class="input-group-text bg-transparent border-start-0" onclick="changeAssLeadTo();">
                                                                        <span id="icueChgAssLeadTo"><i class="bi bi-x text-danger"></i></span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td class="text-center" style={{fontFamily:"Inter"}}>
                                                            website
                                                        </td>
                                                        <td style={{fontFamily:"Inter"}}>
                                                            not sent
                                                        </td>
                                                        <td>
                                                            <button type="button" class="btn btn-sm text-white rounded-3 w-100 icueFeedBackBtn text-capitalize" id="sendBtn_25" data-bs-toggle="modal" data-bs-target="#sendRfqModal" style={{lineHeight:"10px", backgroundColor:"#3A3285"}}>send</button>
                                                        </td>
                                                        <td>
                                                            <button type='button' class='btn btn-sm btn-toggle' data-toggle='button' aria-pressed='false' autocomplete='off' id='toggBtn_25'><div class='handle'></div></button>
                                                        </td>
                                                        <td class="text-center">
                                                            <button type="button" class="btn btn-sm btn-transparent icueDelBtnRow text-muted" id="remMod_25"><i class="bi bi-trash"></i></button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{fontFamily:"poppins"}}>100026</td>
                                                        <td style={{fontFamily:"poppins"}}>mia evans</td>
                                                        <td style={{fontFamily:"Inter"}}>H.No. 508, KPHB Colony, Kukatpally, Hyderabad</td>
                                                        <td style={{fontFamily:"Inter"}}>car insurance</td>
                                                        <td class="text-center">
                                                            <span class="badge rounded-pill bg-danger text-uppercase px-2 py-2 w-75"style={{fontFamily:"Mulish"}}>hot</span>
                                                        </td>
                                                        <td style={{fontFamily:"Inter"}}>25-aug-2021</td>
                                                        <td>
                                                            <button type="button" class="btn btn-sm text-capitalize assignModal" data-bs-toggle="modal" data-bs-target="#assignModal" id="assignLeadTo_26"style={{fontFamily:"Inter"}}>assign</button>
                                                            <div class="icueAssChgBox" id="icueAssChgBox_26">
                                                                <div class="form-group input-group">
                                                                    <input type="text" class="form-control form-control-sm icueAssLeadTo border-end-0 text-capitalize" name="icueAssLeadTo" id="icueAssLeadTo" value="Jhon" />
                                                                    <span class="input-group-text bg-transparent border-start-0" onclick="changeAssLeadTo();">
                                                                        <span id="icueChgAssLeadTo"><i class="bi bi-x text-danger"></i></span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td class="text-center"style={{fontFamily:"Inter"}}>
                                                            website
                                                        </td>
                                                        <td style={{fontFamily:"Inter"}}>
                                                            not sent
                                                        </td>
                                                        <td>
                                                            <button type="button" class="btn btn-sm text-white rounded-3 w-100 icueFeedBackBtn text-capitalize" id="sendBtn_26" data-bs-toggle="modal" data-bs-target="#sendRfqModal"style={{lineHeight:"10px", backgroundColor:"#3A3285"}}>send</button>
                                                        </td>
                                                        <td>
                                                            <button type='button' class='btn btn-sm btn-toggle' data-toggle='button' aria-pressed='false' autocomplete='off' id='toggBtn_26'><div class='handle'></div></button>
                                                        </td>
                                                        <td class="text-center">
                                                            <button type="button" class="btn btn-sm btn-transparent icueDelBtnRow text-muted" id="remMod_26"><i class="bi bi-trash"></i></button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{fontFamily:"poppins"}}>100027</td>
                                                        <td style={{fontFamily:"poppins"}}>ava brown</td>
                                                        <td style={{fontFamily:"Inter"}}>Flat No. 214/B, Waghoil, Pune</td>
                                                        <td style={{fontFamily:"Inter"}}>health insurance</td>
                                                        <td class="text-center">
                                                            <span class="badge rounded-pill bg-success text-uppercase px-2 py-2 w-75" style={{fontFamily:"Mulish"}}>cold</span>
                                                        </td>
                                                        <td style={{fontFamily:"Inter"}}>15-feb-2021</td>
                                                        <td>
                                                            <button type="button" class="btn btn-sm text-capitalize assignModal" data-bs-toggle="modal" data-bs-target="#assignModal" id="assignLeadTo_27"style={{fontFamily:"Inter"}}>assign</button>
                                                            <div class="icueAssChgBox" id="icueAssChgBox_27">
                                                                <div class="form-group input-group">
                                                                    <input type="text" class="form-control form-control-sm icueAssLeadTo border-end-0 text-capitalize" name="icueAssLeadTo" id="icueAssLeadTo" value="Jhon" />
                                                                    <span class="input-group-text bg-transparent border-start-0" onclick="changeAssLeadTo();">
                                                                        <span id="icueChgAssLeadTo"><i class="bi bi-x text-danger"></i></span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td class="text-center" style={{fontFamily:"Inter"}}>
                                                            website
                                                        </td>
                                                        <td style={{fontFamily:"Inter"}}>
                                                            not sent
                                                        </td>
                                                        <td>
                                                            <button type="button" class="btn btn-sm text-white rounded-3 w-100 icueFeedBackBtn text-capitalize" id="sendBtn_27" data-bs-toggle="modal" data-bs-target="#sendRfqModal"style={{lineHeight:"10px", backgroundColor:"#3A3285"}}>send</button>
                                                        </td>
                                                        <td>
                                                            <button type='button' class='btn btn-sm btn-toggle' data-toggle='button' aria-pressed='false' autocomplete='off' id='toggBtn_27'><div class='handle'></div></button>
                                                        </td>
                                                        <td class="text-center">
                                                            <button type="button" class="btn btn-sm btn-transparent icueDelBtnRow text-muted" id="remMod_27"><i class="bi bi-trash"></i></button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{fontFamily:"poppins"}}>100028</td>
                                                        <td style={{fontFamily:"poppins"}}>johnathan fowler</td>
                                                        <td style={{fontFamily:"Inter"}}>Flat.No. 401, Dayai Bagh, Agra</td>
                                                        <td style={{fontFamily:"Inter"}}>life insurance</td>
                                                        <td class="text-center">
                                                            <span class="badge rounded-pill bg-warning text-uppercase px-2 py-2 w-75" style={{fontFamily:"Mulish"}}>warn</span>
                                                        </td>
                                                        <td style={{fontFamily:"Inter"}}>20-jun-2021</td>
                                                        <td>
                                                            <button type="button" class="btn btn-sm text-capitalize assignModal" data-bs-toggle="modal" data-bs-target="#assignModal" id="assignLeadTo_28" style={{fontFamily:"Inter"}}>assign</button>
                                                            <div class="icueAssChgBox" id="icueAssChgBox_28">
                                                                <div class="form-group input-group">
                                                                    <input type="text" class="form-control form-control-sm icueAssLeadTo border-end-0 text-capitalize" name="icueAssLeadTo" id="icueAssLeadTo" value="Jhon" />
                                                                    <span class="input-group-text bg-transparent border-start-0" onclick="changeAssLeadTo();">
                                                                        <span id="icueChgAssLeadTo"><i class="bi bi-x text-danger"></i></span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td class="text-center" style={{fontFamily:"Inter"}}>
                                                            website
                                                        </td>
                                                        <td style={{fontFamily:"Inter"}}>
                                                            not sent
                                                        </td>
                                                        <td>
                                                            <button type="button" class="btn btn-sm text-white rounded-3 w-100 icueFeedBackBtn text-capitalize" id="sendBtn_28" data-bs-toggle="modal" data-bs-target="#sendRfqModal" style={{lineHeight:"10px", backgroundColor:"#3A3285"}}>send</button>
                                                        </td>
                                                        <td>
                                                            <button type='button' class='btn btn-sm btn-toggle' data-toggle='button' aria-pressed='false' autocomplete='off' id='toggBtn_28'><div class='handle'></div></button>
                                                        </td>
                                                        <td class="text-center">
                                                            <button type="button" class="btn btn-sm btn-transparent icueDelBtnRow text-muted" id="remMod_28"><i class="bi bi-trash"></i></button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{fontFamily:"poppins"}}>100029</td>
                                                        <td style={{fontFamily:"poppins"}}>isla roberts</td>
                                                        <td style={{fontFamily:"Inter"}}>Flat No. 302, Indira Nagar, Nashik</td>
                                                        <td style={{fontFamily:"Inter"}}>marine insurance</td>
                                                        <td class="text-center">
                                                            <span class="badge rounded-pill bg-danger text-uppercase px-2 py-2 w-75" style={{fontFamily:"Mulish"}}>hot</span>
                                                        </td>
                                                        <td style={{fontFamily:"Inter"}}>25-aug-2021</td>
                                                        <td>
                                                            <button type="button" class="btn btn-sm text-capitalize assignModal" data-bs-toggle="modal" data-bs-target="#assignModal" id="assignLeadTo_29" style={{fontFamily:"Inter"}}>assign</button>
                                                            <div class="icueAssChgBox" id="icueAssChgBox_29">
                                                                <div class="form-group input-group">
                                                                    <input type="text" class="form-control form-control-sm icueAssLeadTo border-end-0 text-capitalize" name="icueAssLeadTo" id="icueAssLeadTo" value="Jhon" />
                                                                    <span class="input-group-text bg-transparent border-start-0" onclick="changeAssLeadTo();">
                                                                        <span id="icueChgAssLeadTo"><i class="bi bi-x text-danger"></i></span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td class="text-center" style={{fontFamily:"Inter"}}>
                                                            website
                                                        </td>
                                                        <td style={{fontFamily:"Inter"}}>
                                                            not sent
                                                        </td>
                                                        <td>
                                                            <button type="button" class="btn btn-sm text-white rounded-3 w-100 icueFeedBackBtn text-capitalize" id="sendBtn_29" data-bs-toggle="modal" data-bs-target="#sendRfqModal" style={{lineHeight:"10px", backgroundColor:"#3A3285"}}>send</button>
                                                        </td>
                                                        <td>
                                                            <button type='button' class='btn btn-sm btn-toggle' data-toggle='button' aria-pressed='false' autocomplete='off' id='toggBtn_29'><div class='handle'></div></button>
                                                        </td>
                                                        <td class="text-center">
                                                            <button type="button" class="btn btn-sm btn-transparent icueDelBtnRow text-muted" id="remMod_29"><i class="bi bi-trash"></i></button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{fontFamily:"poppins"}}> 100030</td>
                                                        <td style={{fontFamily:"poppins"}}>poppy williams</td>
                                                        <td style={{fontFamily:"Inter"}}>H.NO. 500/3, Rathinapuri, Coimbatore</td>
                                                        <td style={{fontFamily:"Inter"}}>fire insurance</td>
                                                        <td class="text-center">
                                                            <span class="badge rounded-pill bg-success text-uppercase px-2 py-2 w-75" style={{fontFamily:"Inter"}}>cold</span>
                                                        </td>
                                                        <td style={{fontFamily:"Inter"}}>15-feb-2021</td>
                                                        <td>
                                                            <button type="button" class="btn btn-sm text-capitalize assignModal" data-bs-toggle="modal" data-bs-target="#assignModal" id="assignLeadTo_30" style={{fontFamily:"Inter"}}>assign</button>
                                                            <div class="icueAssChgBox" id="icueAssChgBox_30">
                                                                <div class="form-group input-group">
                                                                    <input type="text" class="form-control form-control-sm icueAssLeadTo border-end-0 text-capitalize" name="icueAssLeadTo" id="icueAssLeadTo" value="Jhon" />
                                                                    <span class="input-group-text bg-transparent border-start-0" onclick="changeAssLeadTo();">
                                                                        <span id="icueChgAssLeadTo"><i class="bi bi-x text-danger"></i></span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td class="text-center" style={{fontFamily:"Inter"}}>
                                                            website
                                                        </td>
                                                        <td style={{fontFamily:"Inter"}}>
                                                            not sent
                                                        </td>
                                                        <td>
                                                            <button type="button" class="btn btn-sm text-white rounded-3 w-100 icueFeedBackBtn text-capitalize" id="sendBtn_30" data-bs-toggle="modal" data-bs-target="#sendRfqModal" style={{lineHeight:"10px", backgroundColor:"#3A3285"}}>send</button>
                                                        </td>
                                                        <td>
                                                            <button type='button' class='btn btn-sm btn-toggle' data-toggle='button' aria-pressed='false' autocomplete='off' id='toggBtn_30'><div class='handle'></div></button>
                                                        </td>
                                                        <td class="text-center">
                                                            <button type="button" class="btn btn-sm btn-transparent icueDelBtnRow text-muted" id="remMod_30"><i class="bi bi-trash"></i></button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{fontFamily:"poppins"}}>100031</td>
                                                        <td style={{fontFamily:"poppins"}}>annie rose</td>
                                                        <td style={{fontFamily:"Inter"}}>Block No. 9/C, Dwaraka, Delhi</td>
                                                        <td style={{fontFamily:"Inter"}}>car insurance</td>
                                                        <td class="text-center"  style={{width:"8rem"}}>
                                                            <span class="badge rounded-pill bg-success text-uppercase px-2 py-2 w-75" style={{fontFamily:"Mulish"}}>cold</span>
                                                        </td>
                                                        <td style={{fontFamily:"Inter"}}>15-feb-2021</td>
                                                        <td>
                                                            <button type="button" class="btn btn-sm text-capitalize assignModal" data-bs-toggle="modal" data-bs-target="#assignModal" id="assignLeadTo_31"style={{fontFamily:"Inter"}}>assign</button>
                                                            <div class="icueAssChgBox" id="icueAssChgBox_31">
                                                                <div class="form-group input-group">
                                                                    <input type="text" class="form-control form-control-sm icueAssLeadTo border-end-0 text-capitalize" name="icueAssLeadTo" id="icueAssLeadTo" value="Jhon" />
                                                                    <span class="input-group-text bg-transparent border-start-0" onclick="changeAssLeadTo();">
                                                                        <span id="icueChgAssLeadTo"><i class="bi bi-x text-danger"></i></span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td class="text-center" style={{fontFamily:"Inter"}}>
                                                            website
                                                        </td>
                                                        <td style={{fontFamily:"Inter"}}>
                                                            not sent
                                                        </td>
                                                        <td>
                                                            <button type="button" class="btn btn-sm text-white rounded-3 w-100 icueFeedBackBtn text-capitalize" id="sendBtn_31" data-bs-toggle="modal" data-bs-target="#sendRfqModal"style={{lineHeight:"10px", backgroundColor:"#3A3285"}}>send</button>
                                                        </td>
                                                        <td>
                                                            <button type='button' class='btn btn-sm btn-toggle' data-toggle='button' aria-pressed='false' autocomplete='off' id='toggBtn_31'><div class='handle'></div></button>
                                                        </td>
                                                        <td class="text-center">
                                                            <button type="button" class="btn btn-sm btn-transparent icueDelBtnRow text-muted" id="remMod_31"><i class="bi bi-trash"></i></button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{fontFamily:"poppins"}}>100032</td>
                                                        <td style={{fontFamily:"poppins"}}>harry lewis</td>
                                                        <td style={{fontFamily:"Inter"}}>Flat No. 928/A, Santacruz, Mumbai</td>
                                                        <td style={{fontFamily:"Inter"}}>health insurance</td>
                                                        <td class="text-center">
                                                            <span class="badge rounded-pill bg-warning text-uppercase px-2 py-2 w-75" style={{fontFamily:"Mulish"}}>warm</span>
                                                        </td>
                                                        <td style={{fontFamily:"Inter"}}>20-jun-2021</td>
                                                        <td>
                                                            <button type="button" class="btn btn-sm text-capitalize assignModal" data-bs-toggle="modal" data-bs-target="#assignModal" id="assignLeadTo_32" style={{fontFamily:"Inter"}}>assign</button>
                                                            <div class="icueAssChgBox" id="icueAssChgBox_32">
                                                                <div class="form-group input-group">
                                                                    <input type="text" class="form-control form-control-sm icueAssLeadTo border-end-0 text-capitalize" name="icueAssLeadTo" id="icueAssLeadTo" value="Jhon" />
                                                                    <span class="input-group-text bg-transparent border-start-0" onclick="changeAssLeadTo();">
                                                                        <span id="icueChgAssLeadTo"><i class="bi bi-x text-danger"></i></span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td class="text-center" style={{fontFamily:"Inter"}}>
                                                            website
                                                        </td>
                                                        <td style={{fontFamily:"Inter"}}>
                                                            not sent
                                                        </td>
                                                        <td>
                                                            <button type="button" class="btn btn-sm text-white rounded-3 w-100 icueFeedBackBtn text-capitalize" id="sendBtn_32" data-bs-toggle="modal" data-bs-target="#sendRfqModal" style={{lineHeight:"10px", backgroundColor:"#3A3285"}}>send</button>
                                                        </td>
                                                        <td>
                                                            <button type='button' class='btn btn-sm btn-toggle' data-toggle='button' aria-pressed='false' autocomplete='off' id='toggBtn_32'><div class='handle'></div></button>
                                                        </td>
                                                        <td class="text-center">
                                                            <button type="button" class="btn btn-sm btn-transparent icueDelBtnRow text-muted" id="remMod_32"><i class="bi bi-trash"></i></button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{fontFamily:"poppins"}}>100033</td>
                                                        <td style={{fontFamily:"poppins"}}>amelia davies</td>
                                                        <td style={{fontFamily:"Inter"}}>HIG-277, Shivaji Nagar, Indore</td>
                                                        <td style={{fontFamily:"Inter"}}>life insurance</td>
                                                        <td class="text-center">
                                                            <span class="badge rounded-pill bg-danger text-uppercase px-2 py-2 w-75" style={{fontFamily:"Mulish"}}>hot</span>
                                                        </td>
                                                        <td style={{fontFamily:"Inter"}}>25-aug-2021</td>
                                                        <td>
                                                            <button type="button" class="btn btn-sm text-capitalize assignModal" data-bs-toggle="modal" data-bs-target="#assignModal" id="assignLeadTo_33" style={{fontFamily:"Inter"}}>assign</button>
                                                            <div class="icueAssChgBox" id="icueAssChgBox_33">
                                                                <div class="form-group input-group">
                                                                    <input type="text" class="form-control form-control-sm icueAssLeadTo border-end-0 text-capitalize" name="icueAssLeadTo" id="icueAssLeadTo" value="Jhon" />
                                                                    <span class="input-group-text bg-transparent border-start-0" onclick="changeAssLeadTo();">
                                                                        <span id="icueChgAssLeadTo"><i class="bi bi-x text-danger"></i></span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td class="text-center" style={{fontFamily:"Inter"}}>
                                                            website
                                                        </td>
                                                        <td style={{fontFamily:"Inter"}}>
                                                            not sent
                                                        </td>
                                                        <td>
                                                            <button type="button" class="btn btn-sm text-white rounded-3 w-100 icueFeedBackBtn text-capitalize" id="sendBtn_33" data-bs-toggle="modal" data-bs-target="#sendRfqModal" style={{fontFamily:"Arial"}}>send</button>
                                                        </td>
                                                        <td>
                                                            <button type='button' class='btn btn-sm btn-toggle' data-toggle='button' aria-pressed='false' autocomplete='off' id='toggBtn_33'><div class='handle'></div></button>
                                                        </td>
                                                        <td class="text-center">
                                                            <button type="button" class="btn btn-sm btn-transparent icueDelBtnRow text-muted" id="remMod_33"><i class="bi bi-trash"></i></button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{fontFamily:"poppins"}}>100034</td>
                                                        <td style={{fontFamily:"poppins"}}>emily taylor</td>
                                                        <td style={{fontFamily:"Inter"}}>Flat No. 412, Green Acres, Chennai</td>
                                                        <td style={{fontFamily:"Inter"}}>marine insurance</td>
                                                        <td class="text-center">
                                                            <span class="badge rounded-pill bg-success text-uppercase px-2 py-2 w-75" style={{fontFamily:"Mulish"}}>cold</span>
                                                        </td>
                                                        <td style={{fontFamily:"Inter"}}>15-feb-2021</td>
                                                        <td>
                                                            <button type="button" class="btn btn-sm text-capitalize assignModal" data-bs-toggle="modal" data-bs-target="#assignModal" id="assignLeadTo_34" style={{fontFamily:"Inter"}}>assign</button>
                                                            <div class="icueAssChgBox" id="icueAssChgBox_34">
                                                                <div class="form-group input-group">
                                                                    <input type="text" class="form-control form-control-sm icueAssLeadTo border-end-0 text-capitalize" name="icueAssLeadTo" id="icueAssLeadTo" value="Jhon" />
                                                                    <span class="input-group-text bg-transparent border-start-0" onclick="changeAssLeadTo();">
                                                                        <span id="icueChgAssLeadTo"><i class="bi bi-x text-danger"></i></span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td class="text-center" style={{fontFamily:"Inter"}}>
                                                            website
                                                        </td>
                                                        <td style={{fontFamily:"Inter"}}>
                                                            not sent
                                                        </td>
                                                        <td>
                                                            <button type="button" class="btn btn-sm text-white rounded-3 w-100 icueFeedBackBtn text-capitalize" id="sendBtn_34" data-bs-toggle="modal" data-bs-target="#sendRfqModal" style={{lineHeight:"10px", backgroundColor:"#3A3285"}}>send</button>
                                                        </td>
                                                        <td>
                                                            <button type='button' class='btn btn-sm btn-toggle' data-toggle='button' aria-pressed='false' autocomplete='off' id='toggBtn_34'><div class='handle'></div></button>
                                                        </td>
                                                        <td class="text-center">
                                                            <button type="button" class="btn btn-sm btn-transparent icueDelBtnRow text-muted" id="remMod_34"><i class="bi bi-trash"></i></button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{fontFamily:"poppins"}}>100035</td>
                                                        <td style={{fontFamily:"poppins"}}>alfie smith</td>
                                                        <td style={{fontFamily:"Inter"}}>H.NO. 78, Plot No. 405, Burail, Chandigarh</td>
                                                        <td style={{fontFamily:"Inter"}}>fire insurance</td>
                                                        <td class="text-center">
                                                            <span class="badge rounded-pill bg-warning text-uppercase px-2 py-2 w-75" style={{fontFamily:"Mulish"}}>warm</span>
                                                        </td>
                                                        <td style={{fontFamily:"Inter"}}>20-jun-2021</td>
                                                        <td>
                                                            <button type="button" class="btn btn-sm text-capitalize assignModal" data-bs-toggle="modal" data-bs-target="#assignModal" id="assignLeadTo_35" style={{fontFamily:"Inter"}}>assign</button>
                                                            <div class="icueAssChgBox" id="icueAssChgBox_35">
                                                                <div class="form-group input-group">
                                                                    <input type="text" class="form-control form-control-sm icueAssLeadTo border-end-0 text-capitalize" name="icueAssLeadTo" id="icueAssLeadTo" value="Jhon" />
                                                                    <span class="input-group-text bg-transparent border-start-0" onclick="changeAssLeadTo();">
                                                                        <span id="icueChgAssLeadTo"><i class="bi bi-x text-danger"></i></span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td class="text-center" style={{fontFamily:"Inter"}}>
                                                            website
                                                        </td>
                                                        <td style={{fontFamily:"Inter"}}>
                                                            not sent
                                                        </td>
                                                        <td>
                                                            <button type="button" class="btn btn-sm text-white rounded-3 w-100 icueFeedBackBtn text-capitalize" id="sendBtn_35" data-bs-toggle="modal" data-bs-target="#sendRfqModal" style={{lineHeight:"10px", backgroundColor:"#3A3285"}}>send</button>
                                                        </td>
                                                        <td>
                                                            <button type='button' class='btn btn-sm btn-toggle' data-toggle='button' aria-pressed='false' autocomplete='off' id='toggBtn_35'><div class='handle'></div></button>
                                                        </td>
                                                        <td class="text-center">
                                                            <button type="button" class="btn btn-sm btn-transparent icueDelBtnRow text-muted" id="remMod_35"><i class="bi bi-trash"></i></button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{fontFamily:"poppins"}}>100036</td>
                                                        <td style={{fontFamily:"poppins"}}>mia evans</td>
                                                        <td style={{fontFamily:"Inter"}}>H.No. 508, KPHB Colony, Kukatpally, Hyderabad</td>
                                                        <td style={{fontFamily:"Inter"}}>car insurance</td>
                                                        <td class="text-center">
                                                            <span class="badge rounded-pill bg-danger text-uppercase px-2 py-2 w-75" style={{fontFamily:"Mulish"}}>hot</span>
                                                        </td>
                                                        <td style={{fontFamily:"Inter"}}>25-aug-2021</td>
                                                        <td>
                                                            <button type="button" class="btn btn-sm text-capitalize assignModal" data-bs-toggle="modal" data-bs-target="#assignModal" id="assignLeadTo_36" style={{fontFamily:"Inter"}}>assign</button>
                                                            <div class="icueAssChgBox" id="icueAssChgBox_36">
                                                                <div class="form-group input-group">
                                                                    <input type="text" class="form-control form-control-sm icueAssLeadTo border-end-0 text-capitalize" name="icueAssLeadTo" id="icueAssLeadTo" value="Jhon" />
                                                                    <span class="input-group-text bg-transparent border-start-0" onclick="changeAssLeadTo();">
                                                                        <span id="icueChgAssLeadTo"><i class="bi bi-x text-danger"></i></span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td class="text-center" style={{fontFamily:"Inter"}}>
                                                            website
                                                        </td>
                                                        <td style={{fontFamily:"Inter"}}>
                                                            not sent
                                                        </td>
                                                        <td>
                                                            <button type="button" class="btn btn-sm text-white rounded-3 w-100 icueFeedBackBtn text-capitalize" id="sendBtn_36" data-bs-toggle="modal" data-bs-target="#sendRfqModal" style={{lineHeight:"10px", backgroundColor:"#3A3285"}}>send</button>
                                                        </td>
                                                        <td>
                                                            <button type='button' class='btn btn-sm btn-toggle' data-toggle='button' aria-pressed='false' autocomplete='off' id='toggBtn_36'><div class='handle'></div></button>
                                                        </td>
                                                        <td class="text-center">
                                                            <button type="button" class="btn btn-sm btn-transparent icueDelBtnRow text-muted" id="remMod_36"><i class="bi bi-trash"></i></button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td  style={{fontFamily:"poppins"}}>100037</td>
                                                        <td style={{fontFamily:"poppins"}}>ava brown</td>
                                                        <td style={{fontFamily:"Inter"}}>Flat No. 214/B, Waghoil, Pune</td>
                                                        <td  style={{fontFamily:"Inter"}}>health insurance</td>
                                                        <td class="text-center">
                                                            <span class="badge rounded-pill bg-success text-uppercase px-2 py-2 w-75" style={{fontFamily:"Mulish"}}>cold</span>
                                                        </td>
                                                        <td style={{fontFamily:"Inter"}}>15-feb-2021</td>
                                                        <td>
                                                            <button type="button" class="btn btn-sm text-capitalize assignModal" data-bs-toggle="modal" data-bs-target="#assignModal" id="assignLeadTo_37" style={{fontFamily:"Inter"}}>assign</button>
                                                            <div class="icueAssChgBox" id="icueAssChgBox_37">
                                                                <div class="form-group input-group">
                                                                    <input type="text" class="form-control form-control-sm icueAssLeadTo border-end-0 text-capitalize" name="icueAssLeadTo" id="icueAssLeadTo" value="Jhon" />
                                                                    <span class="input-group-text bg-transparent border-start-0" onclick="changeAssLeadTo();">
                                                                        <span id="icueChgAssLeadTo"><i class="bi bi-x text-danger"></i></span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td class="text-center" style={{fontFamily:"Inter"}}>
                                                            website
                                                        </td>
                                                        <td style={{fontFamily:"Inter"}}>
                                                            not sent
                                                        </td>
                                                        <td>
                                                            <button type="button" class="btn btn-sm text-white rounded-3 w-100 icueFeedBackBtn text-capitalize" id="sendBtn_37" data-bs-toggle="modal" data-bs-target="#sendRfqModal" style={{lineHeight:"10px", backgroundColor:"#3A3285"}}>send</button>
                                                        </td>
                                                        <td>
                                                            <button type='button' class='btn btn-sm btn-toggle' data-toggle='button' aria-pressed='false' autocomplete='off' id='toggBtn_37'><div class='handle'></div></button>
                                                        </td>
                                                        <td class="text-center">
                                                            <button type="button" class="btn btn-sm btn-transparent icueDelBtnRow text-muted" id="remMod_37"><i class="bi bi-trash"></i></button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{fontFamily:"poppins"}}>100038</td>
                                                        <td style={{fontFamily:"poppins"}}>johnathan fowler</td>
                                                        <td style={{fontFamily:"Inter"}}>Flat.No. 401, Dayai Bagh, Agra</td>
                                                        <td style={{fontFamily:"Inter"}}>life insurance</td>
                                                        <td class="text-center">
                                                            <span class="badge rounded-pill bg-warning text-uppercase px-2 py-2 w-75" style={{fontFamily:"Mulish"}}>warm</span>
                                                        </td>
                                                        <td style={{fontFamily:"Inter"}}>20-jun-2021</td>
                                                        <td>
                                                            <button type="button" class="btn btn-sm text-capitalize assignModal" data-bs-toggle="modal" data-bs-target="#assignModal" id="assignLeadTo_38" style={{fontFamily:"Inter"}} >assign</button>
                                                            <div class="icueAssChgBox" id="icueAssChgBox_38">
                                                                <div class="form-group input-group">
                                                                    <input type="text" class="form-control form-control-sm icueAssLeadTo border-end-0 text-capitalize" name="icueAssLeadTo" id="icueAssLeadTo" value="Jhon" />
                                                                    <span class="input-group-text bg-transparent border-start-0" onclick="changeAssLeadTo();">
                                                                        <span id="icueChgAssLeadTo"><i class="bi bi-x text-danger"></i></span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td class="text-center" style={{fontFamily:"Inter"}}>
                                                            website
                                                        </td>
                                                        <td style={{fontFamily:"Inter"}}>
                                                            not sent
                                                        </td>
                                                        <td>
                                                            <button type="button" class="btn btn-sm text-white rounded-3 w-100 icueFeedBackBtn text-capitalize" id="sendBtn_38" data-bs-toggle="modal" data-bs-target="#sendRfqModal" style={{lineHeight:"10px", backgroundColor:"#3A3285"}}>send</button>
                                                        </td>
                                                        <td>
                                                            <button type='button' class='btn btn-sm btn-toggle' data-toggle='button' aria-pressed='false' autocomplete='off' id='toggBtn_38'><div class='handle'></div></button>
                                                        </td>
                                                        <td class="text-center">
                                                            <button type="button" class="btn btn-sm btn-transparent icueDelBtnRow text-muted" id="remMod_38"><i class="bi bi-trash"></i></button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{fontFamily:"poppins"}}>100039</td>
                                                        <td style={{fontFamily:"poppins"}}>isla roberts</td>
                                                        <td style={{fontFamily:"Inter"}}>Flat No. 302, Indira Nagar, Nashik</td>
                                                        <td style={{fontFamily:"Inter"}}>marine insurance</td>
                                                        <td class="text-center">
                                                            <span class="badge rounded-pill bg-danger text-uppercase px-2 py-2 w-75" style={{fontFamily:"Mulish"}}>hot</span>
                                                        </td>
                                                        <td style={{fontFamily:"Inter"}}>25-aug-2021</td>
                                                        <td>
                                                            <button type="button" class="btn btn-sm text-capitalize assignModal" data-bs-toggle="modal" data-bs-target="#assignModal" id="assignLeadTo_39" style={{fontFamily:"Inter"}}>assign</button>
                                                            <div class="icueAssChgBox" id="icueAssChgBox_39">
                                                                <div class="form-group input-group">
                                                                    <input type="text" class="form-control form-control-sm icueAssLeadTo border-end-0 text-capitalize" name="icueAssLeadTo" id="icueAssLeadTo" value="Jhon" />
                                                                    <span class="input-group-text bg-transparent border-start-0" onclick="changeAssLeadTo();">
                                                                        <span id="icueChgAssLeadTo"><i class="bi bi-x text-danger"></i></span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td class="text-center" style={{fontFamily:"Inter"}}>
                                                            website
                                                        </td>
                                                        <td style={{fontFamily:"Inter"}}>
                                                            not sent
                                                        </td>
                                                        <td>
                                                            <button type="button" class="btn btn-sm text-white rounded-3 w-100 icueFeedBackBtn text-capitalize" id="sendBtn_39" data-bs-toggle="modal" data-bs-target="#sendRfqModal" style={{lineHeight:"10px", backgroundColor:"#3A3285"}}>send</button>
                                                        </td>
                                                        <td>
                                                            <button type='button' class='btn btn-sm btn-toggle' data-toggle='button' aria-pressed='false' autocomplete='off' id='toggBtn_39'><div class='handle'></div></button>
                                                        </td>
                                                        <td class="text-center">
                                                            <button type="button" class="btn btn-sm btn-transparent icueDelBtnRow text-muted" id="remMod_39"><i class="bi bi-trash"></i></button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{fontFamily:"poppins"}}>100040</td>
                                                        <td style={{fontFamily:"poppins"}}>poppy williams</td>
                                                        <td style={{fontFamily:"Inter"}}>H.NO. 500/3, Rathinapuri, Coimbatore</td>
                                                        <td style={{fontFamily:"Inter"}}>fire insurance</td>
                                                        <td class="text-center">
                                                            <span class="badge rounded-pill bg-success text-uppercase px-2 py-2 w-75" style={{fontFamily:"Mulish"}}>cold</span>
                                                        </td>
                                                        <td style={{fontFamily:"Inter"}}>15-feb-2021</td>
                                                        <td>
                                                            <button type="button" class="btn btn-sm text-capitalize assignModal" data-bs-toggle="modal" data-bs-target="#assignModal" id="assignLeadTo_40" style={{fontFamily:"Inter"}}>assign</button>
                                                            <div class="icueAssChgBox" id="icueAssChgBox_40">
                                                                <div class="form-group input-group">
                                                                    <input type="text" class="form-control form-control-sm icueAssLeadTo border-end-0 text-capitalize" name="icueAssLeadTo" id="icueAssLeadTo" value="Jhon" />
                                                                    <span class="input-group-text bg-transparent border-start-0" onclick="changeAssLeadTo();">
                                                                        <span id="icueChgAssLeadTo"><i class="bi bi-x text-danger"></i></span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td class="text-center" style={{fontFamily:"Inter"}}>
                                                            website
                                                        </td>
                                                        <td style={{fontFamily:"Inter"}}>
                                                            not sent
                                                        </td>
                                                        <td>
                                                            <button type="button" class="btn btn-sm text-white rounded-3 w-100 icueFeedBackBtn text-capitalize" id="sendBtn_40" data-bs-toggle="modal" data-bs-target="#sendRfqModal" style={{lineHeight:"10px", backgroundColor:"#3A3285"}}>send</button>
                                                        </td>
                                                        <td>
                                                            <button type='button' class='btn btn-sm btn-toggle' data-toggle='button' aria-pressed='false' autocomplete='off' id='toggBtn_40'><div class='handle'></div></button>
                                                        </td>
                                                        <td class="text-center">
                                                            <button type="button" class="btn btn-sm btn-transparent icueDelBtnRow text-muted" id="remMod_40"><i class="bi bi-trash"></i></button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
{/* <!-- Assign Lead To Modal --> */}
<div class="modal fade" id="assignModal" tabindex="-1" aria-labelledby="assignModalLabel" aria-hidden="true">
    {/* <!-- <div class="modal-dialog modal-dialog-centered"> --> */}
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header py-1 shadow-sm">
                <h5 class="modal-title" id="assignModalLabel">Assign Lead to</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body" style={{backgroundColor: "#edf5fb"}}>
                <form class="">
                    <div class="row">
                        <div class="col-lg-8 col-md-8 col-sm-12 col-12 mx-auto">
                            <div class="form-group pb-2">
                                {/* <input class="form-control" type="text" name="icueAssLedToNameCode" placeholder="Enter Name/Code"  /> */}
                                <input class="form-control" type="text" name="icueAssLedToNameCode" placeholder="Enter Name/Code"  style={{width:"100%", alignItems:"center", marginTop:"20px"}}></input>
                            </div>
                        </div>
                        <div class="col-lg-8 col-md-8 col-sm-12 col-12 py-3 mx-auto">
                            <div class="d-grid gap-2">
                            <button type="button" class="btn text-white" data-bs-dismiss="modal" id="icueAssLeadToSubBtn" style={{backgroundColor:"#3A3285"}}>
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
  </div>
  {/* <!-- /Assign Lead To Modal -->

<!-- Send RFQ Modal --> */}
<div class="modal fade" id="sendRfqModal" tabindex="-1" aria-labelledby="sendRfqModalLabel" aria-hidden="true">
    {/* <!-- <div class="modal-dialog modal-dialog-centered"> --> */}
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header py-1 shadow-sm">
                <h5 class="modal-title" id="sendModalLabel">RFQ</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body" style={{backgroundColor: "#edf5fb"}}>
                <form class="">
                    <div class="row">
                        <div class="col-lg-9 col-md-9 col-sm-12 col-12 mx-auto">
                            <div class="form-group pb-2">
                                {/* <!-- <input class="form-control" type="text" name="icueMemEmail" placeholder="Enter Name/Code" value="" /> --> */}
                                <select id="icueRfqSelBox" name="icueRfqSelBox" class="form-select text-capitalize">
                                    <option value="hdfc ergo">HDFC ERGO</option>
                                    <option value="ICICI Term Life">ICICI Term Life</option>
                                    <option value="royal sundaram">royal sundaram</option>
                                    <option value="sompa">sompa</option>
                                    <option value="SBI general">SBI general</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-lg-9 col-md-9 col-sm-12 col-12 mx-auto">
                            <div class="form-group pb-2">
                                <textarea class="form-control" id="icueRFQDesc" rows="3" placeholder="Description of quotation" style={{width:"100%"}}></textarea>
                            </div>
                        </div>
                        <div class="col-lg-9 col-md-9 col-sm-12 col-12 mx-auto">
                            <div class="mb-4">
                                <div class="d-flex justify-content-between ">
                                    <label for="choose-file" class="custom-file-upload bg-primary text-white mt-4 mb-5 " id="choose-file-label ">
                                        Upload Document
                                    </label>
                                    <input name="uploadDocument" type="file" id="choose-file" 
                                        accept=".jpg,.jpeg,.pdf,doc,docx,application/msword,.png" style={{display:"none"}}/>
                                    <div className="Line" style={{width:"55%"}}>
                                        <input type="text" readonly id="icueRfqUploadedDoc" value="document.pdf" style={{fontFamily:"Calibri", fontSize:"16px", color:"#494949"}}  />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-9 col-md-9 col-sm-12 col-12 py-3 mx-auto">
                            <div class="d-grid gap-2">
                            <button type="button" class="btn text-white " data-bs-dismiss="modal" style={{marginTop:"-2.3rem",  backgroundColor:"#3A3285"}}>
                                    Send
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
{/* <!-- /Send RFQ Modal --> */}
                    <div class="row my-2">
                        <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 py-2 px-3">
                            <div class="bg-white p-2 rounded-3 shadow-sm">
                                
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    </div>
    </div>)
}