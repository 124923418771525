import React from 'react'
import './NavBar.css'

function NavBar() {
    return (
        <nav class="navbar navbar-expand-lg fixed-top navbar-light" >
            <div class="container">
                <a class="navbar-brand logo-image" href="/"><img src="images/policicue_logo.svg" alt="alternative" /></a>
                <div class="navbar-collapse offcanvas-collapse" id="navbarsExampleDefault">
                    <ul class="navbar-nav ml-auto">
                        <li class="nav-items">
                            <div class="nav-links contact-feature" href="#"><img src="images/Group.svg" class="img-customer-cares" alt="" />9820153600</div>
                        </li>
                        <li class="nav-items">
                            <div class="nav-links contact-feature" href="#"><img src="images/Group.svg" class="img-customer-cares" alt="" />Email: contact@gmail.com</div>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}

export default NavBar
