import React, { useState } from 'react'
import NavBar from '../../ReusableComponents/NavBar'
import Footer from '../../ReusableComponents/Footer'
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core'
import { TextField, InputLabel, Select, FormControl, MenuItem } from '@material-ui/core';
import Stepper from '../../ReusableComponents/Stepper';


import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";


const prInsuredSchema = yup.object({
    dob: yup.string().required(),
    approxAnnualIncome: yup.string().required(),
    ageOfRetirement: yup.string().required(),
}).required();


const useStyles = makeStyles({
    relation: {
        width: '230px',
        marginRight: '30px',
        // outline:'red',
        [`& fieldset`]: {
            borderRadius: '10px',
            // borderColor:'red',
            // paddingBottom:'25px
        },

    }
})
export default function PRinsured() {
    const classes = useStyles()
    const history = useHistory();

    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(prInsuredSchema)
    });
    const onSubmit = data => {
        if (data) {
            history.push("/PRinvest")
        }
    };

    return (
        <>
            <div style={{ background: '#F2F6FF' }}>
                <NavBar />
                <br /><br />
                <div class='step4-car-insurance'>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <div class='H-step1-topimg'>
                            <img src='images/Component 4.png' alt="topimg"></img>
                        </div>
                    </div>
                    <div class="row" style={{ marginTop: '50px' }}>
                        <div class='col-lg-1 col-md-0 ' >
                            <Stepper current={1} thirdStep={true} src="images/healthActive.png" />
                        </div>
                        <div class="H-step1-content col-lg-5 col-md-6">
                            <h6 style={{ color: 'black' }}>Tell us about the insured  - </h6>
                            <div class='d-sm-flex mt-4'>
                                <div id='ck-button1' >
                                    <label >
                                        <input type='radio' name='gender' checked /><span class='span'>Male</span>
                                    </label>
                                </div>
                                <div id='ck-button1' >
                                    <label >
                                        <input type='radio' name='gender' /><span class='span'>Female</span>
                                    </label>
                                </div>
                            </div>
                            <div class="car2-form-groupp" >
                                <input
                                    type="date"
                                    id="icueRegistrationNo"
                                    name="icueRegistrationNo"
                                    class="car2-form-controll"
                                    style={{ width: '230px' }}
                                    {...register('dob')}
                                />
                                <label class="ms-3 car2-form-control-placeholderr" for="icueRegistrationNo">
                                    DOB
                                </label>
                            </div>
                            <p class="text-danger">{errors.dob?.message}</p>


                            <div class="d-sm-flex" style={{ marginTop: '30px' }}>

                                <div class="health-form-groupp ">
                                    <select
                                        id="ApproxAnnualIncome"
                                        name="ApproxAnnualIncome"
                                        class="health-form-controll "
                                        required
                                        {...register("approxAnnualIncome")}

                                    >
                                        <option value="" selected disabled hidden>Approx Annual Income</option>
                                        <option value="100000">100000</option>
                                        <option value="300000">300000</option>
                                        <option value="500000">500000</option>
                                        <option value="800000">800000</option>
                                        <option value="1000000">1000000</option>
                                        <option value="1500000">1500000</option>
                                        <option value="2000000">2000000</option>
                                        <option value="More than 2000000">More than 2000000</option>
                                    </select>
                                    <p class="text-danger">{errors.approxAnnualIncome?.message}</p>


                                </div>

                                <div class="health-form-groupp ">
                                    <select
                                        id="sumInsured"
                                        name="sumInsured"
                                        class="health-form-controll "
                                        required
                                        {...register("ageOfRetirement")}
                                    >
                                        <option value="" selected disabled hidden>Age of Retirement</option>
                                        <option value="1">1</option>
                                        <option value="6">6</option>
                                        <option value="11">11</option>
                                        <option value="16">16</option>
                                        <option value="21">21</option>
                                        <option value="26">26</option>
                                    </select>
                                    <p class="text-danger">{errors.ageOfRetirement?.message}</p>
                                </div>

                            </div>
                            <br />

                            <div class='H-step1-nextbtn ' >
                                <button
                                    onClick={handleSubmit(onSubmit)}
                                >Next</button>
                            </div>
                        </div>



                        <div class=" H-step1-rightimg col-lg-6  col-md-6">
                            <img src='images/im2 4.png' alt="alternative"></img>
                        </div>

                    </div>
                </div>
                <Footer />
            </div>
        </>
    )
}