import axios from "axios";
import { useDispatch } from "react-redux";
import Constant from "../utils/constant";


const SubmitQuery = (firstName, lastName, mobileNo, emailId, isChecked, url) => {

    return fetch(Constant.BASE_URL + url,
        {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            },
            body: JSON.stringify({
                firstName,
                lastName,
                mobileNo,
                emailId,
                isChecked
            })
        }
    )
};
export default SubmitQuery;