

import React, { useRef, useState, useEffect } from 'react'
import '../../css/new_car_step1.css'
import Footer from '../ReusableComponents/Footer'
import NavBar from '../ReusableComponents/NavBar'
import { useHistory } from 'react-router-dom';
import SubmitQuery from '../../services/insurance-query.service'
import './OtherInsurance.css';
import { Modal } from 'react-bootstrap'
import { Model } from '../ReusableComponents/Modal';
import TermsUsage from './TermsUsage';
import Terms from '../ReusableComponents/Terms';

export default function OtherInsurance() {
    const [checked, setChecked] = useState(true);
    const history = useHistory();
    const [show, setShow] = useState(false);
    const ShowModal = () => setShow(true);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [showModal, setModalShow] = useState(false);
    const handleCloseModal = () => setModalShow(false);
    const handleShowModal = () => setModalShow(true);

    const sectionContent = {
        textDecoration: "none",
        fontSize: 14,
    }
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
        return () => {
        }
    }, [])
    let car_insurance = useRef();
    let different_types_car_insurance = useRef();
    let topic1 = useRef();
    let addOn = useRef();
    let faqs = useRef();

    const initialValues = { firstName: "", email: "", lastName: "", mobile: "", isChecked: true };
    const [formValues, setFormValues] = useState(initialValues);
    const [formErrors, setFormErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setFormErrors(validate(formValues));
        setIsSubmit(true);
        if (true) {
            console.log(formValues);
            SubmitQuery(formValues.firstName, formValues.lastName, formValues.mobile, formValues.email, "travel/purchaseOtherInsurances")
                .then(response => response.json())
                .then(async response => {
                    console.log(JSON.stringify(response));
                    if (response.status == 1) {
                        await handleShow();
                        setFormValues(initialValues);
                    } else if (response.status == 0) {
                        alert(response.message);
                    }
                });
        }
    };

    useEffect(() => {
        console.log(formErrors);
        if (Object.keys(formErrors).length === 0 && isSubmit) {
            console.log(formValues);
        }
    }, [formErrors]);

    const validate = (values) => {
        const errors = {};
        const email_regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        const fname_regex = /^[a-zA-Z ]+$/;
        const lname_regex = /([a-zA-Z]{1,30})$/;
        const mobile_regex = /^[0-9\b]+$/;


        if (!values.firstName) {
            errors.firstName = "First Name is Required!";
        } else if (!fname_regex.test(values.firstName)) {
            errors.firstName = "Enter Valid First Name!";
        }
        // if (!values.lastName) {
        //   errors.lastName = "Last Name is Required!";
        // } else if (!lname_regex.test(values.lastName)) {
        //   errors.lastName = "Enter Valid Last Name!";
        // }
        if (!values.email) {
            errors.email = "Email is Required!";
        } else if (!email_regex.test(values.email)) {
            errors.email = "Enter Valid Email!";
        }
        if (!values.mobile) {
            errors.mobile = "Mobile No. is Required";
        } else if (values.mobile.length < 10) {
            errors.mobile = "Mobile No. must be 10 Digits";
        }
        else if (!mobile_regex.test(values.mobile)) {
            errors.mobile = "Enter valid Mobile No.";
        }
        if (!values.isChecked) {
            errors.isChecked = "Kindly accepts the terms and conditions to submit";
        }
        return errors;
    };



    return (
        <div className='main-div'>
            <NavBar />

            <br />
            <br />
            <br />

            <div class='car-insurance-top'>
                <br />
                <div class="container" >
                    <div class="row my-2 form-container">
                        <div class="col-lg-8" >
                            <div class='row' style={{ marginTop: '20px', marginLeft: '1.25rem', paddingTop: '10px' }}>
                                <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 py-1 px-3" >
                                    <div class="bg-white p-2" >
                                        <div class="px-2 py-3">
                                            <div class="container" style={{ Shadow: "0px 30px 50px rgb(0 0 0 / 10%)" }}>
                                                <div class="row" style={{ marginTop: "-30px", marginLeft: "-70px" }}></div>
                                                <form onSubmit={handleSubmit}>
                                                    <h2 class="icueTextPrimary">Other Insurance </h2>
                                                    <p className='textPrimary'>Please share your details, we will connect and arrange best suited insurance coverage for
                                                        you</p> <br />
                                                    <div class="step2-content col-lg-5 col-md-7" style={{marginLeft: '-3rem'}}>
                                                        <div class='col-lg-10'>
                                                            <div class='d-sm-flex'>
                                                                <div class="car3-form-groupp mt-3 mb-2">
                                                                    <input
                                                                        class="car2-form-controll"
                                                                        id="firstName"
                                                                        type="text"
                                                                        name="firstName"
                                                                        value={formValues.firstName}
                                                                        onChange={handleChange}
                                                                        style={{ marginLeft: '20px', marginRight: '20px', textTransform: "capitalize" }}
                                                                    />
                                                                    <label class="ms-3 car2-form-control-placeholderr" for="firstName">First Name</label>
                                                                    <p className='p-error'>{formErrors.firstName}</p>
                                                                </div>
                                                                <div class="car3-form-groupp mt-3 mb-2">

                                                                    <input
                                                                        class="car2-form-controll"
                                                                        id="lastName"
                                                                        type="text"
                                                                        name="lastName"
                                                                        value={formValues.lastName}
                                                                        onChange={handleChange}
                                                                        style={{ marginLeft: '20px', marginRight: '20px', textTransform: "capitalize" }}
                                                                    />
                                                                    <label class="ms-3 car2-form-control-placeholderr" for="lastName">Last Name</label>

                                                                    {/* <p className='p-error'>{formErrors.lastName}</p> */}
                                                                </div>

                                                            </div>
                                                            <div class='d-sm-flex'>
                                                                <div class="car3-form-groupp mt-3 mb-2">

                                                                    <input
                                                                        class="car2-form-controll"
                                                                        id="mobile"
                                                                        type="text"
                                                                        name="mobile"
                                                                        value={formValues.mobile}
                                                                        onChange={handleChange}
                                                                        maxLength={10}
                                                                        style={{ marginLeft: '20px', marginRight: '20px', textTransform: "lowercase" }}
                                                                    />
                                                                    <label class="ms-3 car2-form-control-placeholderr" for="mobile">Mobile</label>

                                                                    <p className='p-error'>{formErrors.mobile}</p>
                                                                </div>
                                                                <div class="car3-form-groupp mt-3 mb-2">
                                                                    <input
                                                                        id="email"
                                                                        class="car2-form-controll"
                                                                        type="text"
                                                                        name="email"
                                                                        value={formValues.email}
                                                                        onChange={handleChange}
                                                                        style={{ marginLeft: '20px', marginRight: '20px', textTransform: "lowercase" }}
                                                                    />
                                                                    <label class="ms-3 car2-form-control-placeholderr" for="email">Email</label>

                                                                    <p className='p-error'>{formErrors.email}</p>
                                                                </div>
                                                            </div>
                                                            {/* <button className="fluid ui button blue">Submit</button> */}
                                                        </div>
                                                    </div>

                                                    <br />

                                                    <div className='privacypolicy-chechbox' >
                                                        <input class='acceptTandC' style={{ width: '20px' }} type='checkbox' defaultChecked={formValues.isChecked} onChange={() => { setChecked(!checked); setFormValues({ ...formValues, 'isChecked': !formValues.isChecked }); }} /><a href="javascript:void(0)"> I/We accept the <span className='link-terms'
                                                            onClick={handleShowModal}
                                                        >Terms and conditions</span> </a>


                                                        <Model show={showModal} onHide={handleCloseModal}>
                                                            <Terms />
                                                        </Model>

                                                        <p className='p-error'>{formErrors.isChecked}</p>
                                                        <br />
                                                        <span style={{
                                                            fontSize: '0.9rem',
                                                            fontFamily: 'Calibri',
                                                            paddingTop: '1rem !important'
                                                        }}>(You authorise PoliciCue Insurance Brokers Pvt Ltd (PoliciCue) to contact you through call, SMS, email, WhatsApp or any other mode in future.
                                                            You hereby override your NDNC registration.)
                                                        </span>
                                                        {/* <input class='acceptTandC' style={{ width: '20px' }} type='checkbox' defaultChecked={checked} onChange={() => setChecked(!checked)} /><a href="/LegalAdminPolicies"> I/We accept the <span className='link-terms' onClick={() => history.push("/LegalAdminPolicies")}>Terms and conditions</span> </a> */}
                                                    </div>
                                                    <div class='step2-car-nextbtn ' style={{ marginLeft: "-55px", lineHeight: "20px", height: "40px" }}><button class='' type='submit'>Submit</button></div>
                                                </form>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 text-center" style={{ backgroundColor: "#F4F7FC", display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                            <img src='./images/otherinsurance_edit.png' className='car-step1-img' style={{ backgroundColor: "#F4F7FC", display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}></img>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
            <Modal show={show} onHide={handleClose}>

                <Modal.Body>
                    <div className='modal-block-close-btn'>
                        <button type="button" class="btn text-muted modal-btn-size" onClick={handleClose} ><i class="bi bi-x-circle fas fa-lg modal-btn-icon"></i></button>
                    </div>
                    <div className='modal-block'>
                        <img className="thankyou" src="images/thankyou.png" alt="alternative" style={{ width: "100px" }} />
                        <h2>Thank You!</h2>
                        <div class="mb-4">
                            <div class="d-flex justify-content-between" >
                                <label class="" style={{ textAlign: "center", fontFamily: "Calibri", color: "black" }}>
                                    Your query has been submitted successfully and our executive will contact you shortly.
                                </label>

                            </div>
                        </div>
                    </div>

                </Modal.Body>

            </Modal>
        </div>
    )
}
