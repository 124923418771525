import { LazyLoadImage } from "react-lazy-load-image-component";
import X from "../../assets/img/x.png";
import ImagePlaceholder from "../../assets/img/img_placeholder.png";
import { file, stringFormat } from "../../utils/methods";

const BankDetails = ({
  updateValue = void 0,
  personalDetail = [],
  bankLoader = false,
  bankPhotoUpload = "",
  uploadSingleFile = void 0,
  deleteFile = void 0,
  formErrors = [],
  errors = [],
  isDisabled = false,
  data = [],
}) => {

  return (
    <>
      <div className="row row-cols-1 row-cols-lg-3 gy-4 form pt-3 pb-3 mt-1">
        <div className="col position-relative">
          <input
            className="w-100"
            id="accountHolderName"
            type="text"
            name="accountHolderName"
            maxLength={50}
            onChange={(e) => updateValue("accountHolderName", e)}
            value={
              !isDisabled
                ? personalDetail.accountHolderName
                : stringFormat(data.account_holder_name)
            }
            disabled={false}
          />
          <label className="ms-3 position-absolute" htmlFor="others">
            Account Holder Name
          </label>
            <p className="p-error">{errors?.accountHolderName || formErrors?.accountHolderName}</p>
        </div>
        <div className="col position-relative">
          <input
            className="w-100"
            id="accountNumber"
            type="number"
            name="accountNumber"
            maxLength={20}
            onChange={(e) => updateValue("accountNumber", e)}
            value={
              !isDisabled
                ? personalDetail.accountNumber
                : stringFormat(data.account_number)
            }
            disabled={isDisabled}
          />
          <label className="ms-3 position-absolute" htmlFor="Account Number">
            Account Number
          </label>
           <p className="p-error">{errors?.accountNumber || formErrors?.accountNumber}</p>
        </div>
        <div className="col position-relative">
          <input
            className="w-100"
            id="bankName"
            type="text"
            name="bankName"
            maxLength={50}
            onChange={(e) => updateValue("bankName", e)}
            value={
              !isDisabled
                ? personalDetail.bankName
                : stringFormat(data.bank_name)
            }
            disabled={isDisabled}
          />
          <label className="ms-3 position-absolute" htmlFor="Bank Name">
            Bank Name
          </label>
           <p className="p-error">{errors?.bankName || formErrors?.bankName}</p>
        </div>
        <div className="col position-relative">
          <input
            className="w-100"
            id="branch"
            type="text"
            name="branch"
            maxLength={50}
            onChange={(e) => updateValue("branch", e)}
            value={
              !isDisabled
                ? personalDetail.branch
                : stringFormat(data.branch)
            }
            disabled={isDisabled}
          />
          <label className="ms-3 position-absolute" htmlFor="Branch">
            Branch
          </label>
        </div>
        <div className="col position-relative">
          <input
            className="w-100"
            id="ifscCode"
            type="text"
            maxLength={11}
            name="ifscCode"
            onChange={(e) => updateValue("ifscCode", e)}
            value={
              !isDisabled
                ? personalDetail.ifscCode
                : stringFormat(data.ifsc_code)
            }
            disabled={isDisabled}
          />
          <label className="ms-3 position-absolute" htmlFor="IFSC Code">
            IFSC Code
          </label>
          <p className="p-error">{errors?.ifscCode || formErrors?.ifscCode}</p>
        </div>
      </div>

      <div className="row form col-12 d-flex flex-column">
        {!isDisabled && <label htmlFor="Upload File" style={{color:"#29166F", fontSize:"18px"}}>Upload File</label>}
        <div>
          {!bankPhotoUpload.length > 0 &&
          !personalDetail.upload_bank_photo &&
          !isDisabled ? (
            <div>
              <label>
                {!bankPhotoUpload.length && bankLoader ? (
                  <div
                    className="Group"
                    src="images/not-found.png"
                    alt="alternative"
                  >
                    <span className="spinner-border spinner-border-lg"></span>
                  </div>
                ) : (
                  <img
                    style={{ height: "190px" }}
                    src={ImagePlaceholder}
                    alt="alternative"
                  ></img>
                )}
                <input
                  type="file"
                  className="d-none"
                  size="60"
                  name="Cheque"
                  disabled={bankPhotoUpload.length === 2}
                  onChange={(e) => uploadSingleFile(e, "bankImage")}
                />
              </label>
              {!bankPhotoUpload.length > 0 && (
                <p className="p-error">
                  {errors.bankPhotoUpload || formErrors.bankPhotoUpload}
                </p>
              )}
            </div>
          ) : (
            <div className="row form-group">
              {isDisabled && data?.upload_bank_photo !== null && (
                <div className="img-preview">
                  {data?.upload_bank_photo?.includes(".pdf") == true ? (
                    <a
                      className="btn btn-primary"
                      href={file(data?.upload_bank_photo)}
                      target="_blank"
                    >
                      Preview PDF File
                    </a>
                  ) : (
                    <LazyLoadImage
                      effect="blur"
                      src={file(data?.upload_bank_photo)}
                      alt="preview"
                    />
                  )}
                </div>
              )}
              {!isDisabled && (
                <div className="img-preview">
                  {(
                    bankPhotoUpload?.[0] || personalDetail?.upload_bank_photo
                  )?.includes(".pdf") == true ? (
                    <a
                      className="btn btn-primary"
                      href={file(
                        bankPhotoUpload[0] || personalDetail?.upload_bank_photo
                      )}
                      target="_blank"
                    >
                      Preview PDF File
                    </a>
                  ) : (
                    <img
                      src={file(
                        bankPhotoUpload?.[0] || personalDetail?.upload_bank_photo
                      )}
                      alt="preview"
                    />
                  )}
                  <img
                    onClick={() => deleteFile(0, "bankImage")}
                    className="clear"
                    src={X}
                    alt="clear-preview"
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      <hr />
    </>
  );
};

export default BankDetails;
