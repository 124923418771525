import React from "react";

const ReadOnlyRow = ({ contact, handleEditClick , handleDeleteClick }) => {
  return (
    
    <tr>
      <td style={{width:"15%"}}><img src="images/avatar.png" alt="icueProfilePic" class="icueProfilePic border border-dark rounded-circle" /> &nbsp;{contact.employee}<br></br><div style={{fontSize:"12px", color:" #7D90B2", marginLeft:"38px", marginTop:"-10px"}}> Emp Code:  </div></td>
      <td class="text-center">{contact.leavetype}</td> 
      <td class="text-center" style={{width:"10%"}}>{contact.from}</td>
      <td class="text-center" style={{width:"10%"}}>{contact.to}</td>
      <td class="text-center" style={{width:"8%"}}>{contact.days}</td>
      <td class="text-center">{contact.reason}</td>
      <td class="text-center"style={{width:"10%"}}>{contact.manager}</td>
      <td >
        <div class="selectWrapper">                                                                
        <select class="selectBox far">
        <option value="far fa-scrubber" class="far"  >&#xf192; Declined </option>
        <option  value="far fa-scrubber" class="" >&#xf192; New</option>
        <option value="far fa-scrubber" class="">&#xf192; Pending</option>
         <option value="far fa-scrubber" class="">&#xf192; Approved</option>                                                            
        </select></div>                                                           
      </td>          
           
      <td>
      <button class="btn btn-sm btn-transparent " style={{marginLeft:"-10px", marginTop:"-5px"}}
          type="button"
          onClick={(event) => handleEditClick(event, contact)}
        >
         <i class='bi bi-pencil'></i>
        </button>
        <button class="btn btn-sm btn-transparent "  style={{ marginLeft:"20px", marginTop:"-60px"}}
        type="button" onClick={() => handleDeleteClick(contact.id)}>
       <i class='bi bi-trash'></i>
        </button>
      </td>
    </tr>
  );
};

export default ReadOnlyRow;