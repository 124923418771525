import '../../CarInsurence/BrandNew/CarStep3.css'
import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom';
import NavBar from '../../ReusableComponents/NavBar'
import Footer from '../../ReusableComponents/Footer'
import Stepper from '../../ReusableComponents/Stepper'
import { makeStyles } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useDispatch, useSelector } from 'react-redux'
import { getBikePolicyTypes } from '../../../store/actions/bikePolicyTypes';

import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

const useStyles = makeStyles({
    input: {
        paddingRight: '5.5em',
        width: '355px',
        height: '30px',
        // marginTop:'40px',
        // outline:'red',
        [`& fieldset`]: {
            borderRadius: '10px',
            // borderColor:'red',
        }
    }
})

const vdSchema = yup.object({
    expiryDate: yup.string().required(),
    policy: yup.string().required(),

}).required();

export default function MoreThanPPD() {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch()
    const isActive = useMediaQuery('(max-width: 1520px)')
    const policyTypes = useSelector(state => state.bikePolicyTypes.data.data);

    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(vdSchema)
    });
    const onSubmit = data => {
        if (data) {
            history.push("/MoreThanSYQ")
        }
    }


    useEffect(() => {
        dispatch(getBikePolicyTypes());
        return () => {
            console.log("cleanup");
        }

    }, [dispatch])

    return (
        <div style={{ background: '#F2F6FF' }}>
            <NavBar />
            <br /><br />
            <div class='step2-car-insurance'>
                <div class="container-fluid">
                    <div style={{ textAlign: 'center' }}><img src='images/Component 16.png' alt="top-img" class='step3-top-img'></img></div>

                    <div class="row " style={{ marginTop: '50px' }}>
                        <div class="step2-progress-img col-lg-2 col-md-0" >
                            <Stepper current={3} thirdStep={true} fouthStep={true} src="images/active.png" />
                        </div>
                        <div class="step2-content col-lg-5 col-md-7" >
                            <h2 class="ml-3">Your Previous Policy Details   - </h2><br />
                            <div class='d-sm-flex'>
                                <div class="car2-form-groupp" >
                                    <input
                                        type="date"
                                        id="icueRegistrationNo"
                                        name="icueRegistrationNo"
                                        class="car2-form-controll"
                                        style={{ marginLeft: '20px', marginRight: '20px' }}
                                        {...register('expiryDate')}
                                    // value={brand}
                                    />
                                    <label class="ms-3 car2-form-control-placeholderr" for="icueRegistrationNo">
                                        Expiry Date
                                    </label>
                                    <p class="text-danger">{errors.expiryDate?.message}</p>
                                </div>
                                {/* {isActive && <p style={{marginTop:'25px'}}></p>} */}
                                <div class="car2-form-groupp ">
                                    <select
                                        id="carYearOfPurchase"
                                        name="carYearOfPurchase"
                                        class="car2-form-controll "
                                        style={{ marginLeft: '20px', marginRight: '20px' }}
                                        required
                                        {...register('policy')}
                                    // value={carMake}
                                    >
                                        <option value="" selected disabled></option>
                                        {policyTypes && policyTypes.map(({ name }) => {
                                            return (
                                                <option>{name}</option>
                                            )
                                        })}
                                    </select>

                                    <label
                                        class="ms-3 car2-form-control-placeholderr"
                                        for='carYearOfPurchase'>
                                        Type of past policy
                                    </label>
                                    <p class="text-danger">{errors.policy?.message}</p>
                                </div>
                            </div>
                            <p class='car2-a'><a onClick={() => history.push("/MoreThanSYQ")}> DON'T KNOW</a></p>
                            <div class='step2-car-nextbtn ' ><button class='step2-car-nextbtn' onClick={handleSubmit(onSubmit)}>Next</button></div>
                        </div>
                        <div class=" step2-img col-lg-5 col-md-5">
                            <img class="ml-5 pb-3" src='images/car_step1.jpg'></img>
                        </div>
                    </div>
                </div>

            </div>



            <Footer />
        </div>
    )
}