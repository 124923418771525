import React from 'react'
import './HealthRenew.css'
import NavBar from '../ReusableComponents/NavBar'
import Footer from '../ReusableComponents/Footer'
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import { TextField } from '@material-ui/core';

import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

const healthRenewSchema = yup.object({
    policyNumber: yup.string().min(10).max(10).required(),
})


const useStyles = makeStyles({
    policyNumber: {
        width: '300px',
        // marginLeft: '100px',
        // outline:'red',
        [`& fieldset`]: {
            borderRadius: '10px',
        },

    }
})

export default function HealthRenew() {
    const history = useHistory();
    const classes = useStyles()

    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(healthRenewSchema)
    });
    const onSubmit = data => {
        if (data) {
            history.push("/")
        }
    }

    return (
        <>
            <div style={{ background: '#F2F6FF' }}>
                <NavBar />
                <br /><br />
                <div class='step4-car-insurance'>
                    <div class="container-fluid ">
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <div class='H-step1-topimg'><img src='images/Component 17.png' ></img>
                            </div>
                        </div>
                        <div class="row" style={{ marginTop: '50px' }}>
                            <div class='col-lg-1 col-md-0 ' >
                            </div>
                            <div class="H-step1-content col-lg-6 col-md-6">
                                <div>
                                    <button onClick={() => history.push("/Health")} class='H-toprenew-button2'>Buy New Policy</button>
                                    <button class='H-toprenew-button1'>Renew</button>
                                    <br /><br /><br />
                                    <div style={{ textAlign: 'center' }}>
                                        <TextField id="outlined-basic" type="number" label="Policy number" variant="outlined" {...register("policyNumber")} placeholder="Enter 10 digit policy number" className={classes.policyNumber} />
                                        <p class="text-danger">{errors.policyNumber?.message}</p>
                                    </div>
                                    <br /><br /><br />
                                    <div class='step4-car-nextbtn' onClick={handleSubmit(onSubmit)}><button >Next</button></div>
                                </div>
                            </div>
                            <div class=" H-step1-rightimg col-lg-5 col-md-6">
                                <img src='images/im2 4.png'></img>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>


    )
}