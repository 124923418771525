import React, { Component } from 'react'
import { HashRouter, Route } from 'react-router-dom';

import Login from '../LoginPage/Login'
import Registration from '../RegistrationPage/Registration'

import CarStep1 from '../CarInsurence/BrandNew/CarStep1'
import CarStep2 from '../CarInsurence/BrandNew/CarStep2'
import CarStep3 from '../CarInsurence/BrandNew/CarStep3'
import CarStep4 from '../CarInsurence/BrandNew/CarStep4'
import MoreThan3VD1 from '../CarInsurence/MoreThan3Years/MoreThan3VD1'
import MoreThan3VD2 from '../CarInsurence/MoreThan3Years/MoreThan3VD2'
import MoreThan3PPD from '../CarInsurence/MoreThan3Years/MoreThan3PPD'
import MoreThan3SYQ from '../CarInsurence/MoreThan3Years/MoreThan3SYQ'

import BikeInsurance from '../BikeInsurance/BikeInsurance'

import VehicleDetails from '../BikeInsurance/VehicleDetails'
import VehicleDetails2 from '../BikeInsurance/VehicleDetails2'
import SaveYourQuotes from '../BikeInsurance/SaveYourQuotes'

import MoreThanVD1 from '../BikeInsurance/MoreThan3Years/MoreThanVD1'
import MoreThanVD2 from '../BikeInsurance/MoreThan3Years/MoreThanVD2'
import MoreThanPPD from '../BikeInsurance/MoreThan3Years/MoreThanPPD'
import MoreThanSYQ from '../BikeInsurance/MoreThan3Years/MoreThanSYQ'

import HealthReNew from '../HealthInsurence/HealthReNew'
import HealthInsurance from '../HealthInsurence/HealthInsurance'
import Health from '../HealthInsurence/Health'
import HealthCover from '../HealthInsurence/HealthCover'
import HealthDisease from '../HealthInsurence/HealthDisease'
import HealthSaveQuotes from '../HealthInsurence/HealthSaveQuotes'
import HomePage from '../HomePage/HomePage'
import OtherInsurance from '../OtherInsurance/OtherInsurane'
import LegalAdminPolicies from '../OtherInsurance/index'

import LifeInsurance from '../LifeInsurance/LifeInsurance'
import LifeStep1 from '../LifeInsurance/LifeStep1'
import TLinsured from '../LifeInsurance/TermLifePlan/TLinsured'
import TLsaveQuotes from '../LifeInsurance/TermLifePlan/TLsaveQuotes'
import SFCinsured from '../LifeInsurance/SavingForChild/SFCinsured'
import SFCinvest from '../LifeInsurance/SavingForChild/SFCinvest'
import SFCsaveQuotes from '../LifeInsurance/SavingForChild/SFCsaveQuotes'
import ITinsured from '../LifeInsurance/Investment&Tax/ITinsured'
import ITinvest from '../LifeInsurance/Investment&Tax/ITinvest'
import ITsaveQuotes from '../LifeInsurance/Investment&Tax/ITsaveQuotes'
import PRinsured from '../LifeInsurance/PensionRetirement/PRinsured'
import PRinvest from '../LifeInsurance/PensionRetirement/PRinvest'
import PRsaveQuotes from '../LifeInsurance/PensionRetirement/PRsaveQuotes'

import SuperAdminLogin from '../SuperAdmin/Login/SuperAdminLogin'
import ForgotPassword from '../SuperAdmin/Reset/ForgotPassword'
import SuperDashboard from '../SuperAdmin/Dashboard/SuperDashboard'
import LMS from '../SuperAdmin/LMS/LMS'
import Lead from '../SuperAdmin/LMS/Corporate/Lead'
import Cust_detail from '../SuperAdmin/LMS/Corporate/Cust_detail'

import AgentDashboard from '../Agent/AgentDashboard/AgentDashboard'
import AgentLMS from '../Agent/AgentLMS/AgentLMS'
import AgentCustomer from '../Agent/Customer/AgentCustomer'
import Followup from '../Agent/AgentDashboard/Followup/Followup'

import RetailDashboard from '../RetailCustomer/RetailDashboard/RetailDashboard'
import Policies from '../RetailCustomer/Policies/Policies'
import EditPolicy from '../RetailCustomer/Policies/Editpolicy/Editpolicy'
import PayInstallment from '../RetailCustomer/Policies/PayInstallment/PayInstallment'
import RenewPolicy from '../RetailCustomer/Policies/RenewPolicy/Renew1/RenewPolicy'

import AddNew from '../RetailCustomer/Policies/AddNew/AddNew'
import Connect from '../RetailCustomer/RetailDashboard/Connect/Connect'
import ConnectToPay from '../RetailCustomer/Policies/PayInstallment/ConnectToPay/ConnectToPay'

import HRDashboard from '../SuperAdmin/HRMS/HRDashboard/HRDashboard'
import Payitems from '../SuperAdmin/HRMS/Payroll/Payitem/Payitems'
import EmpSalary from '../SuperAdmin/HRMS/Payroll/EmpSalary/EmpSalary'
import AddRoles from '../SuperAdmin/HRMS/Roles/AddRoles'
import Department from '../SuperAdmin/HRMS/Department/Department'
import Holidays from '../SuperAdmin/HRMS/Holidays/Holidays'
import LeaveType from '../SuperAdmin/HRMS/Leavemanagement/LeaveType/LeaveType'
import Leaves from '../SuperAdmin/HRMS/Leavemanagement/Leaves/Leaves'
import Attendance from '../SuperAdmin/HRMS/Attendance/Attendance'
import Renew2 from '../RetailCustomer/Policies/RenewPolicy/Renew2/Renew2'
import Renew3 from '../RetailCustomer/Policies/RenewPolicy/Renew3/Renew3'
import Edit2 from '../RetailCustomer/Policies/Editpolicy/Edit2/Edit2'

import AddMembers from '../SuperAdmin/HRMS/Usermanagement/AddMembers/AddMembers'
import ListMembers from '../SuperAdmin/HRMS/Usermanagement/ListMembers/ListMembers'
import UserManagment from '../SuperAdmin/HRMS/Usermanagement/UserManagment'

import ComingSoon from '../ComingSoon/ComingSoon';
import PrivacyPolicy from '../PolicyTerms/PrivacyPolicy'
import Irdai from '../Irdai/Irdai'

import LeadershipView from '../LeadershipView/LeadershipView'
import licence from '../OtherInsurance/licence'
import AddCattleInsurance from '../CattleInsurance/AddCattleInsurance'
import CattleInsurance from '../CattleInsurance/CattleInsurance'


export class AppRoutes extends Component {
    render() {
        return (
            <div>
               <HashRouter>
                        <Route exact path="/login" component={Login} />
                        <Route exact path="/registration" component={Registration} />
                        <Route exact path="/" component={HomePage} />
                        <Route exact path="/OtherInsurance" component={OtherInsurance} />
                        <Route exact path="/carstep1" component={CarStep1} />
                        <Route exact path="/carstep2" component={CarStep2} />
                        <Route exact path="/carstep3" component={CarStep3} />
                        <Route exact path="/carstep4" component={CarStep4} />
                        <Route exact path="/MoreThan3VD1" component={MoreThan3VD1} />
                        <Route exact path="/MoreThan3VD2" component={MoreThan3VD2} />
                        <Route exact path="/MoreThan3PPD" component={MoreThan3PPD} />
                        <Route exact path="/MoreThan3SYQ" component={MoreThan3SYQ} />

                        <Route exact path="/Two-wheeler" component={BikeInsurance} />
                        <Route exact path="/VehicleDetails" component={VehicleDetails} />
                        <Route exact path="/VehicleDetails2" component={VehicleDetails2} />
                        <Route exact path="/MoreThanPPD" component={MoreThanPPD} />
                        <Route exact path="/MoreThanVD1" component={MoreThanVD1} />
                        <Route exact path="/MoreThanVD2" component={MoreThanVD2} />
                        <Route exact path="/MoreThanSYQ" component={MoreThanSYQ} />
                        <Route exact path="/SaveYourQuotes" component={SaveYourQuotes} />

                        <Route exact path="/HealthInsurance" component={HealthInsurance} />
                        <Route exact path="/Health" component={Health} />
                        <Route exact path="/HealthRenew" component={HealthReNew} />
                        <Route exact path="/HealthCover" component={HealthCover} />
                        <Route exact path="/HealthDisease" component={HealthDisease} />
                        <Route exact path="/HealthSaveQuotes" component={HealthSaveQuotes} />

                        <Route exact path="/LifeInsurance" component={LifeInsurance} />
                        <Route exact path="/LifeStep1" component={LifeStep1} />
                        <Route exact path="/TLinsured" component={TLinsured} />
                        <Route exact path="/TLsaveQuotes" component={TLsaveQuotes} />
                        <Route exact path="/SFCinsured" component={SFCinsured} />
                        <Route exact path="/SFCinvest" component={SFCinvest} />
                        <Route exact path="/SFCsaveQuotes" component={SFCsaveQuotes} />
                        <Route exact path="/ITinsured" component={ITinsured} />
                        <Route exact path="/ITinvest" component={ITinvest} />
                        <Route exact path="/ITsaveQuotes" component={ITsaveQuotes} />
                        <Route exact path="/PRinsured" component={PRinsured} />
                        <Route exact path="/PRinvest" component={PRinvest} />
                        <Route exact path="/PRsaveQuotes" component={PRsaveQuotes} />

                        <Route exact path="/superadmin" component={SuperAdminLogin} />
                        <Route exact path="/Forgotpassword" component={ForgotPassword} />
                        <Route exact path="/Superdashboard" component={SuperDashboard} />
                        <Route exact path="/lms" component={LMS} />
                        <Route exact path="/leads" component={Lead} />
                        <Route exact path="/Customer" component={Cust_detail} />

                        <Route exact path="/AgentDashboard" component={AgentDashboard} />
                        <Route exact path="/AgentLMS" component={AgentLMS} />
                        <Route exact path="/AgentCustomer" component={AgentCustomer} />
                        <Route exact path="/Followup" component={Followup} />

                        <Route exact path="/RetailDashboard" component={RetailDashboard} />
                        <Route exact path="/Policies" component={Policies} />
                        <Route exact path="/Editpolicy" component={EditPolicy} />
                        <Route exact path="/Edit2" component={Edit2} />
                        <Route exact path="/PayInstallment" component={PayInstallment} />
                        <Route exact path="/RenewPolicy" component={RenewPolicy} />
                        <Route exact path="/Renew2" component={Renew2} />
                        <Route exact path="/Renew3" component={Renew3} />
                        <Route exact path="/AddNew" component={AddNew} />
                        <Route exact path="/connect" component={Connect} />
                        <Route exact path="/connecttopay" component={ConnectToPay} />

                        <Route exact path="/Hrdashboard" component={HRDashboard} />
                        <Route exact path="/Payitems" component={Payitems} />
                        <Route exact path="/EmpSalary" component={EmpSalary} />
                        <Route exact path="/AddRoles" component={AddRoles} />
                        <Route exact path="/Department" component={Department} />
                        <Route exact path="/Holidays" component={Holidays} />
                        <Route exact path="/LeaveType" component={LeaveType} />
                        <Route exact path="/Leaves" component={Leaves} />
                        <Route exact path="/Attendance" component={Attendance} />


                        <Route exact path="/UserManagment" component={UserManagment} />
                        <Route exact path="/AddMembers" component={AddMembers} />
                        <Route exact path="/ListMembers" component={ListMembers} />

                        <Route exact path="/ComingSoon" component={ComingSoon} />

                        <Route exact path="/Policy" component={PrivacyPolicy} />
                        <Route exact path="/Irdai" component={Irdai} />
                        <Route exact path="/LegalAdminPolicies" component={LegalAdminPolicies} />
                       
                        <Route exact path="/LeadersProfile" component={LeadershipView} />
                        <Route exact path="/cattleInsurance" component={CattleInsurance} />
                        <Route exact path="/addCattleInsurance" component={AddCattleInsurance} />




                </HashRouter>
            </div>
        )
    }
}

export default AppRoutes
