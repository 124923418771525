import React, { useState, Fragment } from "react";
import './Payitems.css';
import { nanoid } from "nanoid";
import data from "./Addition/mock-data1.json";
import data2 from "./Deduction/mock-data2.json";
import ReadOnlyRow2 from "./Deduction/ReadOnlyRow2";
import ReadOnlyRow1 from "./Addition/ReadOnlyRow1";
import EditableRow1 from "./Addition/EditableRow1";
import EditableRow2 from "./Deduction/EditableRow2";
const Payitems=()=>{
    // /----Add Addition----------/ 
    const [contacts, setContacts] = useState(data);
  const [addFormData, setAddFormData] = useState({
    namee: "",
    category: "",
    amount: ""
    
  });
  const [editFormData, setEditFormData] = useState({
    namee: "",
    category: "",
    amount: ""
  });
  const [editContactId, setEditContactId] = useState(null);

  const handleAddFormChange = (event) => {
    event.preventDefault();

    const fieldName = event.target.getAttribute("name");
    const fieldValue = event.target.value;

    const newFormData = { ...addFormData };
    newFormData[fieldName] = fieldValue;

    setAddFormData(newFormData);
  };
  const handleEditFormChange = (event) => {
    event.preventDefault();

    const fieldName = event.target.getAttribute("name");
    const fieldValue = event.target.value;

    const newFormData = { ...editFormData };
    newFormData[fieldName] = fieldValue;

    setEditFormData(newFormData);
  };
  const handleAddFormSubmit = (event) => {
    event.preventDefault();
    const newContact = {
        id: nanoid(),
        namee: addFormData.namee,
        category: addFormData.category,
        amount: addFormData.amount
       
      };
  
      const newContacts = [...contacts, newContact];
    
      setContacts(newContacts);
    };
    const handleEditFormSubmit = (event) => {
        event.preventDefault();
    
        const editedContact = {
          id: editContactId,
          namee: editFormData.namee,
          category: editFormData.category,
          amount: editFormData.amount
         
        };
    
        const newContacts = [...contacts];
    
        const index = contacts.findIndex((contact) => contact.id === editContactId);
    
        newContacts[index] = editedContact;
    
        setContacts(newContacts);
        setEditContactId(null);
      };
      const handleEditClick = (event, contact) => {
        event.preventDefault();
        setEditContactId(contact.id);
    
        const formValues = {
          namee: contact.namee,
          category: contact.category,
          amount:contact.amount
         
        };
    
        setEditFormData(formValues);
      };
      const handleCancelClick = () => {
        setEditContactId(null);
      };
      const handleDeleteClick = (contactId) => {
        const newContacts = [...contacts];
    
        const index = contacts.findIndex((contact) => contact.id === contactId);
    
        newContacts.splice(index, 1);
    
        setContacts(newContacts);
      };

    //   -------------------Add Deduction------------------------------
    const [contacts2, setContacts2] = useState(data2);
  const [addFormData2, setAddFormData2] = useState({
    namee2: "",
    
    amount2: ""
    
  });
  const [editFormData2, setEditFormData2] = useState({
    namee2: "",
   
    amount2: ""
  });
  const [editContactId2, setEditContactId2] = useState(null);

  const handleAddFormChange2 = (event) => {
    event.preventDefault();

    const fieldName = event.target.getAttribute("name");
    const fieldValue = event.target.value;

    const newFormData2 = { ...addFormData2 };
    newFormData2[fieldName] = fieldValue;

    setAddFormData2(newFormData2);
  };
  const handleEditFormChange2 = (event) => {
    event.preventDefault();

    const fieldName = event.target.getAttribute("name");
    const fieldValue = event.target.value;

    const newFormData2 = { ...editFormData2 };
    newFormData2[fieldName] = fieldValue;

    setEditFormData2(newFormData2);
  };
  const handleAddFormSubmit2 = (event) => {
    event.preventDefault();
    const newContact2 = {
        id: nanoid(),
        namee2: addFormData2.namee2,
       
        amount2: addFormData2.amount2
       
      };
  
      const newContacts2 = [...contacts2, newContact2];
    
      setContacts2(newContacts2);
    };
    const handleEditFormSubmit2 = (event) => {
        event.preventDefault();
    
        const editedContact2 = {
          id: editContactId2,
          namee2: editFormData2.namee2,
        
          amount2: editFormData2.amount2
         
        };
    
        const newContacts2 = [...contacts2];
    
        const index = contacts2.findIndex((contact2) => contact2.id === editContactId2);
    
        newContacts2[index] = editedContact2;
    
        setContacts2(newContacts2);
        setEditContactId2(null);
      };
      const handleEditClick2 = (event, contact2) => {
        event.preventDefault();
        setEditContactId2(contact2.id);
    
        const formValues = {
          namee2: contact2.namee2,
         
          amount2:contact2.amount2
         
        };
    
        setEditFormData2(formValues);
      };
      const handleCancelClick2 = () => {
        setEditContactId2(null);
      };
      const handleDeleteClick2 = (contactId2) => {
        const newContacts2 = [...contacts2];
    
        const index = contacts2.findIndex((contact2) => contact2.id === contactId2);
    
        newContacts2.splice(index, 1);
    
        setContacts2(newContacts2);
      };
    return(<div className="Payitems" id="Payitems">
        {/* <!-- Top Bar --> */}
    <div class="container-fluid1 py-2 icueTopBarContainer bg-white">
        <div class="row">
            <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                <div class="topBarLfContainer d-flex">
                    <div class="icueLogoContainer">
                        <img src="images/logo.png" alt="ICue Logo" class="icueLogo" />
                    </div>
                    <div class="icueSearchBox w-75 mx-3">
                        <div class="input-group ">
                            <span class="input-group-text" id="basic-addon1">
                                <i class="bi bi-search"></i>
                            </span>
                            <input type="search" id="text-search" class="form-control icueSearchBoxControl" placeholder="Enter keywords..." aria-label="Username" aria-describedby="basic-addon1"/>
                          </div>
                    </div>
                </div>
            </div>

            <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                <div class="d-flex flex-row-reverse">
                    <div class="icueProfile px-2">
                        <img src="images/avatar.png" alt="icueProfilePic" class="icueProfilePic border border-dark rounded-circle" />
                    </div>
                    <div class="position-relative px-2">
                        <a href="#" id="icueNotifyBoxHandler">
                            <i class="bi bi-bell">
                                <span class="position-absolute icueNofIndic"></span>
                            </i>
                        </a>
                        <div class="icueNotifyBox" id="icueNotifyBox">
                            Notifications
                        </div>
                    </div>

                    <div class="icueUserName fs-6 px-2">
                        Viral Patel
                    </div>

                    <div class="icueMail px-2">
                        <i class="bi bi-headset"></i> &nbsp;
                        adminhelpdesk@gmail.com
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* <!-- /Top Bar -->

    <!-- Main Body --> */}
    <div class="container-fluid my-2">
        <div class="row">
            <div class="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12 bg-white">
                <div class="icueRespSideBar d-xxl-none d-xl-none d-lg-none d-md-block d-sm-block d-block" id="icueRespSideBar">
                    <button type="button" class="btn btn-primary" id="icueRespHumburger">
                        <i class="bi bi-grid-3x3-gap"></i>
                    </button>
                    <div class="icueSideBar" id="icueSideBar">
                        <nav class="navbar icueSideNavContainer">
                            <ul class="icueNavBarList">
                                <li class="icueNavBarItem ">
                                    <a href="/Hrdashboard" class="icueNavBarLink" id="">Dashboard</a>
                                </li>
                                <li class="icueNavBarItem border-bottom-0">
                                <a href="#" class="icueNavBarLink" id="">User Management</a>
                            </li>

                            <li>
                                <ul class="icueSubNavBar">
                                    <li class="icueSubNavItem">
                                        {/* <!-- <a href="#" data-bs-toggle="modal" data-bs-target="#addid" data-bs-whatever="@getbootstrap" class="icueSubNavLink  text-capitalize">Add Member</a> --> */}
                                        <a href="#" class="icueSubNavLink  text-capitalize">Add Members</a>
                                    </li>
                                    <li class="icueSubNavItem">
                                        <a href="/ListMembers" class="icueSubNavLink  text-capitalize">List of Members</a>
                                    </li>
                                </ul>
                            </li>
                            <hr></hr>

                                <li class="icueNavBarItem border-bottom-0">
                                <a href="#" class="icueNavBarLink" id="">Leave Management</a>
                            </li>

                            <li>
                                <ul class="icueSubNavBar">
                                    <li class="icueSubNavItem">
                                        {/* <!-- <a href="#" data-bs-toggle="modal" data-bs-target="#addid" data-bs-whatever="@getbootstrap" class="icueSubNavLink  text-capitalize">Add Member</a> --> */}
                                        <a href="/Leaves" class="icueSubNavLink  text-capitalize">Leaves</a>
                                    </li>
                                    <li class="icueSubNavItem">
                                        <a href="/LeaveType" class="icueSubNavLink  text-capitalize">Leave Type</a>
                                    </li>
                                </ul>
                            </li>
                            <hr></hr>
                            <li class="icueNavBarItem border-bottom-1">
                                <a href="/Holidays" class="icueNavBarLink" id="">Holidays</a>
                            </li>                            
                            <li class="icueNavBarItem border-bottom-1">
                                <a href="/Attendance" class="icueNavBarLink" id="">Attendance</a>
                            </li>                       
    
                                 
    
                                <li class="icueNavBarItem">
                                    <a href="/Department" class="icueNavBarLink" id="">Department</a>
                                </li>
    
                                <li class="icueNavBarItem">
                                    <a href="/AddRoles" class="icueNavBarLink" id="">Roles</a>
                                </li>
                                
                                <li class="icueNavBarItem border-bottom-0">
                                <a href="#" class="icueNavBarLink" id="">Payroll</a>
                            </li>
                            <li  >
                                <ul class="icueSubNavBar">
                                    <li class="icueSubNavItem"  style={{marginLeft:"-20px" , marginTop:"-10px"}}>
                                        <a href="/EmpSalary" class="icueSubNavLink  text-capitalize">Employee Salary</a>
                                    </li>                                    
                                </ul>                                
                            </li>
                            <li className="active">
                                <ul>
                                <li class="icueSubNavItem">
                                        <a href="/Payitems" class="icueSubNavLink  text-capitalize">Payroll Items</a>
                                    </li>
                                </ul>
                            </li>
                                <hr/>
                                <li class="icueNavBarItem border-bottom-0" style={{marginTop:"-18px"}}>
                                    <a href="#" class="icueNavBarLink" id="">Settings</a>
                                </li>
                                <li>
                                    <ul class="icueSubNavBar">
                                        <li class="icueSubNavItem">
                                            <a href="#" class="icueSubNavLink  text-capitalize">Change Password</a>
                                        </li>
                                        <li class="icueSubNavItem">
                                            <a href="#" class="icueSubNavLink  text-capitalize">Profile</a>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>

                <div class="icueNavSideBar d-xxl-block d-xl-block d-lg-block d-md-none d-sm-none d-none" id="icueNavSideBar">
                    <nav>
                        <ul class="icueNavBarList">
                            <li class="icueNavBarItem ">
                                <a href="/Hrdashboard" class="icueNavBarLink" id="">Dashboard</a>
                            </li>

                            <li class="icueNavBarItem border-bottom-0">
                                <a href="#" class="icueNavBarLink" id="">User Management</a>
                            </li>

                            <li>
                                <ul class="icueSubNavBar">
                                    <li class="icueSubNavItem"  style={{marginTop:"-8px"}}>
                                        {/* <!-- <a href="#" data-bs-toggle="modal" data-bs-target="#addid" data-bs-whatever="@getbootstrap" class="icueSubNavLink  text-capitalize">Add Member</a> --> */}
                                        <a href="#" class="icueSubNavLink  text-capitalize">Add Member</a>
                                    </li>
                                    <li class="icueSubNavItem">
                                        <a href="/ListMembers" class="icueSubNavLink  text-capitalize">List of Members</a>
                                    </li>
                                </ul>
                            </li>
                            <hr></hr>

                            <li class="icueNavBarItem border-bottom-0" style={{marginTop:"-18px"}}>
                                <a href="#" class="icueNavBarLink" id="" >Leave Management</a>
                            </li>

                            <li>
                                <ul class="icueSubNavBar ">
                                    <li class="icueSubNavItem" style={{marginTop:"-8px"}}>
                                        {/* <!-- <a href="#" data-bs-toggle="modal" data-bs-target="#addid" data-bs-whatever="@getbootstrap" class="icueSubNavLink  text-capitalize">Add Member</a> --> */}
                                        <a href="/Leaves" class="icueSubNavLink  text-capitalize">Leaves</a>
                                    </li>
                                    <li class="icueSubNavItem">
                                        <a href="/LeaveType" class="icueSubNavLink  text-capitalize">Leave Type</a>
                                    </li>
                                </ul>
                                <hr></hr>
                            </li>              

                            <li class="icueNavBarItem "style={{marginTop:"-18px"}}>
                                <a href="/Holidays" class="icueNavBarLink" id="">Holidays</a>
                            </li>   
                            <li class="icueNavBarItem ">
                                <a href="/Attendance" class="icueNavBarLink" id="">Attendance</a>
                            </li>   
                            
                            

                            
                            <li class="icueNavBarItem" >
                                <a href="/Department" class="icueNavBarLink" id="">Department</a>
                            </li>

                            <li class="icueNavBarItem">
                                <a href="/AddRoles" class="icueNavBarLink" id="">Roles</a>
                            </li>
                            

                            <li class="icueNavBarItem border-bottom-0">
                                <a href="#" class="icueNavBarLink" id="">Payroll</a>
                            </li>
                            <li>
                                <ul class="icueSubNavBar">
                                    <li class="icueSubNavItem" style={{marginTop:"-10px"}}>
                                        <a href="/EmpSalary" class="icueSubNavLink  text-capitalize">Employee Salary</a>
                                    </li>                                    
                                </ul>                                
                            </li>
                            <li className="active">
                                <ul>
                                <li class="icueSubNavItem" style={{marginLeft:"-20px"}}>
                                        <a href="/Payitems" class="icueSubNavLink  text-capitalize">Payroll Items</a>
                                    </li>
                                </ul>
                            </li>
                           <hr/>
                            <li class="icueNavBarItem border-bottom-0"  style={{marginTop:"-18px"}}>
                                <a href="#" class="icueNavBarLink" id="">Settings</a>
                            </li>
                            <li>
                                <ul class="icueSubNavBar">
                                    <li class="icueSubNavItem">
                                        <a href="#" class="icueSubNavLink  text-capitalize">Change Password</a>
                                    </li>
                                    <li class="icueSubNavItem">
                                        <a href="#" class="icueSubNavLink  text-capitalize">Profile</a>
                                    </li>
                                </ul>
                                <hr></hr>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>

            <div class="col-xxl-10 col-xl-10 col-lg-10 col-md-12 col-sm-12 col-12 ">
                <main class="mainBodyContainer">
                    <div class="row my-2">
                        <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 py-2 px-3">
                            <div class="d-flex justify-content-between">
                                <h4 class="text-captalize"style={{fontFamily:"ErasDemiITC", fontWeight:"bold", marginTop:"1rem", marginLeft:"12px"}}>Payroll Items</h4>
                                <div class="bg-transparent p-2  rounded-3">
                                <nav style={{bsbreadcrumbdivider: 'url(&#34;"data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="8" height="8"%3E%3Cpath d="M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z" fill="currentColor"/%3E%3C/svg%3E&#34")'}} aria-label="breadcrumb">
                                    <ol class="breadcrumb mb-0 justify-content-end">
                                        <li class="breadcrumb-item bg-transparent" style={{fontFamily:"Calibri", fontSize:"bold"}}><a href="/Hrdashboard" class="text-decoration-none text-info">Dashboard</a></li>
                                        <li class="breadcrumb-item bg-transparent" style={{fontFamily:"Calibri"}}><a href="#" class="text-decoration-none text-info">Payroll</a></li>
                                        <li class="breadcrumb-item text-muted text-capitalize bg-transparent" aria-current="page" style={{  fontSize:"bold"}}>Payroll Items</li>
                                    </ol>
                                </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                   
                    <div class="row my-2">
                        <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 py-1 px-3" >
                            <div class="bg-white p-2  rounded-3 shadow-sm">
                                <div class="px-2 py-3">                  
                                      <div class="container1">
                                           <div class="row">
                                             <div class=" col-xs-12 bg-white ">
                                                 <ul class="nav nav-tabs" id="myTab" role="tablist" style={{marginTop:"-25px", marginLeft:"30px"}}>
                                                    <li class="nav-item">
                                                      <a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">Additions</a>
                                                    </li>
                                                    <li class="nav-item">
                                                       <a class="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">Deductions</a>
                                                     </li>                                       

                                                 </ul>

                                             
                                              </div>
                                              
                                               <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12  bg-white">
                                                    <div class="tab-content py-1 px-3 px-sm-0" id="myTabContent">
                                                        <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                                        <a href="#" type="button" class=" icueBgPrimary text-white pt-2  " data-bs-toggle="modal" data-bs-target="#addid1" style={{fontFamily:"Calibri", color:"white", fontSize:"bold", height:"30px", lineHeight:"10px",  width:"125px", marginLeft:"890px", textAlign:"center"}}><i class="bi bi-plus-circle"></i> &nbsp;   Add Addition</a>   
                                                                         
                                                             
                                                               <form id="icueFollowInfoForm" onSubmit={handleEditFormSubmit}>
                                            <table id="payrollTable" class="table table-striped table-hover  " width="auto" >
                                                <thead>
                                                    <tr class="text-uppercase" style={{lineHeight:"15px", fontFamily:"Calibri", fontStyle:"normal",  fontSize:"12px", backgroundColor:"#F4F7FC"}}>
                                                        <th class="text-left py-3">Name</th>
                                                        <th  class="text-left py-3">Category</th>
                                                        <th  class="text-right py-3">Default/ Unit amount</th>
                                                        <th  class="text-right py-3">Actions</th>
                                                                                                               
                                                    </tr>
                                                </thead>
                                                <tbody style={{lineHeight:"15px", fontFamily:"Calibri", fontSize:"16px"}}>
                                                    
                                                   {contacts.map((contact) => (
                                                      <Fragment>
                                                        {editContactId === contact.id ? (
                                                            <EditableRow1
                                                              editFormData={editFormData}
                                                              handleEditFormChange={handleEditFormChange}
                                                              handleCancelClick={handleCancelClick} />
                                                             ) : (
                                                             <ReadOnlyRow1
                                                             contact={contact}
                                                             handleEditClick={handleEditClick}
                                                             handleDeleteClick={handleDeleteClick} />
                                                             )}
                                                       </Fragment>
            ))}
                                                </tbody>
                                            </table>
                                            </form>
                                       
                                   
                                     
                                   </div>
                                   
                                        <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                                            <div class="row">              
                                                  <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12  bg-white"> 
                                                  <a href="#" type="button" class=" icueBgPrimary text-white pt-2 top-right " data-bs-toggle="modal" data-bs-target="#addid2" style={{fontFamily:"Calibri", color:"white", fontSize:"bold", height:"30px", lineHeight:"10px", marginTop:"-270px", width:"125px", marginLeft:"850px", textAlign:"center"}}><i class="bi bi-plus-circle"></i> &nbsp;   Add Deduction</a>
                                                  <form id="icueFollowInfoForm" onSubmit={handleEditFormSubmit2}>
                                                 
                                                     <table id="payrollTable" class="table  table-hover table-striped  " width="100%" >
                                                <thead>
                                                   
                                                    <tr class="text-uppercase" style={{lineHeight:"15px", fontFamily:"Calibri",   fontStyle:"normal",  fontSize:"12px", backgroundColor:"#F4F7FC", marginLeft:"20px"}}>
                                                        <th class="text-left py-3" >Name</th>
                                                       
                                                        <th  class="text-right py-3">Default/ Unit amount</th>
                                                        
                                                        <th class="text-right py-3" >Actions</th>
                                                                                                               
                                                    </tr>
                                                </thead>
                                                <tbody style={{lineHeight:"15px", fontFamily:"Calibri", fontSize:"16px"}}>
                                                    {/* <tr  style={{ border:"0"}}>
                                                        <td >Provident Fund</td>
                                                        
                                                        <td class="text-center">2600</td>
                                                        <td></td>
                                                        <td  class="text-center py-0">  <button type='button'class='btn btn-sm btn-transparent editModal text-muted' id='editMod_1'> <a href="#"><i class='bi bi-pencil'></i></a></button> 
                                                         <button type='button' class='btn btn-sm btn-transparent icueDelBtnRow text-muted' id='remMod_1'style={{marginLeft:"-30px"}}><i class='bi bi-trash'></i></button> </td>
                                                                                                            
                                                    </tr>

                                                    <tr style={{ border:"0", backgroundColor:"#F4F7FC"}}>
                                                        <td >LWF</td>
                                                        
                                                        <td class="text-center">50</td>
                                                        <td></td>
                                                        <td class="text-center py-0">  <button type='button'class='btn btn-sm btn-transparent editModal text-muted' id='editMod_1'> <a href="#"><i class='bi bi-pencil'></i></a></button> 
                                                        <button type='button' class='btn btn-sm btn-transparent icueDelBtnRow text-muted' id='remMod_1'style={{marginLeft:"-30px"}}><i class='bi bi-trash'></i></button> </td>
                                                                                                            
                                                    </tr>
                                                    <tr  style={{ border:"0"}}>
                                                        <td >Unpaid Leave</td>
                                                         
                                                        <td class="text-center">1000</td>
                                                        <td></td>
                                                        <td class="text-center py-0" >  <button type='button'class='btn btn-sm btn-transparent editModal text-muted' id='editMod_1'> <a href="#"><i class='bi bi-pencil'></i></a></button> 
                                                        <button type='button' class='btn btn-sm btn-transparent icueDelBtnRow text-muted' id='remMod_1'style={{marginLeft:"-30px"}}><i class='bi bi-trash'></i></button> </td>
                                                                                                            
                                                    </tr>
                                                    <tr style={{ border:"0", backgroundColor:"#F4F7FC"}}>
                                                        <td >Group Mediclaim</td>
                                                         
                                                        <td class="text-center">596</td>
                                                        <td></td>
                                                        <td class="text-center py-1">  <button type='button'class='btn btn-sm btn-transparent editModal text-muted' id='editMod_1'> <a href="#"><i class='bi bi-pencil'></i></a></button> 
                                                        <button type='button' class='btn btn-sm btn-transparent icueDelBtnRow text-muted' id='remMod_1'style={{marginLeft:"-30px"}}><i class='bi bi-trash'></i></button> </td>
                                                                                                            
                                                    </tr> */}
                                                    {contacts2.map((contact2) => (
              <Fragment>
                {editContactId2 === contact2.id ? (
                  <EditableRow2
                    editFormData2={editFormData2}
                    handleEditFormChange2={handleEditFormChange2}
                    handleCancelClick2={handleCancelClick2}
                  />
                ) : (
                  <ReadOnlyRow2
                    contact2={contact2}
                    handleEditClick2={handleEditClick2}
                    handleDeleteClick2={handleDeleteClick2}
                  />
                )}
              </Fragment>
            ))}
                                                </tbody>
                                            </table>
                                            
                                            </form>
                                        </div>
                                    </div>   
</div>
<div class="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
<div className="row my-2" >
                        <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 py-2 px-3 pb-0 mb-0">
                            <div className=" p-2  rounded-3 shadow-sm" style={{marginLeft:"-1rem", marginRight:"-1rem", backgroundColor:"#F1F2F6",marginTop:"-10px"}}>
                                <div className="px-4 py-1">
                                    <div className="row">                                       
                                        <div class="row" style={{marginTop:"-45px"}}>
                                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12" style={{marginTop:"52px"}}>
                                   <div className="icueSearchBox w-75 mx-3 " style={{marginLeft:"10px"}}>
                                      <div className="input-group ">
                                      <span className="input-group-text bg-white" id="basic-addon1">
                                         <i className="bi bi-search"></i>
                                      </span>
                                         <input type="search" className="form-control icueSearchBoxControl bg-white" placeholder="Search by Policy No., Insurance Type, Company Name" aria-label="Username" aria-describedby="basic-addon1"/>
                                       </div>
                                   </div>
                                </div>
                                            
                                            <div class="col-lg-3 col-md-3 col-sm-3 col-3 pt-4" style={{marginTop:"10px", marginLeft:"118px"}}>
                                            <label class="text-muted" for=""></label>
                                                 <br/>
                                                <div class="form-group pb-0 mb-0" >
                                                <a href="/AddNew" type="button" class="btn icueBgPrimary text-white  rounded-3 w-78  "  style={{fontFamily:"Calibri", color:"white", fontSize:"bold", height:"35px", lineHeight:"10px", marginTop:"15px", marginLeft:"300px", width:"100px"}}> Add New</a>

                                                
                                                </div>             
                                    
                                            </div>
                                            </div>
                                            </div>
                                            </div>
                                            </div>
                                            </div>
                                            </div>

</div>
</div>
</div>
</div>
</div>

        </div>
    </div>
</div>
</div>
<div class="modal" id="addid1" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog  modal-dialog-centered">
        <div class="modal-content rounded-3">
            <div class="modal-header d-flex flex-column" id="addModalHeader">
                <div class="text-end w-100" >
                    <button type="button" class="btn text-muted " data-bs-dismiss="modal" ><i class="bi bi-x-circle" style={{color:"#8692A6"}}></i></button>
                </div>
                <div class="text-center">
                    <h5 class="modal-title" style={{color:"black", fontFamily:'ErasDemiITC'}}>Add Addition</h5>
                    {/* <p class="text-muted">Please add a valid email</p> */}
                </div>
            </div>
            <br/>
            <div class="modal-body" id="addModalBody">
                <form class="">
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                            <div class="form-group pb-2">
                                <input class="form-control" type="text" name="namee"  onChange={handleAddFormChange} placeholder="Name"  />
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                            <div class="form-group pb-2">
                                <select class="form-select text-muted" aria-label="icueAddDepartment" name="category" onChange={handleAddFormChange}>
                                    <option>Monthly remuneration</option>
                                    <option value="marketing">Additional remuneration</option>                                                                      
                                </select>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                            <div class="form-group pb-2">
                                <input class="form-control" type="text" name="amount" placeholder="Unit Amount"  onChange={handleAddFormChange} />
                            </div>
                        </div>                     
                       
                        
                        <div class="col-12 py-3">
                            <div class="d-grid gap-2">
                                <button type="button" data-bs-dismiss="modal" class="btn text-white " onClick={handleAddFormSubmit} style={{backgroundColor:"#3A3285"}}>
                                   Submit
                                </button>
                            </div>
                        </div>
                    </div>                   
                </form>
            </div>
        </div>
    </div>
</div>


<div class="modal" id="addid2" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog  modal-dialog-centered">
        <div class="modal-content rounded-3">
            <div class="modal-header d-flex flex-column" id="addModalHeader">
                <div class="text-end w-100" >
                    <button type="button" class="btn text-muted " data-bs-dismiss="modal" ><i class="bi bi-x-circle" style={{color:"#8692A6"}}></i></button>
                </div>
                <div class="text-center">
                    <h5 class="modal-title" style={{color:"black", fontFamily:'ErasDemiITC'}}>Add Deduction</h5>
                    {/* <p class="text-muted">Please add a valid email</p> */}
                </div>
            </div>
            <br/>
            <div class="modal-body" id="addModalBody">
                <form class="">
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                            <div class="form-group pb-2">
                                <input class="form-control" type="text" name="namee2" onChange={handleAddFormChange2} placeholder="Name"  />
                            </div>
                        </div>
                        
                        <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                            <div class="form-group pb-2">
                                <input class="form-control" type="text" name="amount2" onChange={handleAddFormChange2} placeholder="Unit Amount"  />
                            </div>
                        </div>
                        
                       
                        
                        <div class="col-12 py-3">
                            <div class="d-grid gap-2">
                                <button type="button" data-bs-dismiss="modal" class="btn text-white " onClick={handleAddFormSubmit2} style={{backgroundColor:"#3A3285"}}>
                                   Submit
                                </button>
                            </div>
                        </div>
                    </div>

                    
                </form>
            </div>
        </div>
    </div>
</div>
</main>
</div>
        </div>
    </div>
</div>
    )}
    export default Payitems;