import axios from "axios"
import Constant from "../../utils/constant"


export const addPosCattleInfo = (data) => {
    return new Promise((resolve) => {
      axios
        .post(Constant.BASE_URL + "cattle/cattleOnSubmitPos", data)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          resolve(error.response);
        });
    });
  };