const file = (key) => {
    // return `${Constant.BASE_URL}pos/file/pos/${key}`;
    return key;
  }
  
  const stringFormat = (value) => {
    if (value === null) { return "" };
    return String(value);
  }

  const format_date = (date) => {
    const d = new Date(date).toLocaleDateString("en-IN", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
    var mdy = d.split("/");
    var day = mdy[0];
    var month = mdy[1];
    var year = mdy[2];
    return `${day}-${month}-${year}`;
  };

  const cattleformat_date = (date) => {
    const d = new Date(date).toLocaleDateString("en-IN", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
    var mdy = d.split("/");
    var day = mdy[0];
    var month = mdy[1];
    var year = mdy[2];
    return `${day}-${month}-${year}`;
  };
  
  export { file, stringFormat, format_date, cattleformat_date };