import React, { useState } from 'react'
import NavBar from '../ReusableComponents/NavBar'
import Footer from '../ReusableComponents/Footer'
import { useHistory } from 'react-router-dom';
import './LifeStep1.css'
import Stepper from '../ReusableComponents/Stepper';

export default function LifeStep1() {
    const history = useHistory();
    return (
        <>
            <div style={{ background: '#F2F6FF' }}>
                <NavBar />
                <br /><br />
                <div class='step4-car-insurance'>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <div class='H-step1-topimg'>
                            <img src='images/Component 4.png' ></img>
                        </div>
                    </div>
                    <div class="row" style={{ marginTop: '50px' }}>
                        <div class='col-lg-1 col-md-0 ' >
                            <Stepper current={1} src="images/healthActive.png" />
                        </div>
                        <div class="H-step1-content col-lg-5 col-md-6">
                            <div class='d-sm-flex'>
                                <button onClick={() => history.push("/TLinsured")} class='H-toprenew-button2 L-Step1'>Term Life Plan</button>
                                <button onClick={() => history.push("/HealthRenew")} class='H-toprenew-button2 L-Step1'>Saving For Child</button>
                            </div>
                            <br />
                            <div class='d-sm-flex'>
                                <button onClick={() => history.push("/ITinsured")} class='H-toprenew-button2 L-Step1'>Investment & Tax Planning</button>
                                <button onClick={() => history.push("/PRinsured")} class='H-toprenew-button2 L-Step1'>Pension / Retirement</button>
                            </div>
                        </div>
                        <div class=" H-step1-rightimg col-lg-6  col-md-6">
                            <img src='images/im2 4.png'></img>
                        </div>

                    </div>
                </div>
                <Footer />
            </div>
        </>
    )
}