import React from 'react';
import './Attendance.css';
export default function Attendance(){
    return(<div className="Attendance" id="Attendance">
        {/* <!-- Top Bar --> */}
    <div class="container-fluid1 py-2 icueTopBarContainer bg-white">
        <div class="row">
            <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                <div class="topBarLfContainer d-flex">
                    <div class="icueLogoContainer">
                        <img src="images/logo.png" alt="ICue Logo" class="icueLogo" />
                    </div>
                    <div class="icueSearchBox w-75 mx-3">
                        <div class="input-group ">
                            <span class="input-group-text" id="basic-addon1">
                                <i class="bi bi-search"></i>
                            </span>
                            <input type="search" id="text-search" class="form-control icueSearchBoxControl" placeholder="Enter keywords..." aria-label="Username" aria-describedby="basic-addon1"/>
                          </div>
                    </div>
                </div>
            </div>

            <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                <div class="d-flex flex-row-reverse">
                    <div class="icueProfile px-2">
                        <img src="images/avatar.png" alt="icueProfilePic" class="icueProfilePic border border-dark rounded-circle" />
                    </div>
                    <div class="position-relative px-2">
                        <a href="#" id="icueNotifyBoxHandler">
                            <i class="bi bi-bell">
                                <span class="position-absolute icueNofIndic"></span>
                            </i>
                        </a>
                        <div class="icueNotifyBox" id="icueNotifyBox">
                            Notifications
                        </div>
                    </div>

                    <div class="icueUserName fs-6 px-2">
                        Viral Patel
                    </div>

                    <div class="icueMail px-2">
                        <i class="bi bi-headset"></i> &nbsp;
                        adminhelpdesk@gmail.com
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* <!-- /Top Bar -->

    <!-- Main Body --> */}
    <div class="container-fluid my-2">
        <div class="row">
            <div class="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12 bg-white">
                <div class="icueRespSideBar d-xxl-none d-xl-none d-lg-none d-md-block d-sm-block d-block" id="icueRespSideBar">
                    <button type="button" class="btn btn-primary" id="icueRespHumburger">
                        <i class="bi bi-grid-3x3-gap"></i>
                    </button>
                    <div class="icueSideBar" id="icueSideBar">
                        <nav class="navbar icueSideNavContainer">
                            <ul class="icueNavBarList">
                                <li class="icueNavBarItem ">
                                    <a href="/Hrdashboard" class="icueNavBarLink" id="">Dashboard</a>
                                </li>
                                <li class="icueNavBarItem border-bottom-0">
                                <a href="#" class="icueNavBarLink" id="">User Management</a>
                            </li>

                            <li>
                                <ul class="icueSubNavBar">
                                    <li class="icueSubNavItem">
                                        {/* <!-- <a href="#" data-bs-toggle="modal" data-bs-target="#addid" data-bs-whatever="@getbootstrap" class="icueSubNavLink  text-capitalize">Add Member</a> --> */}
                                        <a href="#" class="icueSubNavLink  text-capitalize">Add Members</a>
                                    </li>
                                    <li class="icueSubNavItem">
                                        <a href="/ListMembers" class="icueSubNavLink  text-capitalize">List of Members</a>
                                    </li>
                                </ul>
                            </li>
                            <hr></hr>

                                <li class="icueNavBarItem border-bottom-0">
                                <a href="#" class="icueNavBarLink" id="">Leave Management</a>
                            </li>

                            <li>
                                <ul class="icueSubNavBar">
                                    <li class="icueSubNavItem">
                                        {/* <!-- <a href="#" data-bs-toggle="modal" data-bs-target="#addid" data-bs-whatever="@getbootstrap" class="icueSubNavLink  text-capitalize">Add Member</a> --> */}
                                        <a href="/Leaves" class="icueSubNavLink  text-capitalize">Leaves</a>
                                    </li>
                                    <li class="icueSubNavItem">
                                        <a href="/LeaveType" class="icueSubNavLink  text-capitalize">Leave Type</a>
                                    </li>
                                </ul>
                            </li>
                            <hr></hr>
                            <li class="icueNavBarItem border-bottom-1">
                                <a href="/Holidays" class="icueNavBarLink" id="">Holidays</a>
                            </li>                            
                            <li class="icueNavBarItem border-bottom-1 active">
                                <a href="#" class="icueNavBarLink" id="">Attendance</a>
                            </li>                       
    
                                 
    
                                <li class="icueNavBarItem">
                                    <a href="/Department" class="icueNavBarLink" id="">Department</a>
                                </li>
    
                                <li class="icueNavBarItem">
                                    <a href="/AddRoles" class="icueNavBarLink" id="">Roles</a>
                                </li>
                                
                                <li class="icueNavBarItem border-bottom-0">
                                <a href="#" class="icueNavBarLink" id="">Payroll</a>
                            </li>
                            <li >
                                <ul class="icueSubNavBar">
                                    <li class="icueSubNavItem" style={{marginLeft:"-20px"}}>
                                        <a href="/EmpSalary" class="icueSubNavLink  text-capitalize">Employee Salary</a>
                                    </li>                                    
                                </ul>                                
                            </li>
                            <li>
                                <ul>
                                <li class="icueSubNavItem">
                                        <a href="/Payitems" class="icueSubNavLink  text-capitalize">Payroll Items</a>
                                    </li>
                                </ul>
                            </li>
                                <hr/>
                                <li class="icueNavBarItem border-bottom-0" style={{marginTop:"-18px"}}>
                                    <a href="#" class="icueNavBarLink" id="">Settings</a>
                                </li>
                                <li>
                                    <ul class="icueSubNavBar">
                                        <li class="icueSubNavItem">
                                            <a href="#" class="icueSubNavLink  text-capitalize">Change Password</a>
                                        </li>
                                        <li class="icueSubNavItem">
                                            <a href="#" class="icueSubNavLink  text-capitalize">Profile</a>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>

                <div class="icueNavSideBar d-xxl-block d-xl-block d-lg-block d-md-none d-sm-none d-none" id="icueNavSideBar">
                    <nav>
                        <ul class="icueNavBarList">
                            <li class="icueNavBarItem ">
                                <a href="/Hrdashboard" class="icueNavBarLink" id="">Dashboard</a>
                            </li>

                            <li class="icueNavBarItem border-bottom-0">
                                <a href="#" class="icueNavBarLink" id="">User Management</a>
                            </li>

                            <li>
                                <ul class="icueSubNavBar">
                                    <li class="icueSubNavItem"  style={{marginTop:"-8px"}}>
                                        {/* <!-- <a href="#" data-bs-toggle="modal" data-bs-target="#addid" data-bs-whatever="@getbootstrap" class="icueSubNavLink  text-capitalize">Add Member</a> --> */}
                                        <a href="#" class="icueSubNavLink  text-capitalize">Add Member</a>
                                    </li>
                                    <li class="icueSubNavItem">
                                        <a href="/ListMembers" class="icueSubNavLink  text-capitalize">List of Members</a>
                                    </li>
                                </ul>
                            </li>
                            <hr></hr>

                            <li class="icueNavBarItem border-bottom-0" style={{marginTop:"-18px"}}>
                                <a href="#" class="icueNavBarLink" id="" >Leave Management</a>
                            </li>

                            <li>
                                <ul class="icueSubNavBar ">
                                    <li class="icueSubNavItem" style={{marginTop:"-8px"}}>
                                        {/* <!-- <a href="#" data-bs-toggle="modal" data-bs-target="#addid" data-bs-whatever="@getbootstrap" class="icueSubNavLink  text-capitalize">Add Member</a> --> */}
                                        <a href="/Leaves" class="icueSubNavLink  text-capitalize">Leaves</a>
                                    </li>
                                    <li class="icueSubNavItem">
                                        <a href="/LeaveType" class="icueSubNavLink  text-capitalize">Leave Type</a>
                                    </li>
                                </ul>
                                <hr></hr>
                            </li>              

                            <li class="icueNavBarItem "style={{marginTop:"-18px"}}>
                                <a href="/Holidays" class="icueNavBarLink" id="">Holidays</a>
                            </li>   
                            <li class="icueNavBarItem active ">
                                <a href="#" class="icueNavBarLink" id="">Attendance</a>
                            </li>   
                            
                            

                            
                            <li class="icueNavBarItem" >
                                <a href="/Department" class="icueNavBarLink" id="">Department</a>
                            </li>

                            <li class="icueNavBarItem">
                                <a href="/AddRoles" class="icueNavBarLink" id="">Roles</a>
                            </li>
                            

                            <li class="icueNavBarItem border-bottom-0">
                                <a href="#" class="icueNavBarLink" id="">Payroll</a>
                            </li>
                            <li  >
                                <ul class="icueSubNavBar">
                                    <li class="icueSubNavItem"  style={{marginLeft:"-20px"}}>
                                        <a href="/EmpSalary" class="icueSubNavLink  text-capitalize">Employee Salary</a>
                                    </li>                                    
                                </ul>                                
                            </li>
                            <li>
                                <ul>
                                <li class="icueSubNavItem">
                                        <a href="/Payitems" class="icueSubNavLink  text-capitalize">Payroll Items</a>
                                    </li>
                                </ul>
                            </li>
                           <hr/>
                            <li class="icueNavBarItem border-bottom-0"  style={{marginTop:"-18px"}}>
                                <a href="#" class="icueNavBarLink" id="">Settings</a>
                            </li>
                            <li>
                                <ul class="icueSubNavBar">
                                    <li class="icueSubNavItem">
                                        <a href="#" class="icueSubNavLink  text-capitalize">Change Password</a>
                                    </li>
                                    <li class="icueSubNavItem">
                                        <a href="#" class="icueSubNavLink  text-capitalize">Profile</a>
                                    </li>
                                </ul>
                                <hr></hr>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>

            <div class="col-xxl-10 col-xl-10 col-lg-10 col-md-12 col-sm-12 col-12 ">
                <main class="mainBodyContainer">
                    <div class="row my-2">
                        <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 py-2 px-3">
                            <div class="d-flex justify-content-between">
                                <h4 class="text-captalize"style={{fontFamily:"ErasDemiITC", fontWeight:"bold", marginTop:"1rem", marginLeft:"12px"}}>Attendance</h4>
                                <div class="bg-transparent p-2  rounded-3">
                                <nav style={{bsbreadcrumbdivider: 'url(&#34;"data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="8" height="8"%3E%3Cpath d="M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z" fill="currentColor"/%3E%3C/svg%3E&#34")'}} aria-label="breadcrumb">
                                    <ol class="breadcrumb mb-0 justify-content-end">
                                        <li class="breadcrumb-item bg-transparent" style={{fontFamily:"Calibri", fontSize:"bold"}}><a href="/Hrdashboard" class="text-decoration-none text-info">Dashboard</a></li>
                                        {/* <li class="breadcrumb-item bg-transparent" style={{fontFamily:"Calibri"}}><a href="/lms" class="text-decoration-none text-info">Payroll</a></li> */}
                                        <li class="breadcrumb-item text-muted text-capitalize bg-transparent" aria-current="page" style={{  fontSize:"bold"}}>Attendance</li>
                                    </ol>
                                </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                   
                    <div class="row my-2">
                        <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 py-1 px-3" >
                            <div class="bg-white p-2  rounded-3 shadow-sm">
                                <div class="px-2 py-3">                  
                                      <div class="container1">
                                           <div class="row">
                                             {/* <div class=" col-xs-12 bg-white "> */}
                                            
                                             
                                <div class=" col-xl-2 col-lg-2 col-md-2 col-sm-4 col-4">
                                    <div class="form-group pb-2" style={{marginTop:"-15px"}}>
                                        <select class="form-select form-select-sm  text-muted text-capitalize" >
                                            <option value="October 2021">October 2021</option>
                                            <option value="November 2021">November 2021</option>
                                            <option value="December 2021">December 2021</option>
                                            <option value="January 2021">January 2021</option>
                                            <option value="Feburary 2021">Feburary 2021</option>
                                            <option value="March 2021">March 2021</option>
                                            <option value="April 2021">April 2021</option>
                                            <option value="May 2021">May 2021</option>
                                            <option value="June 2021">June 2021</option>
                                            <option value="July 2021">July 2021</option>
                                            <option value="August 2021">August 2021</option>
                                            <option value="September 2021">September 2021</option>                                            
                                        </select>
                                    </div>
                                    <div class=" col-xl-10 col-lg-10 col-md-10 col-sm-8 col-8">
                                    <a href="#" type="button" class="btn icueBgPrimary text-white  "  style={{fontFamily:"Calibri", color:"white", fontSize:"bold", lineHeight:"10px", marginTop:"-105px", width:"95px", marginLeft:"160px", textAlign:"center"}}>Search</a>                  
                                </div>  
                                  </div>             
                        
                  
                    
              
                                              
                                               <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12  bg-white" style={{marginTop:"-25px"}}>
                                                    <div class="tab-content py-1 px-3 px-sm-0" id="myTabContent">
                                                        <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                                          <div class="row">              
                                                               <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12  bg-white">              
                                           
                                            <table id="payrollTable" class="table  table-hover  " width="auto" >
                                                <thead>
                                                    <tr class="text-uppercase" style={{lineHeight:"15px", fontFamily:"Calibri", fontStyle:"normal",  fontSize:"12px", backgroundColor:"#F4F7FC"}}>
                                                        <th class="py-3" >Employee</th>
                                                        <th  class="text-center py-3">1</th>
                                                        <th  class="text-center py-3">2</th>
                                                        <th  class="text-center py-3">3</th>
                                                        <th  class="text-center py-3">4</th>
                                                        <th  class="text-center py-3">5</th>
                                                        <th  class="text-center py-3">6</th>
                                                        <th  class="text-center py-3">7</th>
                                                        <th  class="text-center py-3">8</th>
                                                        <th  class="text-center py-3">9</th>
                                                        <th  class="text-center py-3">10</th>
                                                        <th  class="text-center py-3">11</th>
                                                        <th  class="text-center py-3">12</th>
                                                        <th  class="text-center py-3">13</th>
                                                        <th  class="text-center py-3">14</th>
                                                        <th  class="text-center py-3">15</th>
                                                        <th  class="text-center py-3">16</th>
                                                        <th  class="text-center py-3">17</th>
                                                        <th  class="text-center py-3">18</th>
                                                        <th  class="text-center py-3">19</th>
                                                        <th  class="text-center py-3">20</th>
                                                        <th  class="text-center py-3">21</th>
                                                        <th  class="text-center py-3">22</th>
                                                        <th  class="text-center py-3">23</th>
                                                        <th  class="text-center py-3">24</th>
                                                        <th  class="text-center py-3">25</th>
                                                        <th  class="text-center py-3">26</th>
                                                        <th  class="text-center py-3">27</th>
                                                        <th  class="text-center py-3">28</th>
                                                        <th  class="text-center py-3">29</th>
                                                        <th  class="text-center py-3">30</th>
                                                        <th  class="text-center py-3">31</th>

                                                       
                                                                                                               
                                                    </tr>
                                                </thead>
                                                <tbody style={{lineHeight:"15px", fontFamily:"Calibri", fontSize:"16px"}}>
                                                    <tr  style={{ border:"0"}}>
                                                        <td >
                                                        <img src="images/avatar.png" alt="icueProfilePic" class="icueProfilePic border border-dark rounded-circle" /> &nbsp;
                                                            Shane Law <br></br><div style={{fontSize:"12px", color:" #7D90B2", marginLeft:"38px", marginTop:"-10px"}}> Emp Code: 100001  </div></td>
                                                         <td class="text-center">  </td>
                                                        <td class="text-center"></td>
                                                        <td > </td>
                                                        <td></td>
                                                        <td> </td>
                                                        <td > </td>
                                                        <td > </td>
                                                        <td></td>
                                                        <td> </td>
                                                        <td > </td>
                                                        <td > </td>
                                                        <td></td>
                                                        <td> </td>
                                                        <td > </td>
                                                        <td > </td>
                                                        <td></td>
                                                        <td> </td>
                                                        <td > </td>
                                                        <td > </td>
                                                        <td></td>
                                                        <td> </td>
                                                        <td > </td>
                                                        <td > </td>
                                                        <td></td>
                                                        <td> </td>
                                                        <td > </td>
                                                        <td > </td>
                                                        <td></td>
                                                        <td> </td>
                                                        <td > </td>
                                                                                                            
                                                    </tr>
                                                    <tr  style={{ border:"0", backgroundColor:"#F4F7FC"}}>
                                                        <td > <img src="images/avatar.png" alt="icueProfilePic" class="icueProfilePic border border-dark rounded-circle" /> &nbsp;Nicholas Motley <br></br><div style={{fontSize:"12px", color:" #7D90B2", marginLeft:"38px", marginTop:"-10px"}}> Emp Code: 100002  </div></td>
                                                        <td class="text-center">  </td>
                                                        <td class="text-center"></td>
                                                        <td > </td>
                                                        <td></td>
                                                        <td> </td>
                                                        <td > </td>
                                                        <td > </td>
                                                        <td></td>
                                                        <td> </td>
                                                        <td > </td>
                                                        <td > </td>
                                                        <td></td>
                                                        <td> </td>
                                                        <td > </td>
                                                        <td > </td>
                                                        <td></td>
                                                        <td> </td>
                                                        <td > </td>
                                                        <td > </td>
                                                        <td></td>
                                                        <td> </td>
                                                        <td > </td>
                                                        <td > </td>
                                                        <td></td>
                                                        <td> </td>
                                                        <td > </td>
                                                        <td > </td>
                                                        <td></td>
                                                        <td> </td>
                                                        <td > </td>
                                                                                                            
                                                    </tr>
                                                    <tr  style={{ border:"0"}}>
                                                        <td ><img src="images/avatar.png" alt="icueProfilePic" class="icueProfilePic border border-dark rounded-circle" /> &nbsp;Drake Erickson <br></br><div style={{fontSize:"12px", color:" #7D90B2", marginLeft:"38px", marginTop:"-10px"}}> Emp Code: 100003  </div></td>
                                                        <td class="text-center">  </td>
                                                        <td class="text-center"></td>
                                                        <td > </td>
                                                        <td></td>
                                                        <td> </td>
                                                        <td > </td>
                                                        <td > </td>
                                                        <td></td>
                                                        <td> </td>
                                                        <td > </td>
                                                        <td > </td>
                                                        <td></td>
                                                        <td> </td>
                                                        <td > </td>
                                                        <td > </td>
                                                        <td></td>
                                                        <td> </td>
                                                        <td > </td>
                                                        <td > </td>
                                                        <td></td>
                                                        <td> </td>
                                                        <td > </td>
                                                        <td > </td>
                                                        <td></td>
                                                        <td> </td>
                                                        <td > </td>
                                                        <td > </td>
                                                        <td></td>
                                                        <td> </td>
                                                        <td > </td>
                                                                                                            
                                                    </tr>
                                                    <tr  style={{ border:"0", backgroundColor:"#F4F7FC"}}>
                                                        <td ><img src="images/avatar.png" alt="icueProfilePic" class="icueProfilePic border border-dark rounded-circle" /> &nbsp;Wally Mann <br></br><div style={{fontSize:"12px", color:" #7D90B2", marginLeft:"38px", marginTop:"-10px"}}> Emp Code: 100004  </div></td>
                                                        <td class="text-center">  </td>
                                                        <td class="text-center"></td>
                                                        <td > </td>
                                                        <td></td>
                                                        <td> </td>
                                                        <td > </td>
                                                        <td > </td>
                                                        <td></td>
                                                        <td> </td>
                                                        <td > </td>
                                                        <td > </td>
                                                        <td></td>
                                                        <td> </td>
                                                        <td > </td>
                                                        <td > </td>
                                                        <td></td>
                                                        <td> </td>
                                                        <td > </td>
                                                        <td > </td>
                                                        <td></td>
                                                        <td> </td>
                                                        <td > </td>
                                                        <td > </td>
                                                        <td></td>
                                                        <td> </td>
                                                        <td > </td>
                                                        <td > </td>
                                                        <td></td>
                                                        <td> </td>
                                                        <td > </td>
                                                                                                            
                                                    </tr>
                                                    <tr  style={{ border:"0"}}>
                                                        <td ><img src="images/avatar.png" alt="icueProfilePic" class="icueProfilePic border border-dark rounded-circle" /> &nbsp;Howard Savage <br></br><div style={{fontSize:"12px", color:" #7D90B2", marginLeft:"38px", marginTop:"-10px"}}> Emp Code: 100005  </div></td>
                                                        <td class="text-center">  </td>
                                                        <td class="text-center"></td>
                                                        <td > </td>
                                                        <td></td>
                                                        <td> </td>
                                                        <td > </td>
                                                        <td > </td>
                                                        <td></td>
                                                        <td> </td>
                                                        <td > </td>
                                                        <td > </td>
                                                        <td></td>
                                                        <td> </td>
                                                        <td > </td>
                                                        <td > </td>
                                                        <td></td>
                                                        <td> </td>
                                                        <td > </td>
                                                        <td > </td>
                                                        <td></td>
                                                        <td> </td>
                                                        <td > </td>
                                                        <td > </td>
                                                        <td></td>
                                                        <td> </td>
                                                        <td > </td>
                                                        <td > </td>
                                                        <td></td>
                                                        <td> </td>
                                                        <td > </td>
                                                                                                            
                                                    </tr>
                                                    <tr  style={{ border:"0", backgroundColor:"#F4F7FC"}}>
                                                        <td ><img src="images/avatar.png" alt="icueProfilePic" class="icueProfilePic border border-dark rounded-circle" /> &nbsp;Ford Becker <br></br><div style={{fontSize:"12px", color:" #7D90B2", marginLeft:"38px", marginTop:"-10px"}}>  Emp Code: 100006  </div></td>
                                                        <td class="text-center">  </td>
                                                        <td class="text-center"></td>
                                                        <td > </td>
                                                        <td></td>
                                                        <td> </td>
                                                        <td > </td>
                                                        <td > </td>
                                                        <td></td>
                                                        <td> </td>
                                                        <td > </td>
                                                        <td > </td>
                                                        <td></td>
                                                        <td> </td>
                                                        <td > </td>
                                                        <td > </td>
                                                        <td></td>
                                                        <td> </td>
                                                        <td > </td>
                                                        <td > </td>
                                                        <td></td>
                                                        <td> </td>
                                                        <td > </td>
                                                        <td > </td>
                                                        <td></td>
                                                        <td> </td>
                                                        <td > </td>
                                                        <td > </td>
                                                        <td></td>
                                                        <td> </td>
                                                        <td > </td>
                                                       
                                                                                                            
                                                    </tr>
                                                    <tr  style={{ border:"0"}}>
                                                        <td ><img src="images/avatar.png" alt="icueProfilePic" class="icueProfilePic border border-dark rounded-circle" /> &nbsp;Byron Cunningham <br></br><div style={{fontSize:"12px", color:" #7D90B2", marginLeft:"38px", marginTop:"-10px"}}>  Emp Code: 100007  </div></td>
                                                        <td class="text-center">  </td>
                                                        <td class="text-center"></td>
                                                        <td > </td>
                                                        <td></td>
                                                        <td> </td>
                                                        <td > </td>
                                                        <td > </td>
                                                        <td></td>
                                                        <td> </td>
                                                        <td > </td>
                                                        <td > </td>
                                                        <td></td>
                                                        <td> </td>
                                                        <td > </td>
                                                        <td > </td>
                                                        <td></td>
                                                        <td> </td>
                                                        <td > </td>
                                                        <td > </td>
                                                        <td></td>
                                                        <td> </td>
                                                        <td > </td>
                                                        <td > </td>
                                                        <td></td>
                                                        <td> </td>
                                                        <td > </td>
                                                        <td > </td>
                                                        <td></td>
                                                        <td> </td>
                                                        <td > </td>
                                                                                                            
                                                    </tr>
                                                    <tr  style={{ border:"0", backgroundColor:"#F4F7FC"}}>
                                                        <td ><img src="images/avatar.png" alt="icueProfilePic" class="icueProfilePic border border-dark rounded-circle" /> &nbsp;Stephenia Nicol<br></br><div style={{fontSize:"12px", color:" #7D90B2", marginLeft:"38px", marginTop:"-10px"}}>   Emp Code: 100008  </div></td>
                                                        <td class="text-center">  </td>
                                                        <td class="text-center"></td>
                                                        <td > </td>
                                                        <td></td>
                                                        <td> </td>
                                                        <td > </td>
                                                        <td > </td>
                                                        <td></td>
                                                        <td> </td>
                                                        <td > </td>
                                                        <td > </td>
                                                        <td></td>
                                                        <td> </td>
                                                        <td > </td>
                                                        <td > </td>
                                                        <td></td>
                                                        <td> </td>
                                                        <td > </td>
                                                        <td > </td>
                                                        <td></td>
                                                        <td> </td>
                                                        <td > </td>
                                                        <td > </td>
                                                        <td></td>
                                                        <td> </td>
                                                        <td > </td>
                                                        <td > </td>
                                                        <td></td>
                                                        <td> </td>
                                                        <td > </td>
                                                                                                            
                                                    </tr>
                                                    <tr  style={{ border:"0"}}>
                                                        <td ><img src="images/avatar.png" alt="icueProfilePic" class="icueProfilePic border border-dark rounded-circle" /> &nbsp;Eddie Lake <br></br><div style={{fontSize:"12px", color:" #7D90B2", marginLeft:"38px", marginTop:"-10px"}}>  Emp Code: 100009  </div></td>
                                                        <td class="text-center">  </td>
                                                        <td class="text-center"></td>
                                                        <td > </td>
                                                        <td></td>
                                                        <td> </td>
                                                        <td > </td>
                                                        <td > </td>
                                                        <td></td>
                                                        <td> </td>
                                                        <td > </td>
                                                        <td > </td>
                                                        <td></td>
                                                        <td> </td>
                                                        <td > </td>
                                                        <td > </td>
                                                        <td></td>
                                                        <td> </td>
                                                        <td > </td>
                                                        <td > </td>
                                                        <td></td>
                                                        <td> </td>
                                                        <td > </td>
                                                        <td > </td>
                                                        <td></td>
                                                        <td> </td>
                                                        <td > </td>
                                                        <td > </td>
                                                        <td></td>
                                                        <td> </td>
                                                        <td > </td>
                                                                                                          
                                                    </tr>
                                                    <tr  style={{ border:"0", backgroundColor:"#F4F7FC"}}>
                                                        <td ><img src="images/avatar.png" alt="icueProfilePic" class="icueProfilePic border border-dark rounded-circle" /> &nbsp;Daniel Hamilton <br></br><div style={{fontSize:"12px", color:" #7D90B2", marginLeft:"38px", marginTop:"-10px"}}> Emp Code: 100010  </div></td>
                                                        <td class="text-center">  </td>
                                                        <td class="text-center"></td>
                                                        <td > </td>
                                                        <td></td>
                                                        <td> </td>
                                                        <td > </td>
                                                        <td > </td>
                                                        <td></td>
                                                        <td> </td>
                                                        <td > </td>
                                                        <td > </td>
                                                        <td></td>
                                                        <td> </td>
                                                        <td > </td>
                                                        <td > </td>
                                                        <td></td>
                                                        <td> </td>
                                                        <td > </td>
                                                        <td > </td>
                                                        <td></td>
                                                        <td> </td>
                                                        <td > </td>
                                                        <td > </td>
                                                        <td></td>
                                                        <td> </td>
                                                        <td > </td>
                                                        <td > </td>
                                                        <td></td>
                                                        <td> </td>
                                                        <td > </td>
                                                                                                            
                                                    </tr>
                                                  
                                                   
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>   
                                     
                                   </div>
                                   <div class="modal" id="icuePayModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog  modal-dialog-centered">
        <div class="modal-content rounded-3">
            <div class="modal-header d-flex flex-column" id="addModalHeader">
                <div class="text-end w-100">
                    <button type="button" class="btn text-muted" data-bs-dismiss="modal"><i class="bi bi-x-circle"></i></button>
                </div>
                <div class="text-center">
                    <h4 class="modal-title text-capitalize">insurance companies</h4>
                </div>
            </div>
            <div class="modal-body" id="addModalBody">
                <form class="">
                    <div class="row">
                        <div class="col-xxl-7 col-xl-7 col-lg-7 col-md-8 col-sm-8 col-10 mx-auto">
                            <div class="row">
                                <div class="col-lg-6 col-md-6 col-sm-12 col-12 pt-4">
                                    <div class="form-group pb-2 text-end">
                                        <button type="button" class="btn btn-sm btn-primary w-100 icueSelAll" id="icueSelAll">Select All</button>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-12 col-12 pt-4">
                                    <div class="form-group pb-2">
                                        <button type="button" class="btn btn-sm btn-primary w-100 iuceSelNon" id="icueSelNon">None</button>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6 col-sm-12 col-12 pt-2">
                                    <div class="form-check">
                                        <input class="form-check-input icuePolicySel" type="checkbox" value="" id="flexCheckChecked" checked/>
                                        <label class="form-check-label fs-7" for="flexCheckChecked">
                                            HDFC Ergo
                                        </label>
                                    </div>
                                </div>
                                
                                <div class="col-lg-6 col-md-6 col-sm-12 col-12 pt-2">
                                    <div class="form-check">
                                        <input class="form-check-input icuePolicySel" type="checkbox" value="" id="flexCheckChecked" checked/>
                                        <label class="form-check-label fs-7" for="flexCheckChecked">
                                            SBI General
                                        </label>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-12 col-12 pt-2">
                                    <div class="form-check">
                                        <input class="form-check-input icuePolicySel" type="checkbox" value="" id="flexCheckChecked" checked/>
                                        <label class="form-check-label fs-7" for="flexCheckChecked">
                                            ICICI Lombard
                                        </label>
                                    </div>
                                </div>
                                
                                <div class="col-lg-6 col-md-6 col-sm-12 col-12 pt-2">
                                    <div class="form-check">
                                        <input class="form-check-input icuePolicySel" type="checkbox" value="" id="flexCheckChecked" checked/>
                                        <label class="form-check-label fs-7" for="flexCheckChecked">
                                            Max Bupa
                                        </label>
                                    </div>
                                </div>
                                
                                <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                                    <div class="form-check">
                                        <input class="form-check-input icuePolicySel" type="checkbox" value="" id="flexCheckChecked" checked/>
                                        <label class="form-check-label fs-7" for="flexCheckChecked">
                                            Tata AIG
                                        </label>
                                    </div>
                                </div>

                                <div class="col-12 mx-auto py-3">
                                    <div class="d-grid gap-2">
                                        <button type="button" class="btn btn-primary btnIcon2">
                                            Send Invite
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<div class="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
<div className="row my-2" >
                        <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 py-2 px-3 pb-0 mb-0">
                            <div className=" p-2  rounded-3 shadow-sm" style={{marginLeft:"-1rem", marginRight:"-1rem", backgroundColor:"#F1F2F6",marginTop:"-10px"}}>
                                <div className="px-4 py-1">
                                    <div className="row">                                       
                                        <div class="row" style={{marginTop:"-45px"}}>
                                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12" style={{marginTop:"52px"}}>
                                   <div className="icueSearchBox w-75 mx-3 " style={{marginLeft:"10px"}}>
                                      <div className="input-group ">
                                      <span className="input-group-text bg-white" id="basic-addon1">
                                         <i className="bi bi-search"></i>
                                      </span>
                                         <input type="search" className="form-control icueSearchBoxControl bg-white" placeholder="Search by Policy No., Insurance Type, Company Name" aria-label="Username" aria-describedby="basic-addon1"/>
                                       </div>
                                   </div>
                                </div>
                                            
                                            <div class="col-lg-3 col-md-3 col-sm-3 col-3 pt-4" style={{marginTop:"10px", marginLeft:"118px"}}>
                                            <label class="text-muted" for=""></label>
                                                 <br/>
                                                <div class="form-group pb-0 mb-0" >
                                                <a href="/AddNew" type="button" class="btn icueBgPrimary text-white  rounded-3 w-78  "  style={{fontFamily:"Calibri", color:"white", fontSize:"bold", height:"35px", lineHeight:"10px", marginTop:"15px", marginLeft:"300px", width:"100px"}}> Add New</a>

                                                
                                                </div>             
                                    
                                            </div>
                                            </div>
                                            </div>
                                            </div>
                                            </div>
                                            </div>
                                            </div>

</div>
</div>
</div>
</div>
</div>

        </div>
    </div>
</div>
</div>
</main>
</div>
        </div>
    </div>
</div>
    )}