import React from "react";

const PastInsurerDetails = ({
  personalchk = void 0,
  personalDetail = [],
  formErrors = [],
  errors = [],
  isDisabled = false,
  data = [],
}) => {

    function checkInsurerInPast(value) {
        if (isDisabled && data?.past_any_prev_insurer === value) {
        return true;
        }
        return false;
    }

    function checkDeclinedInsuranceName(value) {
      if (isDisabled && data?.past_declined === value) {
      return true;
      }
      return false;
    }

    function checkImposedSpecialConditionsName(value) {
      if (isDisabled && data?.past_imposed_special_condition === value) {
      return true;
      }
      return false;
    }

    function checkRefusedRenewalName(value) {
      if (isDisabled && data?.past_refusal === value) {
      return true;
      }
      return false;
    }

    function checkInceasedPremiumName(value) {
      if (isDisabled && data?.past_increased_premium === value) {
        return true;
      }
      return false;
    }

  return (
    <>  
      <div className="row row-cols-1 row-cols-lg-1 form">
        <div className="col d-flex align-items-center">
          <span className="radio-label-text">Has any insurer in the past &nbsp; </span>
          {!checkInsurerInPast(1) ? (
            <input
              type="radio"
              id={"yes"}
              name="past_any_prev_insurer"
              className="me-3 ml-10"
              value={1}
              onChange={(e) => personalchk("past_any_prev_insurer", e)}
              disabled={isDisabled}
              checked={personalDetail.past_any_prev_insurer === 1}
            />
          ) : (
            <i className="bi bi-check-circle-fill text-primary me-2"></i>
          )}
          <span>Yes</span>
          {!checkInsurerInPast(0) ? (
            <input
              type="radio"
              id={"no"}
              name="past_any_prev_insurer"
              className="me-3 ml-10"
              value={0}
              onChange={(e) => personalchk("past_any_prev_insurer", e)}
              checked={personalDetail.past_any_prev_insurer === 0}
              disabled={isDisabled}
            />
          ) : (
            <i className="bi bi-check-circle-fill text-primary me-2"></i>
          )}
          <span>No</span>
        </div>
        {!isDisabled && <p className="p-error">{errors?.past_any_prev_insurer || formErrors?.past_any_prev_insurer}</p>}
      </div>

      {personalDetail.past_any_prev_insurer !== "" && personalDetail.past_any_prev_insurer == 1 && <div className="row row-cols-1 row-cols-lg-2 form">
        <div className="col d-flex align-items-center">
          <span>Declined Insurance </span>
          {!checkDeclinedInsuranceName(1) ? (
            <input
              type="radio"
              id={'yes'}
              name="past_declined"
              className="me-3 ml-10"
              value={1}
              onChange={(e) => personalchk("past_declined", e)}
              disabled={isDisabled}
              checked={personalDetail.past_declined === 1}
            />
          ) : (
            <i className="bi bi-check-circle-fill text-primary me-2"></i>
          )}
          <span>Yes</span>
          {!checkDeclinedInsuranceName(0) ? (
            <input
              type="radio"
              id={'no'}
              name="past_declined"
              className="me-3 ml-10"
              value={0}
              onChange={(e) => personalchk("past_declined", e)}
              checked={personalDetail.past_declined === 0}
              disabled={isDisabled}
            />
          ) : (
            <i className="bi bi-check-circle-fill text-primary me-2"></i>
          )}
          <span>No</span>
        </div>
        <div className="col d-flex align-items-center">
          <span>Imposed Special Conditions </span>
          {!checkImposedSpecialConditionsName(1) ? (
            <input
              type="radio"
              id={'yes'}
              name="past_imposed_special_condition"
              className="me-3 ml-10"
              value={1}
              onChange={(e) => personalchk("past_imposed_special_condition", e)}
              disabled={isDisabled}
              checked={personalDetail.past_imposed_special_condition === 1}
            />
          ) : (
            <i className="bi bi-check-circle-fill text-primary me-2"></i>
          )}
          <span>Yes</span>
          {!checkImposedSpecialConditionsName(0) ? (
            <input
              type="radio"
              id={'no'}
              name="past_imposed_special_condition"
              className="me-3 ml-10"
              value={0}
              onChange={(e) => personalchk("past_imposed_special_condition", e)}
              checked={personalDetail.past_imposed_special_condition === 0}
              disabled={isDisabled}
            />
          ) : (
            <i className="bi bi-check-circle-fill text-primary me-2"></i>
          )}
          <span>No</span>
        </div>
      </div>}

      {personalDetail.past_any_prev_insurer !== "" && personalDetail.past_any_prev_insurer == 1 && <div className="row row-cols-1 row-cols-lg-2 form">
        <div className="col d-flex align-items-center">
          <span style={{ paddingRight: '12px' }}>Refused Renewal </span>
          {!checkRefusedRenewalName(1) ? (
            <input
              type="radio"
              id={'yes'}
              name="past_refusal"
              className="me-3 ml-10"
              value={1}
              onChange={(e) => personalchk("past_refusal", e)}
              disabled={isDisabled}
              checked={personalDetail.past_refusal === 1}
            />
          ) : (
            <i className="bi bi-check-circle-fill text-primary me-2"></i>
          )}
          <span>Yes</span>
          {!checkRefusedRenewalName(0) ? (
            <input
              type="radio"
              id={'no'}
              name="past_refusal"
              className="me-3 ml-10"
              value={0}
              onChange={(e) => personalchk("past_refusal", e)}
              checked={personalDetail.past_refusal === 0}
              disabled={isDisabled}
            />
          ) : (
            <i className="bi bi-check-circle-fill text-primary me-2"></i>
          )}
          <span>No</span>
        </div>
        <div className="col d-flex align-items-center">
          <span style={{ paddingRight: '27px' }}>Increased The Premium </span>
          {!checkInceasedPremiumName(1) ? (
            <input
              type="radio"
              id={'yes'}
              name="past_increased_premium"
              className="me-3 ml-10"
              value={1}
              onChange={(e) => personalchk("past_increased_premium", e)}
              disabled={isDisabled}
              checked={personalDetail.past_increased_premium === 1}
            />
          ) : (
            <i className="bi bi-check-circle-fill text-primary me-2"></i>
          )}
          <span>Yes</span>
          {!checkInceasedPremiumName(0) ? (
            <input
              type="radio"
              id={'no'}
              name="past_increased_premium"
              className="me-3 ml-10"
              value={0}
              onChange={(e) => personalchk("past_increased_premium", e)}
              checked={personalDetail.past_increased_premium === 0}
              disabled={isDisabled}
            />
          ) : (
            <i className="bi bi-check-circle-fill text-primary me-2"></i>
          )}
          <span>No</span>
        </div>
      </div>}


      {isDisabled && 
      <>
        {data?.past_any_prev_insurer !== "" && data?.past_any_prev_insurer == 1 && <div className="row row-cols-1 row-cols-lg-2 form">
        <div className="col d-flex align-items-center">
          <span>Declined Insurance </span>
          {!checkDeclinedInsuranceName(1) ? (
            <input
              type="radio"
              id={'yes'}
              name="past_declined"
              className="me-3 ml-10"
              value={1}
              onChange={(e) => personalchk("past_declined", e)}
              disabled={isDisabled}
              checked={data?.past_declined === 1}
            />
          ) : (
            <i className="bi bi-check-circle-fill text-primary me-2"></i>
          )}
          <span>Yes &nbsp; </span>
          {!checkDeclinedInsuranceName(0) ? (
            <input
              type="radio"
              id={'no'}
              name="past_declined"
              className="me-3 ml-10"
              value={0}
              onChange={(e) => personalchk("past_declined", e)}
              checked={data?.past_declined === 0}
              disabled={isDisabled}
            />
          ) : (
            <i className="bi bi-check-circle-fill text-primary me-2"></i>
          )}
          <span>No</span>
        </div>
        <div className="col d-flex align-items-center">
          <span>Imposed Special Conditions &nbsp;</span>
          {!checkImposedSpecialConditionsName(1) ? (
            <input
              type="radio"
              id={'yes'}
              name="past_imposed_special_condition"
              className="me-3 ml-10"
              value={1}
              onChange={(e) => personalchk("past_imposed_special_condition", e)}
              disabled={isDisabled}
              checked={data?.past_imposed_special_condition === 1}
            />
          ) : (
            <i className="bi bi-check-circle-fill text-primary me-2"></i>
          )}
          <span>Yes</span>
          {!checkImposedSpecialConditionsName(0) ? (
            <input
              type="radio"
              id={'no'}
              name="past_imposed_special_condition"
              className="me-3 ml-10"
              value={0}
              onChange={(e) => personalchk("past_imposed_special_condition", e)}
              checked={data?.past_imposed_special_condition === 0}
              disabled={isDisabled}
            />
          ) : (
            <i className="bi bi-check-circle-fill text-primary me-2"></i>
          )}
          <span>No</span>
        </div>
      </div>}

      {data?.past_any_prev_insurer !== "" && data?.past_any_prev_insurer == 1 && <div className="row row-cols-1 row-cols-lg-2 form">
        <div className="col d-flex align-items-center">
          <span style={{ paddingRight: '12px' }}>Refused Renewal </span>
          {!checkRefusedRenewalName(1) ? (
            <input
              type="radio"
              id={'yes'}
              name="past_refusal"
              className="me-3 ml-10"
              value={1}
              onChange={(e) => personalchk("past_refusal", e)}
              disabled={isDisabled}
              checked={data?.past_refusal === 1}
            />
          ) : (
            <i className="bi bi-check-circle-fill text-primary me-2"></i>
          )}
          <span>Yes</span>
          {!checkRefusedRenewalName(0) ? (
            <input
              type="radio"
              id={'no'}
              name="past_refusal"
              className="me-3 ml-10"
              value={0}
              onChange={(e) => personalchk("past_refusal", e)}
              checked={data?.past_refusal === 0}
              disabled={isDisabled}
            />
          ) : (
            <i className="bi bi-check-circle-fill text-primary me-2"></i>
          )}
          <span>No</span>
        </div>
        <div className="col d-flex align-items-center">
          <span style={{ paddingRight: '27px' }}>Increased The Premium </span>
          {!checkInceasedPremiumName(1) ? (
            <input
              type="radio"
              id={'yes'}
              name="past_increased_premium"
              className="me-3 ml-10"
              value={1}
              onChange={(e) => personalchk("past_increased_premium", e)}
              disabled={isDisabled}
              checked={data?.past_increased_premium === 1}
            />
          ) : (
            <i className="bi bi-check-circle-fill text-primary me-2"></i>
          )}
          <span>Yes</span>
          {!checkInceasedPremiumName(0) ? (
            <input
              type="radio"
              id={'no'}
              name="past_increased_premium"
              className="me-3 ml-10"
              value={0}
              onChange={(e) => personalchk("past_increased_premium", e)}
              checked={data?.past_increased_premium === 0}
              disabled={isDisabled}
            />
          ) : (
            <i className="bi bi-check-circle-fill text-primary me-2"></i>
          )}
          <span>No</span>
        </div>
      </div>}
      </>
      }


    </>
  );
};

export default PastInsurerDetails;
