import React from "react";

const ReadOnlyRow2 = ({ contact2, handleEditClick2 , handleDeleteClick2 }) => {
  return (
    <tr>
      <td class="text-left">{contact2.namee2}</td>
     
      <td class="text-right" >{contact2.amount2}</td>
      
      <td class="text-right">
        <button class="btn btn-sm btn-transparent  " style={{marginLeft:"120px", marginTop:"-15px"}}
          type="button"
          onClick={(event) => handleEditClick2(event, contact2)}
        >
         <i class='bi bi-pencil'></i>
        </button>
        <button class="btn btn-sm btn-transparent "  style={{ marginLeft:"-20px", marginTop:"-15px"}}
        type="button" onClick={() => handleDeleteClick2(contact2.id)}>
       <i class='bi bi-trash'></i>
        </button>
      </td>
    </tr>
  );
};

export default ReadOnlyRow2;