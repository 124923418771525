import React from "react";
import "../../css/styles.css";
import Products from "../HomePage/Products";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../store/actions/auth";
import { Container, Navbar } from "react-bootstrap";

export default function NavBar() {
  const history = useHistory();
  const dispatch = useDispatch();

  const { isLoggedIn } = useSelector((state) => state.auth);

  const logOut = () => {
    setTimeout(() => {
      dispatch(logout());
      window.location.reload();
      history.push("/");
    }, 1000);
  };
  const navSpacing = {
    borderRight: "2px solid lightgray",
  };
  return (
    <div>
      {/* <!-- Navigation --> */}
      <Navbar
        collapseOnSelect
        expand="lg"
        bg="light"
        fixed="top"
        variant="light"
      >
        <Container>
          <a class="navbar-brand logo-image" href="https://www.policicue.com/">
            <img src="images/logo_new.png" alt="alternative" />
          </a>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <ul class="navbar-nav ml-auto">
              <div className="d-flex flex-row align-items-baseline">
                <li
                  class="nav-item dropdown"
                  style={{ position: "relative", fontFamily: "ErasMediumITC" }}
                >
                  <a
                    class="nav-link "
                    href="#"
                    id="dropdown01"
                    style={{ color: "rgb(41, 22, 111)" }}
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <span
                      style={{ color: "rgb(41, 22, 111)", fontSize: "14px" }}
                    >
                      Products
                    </span>
                    <img
                      src="images/chevron-bottom.svg"
                      width={20}
                      class="img-chevron-down"
                    />
                  </a>
                  {/* <div class="dropdown-menu" aria-labelledby="dropdown01"> */}
                  <div
                    className="dropdown-menu"
                    style={{
                      width: "220px",
                      top: "50px",
                      position: "absolute",
                      left: "0",
                      backgroundColor: "white",
                      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                    }}
                    aria-labelledby="dropdown01"
                  >
                    <Products />
                  </div>
                  {/* </div> */}
                </li>
                <li
                  className="nav-item dropdown mx-3"
                  style={{ fontFamily: "ErasMediumITC", marginTop: "5px" }}
                >
                  <a
                    className="font-weight-bold"
                    // href="https://policiecue-pos.mobileprogramming.net/"
                    // href="http://prod-pos-env.eba-wumespsv.ap-south-1.elasticbeanstalk.com/#/"
                    target="_blank"
                    href="https://pos.policicue.com/"
                    style={{ color: "rgb(41, 22, 111)" }}
                  >
                    <span
                      style={{ color: "rgb(41, 22, 111)", fontSize: "14px" }}
                    >
                      Become POS
                    </span>
                  </a>
                </li>
                <li
                  class="nav-item"
                  style={{ fontFamily: "ErasMediumITC", marginTop: "5px" }}
                >
                  {/* <a class="nav-link file-a-claim" href="#features"><img src="images/file-checked.svg" class="img-file-checked" />File a Claim</a> */}
                  <a
                    class="nav-link file-a-claim"
                    href="mailto:info@policicue.com"
                  >
                    <img
                      src="images/landing/mail.png"
                      class="img-mail"
                      style={{ maxWidth: 20 }}
                    />
                    info@policicue.com
                  </a>
                </li>
              </div>
              {/* <li class="nav-item" style={{ fontFamily: "ErasMediumITC" }}>
                                <a class="nav-link contact-feature" href="#details"><img src="images/landing/dial.png" class="img-dial" />18003654897</a>
                            </li> */}
            </ul>
            {isLoggedIn ? (
              <span class="nav-item app-store-icons">
                <button
                  class="btn btn-primary logout"
                  style={{ fontFamily: "ErasMediumITC" }}
                  onClick={logOut}
                >
                  logout
                </button>
              </span>
            ) : (
              <span class="nav-item app-store-icons">
                {/* No changes */}
                {/* <button class="btn btn-outline-info signup" style={{ fontFamily: "ErasMediumITC" }}
                                    onClick={() => history.push("/Registration")} >Sign up</button> */}

                {/* <button
                  class="btn btn-outline-secondary bg-white"
                  style={{ fontFamily: "ErasMediumITC", color: "black" }}
                  onClick={() => history.push("/Registration")}
                >
                  Sign up
                </button> */}
                {/* <button
                  class="btn btn-outline-secondary bg-white"
                  style={{ fontFamily: "ErasMediumITC", color: "black" }}
                  onClick={() => history.push("/Login")}
                >
                  Login
                </button> */}

                {/* <button
                  class="btn btn-primary signin"
                  style={{ fontFamily: "ErasMediumITC" }}
                  onClick={() => history.push("/Login")}
                >
                  Login
                </button> */}
              </span>
            )}
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}
