

import React, { useEffect, useRef } from 'react'
import '../../css/new_car_step1.css'
import Footer from '../ReusableComponents/Footer'
import NavBar from '../ReusableComponents/NavBar'
import gimg from '../HomePage/Group 154.png'
import { useHistory, useLocation } from 'react-router-dom';
import SubmitQuery from '../../services/insurance-query.service'
import './LeadershipView.css'
export default function LeadershipView() {
    const location = useLocation();

    const Profile1 = useRef(null);
    const Profile2 = useRef(null);
    const Profile3 = useRef(null);

    useEffect(() => {
        // window.scrollTo(0, 0)

        if (location?.state?.val == 1) {
            window.scrollTo({
                top: Profile1.current.offsetTop - 60,
                behavior: "smooth",
            });
        } else if (location?.state?.val == 2) {
            window.scrollTo({
                top: Profile2.current.offsetTop - 60,
                behavior: "smooth",
            });
        } else if (location?.state?.val == 3) {
            window.scrollTo({
                top: Profile3.current.offsetTop - 60,
                behavior: "smooth",
            });
        }
    }, []);

    return (
        <div>
            < NavBar />
            <header className="header-block-1">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-sm-12">
                            <div className="text-container-1">
                                <h1 className="h1-large header-1">Leadership</h1>
                                <h1 className="h1-too-large header-2">Shareholder and Directors</h1>
                            </div>
                        </div>
                    </div>
                </div>

            </header>
            <div className='row' style={{ marginBottom: '2rem' }} id="profile1" ref={Profile1}>
                <div className='profile-block'>
                    <div style={{ marginBottom: '2rem' }}>
                        <h4 className='h4-text-name h4-gap'>Sanjay Vats</h4>
                        <div className='individual-block'>
                            <img className="point-logo" src="images/landing/leader-desc-img-1.png" alt="logo" />
                            <div className='individual-text-container'>
                                <h6 className='exp-heading'>EXPERIENCE & QUALIFICATIONS</h6>
                                <p className='exp-desc'>Has 24+ years of experience in the Insurance Industry with intermediary as well as insurer. Is BQP trained for Life and Non-Life Insurance sectors. Has ACS, CFA, FIII qualifications to his credit.
                                </p>
                            </div>
                        </div>
                        <div className='individual-block'>
                            <img className="point-logo" src="images/landing/leader-desc-img-2.png" alt="logo" />
                            <div className='individual-text-container'>
                                <h6 className='exp-heading'>PROFESSIONAL EXPERTISE</h6>
                                <p className='exp-desc'>Has extensive experience in Claims, Underwriting, Legal Operations, Team Management, MIS and Trainings etc.
                                </p>
                            </div>
                        </div>
                        <div className='individual-block'>
                            <img className="point-logo" src="images/landing/leader-desc-img-3.png" alt="logo" />
                            <div className='individual-text-container'>
                                <h6 className='exp-heading'>PROFESSIONAL CAREER</h6>
                                <p className='exp-desc'>Has worked with National Insurance Company Limited, Cholamandalam MS GIC Ltd., Reliance GIC Ltd., Sargam Insurance Brokers Private Limited and Universal Sompo GIC Ltd
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='profile-container'>
                        <img className="profile-img" src="images/landing/p1.png" alt="Sanjay Vats" />
                    </div>
                </div>
            </div>
            <div className='row' style={{ marginBottom: '2rem' }} id="profile2" ref={Profile2}>
                <div className='profile-block'>
                    <div className='profile-container'>
                        <img className="profile-img" src="images/landing/p2.png" alt="Vikas Mathur" />
                    </div>
                    <div style={{ marginBottom: '2rem' }}>
                        <h4 className='h4-text-name h4-gap' >Vikas Mathur</h4>
                        <div className='individual-block'>
                            <img className="point-logo" src="images/landing/leader-desc-img-1.png" alt="logo" />
                            <div className='individual-text-container'>
                                <h6 className='exp-heading'>EXPERIENCE & QUALIFICATIONS</h6>
                                <p className='exp-desc'>Has 24+ years of experience in the Insurance Industry. Is BQP trained for Life, Non-Life Insurance and Reinsurance. Has M.Com & FIII qualifications to his credit

                                </p>
                            </div>
                        </div>
                        <div className='individual-block'>
                            <img className="point-logo" src="images/landing/leader-desc-img-2.png" alt="logo" />
                            <div className='individual-text-container'>
                                <h6 className='exp-heading'>PROFESSIONAL EXPERTISE</h6>
                                <p className='exp-desc'>Has extensive experience in Sales & Marketing, Business Development, Digital Partnerships, Health Insurance, Underwriting, Team Management, Channel Management
                                </p>
                            </div>
                        </div>
                        <div className='individual-block'>
                            <img className="point-logo" src="images/landing/leader-desc-img-3.png" alt="logo" />
                            <div className='individual-text-container'>
                                <h6 className='exp-heading'>PROFESSIONAL CAREER</h6>
                                <p className='exp-desc'>Has worked with New India Assurance Company Limited, IFFCO Tokio GIC Ltd. and Universal Sompo GIC Ltd.
                                </p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div className='row' style={{ marginBottom: '2rem' }} id="profile3" ref={Profile3}>
                <div className='profile-block'>
                    <div style={{ marginBottom: '2rem' }}>
                        <h4 className='h4-text-name h4-gap'>Vishwa Bhushan Rao</h4>
                        <div className='individual-block'>
                            <img className="point-logo" src="images/landing/leader-desc-img-1.png" alt="logo" />
                            <div className='individual-text-container'>
                                <h6 className='exp-heading'>EXPERIENCE & QUALIFICATIONS</h6>
                                <p className='exp-desc'>Has 18+ years of experience in the Insurance Industry with intermediary as well as insurer. Is BQP trained for Life and Non-Life Insurance sectors. Has PGDBA, BA (Geo Honours) qualifications to his credit.

                                </p>
                            </div>
                        </div>
                        <div className='individual-block'>
                            <img className="point-logo" src="images/landing/leader-desc-img-2.png" alt="logo" />
                            <div className='individual-text-container'>
                                <h6 className='exp-heading'>PROFESSIONAL EXPERTISE</h6>
                                <p className='exp-desc'>Has extensive experience in Sales & Marketing, Business Development, Key Account Management, Risk Management, Underwriting, Team Management, Channel Management, Direct Sales, Brand Promotion.
                                </p>
                            </div>
                        </div>
                        <div className='individual-block'>
                            <img className="point-logo" src="images/landing/leader-desc-img-3.png" alt="logo" />
                            <div className='individual-text-container'>
                                <h6 className='exp-heading'>PROFESSIONAL CAREER</h6>
                                <p className='exp-desc'>Has worked with ICICI Lombard GIC Ltd., IFFCO Tokio GIC Ltd., Universal Sompo GIC Ltd. and Leads Brokers Pvt. Ltd.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='profile-container'>
                        <img className="profile-img" src="images/landing/p3.png" alt="Vishwa Bhushan Rao" />
                    </div>
                </div>
            </div>
        </div>
    )



}
