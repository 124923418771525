import { React, useState } from 'react';
import NavBar from '../ReusableComponents/NavBar';
import './RegistrationPage.css';
import { useHistory } from 'react-router-dom';

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { useDispatch, useSelector } from 'react-redux';
import { signUp, OTP, resendOTP } from '../../store/actions/auth';
import { Redirect } from 'react-router-dom';


const phoneRegExp = /^[6-9]{1}[0-9]{9}$/;

export default function Registration() {
    const [showForm, setShowForm] = useState(false)

    const schema = yup.object().shape({
        email: yup.string().email().required(),
        phone: yup.string().matches(phoneRegExp, 'Phone number is not valid').min(10).max(10).required(),
        password: showForm && yup.string()
            .required('Please Enter your password')
            .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
            )
            .min(8).max(20).required(),

        confirmPassword: showForm && yup.string().oneOf([yup.ref("password"), null]),
        otp: showForm && yup.string().required(),
        terms: showForm && yup
            .boolean()
            .oneOf([true], 'Please Accept Terms and Conditions'),
    });

    const dispatch = useDispatch()
    const { isLoggedIn } = useSelector(state => state.auth);

    const { register, getValues, handleSubmit,
        formState: { errors } } = useForm({
            resolver: yupResolver(schema),
        });

    const [loading, setLoading] = useState(false)

    const [otpValues, setOtpValues] = useState({
        phone: '',
        email: '',
    })


    const otpHandler = e => {
        setOtpValues({ ...otpValues, [e.target.name]: e.target.value })
    }
    const [allValues, setAllValues] = useState({
        email: '',
        phone: '',
        otp: '',
        password: '',
        confirmPassword: ''

    })


    const allHandler = e => {
        setAllValues({ ...allValues, [e.target.name]: e.target.value })
    }
    const history = useHistory();

    const submitForm = ({ email, phone, otp, password, confirmPassword }) => {
        if (email && phone && otp && password && confirmPassword) {
            dispatch(signUp(email, phone, otp, password, confirmPassword))
                .then((response) => {
                    console.log("response", response);
                    if (localStorage.getItem("user")) {
                        history.push("/")
                        window.location.reload();
                    }
                    else {
                        setLoading(false)
                    }
                })
                .catch(() => {
                    setLoading(false);
                });
        }
    };


    if (isLoggedIn) {
        return <Redirect to="/" />;
    }

    const sendOTP = ({ phone, email }) => {
        dispatch(OTP(phone, email)).then(res => {
            if (res !== 0) {
                setShowForm(true)
            } else {
                setShowForm(false)
            }
        })
    }


    const resendOtp = () => {
        const Phone = getValues("phone");
        const Email = getValues("email");
        dispatch(resendOTP(Phone, Email))
    }

    return (
        <div className='registration-body'>
            <NavBar />
            <br /><br /><br /><br />
            <div className='registration-card'>
                <img class='cross-icon' src='images/x-mark.png' alt='x-mark' onClick={() => history.push('/')}></img>
                {/* < CancelOutlinedIcon className='cross-icon' /> */}
                <h3 class="heading">To Sign Up, please fill the form to create an account</h3>
                <hr style={{ width: '60px', height: '4px', backgroundColor: '#0093dd ', border: 'none', marginLeft: '45%' }} />
                <div style={{ display: 'flex' }}>
                    <div className='registration-form'>

                        <form>

                            <div class="car2-form-groupp ml-5">
                                <input
                                    type="text"
                                    id="icueRegistrationNo"
                                    name="icueRegistrationNo"
                                    class="car2-form-controll"
                                    required
                                    onChange={(e) => { otpHandler(e); allHandler(e) }}
                                    style={{ marginLeft: '20px', marginRight: '20px', width: '350px' }}
                                    {...register("phone")}
                                />
                                <label class="ms-3 car2-form-control-placeholderr" for="icueRegistrationNo">
                                    Contact Number
                                </label>
                                <p style={{ color: 'red' }}> {errors.phone?.message} </p>
                            </div>

                            <br />

                            <div class="car2-form-groupp ml-5">
                                <input
                                    type="text"
                                    id="icueRegistrationNo"
                                    name="icueRegistrationNo"
                                    class="car2-form-controll"
                                    required
                                    onChange={(e) => { allHandler(e) }}
                                    style={{ marginLeft: '20px', marginRight: '20px', width: '350px' }}
                                    {...register("email")}
                                />
                                <label class="ms-3 car2-form-control-placeholderr" for="icueRegistrationNo">
                                    Email
                                </label>
                                <p style={{ color: 'red' }}> {errors.email?.message} </p>
                            </div>

                            <div className='verify-otp'>
                                <a className='verifyotp-btn'
                                    onClick={handleSubmit(sendOTP)}
                                >Verify through OTP</a>
                                {!showForm ? (
                                    <div style={{ pointerEvents: 'none', opacity: '0.4' }} class='resend-otp'><a href='#'>Resend OTP</a></div>
                                ) : (<div class='resend-otp'><a href='#' onClick={resendOtp}>Resend OTP</a></div>)}

                            </div>

                            {showForm ? (
                                <>
                                    <div class="car2-form-groupp ml-5">
                                        <input
                                            type="text"
                                            id="icueRegistrationNo"
                                            name="icueRegistrationNo"
                                            class="car2-form-controll"
                                            required
                                            onChange={allHandler}
                                            style={{ marginLeft: '20px', marginRight: '20px', width: '350px' }}
                                            {...register("otp")}
                                        />
                                        <label class="ms-3 car2-form-control-placeholderr" for="icueRegistrationNo">
                                            Enter OTP
                                        </label>
                                        <p style={{ color: 'red' }}> {errors.otp?.message} </p>
                                    </div>

                                    <br />

                                    <div class="car2-form-groupp ml-5">
                                        <input
                                            type="password"
                                            id="icueRegistrationNo"
                                            name="icueRegistrationNo"
                                            class="car2-form-controll"
                                            required
                                            onChange={allHandler}
                                            style={{ marginLeft: '20px', marginRight: '20px', width: '350px' }}
                                            {...register("password")}
                                        />
                                        <label class="ms-3 car2-form-control-placeholderr" for="icueRegistrationNo">
                                            Password
                                        </label>
                                        <p style={{ color: 'red' }}> {errors.password?.message} </p>
                                    </div>
                                    <br />

                                    <div class="car2-form-groupp ml-5">
                                        <input
                                            type="password"
                                            id="icueRegistrationNo"
                                            name="icueRegistrationNo"
                                            class="car2-form-controll"
                                            required
                                            onChange={(e) => { allHandler(e) }}
                                            style={{ marginLeft: '20px', marginRight: '20px', width: '350px' }}
                                            {...register("confirmPassword")}
                                        />
                                        <label class="ms-3 car2-form-control-placeholderr" for="icueRegistrationNo">
                                            Confirm Password
                                        </label>
                                        <p style={{ color: 'red' }}> {errors.confirmPassword && "Passwords Should Match!"} </p>
                                    </div>
                                    <br /><br />
                                    <div className='privacypolicy-chechbox'>
                                        <input class='acceptTandC' style={{ width: '20px' }} type='checkbox' {...register("terms")} /> I accept the terms and use of privacy policy
                                        <p style={{ color: 'red' }}> {errors.terms?.message} </p>
                                    </div>


                                    {/* <div className='signupbtn' > */}
                                    {!loading ? (
                                        <button
                                            className='signup-btn'
                                            type="submit"
                                            id='submit'
                                            onClick={handleSubmit(submitForm)}
                                        > Sign Up </button>
                                    ) : (
                                        <span className="spinner-border spinner-border-md"></span>
                                    )}
                                    {/* </div> */}
                                    <div className='already-have-account'>
                                        Already have an account?
                                        <a onClick={() => history.push("/Login")}
                                            style={{ cursor: 'pointer', color: '#29166F', fontFamily: 'ERASMEDIUMITC', marginLeft: 10 }}>Login</a>
                                    </div>
                                </>
                            ) : null}
                            <div className='social-login-btn'>
                                {/* <FacebookLogin
                                    appId="589681292161647"
                                    autoLoad={true}
                                    size="small"
                                    textButton={<img style={{ width: '68px', height: '42px' }} src='images/facebook-logo.png'></img>}
                                    cssClass="fb-btn"
                                    fields="name,email,picture"
                                    onClick={componentClicked}
                                    callback={responseFacebook} /> */}
                                <img style={{ width: '60px', height: '40px' }} src='images/facebook-logo.png'></img>
                                <img style={{ width: '46px', height: '46px' }} src='images/google-logo.png'></img>
                            </div>
                        </form>
                    </div>
                    <div className='signupimg'>
                        <img src='images/signup 1.png' alt='signupimg'></img>
                    </div>
                </div>
            </div >
        </div >
    )

}