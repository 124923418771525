import React, { useState } from 'react'
import NavBar from '../../ReusableComponents/NavBar'
import Footer from '../../ReusableComponents/Footer'
import { makeStyles } from '@material-ui/core';
import { TextField, InputLabel, Select, FormControl, MenuItem } from '@material-ui/core';
import Stepper from '../../ReusableComponents/Stepper';
import { useHistory } from 'react-router';


import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

const phoneRegExp = /^[6-9]{1}[0-9]{9}$/

const syqSchema = yup.object({
    name: yup.string().required(),
    phone: yup.string().required().matches(phoneRegExp, "Invalid Phone Number").min(10).max(10),
    email: yup.string().email().required(),
}).required();

const useStyles = makeStyles({
    saveQuotes: {
        width: '280px',
        margin: '20px',
        // marginLeft:'6.25em',
        // outline:'red',
        [`& fieldset`]: {
            borderRadius: '10px',
            // borderColor:'red',
            // paddingBottom:'25px
        },

    }
})
export default function SFCsaveQuotes() {
    const classes = useStyles();
    const history = useHistory();

    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(syqSchema)
    });
    const onSubmit = data => {
        if (data) {
            history.push("/", data)
        }
    };

    return (
        <>
            <div style={{ background: '#F2F6FF' }}>
                <NavBar />
                <br /><br />
                <div class='step4-car-insurance'>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <div class='H-step1-topimg'>
                            <img src='images/Component 4.png' ></img>
                        </div>
                    </div>
                    <div class="row" style={{ marginTop: '50px' }}>
                        <div class='col-lg-1 col-md-0 ' >
                            <Stepper current={3} thirdStep={true} src="images/healthActive.png" />
                        </div>
                        <div class="H-step1-content col-lg-5 col-md-6">
                            <h3 style={{ marginBottom: '10px', marginLeft: '15px' }}>Save Your Quotes - </h3>
                            <h6 style={{ marginLeft: '15px' }}>Saved quotes will be sent to you via email or sms</h6>
                            <br /><br />
                            <div class='row'>
                                <div class="car2-form-groupp" >
                                    <input
                                        type="text"
                                        id="TLname"
                                        name="TLname"
                                        class="car2-form-controll"
                                        required
                                        style={{ marginLeft: '20px', marginRight: '20px' }}
                                        {...register("name")}
                                    // value={brand}
                                    />
                                    <label class="ms-3 car2-form-control-placeholderr" for="TLname">
                                        Name
                                    </label>
                                    <p class="text-danger" style={{marginLeft: '1.2rem'}}>{errors.name?.message}</p>
                                </div>
                            </div>
                            <div class='row'>
                                <div class="car2-form-groupp" >
                                    <input
                                        type="number"
                                        id="TLmobile"
                                        name="TLmobile"
                                        class="car2-form-controll"
                                        required
                                        style={{ marginLeft: '20px', marginRight: '20px' }}
                                        {...register("phone")}
                                    // value={brand}
                                    />
                                    <label class="ms-3 car2-form-control-placeholderr" for="TLmobile">
                                        Mobile No
                                    </label>
                                    <p class="text-danger" style={{marginLeft: '1.2rem'}}>{errors.phone?.message}</p>
                                </div>
                            </div>
                            <div class='row'>
                                <div class="car2-form-groupp" >
                                    <input
                                        type="email"
                                        id="TLemail"
                                        name="TLemail"
                                        class="car2-form-controll"
                                        required
                                        style={{ marginLeft: '20px', marginRight: '20px' }}
                                        {...register("email")}
                                    // value={brand}
                                    />
                                    <label class="ms-3 car2-form-control-placeholderr" for="TLemail">
                                        Email
                                    </label>
                                    <p class="text-danger" style={{marginLeft: '1.2rem'}}>{errors.email?.message}</p>
                                </div>
                            </div>


                            <div style={{ textAlign: 'center', marginLeft: '12rem' }}><a style={{ color: '#0093DD' }} href='/' >Skip</a></div>
                            <div class='H-step1-nextbtn ' ><button onClick={handleSubmit(onSubmit)}>Next</button></div>

                        </div>
                        <div class=" H-step1-rightimg col-lg-6  col-md-6">
                            <img src='images/im2 4.png' alt="alternative"></img>
                        </div>

                    </div>
                </div>
                <Footer />
            </div>
        </>


    )
}