import axios from "axios";
import Constant from "../utils/constant"


const Register = (email, phone, otp, password, confirmpassword) => {
    return axios.post(Constant.BASE_URL + "user/verifyOtp", {
        email,
        phone,
        otp,
        password,
        confirmpassword
    })
        .then((response) => {
            if (response.data.data) {
                localStorage.setItem("user", JSON.stringify(response.data.data));
            }
            else {
                alert(response.data.message);
            }

            return response.data.data;
        }).catch((e) => {
            console.log(e);
        })
};



const otp = (phone, email) => {
    return axios
        .post(Constant.BASE_URL + "user/websiteSignup", {
            phone,
            email
        })
        .then((response) => {
            if (response.data.data) {
                alert(response.data.message)
                return response.data.status
            }

            else {
                alert(response.data.message)
                return response.data.status
            }
            return response.data.data
        }).catch(e => {
            console.log(e);
        })
}


const resendOtp = (phone, email) => {
    return axios
        .post(Constant.BASE_URL + "user/resendOtp", {
            phone,
            email
        })
        .then((response) => {
            if (response.data.data) {
                alert(response.data.message)
            }

            else {
                alert(response.data.message)
            }
            return response.data.data
        }).catch(e => {
            console.log(e);
        })
}


const login = (username, password) => {
    return axios
        .post(Constant.BASE_URL + "user/websiteSignIn", {
            username,
            password
        })
        .then((response) => {
            if (response.data.data) {
                localStorage.setItem("user", JSON.stringify(response.data.data));
            }
            else {
                alert(response.data.message)
            }
            return response.data.data;
        })
};


const logout = () => {
    localStorage.removeItem("user");
};

export default {
    Register,
    login,
    otp,
    resendOtp,
    logout,
};