import React, { useState, Fragment } from "react";
import '../Holidays/Holidays.css';
import { nanoid } from "nanoid";
import data from "../Holidays/mock-data.json";
import ReadOnlyRow from "../Holidays/ReadOnlyRow";
import EditableRow from "../Holidays/EditableRow";


const UserManagment = () => {
    const [contacts, setContacts] = useState(data);
    const [addFormData, setAddFormData] = useState({
        namee: "",
        date: "",
        day: ""

    });
    const [editFormData, setEditFormData] = useState({
        namee: "",
        date: "",
        day: ""

    });
    const [editContactId, setEditContactId] = useState(null);

    const handleAddFormChange = (event) => {
        event.preventDefault();

        const fieldName = event.target.getAttribute("name");
        const fieldValue = event.target.value;

        const newFormData = { ...addFormData };
        newFormData[fieldName] = fieldValue;

        setAddFormData(newFormData);
    };
    const handleEditFormChange = (event) => {
        event.preventDefault();

        const fieldName = event.target.getAttribute("name");
        const fieldValue = event.target.value;

        const newFormData = { ...editFormData };
        newFormData[fieldName] = fieldValue;

        setEditFormData(newFormData);
    };

    const handleAddFormSubmit = (event) => {
        event.preventDefault();
        const newContact = {
            id: nanoid(),
            namee: addFormData.namee,
            date: addFormData.date,
            day: addFormData.day,

        };
        const newContacts = [...contacts, newContact];

        setContacts(newContacts);
    };
    const handleEditFormSubmit = (event) => {
        event.preventDefault();

        const editedContact = {
            id: editContactId,
            namee: editFormData.namee,
            date: editFormData.date,
            day: editFormData.day,

        };

        const newContacts = [...contacts];

        const index = contacts.findIndex((contact) => contact.id === editContactId);

        newContacts[index] = editedContact;

        setContacts(newContacts);
        setEditContactId(null);
    };
    const handleEditClick = (event, contact) => {
        event.preventDefault();
        setEditContactId(contact.id);

        const formValues = {
            namee: contact.namee,
            date: contact.date,
            day: contact.day,

        };

        setEditFormData(formValues);
    };

    const handleCancelClick = () => {
        setEditContactId(null);
    };

    const handleDeleteClick = (contactId) => {
        const newContacts = [...contacts];

        const index = contacts.findIndex((contact) => contact.id === contactId);

        newContacts.splice(index, 1);

        setContacts(newContacts);
    };



    return (<div className="Holidays" id="Holidays">
        {/* <!-- Top Bar --> */}
        <div class="container-fluid1 py-2 icueTopBarContainer bg-white">
            <div class="row">
                <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                    <div class="topBarLfContainer d-flex">
                        <div class="icueLogoContainer">
                            <img src="images/logo.png" alt="ICue Logo" class="icueLogo" />
                        </div>
                        <div class="icueSearchBox w-75 mx-3">
                            <div class="input-group ">
                                <span class="input-group-text" id="basic-addon1">
                                    <i class="bi bi-search"></i>
                                </span>
                                <input type="search" class="form-control icueSearchBoxControl" placeholder="Enter keywords..." aria-label="Username" aria-describedby="basic-addon1" />
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                    <div class="d-flex flex-row-reverse">
                        <div class="icueProfile px-2">
                            <img src="images/avatar.png" alt="icueProfilePic" class="icueProfilePic border border-dark rounded-circle" />
                        </div>
                        <div class="position-relative px-2">
                            <a href="#" id="icueNotifyBoxHandler">
                                <i class="bi bi-bell">
                                    <span class="position-absolute icueNofIndic"></span>
                                </i>
                            </a>
                            <div class="icueNotifyBox" id="icueNotifyBox">
                                Notifications
                            </div>
                        </div>

                        <div class="icueUserName fs-6 px-2">
                            Viral Patel
                        </div>

                        <div class="icueMail px-2">
                            <i class="bi bi-headset"></i> &nbsp;
                            adminhelpdesk@gmail.com
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* <!-- /Top Bar -->

    <!-- Main Body --> */}
        <div class="container-fluid my-2">
            <div class="row">
                <div class="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12 bg-white">
                    <div class="icueRespSideBar d-xxl-none d-xl-none d-lg-none d-md-block d-sm-block d-block" id="icueRespSideBar">
                        <button type="button" class="btn btn-primary" id="icueRespHumburger">
                            <i class="bi bi-grid-3x3-gap"></i>
                        </button>
                        <div class="icueSideBar" id="icueSideBar">
                            <nav class="navbar icueSideNavContainer">
                                <ul class="icueNavBarList">
                                    <li class="icueNavBarItem">
                                        <a href="/Hrdashboard" class="icueNavBarLink" id="">Dashboard</a>
                                    </li>
                                    <li class="icueNavBarItem border-bottom-0 active">
                                        <a href="#" class="icueNavBarLink" id="">User Management</a>
                                    </li>

                                    <li>
                                        <ul class="icueSubNavBar">
                                            <li class="icueSubNavItem">
                                                {/* <!-- <a href="#" data-bs-toggle="modal" data-bs-target="#addid" data-bs-whatever="@getbootstrap" class="icueSubNavLink  text-capitalize">Add Member</a> --> */}
                                                <a href="#" class="icueSubNavLink  text-capitalize">Add Members</a>
                                            </li>
                                            <li class="icueSubNavItem">
                                                <a href="/ListMembers" class="icueSubNavLink  text-capitalize">List of Members</a>
                                            </li>
                                        </ul>
                                    </li>
                                    <hr></hr>

                                    <li class="icueNavBarItem border-bottom-0">
                                        <a href="#" class="icueNavBarLink" id="">Leave Management</a>
                                    </li>

                                    <li>
                                        <ul class="icueSubNavBar">
                                            <li class="icueSubNavItem">
                                                {/* <!-- <a href="#" data-bs-toggle="modal" data-bs-target="#addid" data-bs-whatever="@getbootstrap" class="icueSubNavLink  text-capitalize">Add Member</a> --> */}
                                                <a href="/Leaves" class="icueSubNavLink  text-capitalize">Leaves</a>
                                            </li>
                                            <li class="icueSubNavItem">
                                                <a href="/LeaveType" class="icueSubNavLink  text-capitalize">Leave Type</a>
                                            </li>
                                        </ul>
                                    </li>
                                    <hr></hr>
                                    <li class="icueNavBarItem border-bottom-1 active">
                                        <a href="/Holidays" class="icueNavBarLink" id="">Holidays</a>
                                    </li>
                                    <li class="icueNavBarItem border-bottom-1">
                                        <a href="/Attendance" class="icueNavBarLink" id="">Attendance</a>
                                    </li>



                                    <li class="icueNavBarItem">
                                        <a href="/Department" class="icueNavBarLink" id="">Department</a>
                                    </li>

                                    <li class="icueNavBarItem">
                                        <a href="/AddRoles" class="icueNavBarLink" id="">Roles</a>
                                    </li>

                                    <li class="icueNavBarItem border-bottom-0">
                                        <a href="#" class="icueNavBarLink" id="">Payroll</a>
                                    </li>
                                    <li>
                                        <ul class="icueSubNavBar">
                                            <li class="icueSubNavItem">
                                                <a href="/EmpSalary" class="icueSubNavLink  text-capitalize">Employee Salary</a>
                                            </li>
                                            <li class="icueSubNavItem">
                                                <a href="/Payitems" class="icueSubNavLink  text-capitalize">Payroll Items</a>
                                            </li>
                                        </ul>
                                        <hr></hr>
                                    </li>

                                    <li class="icueNavBarItem border-bottom-0" style={{ marginTop: "-18px" }}>
                                        <a href="#" class="icueNavBarLink" id="">Settings</a>
                                    </li>
                                    <li>
                                        <ul class="icueSubNavBar">
                                            <li class="icueSubNavItem">
                                                <a href="#" class="icueSubNavLink  text-capitalize">Change Password</a>
                                            </li>
                                            <li class="icueSubNavItem">
                                                <a href="#" class="icueSubNavLink  text-capitalize">Profile</a>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>

                    <div class="icueNavSideBar d-xxl-block d-xl-block d-lg-block d-md-none d-sm-none d-none" id="icueNavSideBar">
                        <nav>
                            <ul class="icueNavBarList">
                                <li class="icueNavBarItem ">
                                    <a href="/Hrdashboard" class="icueNavBarLink" id="">Dashboard</a>
                                </li>

                                <li class="icueNavBarItem border-bottom-0 active">
                                    <a href="#" class="icueNavBarLink" id="">User Management</a>
                                </li>

                                <li>
                                    <ul class="icueSubNavBar">
                                        <li class="icueSubNavItem" style={{ marginTop: "-8px" }}>
                                            {/* <!-- <a href="#" data-bs-toggle="modal" data-bs-target="#addid" data-bs-whatever="@getbootstrap" class="icueSubNavLink  text-capitalize">Add Member</a> --> */}
                                            <a href="#" class="icueSubNavLink  text-capitalize">Add Member</a>
                                        </li>
                                        <li class="icueSubNavItem">
                                            <a href="/ListMembers" class="icueSubNavLink  text-capitalize">List of Members</a>
                                        </li>
                                    </ul>
                                </li>
                                <hr></hr>

                                <li class="icueNavBarItem border-bottom-0" style={{ marginTop: "-18px" }}>
                                    <a href="#" class="icueNavBarLink" id="" >Leave Management</a>
                                </li>

                                <li>
                                    <ul class="icueSubNavBar ">
                                        <li class="icueSubNavItem" style={{ marginTop: "-8px" }}>
                                            {/* <!-- <a href="#" data-bs-toggle="modal" data-bs-target="#addid" data-bs-whatever="@getbootstrap" class="icueSubNavLink  text-capitalize">Add Member</a> --> */}
                                            <a href="/Leaves" class="icueSubNavLink  text-capitalize">Leaves</a>
                                        </li>
                                        <li class="icueSubNavItem">
                                            <a href="/LeaveType" class="icueSubNavLink  text-capitalize">Leave Type</a>
                                        </li>
                                    </ul>
                                    <hr></hr>
                                </li>

                                <li class="icueNavBarItem " style={{ marginTop: "-18px" }}>
                                    <a href="/Holidays" class="icueNavBarLink" id="">Holidays</a>
                                </li>
                                <li class="icueNavBarItem ">
                                    <a href="/Attendance" class="icueNavBarLink" id="">Attendance</a>
                                </li>




                                <li class="icueNavBarItem" >
                                    <a href="/Department" class="icueNavBarLink" id="">Department</a>
                                </li>

                                <li class="icueNavBarItem">
                                    <a href="/AddRoles" class="icueNavBarLink" id="">Roles</a>
                                </li>


                                <li class="icueNavBarItem border-bottom-0">
                                    <a href="#" class="icueNavBarLink" id="">Payroll</a>
                                </li>
                                <li>
                                    <ul class="icueSubNavBar">
                                        <li class="icueSubNavItem">
                                            <a href="/EmpSalary" class="icueSubNavLink  text-capitalize">Employee Salary</a>
                                        </li>
                                        <li class="icueSubNavItem">
                                            <a href="/Payitems" class="icueSubNavLink  text-capitalize">Payroll Items</a>
                                        </li>
                                    </ul>
                                    <hr></hr>
                                </li>

                                <li class="icueNavBarItem border-bottom-0" style={{ marginTop: "-18px" }}>
                                    <a href="#" class="icueNavBarLink" id="">Settings</a>
                                </li>
                                <li>
                                    <ul class="icueSubNavBar">
                                        <li class="icueSubNavItem">
                                            <a href="#" class="icueSubNavLink  text-capitalize">Change Password</a>
                                        </li>
                                        <li class="icueSubNavItem">
                                            <a href="#" class="icueSubNavLink  text-capitalize">Profile</a>
                                        </li>
                                    </ul>
                                    <hr></hr>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>

            </div>
        </div>

    </div>)
}
export default UserManagment;