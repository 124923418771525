import React, { useState, Fragment } from "react";
import './Followup.css';
import { nanoid } from "nanoid";
import data from "./mock-data.json";
import ReadOnlyRow from "./ReadOnlyRow";
import EditableRow from "./EditableRow";
const Followup=()=>{
    const [contacts, setContacts] = useState(data);
  const [addFormData, setAddFormData] = useState({
    Date: "",
    description: ""
    
  });
  const [editFormData, setEditFormData] = useState({
    Date: "",
    description: ""
  });
  const [editContactId, setEditContactId] = useState(null);

  const handleAddFormChange = (event) => {
    event.preventDefault();

    const fieldName = event.target.getAttribute("name");
    const fieldValue = event.target.value;

    const newFormData = { ...addFormData };
    newFormData[fieldName] = fieldValue;

    setAddFormData(newFormData);
  };
  const handleEditFormChange = (event) => {
    event.preventDefault();

    const fieldName = event.target.getAttribute("name");
    const fieldValue = event.target.value;

    const newFormData = { ...editFormData };
    newFormData[fieldName] = fieldValue;

    setEditFormData(newFormData);
  };
  const handleAddFormSubmit = (event) => {
    event.preventDefault();
    const newContact = {
        id: nanoid(),
        Date: addFormData.Date,
        description: addFormData.description
       
      };
  
      const newContacts = [...contacts, newContact];
    
      setContacts(newContacts);
    };
    const handleEditFormSubmit = (event) => {
        event.preventDefault();
    
        const editedContact = {
          id: editContactId,
          Date: editFormData.Date,
          description: editFormData.description
         
        };
    
        const newContacts = [...contacts];
    
        const index = contacts.findIndex((contact) => contact.id === editContactId);
    
        newContacts[index] = editedContact;
    
        setContacts(newContacts);
        setEditContactId(null);
      };
      const handleEditClick = (event, contact) => {
        event.preventDefault();
        setEditContactId(contact.id);
    
        const formValues = {
          Date: contact.Date,
          description: contact.description
         
        };
    
        setEditFormData(formValues);
      };
    
      const handleCancelClick = () => {
        setEditContactId(null);
      };
      const handleDeleteClick = (contactId) => {
        const newContacts = [...contacts];
    
        const index = contacts.findIndex((contact) => contact.id === contactId);
    
        newContacts.splice(index, 1);
    
        setContacts(newContacts);
      };
    return(<div className="Followup ">
              <div class="container-fluid1 py-2 icueTopBarContainer bg-white">
                 <div class="row">
                    <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                       <div class="topBarLfContainer d-flex">
                           <div class="icueLogoContainer">
                                <img src="images/logo.png" alt="ICue Logo" class="icueLogo" />
                            </div>
                                    <div class="icueSearchBox w-75 mx-3">
                                         <div class="input-group ">
                                            <span class="input-group-text" id="basic-addon1">
                                              <i class="bi bi-search"></i>
                                             </span>
                                      <input type="search" id="text-search" class="form-control icueSearchBoxControl" placeholder="Enter keywords..." aria-label="Username" aria-describedby="basic-addon1"/>
                                         </div>
                                     </div> 
                        </div>
                   </div>

            <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                <div class="d-flex flex-row-reverse">
                    <div class="icueProfile px-2">
                        <img src="images/avatar.png" alt="icueProfilePic" class="icueProfilePic border border-dark rounded-circle" />
                    </div>
                    <div class="position-relative px-2">
                        <a href="#" id="icueNotifyBoxHandler">
                            <i class="bi bi-bell">
                                <span class="position-absolute icueNofIndic"></span>
                            </i>
                        </a>
                        <div class="icueNotifyBox" id="icueNotifyBox">
                            Notifications
                        </div>
                    </div>

                    <div class="icueUserName fs-6 px-2">
                        Viral Patel
                    </div>

                    <div class=" icueMail px-2">
                        <i class="bi bi-headset"></i> &nbsp;
                        adminhelpdesk@gmail.com
                    </div>
                </div>
            </div>
        </div>
    </div>
   
   
    <div class="container-fluid my-2">
        <div class="row">
            <div class="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12 bg-white">
                <div class="icueRespSideBar d-xxl-none d-xl-none d-lg-none d-md-block d-sm-block d-block" id="icueRespSideBar">
                    <button type="button" class="btn btn-primary" id="icueRespHumburger">
                        <i class="bi bi-grid-3x3-gap" id="icueOpenIcon"></i>
                        <i class="bi bi-x-lg" id="icueCloseIcon"></i>
                    </button>
                    <div class="icueSideBar" id="icueSideBar">
                        <nav class="navbar icueSideNavContainer">
                            <ul class="icueNavBarList">
                                <li class="icueNavBarItem active">
                                    <a href="/AgentDashboard" class="icueNavBarLink" id="">Dashboard</a>
                                </li>

                                <li class="icueNavBarItem border-bottom-0">
                                    <a href="#" class="icueNavBarLink" id="">LMS</a>
                                </li>

                                <li>
                                    <ul class="icueSubNavBar">
                                        <li class="icueSubNavItem">
                                            <a href="/AgentLMS" class="icueSubNavLink  text-capitalize">All Leads</a>
                                        </li>
                                    </ul>
                                </li>
                                <hr></hr>
                                <li class="icueNavBarItem border-bottom-0">
                                    <a href="#" class="icueNavBarLink" id="">Settings</a>
                                </li>
                                <li>
                                    <ul class="icueSubNavBar">
                                        <li class="icueSubNavItem">
                                            <a href="#" class="icueSubNavLink  text-capitalize">Change Password</a>
                                        </li>
                                        <li class="icueSubNavItem">
                                            <a href="#" class="icueSubNavLink  text-capitalize">Profile</a>
                                        </li>
                                    </ul>
                                </li>
                                <hr></hr>
                            </ul>
                        </nav>
                    </div>
                </div>

                <div class="icueNavSideBar d-xxl-block d-xl-block d-lg-block d-md-none d-sm-none d-none" id="icueNavSideBar">
                    <nav>
                        <ul class="icueNavBarList">
                            <li class="icueNavBarItem active">
                                <a href="/AgentDashboard" class="icueNavBarLink" id="">Dashboard</a>
                            </li>

                            <li class="icueNavBarItem border-bottom-0">
                                <a href="#" class="icueNavBarLink" id="">LMS</a>
                            </li>

                            <li>
                                <ul class="icueSubNavBar">
                                    <li class="icueSubNavItem" style={{marginTop:"-0.75rem"}}>
                                        <a href="/AgentLMS" class="icueSubNavLink  text-capitalize">All Leads</a>
                                    </li>
                                </ul>
                            </li>
                            <hr></hr>
                            <li class="icueNavBarItem border-bottom-0" style={{marginTop:"-0.625rem"}}>
                                <a href="#" class="icueNavBarLink" id="">Settings</a>
                            </li>
                            <li>
                                <ul class="icueSubNavBar" style={{marginTop:"-0.75rem"}}>
                                    <li class="icueSubNavItem">
                                        <a href="#" class="icueSubNavLink  text-capitalize">Change Password</a>
                                    </li>
                                    <li class="icueSubNavItem">
                                        <a href="#" class="icueSubNavLink  text-capitalize">Profile</a>
                                    </li>
                                </ul>
                            </li>
                            <hr></hr>
                        </ul>
                    </nav>
                </div>
            
            </div>
            <div class="col-xxl-10 col-xl-10 col-lg-10 col-md-12 col-sm-12 col-12 ">
                <main class="mainBodyContainer">
                    <div class="row my-2">
                        <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 py-2 px-3">
                            <div class="d-flex justify-content-between">
                                <h4 class="text-capitalize" style={{fontFamily:"ErasDemiITC", marginLeft:"12px"}}>Follow up</h4>
                                <div class="bg-transparent p-2  rounded-3">
                                <nav style={{bsbreadcrumbdivider: 'url(&#34;"data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="8" height="8"%3E%3Cpath d="M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z" fill="currentColor"/%3E%3C/svg%3E&#34")'}} aria-label="breadcrumb">
                                    <ol class="breadcrumb mb-0 justify-content-end">
                                        <li class="breadcrumb-item bg-transparent"><a href="dashboard.html" class="text-decoration-none text-info">Dashboard</a></li>
                                        <li class="breadcrumb-item text-muted text-capitalize bg-transparent" aria-current="page">follow up</li>
                                    </ol>
                                </nav>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row my-2">
                        <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 py-2 px-3">
                            <div class="bg-white p-2  rounded-3 shadow-sm">
                                <div class="row px-5 py-3">
                                    <div class="col-12">
                                        <div class="d-flex justify-content-between">
                                            <div class="bg-transparent">
                                                
                                            </div>
                                            <div class="bg-white">
                                                <button class="btn btn-sm  text-white" id="icueCreateFollowUpBtn" data-bs-toggle="modal" data-bs-target="#icueCreateFollowUpModal" style={{backgroundColor:"#3A3285"}}><i class="bi bi-plus-lg"></i> Create Follow Up</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div style={{lineHeight:"-50px"}}><hr /></div>
                                <form id="icueFollowInfoForm" onSubmit={handleEditFormSubmit}>
                                    <div class="row  px-5 pt-3 pb-5">
                                        <table class="table table-striped table-hover" id="icueFollowUpTable">
                                            <thead>
                                                <tr class="text-uppercase border-bottom" style={{border:"0"}}>
                                                    <th class="py-3 border-0">follow up date</th>
                                                    <th class="text-center py-3 border-0" >description</th>
                                                    <th class="text-center py-3 border-0" >follow up</th>
                                                    <th class="text-center py-3 border-0">action</th>
                                                </tr>
                                            </thead>
                                            <tbody class="text-capitalize"style={{fontFamily:"Calibri", fontWeight:"normal"}}>
                                                {/* <tr style={{border:"0"}}>
                                                    <td>15-feb-2022</td>
                                                    <td>call for policy renewal</td>
                                                    <td class="text-center">
                                                        <button type="button" class="btn btn-sm btn-toggle" data-toggle="button" aria-pressed="false" autocomplete="off" id="toggBtn_1"><div class="handle"></div></button>
                                                    </td>
                                                    <td class="text-center">
                                                        <button type="button" class="btn btn-sm btn-transparent" data-bs-toggle="modal" data-bs-target="#icueEditFollowUpModal"><i class="bi bi-pencil"></i></button>
                                                        <button type="button" class="btn btn-sm btn-transparent icueDelBtnRow"><i class="bi bi-trash"></i></button>
                                                    </td>
                                                </tr>
                                                <tr style={{border:"0"}}>
                                                    <td>26-aug-2021</td>
                                                    <td>call for policy renewal</td>
                                                    <td class="text-center">
                                                        <button type="button" class="btn btn-sm btn-toggle" data-toggle="button" aria-pressed="false" autocomplete="off" id="toggBtn_2"><div class="handle"></div></button>
                                                    </td>
                                                    <td class="text-center">
                                                        <button type="button" class="btn btn-sm btn-transparent" data-bs-toggle="modal" data-bs-target="#icueEditFollowUpModal"><i class="bi bi-pencil"></i></button>
                                                        <button type="button" class="btn btn-sm btn-transparent icueDelBtnRow"><i class="bi bi-trash"></i></button>
                                                    </td>
                                                </tr> */}
                                                {contacts.map((contact) => (
              <Fragment>
                {editContactId === contact.id ? (
                  <EditableRow
                    editFormData={editFormData}
                    handleEditFormChange={handleEditFormChange}
                    handleCancelClick={handleCancelClick}
                  />
                ) : (
                  <ReadOnlyRow
                    contact={contact}
                    handleEditClick={handleEditClick}
                    handleDeleteClick={handleDeleteClick}
                  />
                )}
              </Fragment>
            ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </form>
                            </div>
                            </div>
                        </div>            
                     

<div class="modal fade " id="icueCreateFollowUpModal" tabindex="-1" aria-labelledby="icueCreateFollowUpModalLabel" aria-hidden="true">
    {/* <!-- <div class="modal-dialog modal-dialog-centered"> --> */}
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header py-1 shadow-sm">
                <h5 class="modal-title text-capitalize" id="editRfqDetModalLabel">Create Follow Up</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body" style={{backgroundColor: "#edf5fb"}}>
                <form class="">
                    <div class="row">
                        <div class="col-lg-9 col-md-9 col-sm-12 col-12 mx-auto">
                            <div class="form-group pb-2">
                                <input class="form-control" name="Date"  onChange={handleAddFormChange} type="Date" style={{width:"100%"}} />
                            </div>
                        </div>
                        {/* <div class="col-lg-9 col-md-9 col-sm-12 col-12 mx-auto">
                            <div class="form-group pb-2">
                                <input class="form-control" type="text" name="icueFollowUpTitle" placeholder="Follow up title"   />
                            </div>
                        </div> */}
                        <div class="col-lg-9 col-md-9 col-sm-12 col-12 mx-auto">
                            <div class="form-group pb-2">
                                <textarea class="form-control" id="icueRfqDetailsDesc" name="description"  onChange={handleAddFormChange} rows="3" placeholder="Description of follow up" style={{width:"100%"}}></textarea>
                            </div>
                        </div>
                        <div class="col-lg-9 col-md-9 col-sm-12 col-12 py-3 mx-auto">
                            <div class="d-grid gap-2">
                                <button type="submit" data-bs-dismiss="modal" onClick={handleAddFormSubmit} class="btn text-white " style={{backgroundColor:"#3A3285"}}>
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

                    
                </main>
            </div>
        </div>
   
        </div>

         
    
    
    </div>)
}
export default Followup;