import React, { useRef, useState, useEffect } from 'react'
import '../../css/new_car_step1.css'
import Footer from '../ReusableComponents/Footer'
// import Footer from '../ReusableComponents/Footer'
import NavBar from '../ReusableComponents/NavBar'
import gimg from '../HomePage/Group 154.png'
import { useHistory } from 'react-router-dom';
import SubmitQuery from '../../services/insurance-query.service'
import './HealthInsurance.css';
import Accordion from 'react-bootstrap/Accordion';
import { Modal } from 'react-bootstrap'
import TermsUsage from '../OtherInsurance/TermsUsage'
import { Model } from '../ReusableComponents/Modal'
import Terms from '../ReusableComponents/Terms'

export default function HealthInsurance() {
    const [checked, setChecked] = useState(true);
    const history = useHistory();
    const [show, setShow] = useState(false);
    const ShowModal = () => setShow(true);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
        return () => {
        }
    }, [])
    let health_insurance = useRef();
    let health_insurance_needed = useRef();
    let addOn = useRef();
    let topic5 = useRef();
    let faqs = useRef();
    const sectionContent = {
        textDecoration: "none",
        fontSize: 14,
    }

    const [showModal, setModalShow] = useState(false);
    const handleCloseModal = () => setModalShow(false);
    const handleShowModal = () => setModalShow(true);

    const initialValues = { firstName: "", email: "", lastName: "", mobile: "", isChecked: true };
    const [formValues, setFormValues] = useState(initialValues);
    const [formErrors, setFormErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setFormErrors(validate(formValues));
        setIsSubmit(true);
        if (true) {
            console.log(formValues);
            SubmitQuery(formValues.firstName, formValues.lastName, formValues.mobile, formValues.email, "health/purchaseHealthInsurance")
                .then(response => response.json())
                .then(async response => {
                    console.log(JSON.stringify(response));
                    if (response.status == 1) {
                        await handleShow();
                        setFormValues(initialValues);

                    } else if (response.status == 0) {
                        alert(response.message);
                    }
                });
        }
    };

    useEffect(() => {
        console.log(formErrors);
        if (Object.keys(formErrors).length === 0 && isSubmit) {
            console.log(formValues);
        }
    }, [formErrors]);

    const validate = (values) => {
        const errors = {};
        const email_regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        const fname_regex = /^[a-zA-Z ]+$/;
        const lname_regex = /([a-zA-Z]{1,30})$/;
        const mobile_regex = /^[0-9\b]+$/;


        if (!values.firstName) {
            errors.firstName = "First Name is Required!";
        } else if (!fname_regex.test(values.firstName)) {
            errors.firstName = "Enter Valid First Name!";
        }
        // if (!values.lastName) {
        //   errors.lastName = "Last Name is Required!";
        // } else if (!lname_regex.test(values.lastName)) {
        //   errors.lastName = "Enter Valid Last Name!";
        // }
        if (!values.email) {
            errors.email = "Email is Required!";
        } else if (!email_regex.test(values.email)) {
            errors.email = "Enter Valid Email!";
        }
        if (!values.mobile) {
            errors.mobile = "Mobile No. is Required";
        } else if (values.mobile.length < 10) {
            errors.mobile = "Mobile No. must be 10 Digits";
        }
        else if (!mobile_regex.test(values.mobile)) {
            errors.mobile = "Enter valid Mobile No.";
        }
        if (!values.isChecked) {
            errors.isChecked = "Kindly accepts the terms and conditions to submit";
        }
        return errors;
    };




    return (
        <div className='main-div' >
            <NavBar />

            <br />
            <br />
            <br />
            <div class='car-insurance-top'>
                <br />
                <div class="container" id="HealthInsurance">
                    <div class="row my-2 form-container">
                        <div class="col-lg-8" >
                            <div class='row' style={{ marginTop: '20px', marginLeft: '1.25rem', paddingTop: '10px' }}>
                                <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 py-1 px-3" >
                                    <div class="bg-white p-2" >
                                        <div class="px-2 py-3">
                                            <div class="container" style={{ Shadow: "0px 30px 50px rgb(0 0 0 / 10%)" }}>
                                                <div class="row" style={{ marginTop: "-30px", marginLeft: "-70px" }}></div>
                                                <form onSubmit={handleSubmit}>
                                                    <h2 class="icueTextPrimary">Health Insurance </h2>
                                                    <p>Secure your Health with best Health Insurance plans offered by insurers.</p> <br />
                                                    <div class="step2-content col-lg-5 col-md-7" style={{marginLeft: '-3rem'}} >
                                                        <div class='col-lg-10'>
                                                            <div class='d-sm-flex'>
                                                                <div class="car3-form-groupp mt-3 mb-2">
                                                                    <input
                                                                        class="car2-form-controll"
                                                                        id="firstName"
                                                                        type="text"
                                                                        name="firstName"
                                                                        value={formValues.firstName}
                                                                        onChange={handleChange}
                                                                        style={{ marginLeft: '20px', marginRight: '20px', textTransform: "capitalize" }}
                                                                    />
                                                                    <label class="ms-3 car2-form-control-placeholderr" for="firstName">First Name</label>
                                                                    <p className='p-error'>{formErrors.firstName}</p>
                                                                </div>
                                                                <div class="car3-form-groupp mt-3 mb-2">

                                                                    <input
                                                                        class="car2-form-controll"
                                                                        id="lastName"
                                                                        type="text"
                                                                        name="lastName"
                                                                        value={formValues.lastName}
                                                                        onChange={handleChange}
                                                                        style={{ marginLeft: '20px', marginRight: '20px', textTransform: "capitalize" }}
                                                                    />
                                                                    <label class="ms-3 car2-form-control-placeholderr" for="lastName">Last Name</label>

                                                                    {/* <p className='p-error'>{formErrors.lastName}</p> */}
                                                                </div>

                                                            </div>
                                                            <div class='d-sm-flex'>
                                                                <div class="car3-form-groupp mt-3 mb-2">

                                                                    <input
                                                                        class="car2-form-controll"
                                                                        id="mobile"
                                                                        type="text"
                                                                        name="mobile"
                                                                        value={formValues.mobile}
                                                                        onChange={handleChange}
                                                                        maxLength={10}
                                                                        style={{ marginLeft: '20px', marginRight: '20px', textTransform: "lowercase" }}
                                                                    />
                                                                    <label class="ms-3 car2-form-control-placeholderr" for="mobile">Mobile</label>

                                                                    <p className='p-error'>{formErrors.mobile}</p>
                                                                </div>
                                                                <div class="car3-form-groupp mt-3 mb-2">
                                                                    <input
                                                                        id="email"
                                                                        class="car2-form-controll"
                                                                        type="text"
                                                                        name="email"
                                                                        value={formValues.email}
                                                                        onChange={handleChange}
                                                                        style={{ marginLeft: '20px', marginRight: '20px', textTransform: "lowercase" }}
                                                                    />
                                                                    <label class="ms-3 car2-form-control-placeholderr" for="email">Email</label>

                                                                    <p className='p-error'>{formErrors.email}</p>
                                                                </div>
                                                            </div>
                                                            {/* <button className="fluid ui button blue">Submit</button> */}
                                                        </div>
                                                    </div>

                                                    <br />

                                                    <div className='privacypolicy-chechbox' >
                                                        <input class='acceptTandC' style={{ width: '20px' }} type='checkbox' defaultChecked={formValues.isChecked} onChange={() => { setChecked(!checked); setFormValues({ ...formValues, 'isChecked': !formValues.isChecked }); }} /><a href="javascript:void(0)"> I/We accept the <span className='link-terms'
                                                            onClick={handleShowModal}

                                                        >Terms and conditions</span> </a>

                                                        <Model show={showModal} onHide={handleCloseModal}>
                                                            <Terms />
                                                        </Model>


                                                        <p className='p-error'>{formErrors.isChecked}</p>
                                                        <br />
                                                        <span style={{
                                                            fontSize: '0.9rem',
                                                            fontFamily: 'Calibri',
                                                            paddingTop: '1rem !important'
                                                        }}>(You authorise PoliciCue Insurance Brokers Pvt Ltd (PoliciCue) to contact you through call, SMS, email, WhatsApp or any other mode in future.
                                                            You hereby override your NDNC registration.)
                                                        </span>
                                                        {/* <input class='acceptTandC' style={{ width: '20px' }} type='checkbox' defaultChecked={checked} onChange={() => setChecked(!checked)} /><a href="/LegalAdminPolicies"> I/We accept the <span className='link-terms' onClick={() => history.push("/LegalAdminPolicies")}>Terms and conditions</span> </a> */}
                                                    </div>
                                                    <div class='step2-car-nextbtn ' style={{ marginLeft: "-55px", lineHeight: "20px", height: "40px" }}><button class='' type='submit'>Submit</button></div>
                                                </form>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4" style={{ backgroundColor: "#F4F7FC", display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                            <img src='./images/im2 4.png' className='car-step1-img'  ></img>
                        </div>
                    </div>
                </div>
            </div>


            <div ref={health_insurance} class="car-insurance">
                <div class="container">
                    <div class="d-sm-flex align-items-center row" style={{maxWidth: '90%'}}>
                        <div class="col-lg-6 col-sm-12">
                            <div class="text-container">
                                <h2 class="h2-header" >What is Health Insurance ?</h2>
                                <p class="paragraph-header">There is an old saying “Health is Wealth”. We all strive to remain healthy and do well in life. We
                                    maintain a lifestyle and work hard to earn money for our better future. We also follow advise on
                                    keeping ourselves healthy by adopting gym &amp; yoga practices. We engage in games to keep ourselves fit.
                                    All these activities keep us motivated to do well and remain healthy. But what if, we have to get some
                                    treatment and need to go hospital or see doctors. Health Insurance gives you protection against these
                                    costs. The Policy, which is also known as Mediclaim Insurance, largely  provides protection against
                                    medical treatment expenses including: <br /><br />

                                    a) Room, Boarding expenses<br />
                                    b) Nursing expenses<br />
                                    c) Fees of surgeon, anesthetist, physician, consultants, specialists<br />
                                    d) Anesthesia, blood, oxygen, operation theatre charges, surgical appliances, medicines, drugs,<br />
                                    diagnostic materials, X-ray, Dialysis, chemotherapy, Radio therapy, cost of pace maker, Artificial limbs,<br />
                                    cost or organs and similar expenses.<br />
                                    e) Pre &amp; Post Hospitalization expenses related to treatment<br /><br />

                                    It is always advisable to take complete view of Policy T&amp;C before buying Insurance and consider which is
                                    the best fit product according to your need.</p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-sm-12">
                            <div class="image-container">
                                <img className='textPrimary' src="images/im2.png" alt="alternative" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- end of car-insurance -->
<!-- end of details 2 --> */}

            {/* <!-- different_types_car_insurance --> */}
            <div ref={health_insurance_needed} class="different_types_car_insurance">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <h2 class="h2-heading-different_types_car_insurance">Why Health Insurance is needed?</h2>
                        </div>
                    </div>
                </div>
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6 col-cls">
                            <div class="card_diff_car_left1">
                                <div class="text-container diff_car_left_text">
                                    <div className="image-container" style={{ marginTop: '-4rem' }}>
                                        <img className='img-cls' src="images/tparty.png" alt="icon" />
                                    </div>
                                    <h6 className='h6-text-heading'>Increase in Lifestyle disease</h6>
                                    <p class="p-diff_car">With change in our lifestyle and food habits we have come across rise in diseases and early
                                        disease symptoms. Treatment cost for these diseases generally makes a very heavy impact onyour pocket and take away all your savings.
                                        So it is always advisable to get protected with
                                        Health Insurance against these unfortunate situations.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6  col-cls">
                            <div class="card_diff_car_right1">
                                <div className="image-container" style={{ marginTop: '-4rem' }}>
                                    <img className='img-cls' src="images/package.png" alt="icon" />
                                </div>
                                <div class="text-container">
                                    <h6 className='h6-text-heading'>Increase in Treatment cost</h6>
                                    <p class="p-diff_car">With rise in inflation, medical inflation is fast and enough to cost you double digit increase in
                                        medical treatment cost year on year. So increased cost of treatment is always a worry and a
                                        clear threat to your savings for a bright future. It is a wise decision to have an adequate Health
                                        Insurance Policy which provides the Medical emergency fund. It is also advisable to take
                                        adequate Sum Insured keeping in mind the average medical inflation for the next 3-5 years in
                                        mind.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <br />
            <br />
            <div class="different_types_car_insurance">

                <div class="container">
                    <div class="row">
                        <div class="col-lg-6 col-cls">
                            <div class="card_diff_car_left1">
                                <div class="text-container diff_car_left_text">
                                    <div className="image-container" style={{ marginTop: '-4rem' }}>
                                        <img className='img-cls' src="images/tparty.png" alt="icon" />
                                    </div>
                                    <h6 className='h6-text-heading'>To minimize Out of Pocket expenses</h6>
                                    <p class="p-diff_car">We have different plan for our life and we budget accordingly, medical emergencies are
                                        uncalled and unforeseen situation where you don’t budget and these expenses bleeds you and drain your other budgeted expenses. You are left with a situation where you suffer at many planned places because of short of financials. Health Insurance helps you to meet these unforeseen expenses and keep you protected from draining your planned budget.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-cls">
                            <div class="card_diff_car_right1">
                                <div className="image-container" style={{ marginTop: '-4rem' }}>
                                    <img className='img-cls' src="images/package.png" alt="icon" />
                                </div>
                                <div class="text-container">
                                    <h6 className='h6-text-heading'>Tax Benefit</h6>
                                    <p class="p-diff_car">Apart from above mentioned benefits where you get protection for yourself and your family,
                                        you are entitled to get Tax Benefit on Health Insurance yearly Premium paid as per Section-80D of Income Tax.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <!-- end of different_types_car_insurance -->
<!-- end of different_types_car_insurance --> */}


            {/* <!------------------------addons-----------------------------------------> */}
            {/* <!-- Basic --> */}
            <div ref={addOn} class="ex-basic-1 pt-5 pb-5">

                <div class="container custom-extend">

                    {/* <!-- Card --> */}
                    <div class="card card-addons">

                        <div class="card-block">
                            <h2 class="h2-heading-addon">Type of Health Insurance</h2>
                            {/* <!-----------------------row one-----------------------------------------> */}
                            <div class="row addons">

                                <div class="col-lg-4 ">
                                    <div class="card addon card-size-1" >
                                        <div class="card-body addon-content">
                                            <h6 class="card-title h6-heading-addon">Individual Health Insurance</h6>
                                            <p class="card-text p-addon-text ">As the name suggest this policy cater the need of an individual and Sum Insured taken under the Policy is for Individual Insured only.
                                            </p>
                                        </div>
                                    </div>

                                </div>

                                <div class="col-lg-4">
                                    <div class="card addon card-size-2" >
                                        <div class="card-body">
                                            <h6 class="card-title h6-heading-addon">Family Health Insurance</h6>
                                            <p class="card-text p-addon-text">Family needs the protection against the medical emergencies so that you remain worry free against the out-of-pocket expenses on Medical needs. Family definition varies insurer to insurer, however the bestselling family floater plan for most of the insurers is Self + Spouse &amp; 2 Children.</p>

                                        </div>
                                    </div>

                                </div>

                                <div class="col-lg-4  ">
                                    <div class="card addon card-size-3" >
                                        <div class="card-body">
                                            <h6 class="card-title h6-heading-addon">Senior Citizen Health Insurance</h6>
                                            <p class="card-text p-addon-text ">With the rising lifestyle diseases and increased medical inflation, old age require more money to take
                                                care of health issues. Mediclaim for senior citizen helps in providing the security against this.</p>

                                        </div>
                                    </div>

                                </div>
                            </div>
                            {/* <!--------end row 1-->
                          <!-------------------------------------row two---------------------------------------------------> */}
                            <div class="row addons">

                                <div class="col-lg-4 ">
                                    <div class="card addon addon card-size-1" >
                                        <div class="card-body">

                                            <h6 class="card-title h6-heading-addon">Critical illness Health Insurance</h6>

                                            <p class="card-text p-addon-text ">Some of the Illnesses need special attention and money as treatment of these illness cost much. It is always a smart protection to have as this provides an additional amount of financial protection at the time of need, and you are worry free in terms of financials.
                                            </p>
                                        </div>
                                    </div>

                                </div>

                                <div class="col-lg-4 ">
                                    <div class="card addon card-size-2" >
                                        <div class="card-body">
                                            <h6 class="card-title h6-heading-addon">Top/Super Top Up Health Insurance</h6>

                                            <p class="card-text p-addon-text ">This Policy gives an additional Sum Insured benefit at very marginal cost. This cost less as the Base policy is generally gives protection to the insured and this policy benefit triggers once the base policy sum insured is exhausted. This works like your vault which you open once you need the cash more than your
                                                daily need.</p>

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- end of basic -->
<!-- end addons --> */}

            {/* <!-- What is Not Covered under a Basic Car Insurance Plan --> */}
            <div ref={topic5} class="car-insurance">
                <div class="container">
                    <div class="d-sm-flex align-items-center row" style={{maxWidth: '90%'}}>
                        <div class="col-lg-6 col-sm-12">
                            <div class="text-container">
                                <h2 class="h2-header" >Considered before buying Health Insurance</h2>


                                <h2 class="h2-header" >Need V/S offering:</h2>
                                <p class="paragraph-header">We advise you not to be influenced by market but wisely consider your future need and take a
                                    best fit policy for yourself and your family. You must consider the family need, if you are
                                    planning for Family Health insurance.
                                </p>

                                <h2 class="h2-header" >Service Network (Cashless Hospital Network): </h2>
                                <p class="paragraph-header"> Better the Cashless Network, better the chances of good service. It is always better to go for
                                    better networked insurers to reduce the paper formality at the time of need/emergency.
                                </p>
                                <h2 class="h2-header" >
                                    Scope of the Policy: </h2>
                                <p class="paragraph-header">  It is advisable to check the list of ailments/disease covered under the policy along with their
                                    limit, if any. One should opt for the Policy where there are no limits on diseases/ailment.

                                </p>
                                <h2 class="h2-header" >
                                    Exclusions in Policy: </h2>
                                <p class="paragraph-header">Please go through the list of exclusions and compare the policy with least exclusions and
                                    decide. Check the abbreviations in glossary section of the Policy wording for complete clarity.

                                </p>
                                <h2 class="h2-header" >
                                    Pre-existing disease:</h2>
                                <p class="paragraph-header"> Even if you are free from any disease it is advisable to take a policy which gives cover to the
                                    disease pre-existing at the earliest. This is because lifestyle diseases are fast catching disease
                                    and early the insured get covered better for the insured.

                                </p>

                            </div>
                        </div>
                        <div class="col-lg-6 col-sm-12">
                            <img className='textPrimary' src="images/im3.png" alt="alternative" />

                        </div>
                    </div>
                </div>
            </div>




            <div ref={faqs} class="faqs">
                <div class="container">
                    <div class="row">
                        <div class="col-align">
                            <h2 class="h2-heading-faqs">Insurance FAQ’s</h2>
                            <p class="p-heading-faqs">If You Are Looking To Buy Insurance You  Ought to Check Below Frequently Asked Questions</p>
                        </div>
                    </div>
                </div>

                <div class="container">
                    <div class="row">
                        <Accordion>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header className='accordion-header-1'>
                                    <img src="./images/Rectangle 22.svg" alt="" />&nbsp;&nbsp;&nbsp;&nbsp; <p className='faqText'>What is Health Insurance?</p></Accordion.Header>
                                <Accordion.Body className='accordion-header-1'>
                                    <p class="p-faqs-para">
                                        The term health insurance is a type of insurance that covers expenses incurred on medical treatment. A health insurance policy is a contract between an insurer and an individual/group in which the insurer agrees to provide specified health insurance cover at a particular “premium”.
                                    </p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header className='accordion-header-2'> <img src="./images/Rectangle 22.svg" alt="" />&nbsp;&nbsp;&nbsp;&nbsp; <p className='faqText'>What are the forms of Health Insurance available?</p></Accordion.Header>
                                <Accordion.Body className='accordion-header-2'>
                                    <p class="p-faqs-para">
                                        The commonest form of health insurance policies in India cover the expenses incurred on Hospitalization, though a variety of products are now available which offer a range of health covers, depending on the need and choice of the insured. The health insurer usually provides either direct payment to hospital (cashless facility) or reimburses the expenses associated with illnesses and injuries or disburses a fixed benefit on occurrence of an illness. The type and amount of health care costs that will be covered by the health plan are specified in advance.
                                    </p>
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="2">
                                <Accordion.Header className='accordion-header-1'>
                                    <img src="./images/Rectangle 22.svg" alt="" />&nbsp;&nbsp;&nbsp;&nbsp; <p className='faqText'>Why is Health Insurance important?</p></Accordion.Header>
                                <Accordion.Body className='accordion-header-1'>
                                    <p class="p-faqs-para">
                                        All of us should buy health insurance and for all members of our family, according to our needs. Buying health insurance protects us from the sudden, unexpected costs of hospitalization (or other covered health events, like critical illnesses) which would otherwise make a major dent into household savings or even lead to indebtedness. Each of us are exposed to various health hazards and a medical emergency can strike to anyone of us without any prior warning. Healthcare is increasingly expensive, with technological advances, new procedures and more effective medicines that have also pushed up the costs of healthcare. While these high treatment expenses may be beyond the reach of many, taking the shield by the way of health insurance is much more affordable.
                                    </p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="3">
                                <Accordion.Header className='accordion-header-2'> <img src="./images/Rectangle 22.svg" alt="" />&nbsp;&nbsp;&nbsp;&nbsp; <p className='faqText'> What kinds of Health Insurance plans are available?</p></Accordion.Header>
                                <Accordion.Body className='accordion-header-2'>
                                    <p class="p-faqs-para">
                                        Health insurance policies are available for various sum insured bands as per one’s need. As the room rents and other expenses payable by insurers are, at times, linked to the sum insured opted for, it is advisable to take adequate sum insured cover from an early age, particularly because it may not be easy to increase the sum insured after a claim occurs or you reach higher age bracket. Also, while most non-life insurance companies offer health insurance policies for a duration of one year, there are policies that are issued for more than one year duration as well. A Hospitalization policy covers, fully or partly, the actual cost of the treatment for hospital admissions during the policy period. This is a wider form of coverage applicable for various hospitalization expenses, including expenses before and after hospitalization for some specified period. Such policies may be available on individual sum insured basis, or on a family floater basis where the sum insured is shared across the family members. Another type of product, the Hospital Daily Cash Benefit policy, provides a fixed daily sum insured for each day of hospitalization. There may also be coverage for a higher daily benefit in case of ICU admissions or for specified illnesses or injuries.  </p>
                                    <p class="p-faqs-para">
                                        A Critical Illness benefit policy provides a fixed lumpsum amount to the insured in case of diagnosis of a specified illness or on undergoing a specified procedure. This amount is helpful in mitigating  various direct and indirect financial consequences of a critical illness. Usually, once this lump sum is paid, the plan ceases to remain in force. </p>
                                    <p class="p-faqs-para">
                                        There are also other types of products, which offer lumpsum payment on undergoing a specified surgery (Surgical Cash Benefit), and others catering to the needs of specified target audience like senior citizens. </p>
                                </Accordion.Body>
                            </Accordion.Item>


                            <Accordion.Item eventKey="4">
                                <Accordion.Header className='accordion-header-1'>
                                    <img src="./images/Rectangle 22.svg" alt="" />&nbsp;&nbsp;&nbsp;&nbsp; <p className='faqText'>What is cashless facility?</p></Accordion.Header>
                                <Accordion.Body className='accordion-header-1'>
                                    <p class="p-faqs-para">
                                        Insurance companies have tie-up arrangements with several hospitals all over the country as part of its network.  Under a health insurance policy offering cashless facility, a policyholder can take treatment in any of the network hospitals without having to pay the hospital bills as the payment is made to the hospital directly by the Third Party Administrator, on behalf of the insurance company. However, expenses beyond the limits or sub-limits allowed by the insurance policy or expenses not covered under the policy have to be settled by you directly with the hospital. Cashless facility, however, is not available if you take treatment in a hospital that is not in the network. </p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="5">
                                <Accordion.Header className='accordion-header-2'> <img src="./images/Rectangle 22.svg" alt="" />&nbsp;&nbsp;&nbsp;&nbsp; <p className='faqText'> What are the factors that affect Health Insurance premium?</p></Accordion.Header>
                                <Accordion.Body className='accordion-header-2'>
                                    <p class="p-faqs-para">
                                        Age is a major factor that determines the premium, the older you are the premium cost will be higher because you are more prone to illnesses. Previous medical history is another major factor that determines the premium. If you have a prior medical history, it may have impact on your premium upwardly as per insurer underwriting.  </p>
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="6">
                                <Accordion.Header className='accordion-header-1'>
                                    <img src="./images/Rectangle 22.svg" alt="" />&nbsp;&nbsp;&nbsp;&nbsp; <p className='faqText'>What does a Health Insurance policy not cover?</p></Accordion.Header>
                                <Accordion.Body className='accordion-header-1'>
                                    <p class="p-faqs-para">
                                        You must read the prospectus/ policy terms and conditions to understand what is not covered under it. Generally, pre-existing diseases (read the policy to understand what a pre-existing disease is defined as) are excluded under a Health Insurance policy. Further, the policy would generally exclude certain diseases from the first/second year of coverage and also impose a waiting period. There would also be certain standard exclusions such as cost of spectacles, contact lenses and hearing aids not being covered, dental treatment/surgery (unless due to accident requiring hospitalization), intentional self-injury, use of intoxicating drugs/alcohol, expenses for diagnosis, x-ray or laboratory tests not consistent with the disease requiring hospitalization and so on. Please read the policy terms and conditions before concluding a sale. </p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="7">
                                <Accordion.Header className='accordion-header-2'> <img src="./images/Rectangle 22.svg" alt="" />&nbsp;&nbsp;&nbsp;&nbsp; <p className='faqText'> Is there any Waiting Period for claims under a policy?</p></Accordion.Header>
                                <Accordion.Body className='accordion-header-2'>
                                    <p class="p-faqs-para">
                                        Yes, when you get a new policy, generally, there will be a 30 days waiting period starting from the policy inception date, during which period any hospitalization charges will not be payable by the insurance company. However, this is not applicable to any emergency hospitalization due to an accident. This waiting period will not be applicable for subsequent policies under renewal. </p>
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="8">
                                <Accordion.Header className='accordion-header-1'>
                                    <img src="./images/Rectangle 22.svg" alt="" />&nbsp;&nbsp;&nbsp;&nbsp; <p className='faqText'> What is pre-existing condition in health insurance policy?</p></Accordion.Header>
                                <Accordion.Body className='accordion-header-1'>
                                    <p class="p-faqs-para">
                                        It is a medical condition/disease that existed before you obtained health insurance policy, and it is significant, because the insurance companies do not cover such pre-existing conditions till the time of waiting period applicable for pre-existing condition under the policy.  It means, pre-existing conditions can be considered for payment after completion of waiting period in continuous insurance cover. </p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="9">
                                <Accordion.Header className='accordion-header-2'> <img src="./images/Rectangle 22.svg" alt="" />&nbsp;&nbsp;&nbsp;&nbsp; <p className='faqText'>If my policy is not renewed in time before expiry date, will I be denied for renewal?</p></Accordion.Header>
                                <Accordion.Body className='accordion-header-2'>
                                    <p class="p-faqs-para">
                                        The policy will be renewable provided you pay the premium before the expiry or maximum within the Grace Period. However, coverage would not be available for the period for which no premium is received by the insurance company. The policy will be considered as break if the premium is not paid within the grace period. </p>
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="10">
                                <Accordion.Header className='accordion-header-1'>
                                    <img src="./images/Rectangle 22.svg" alt="" />&nbsp;&nbsp;&nbsp;&nbsp; <p className='faqText'> Can I transfer my policy from one insurance company to another without losing the renewal benefits?</p></Accordion.Header>
                                <Accordion.Body className='accordion-header-1'>
                                    <p class="p-faqs-para">
                                        Yes, the Insurance Regulatory and Development Authority (IRDA) has issued a circular making it effective from 1st October, 2011, which directs the insurance companies to allow portability from one insurance company to another and from one plan to another, without making the insured to lose the renewal credits for pre-existing conditions, enjoyed in the previous policy. However, this credit will be limited to the Sum Insured (including Bonus) under previous policy. For details, you may check with the insurance company. </p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="11">
                                <Accordion.Header className='accordion-header-2'> <img src="./images/Rectangle 22.svg" alt="" />&nbsp;&nbsp;&nbsp;&nbsp; <p className='faqText'>What happens to the policy coverage after a claim is filed?</p></Accordion.Header>
                                <Accordion.Body className='accordion-header-2'>
                                    <p class="p-faqs-para">
                                        After a claim is filed and settled, the policy coverage is reduced by the amount that has been paid out on settlement. For Example: In January you start a policy with a coverage of Rs 5 Lakh for the year. In April, you make a claim of Rs 2 lakh. The coverage available to you for the May to December will be the balance of Rs.3 lakh. </p>
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="12">
                                <Accordion.Header className='accordion-header-1'>
                                    <img src="./images/Rectangle 22.svg" alt="" />&nbsp;&nbsp;&nbsp;&nbsp; <p className='faqText'>What is ’Any one illness’?</p></Accordion.Header>
                                <Accordion.Body className='accordion-header-1'>
                                    <p class="p-faqs-para">
                                        'Any one illness' would mean the continuous period of illness, including relapse within a certain number of days as specified in the policy. Usually this is 45 days. </p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="13">
                                <Accordion.Header className='accordion-header-2'> <img src="./images/Rectangle 22.svg" alt="" />&nbsp;&nbsp;&nbsp;&nbsp; <p className='faqText'>What is the maximum number of claims allowed over a year?</p></Accordion.Header>
                                <Accordion.Body className='accordion-header-2'>
                                    <p class="p-faqs-para">
                                        Any number of claims is allowed during the policy period unless there is a specific cap prescribed in any policy. However the sum insured is the maximum limit under the policy. </p>
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="14">
                                <Accordion.Header className='accordion-header-1'>
                                    <img src="./images/Rectangle 22.svg" alt="" />&nbsp;&nbsp;&nbsp;&nbsp; <p className="faqText"> What is “health check-up” facility?</p></Accordion.Header>
                                <Accordion.Body className='accordion-header-1'>
                                    <p class="p-faqs-para">
                                        Some health insurance policies pay for specified expenses towards general health check-up once in a few years. </p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="15">
                                <Accordion.Header className='accordion-header-2'> <img src="./images/Rectangle 22.svg" alt="" />&nbsp;&nbsp;&nbsp;&nbsp; <p className='faqText'>What do you mean by Family Floater Policy?</p></Accordion.Header>
                                <Accordion.Body className='accordion-header-2'>
                                    <p class="p-faqs-para">
                                        Family Floater is one single policy that takes care of the hospitalization expenses of your entire family. The policy has one single sum insured, which can be utilised by any/all insured persons in any proportion or amount subject to maximum of overall limit of the policy sum insured. Quite often Family floater plans are better than buying separate individual policies.  Family Floater plans takes care of all the medical expenses during sudden illness, surgeries and accidents. </p>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </div>
                </div>
            </div>
            <Footer />
            <Modal show={show} onHide={handleClose}>

                <Modal.Body>
                    <div className='modal-block-close-btn'>
                        <button type="button" class="btn text-muted modal-btn-size" onClick={handleClose} ><i class="bi bi-x-circle fas fa-lg modal-btn-icon"></i></button>
                    </div>
                    <div className='modal-block'>
                        <img className="thankyou" src="images/thankyou.png" alt="alternative" style={{ width: "100px" }} />
                        <h2>Thank You!</h2>
                        <div class="mb-4">
                            <div class="d-flex justify-content-between" >
                                <label class="" style={{ textAlign: "center", fontFamily: "Calibri", color: "black" }}>
                                    Your query has been submitted successfully and our executive will contact you shortly.
                                </label>

                            </div>
                        </div>
                    </div>

                </Modal.Body>

            </Modal>
            <div class="modal fade " id="thankyouModal" tabindex="-1" aria-labelledby="thankyouModalLabel" aria-hidden="true" style={{ marginTop: "170px" }}>
                {/* <!-- <div class="modal-dialog modal-dialog-centered"> --> */}
                <div class="modal-dialog modal-sm rounded-circle" >
                    <div class="modal-content" style={{ borderRadius: "12px", width: "130%" }} >
                        {/* <div class="modal-header ">                */}
                        {/* <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" style={{marginLeft:"265px"}}></button> */}
                        <button type="button" class="btn text-muted" data-bs-dismiss="modal" style={{ marginLeft: "305px" }}><i class="bi bi-x-circle fas fa-lg"></i></button>
                        {/* </div> */}
                        <div class="modal-body " style={{ backgroundColor: "white" }}>
                            <div class="row">
                                <div class="col-lg-9 col-md-9 col-sm-12 col-12 mx-auto">
                                    <div class="form-group pb-2">

                                        <img className="thankyou" src="images/thankyou.png" alt="alternative" style={{ width: "108px", marginLeft: "75px" }} />
                                        <h2 style={{ marginLeft: "60px" }}>Thank You!</h2>

                                        {/* <h2 ><i class="bi bi-check-circle fas fa-lg" style={{marginLeft:"80px", color:"#00A0E4"}}></i></h2> */}
                                    </div>
                                </div>
                                <div class="col-lg-9 col-md-9 col-sm-12 col-12 mx-auto" style={{ marginTop: "-15px" }}>
                                    <div class="mb-4">
                                        <div class="d-flex justify-content-between" >
                                            <label class="" style={{ textAlign: "center", fontFamily: "Calibri", color: "black" }}>
                                                Your query had been submitted successfully and our executive will contact you shortly.
                                            </label>

                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}