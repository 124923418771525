import React from "react";

const EditableRow = ({
    editFormData,
    handleEditFormChange,
}) => {
  return (
    <tr>
      
        {/* <input
          type="text"
          required="required"
          placeholder="Enter role"
          name="role"
          value={editFormData.role}
          onChange={handleEditFormChange}
        ></input> */}
         {/* <td class="text-center">
         <span className="badge rounded-pill bg-primary text-uppercase px-2 py-2 w-75" 
           >Active</span>
         </td>   */}
     
   
      <td>
        {/* <button type="submit">Save</button>
        <button type="button" onClick={handleCancelClick}>
          Cancel
        </button> */}
         {/* <span className="badge rounded-pill bg-secondary text-uppercase px-3 py-2 w-75"  value={editFormData.status}
          onChange={handleEditFormChange}  >Inactive</span> */}
      </td>
    </tr>
  );
};

export default EditableRow;