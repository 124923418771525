import React,{useEffect, useState} from 'react';
import axios from 'axios'
import './AgentDashboard.css';
export default function AgentDashboard(){
    const [carleads, setCarLeads] = useState([]);
    const[bikeleads, setBikeLeads]=useState([]);
    const[termleads, setTermLeads]=useState([]);
    const[total, setTotal]=useState([]);   
    useEffect(() => {
        axios.get('https://policiecue-api.mobileprogramming.net/motor/carleadsCount')
            .then(res => {
                console.log(res)
                setCarLeads(res.data.data)
            })
            axios.get('https://policiecue-api.mobileprogramming.net/motor/bikeleadsCount')
            .then(res=>{
                console.log(res)
                setBikeLeads(res.data.data)
            })
            axios.get('https://policiecue-api.mobileprogramming.net/lead/leadsCount')
            .then(res=>{
                console.log(res)
                setTotal(res.data.data)
            }) 
            axios.get('https://policiecue-api.mobileprogramming.net/termlife/lifeLeadsCount')
            .then(res=>{
                console.log(res)
                setTermLeads(res.data.data)
            })  
            .catch(err=>{
                console.log(err)
            })
    }, {})

    return(<div className="AgentDashboard" id="AgentDashboard">
        {/* <!-- Top Bar --> */}
    <div className="container-fluid1 py-2 icueTopBarContainer bg-white">
        <div className="row">
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                <div className="topBarLfContainer d-flex">
                    <div className="icueLogoContainer">
                        <img src="images/logo.png" alt="ICue Logo" className="icueLogo" />
                    </div>
                    <div className="icueSearchBox w-75 mx-3">
                        <div className="input-group ">
                            <span className="input-group-text" id="basic-addon1">
                                <i className="bi bi-search"></i>
                            </span>
                            <input type="search" id="text-search" className="form-control icueSearchBoxControl" placeholder="Enter keywords..." aria-label="Username" aria-describedby="basic-addon1"/>
                          </div>
                    </div>
                </div>
            </div>

            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                <div className="d-flex flex-row-reverse">
                    <div className="icueProfile px-2">
                        <img src="images/avatar.png" alt="icueProfilePic" className="icueProfilePic border border-dark rounded-circle" />
                    </div>
                    <div className="position-relative px-2">
                        <a href="#" id="icueNotifyBoxHandler">
                            <i className="bi bi-bell">
                                <span className="position-absolute icueNofIndic"></span>
                            </i>
                        </a>
                        <div className="icueNotifyBox" id="icueNotifyBox">
                            Notifications
                        </div>
                    </div>

                    <div className="icueUserName fs-6 px-2">
                        Viral Patel
                    </div>

                    <div className=" icueMail px-2">
                        <i className="bi bi-headset"></i> &nbsp;
                        adminhelpdesk@gmail.com
                    </div>
                </div>
            </div>
        </div>
    </div>
   
   
    <div className="container-fluid my-2">
        <div className="row">
            <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12 bg-white">
                <div className="icueRespSideBar d-xxl-none d-xl-none d-lg-none d-md-block d-sm-block d-block" id="icueRespSideBar">
                    <button type="button" className="btn btn-primary" id="icueRespHumburger">
                        <i className="bi bi-grid-3x3-gap" id="icueOpenIcon"></i>
                        <i className="bi bi-x-lg" id="icueCloseIcon"></i>
                    </button>
                    <div className="icueSideBar" id="icueSideBar">
                        <nav className="navbar icueSideNavContainer">
                            <ul className="icueNavBarList">
                                <li className="icueNavBarItem active">
                                    <a href="/AgentDashboard" className="icueNavBarLink" id="">Dashboard</a>
                                </li>

                                <li className="icueNavBarItem border-bottom-0">
                                    <a href="#" className="icueNavBarLink" id="">LMS</a>
                                </li>

                                <li>
                                    <ul className="icueSubNavBar">
                                        <li className="icueSubNavItem">
                                            <a href="/AgentLMS" className="icueSubNavLink  text-capitalize">All Leads</a>
                                        </li>
                                    </ul>
                                </li>
                                <hr></hr>
                                <li className="icueNavBarItem border-bottom-0">
                                    <a href="#" className="icueNavBarLink" id="">Settings</a>
                                </li>
                                <li>
                                    <ul className="icueSubNavBar">
                                        <li className="icueSubNavItem">
                                            <a href="#" className="icueSubNavLink  text-capitalize">Change Password</a>
                                        </li>
                                        <li className="icueSubNavItem">
                                            <a href="#" className="icueSubNavLink  text-capitalize">Profile</a>
                                        </li>
                                    </ul>
                                </li>
                                <hr></hr>
                            </ul>
                        </nav>
                    </div>
                </div>

                <div className="icueNavSideBar d-xxl-block d-xl-block d-lg-block d-md-none d-sm-none d-none" id="icueNavSideBar">
                    <nav>
                        <ul className="icueNavBarList">
                            <li className="icueNavBarItem active">
                                <a href="/AgentDashboard" className="icueNavBarLink" id="">Dashboard</a>
                            </li>

                            <li className="icueNavBarItem border-bottom-0">
                                <a href="#" className="icueNavBarLink" id="">LMS</a>
                            </li>

                            <li>
                                <ul className="icueSubNavBar">
                                    <li className="icueSubNavItem" style={{marginTop:"-0.75rem"}}>
                                        <a href="/AgentLMS" className="icueSubNavLink  text-capitalize">All Leads</a>
                                    </li>
                                </ul>
                            </li>
                            <hr></hr>
                            <li className="icueNavBarItem border-bottom-0" style={{marginTop:"-0.625rem"}}>
                                <a href="#" className="icueNavBarLink" id="">Settings</a>
                            </li>
                            <li>
                                <ul className="icueSubNavBar" style={{marginTop:"-0.75rem"}}>
                                    <li className="icueSubNavItem">
                                        <a href="#" className="icueSubNavLink  text-capitalize">Change Password</a>
                                    </li>
                                    <li className="icueSubNavItem">
                                        <a href="#" className="icueSubNavLink  text-capitalize">Profile</a>
                                    </li>
                                </ul>
                            </li>
                            <hr></hr>
                        </ul>
                    </nav>
                </div>
            </div>

            <div className="col-xxl-10 col-xl-10 col-lg-10 col-md-12 col-sm-12 col-12 ">
                <main className="mainBodyContainer">
                    <div className="row my-2">
                        <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 py-2 px-3">
                            <div className="bg-transparent p-2  rounded-3">
                            <nav style={{bsbreadcrumbdivider: 'url("data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="8" height="8"%3E%3Cpath d="M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z" fill="currentColor"/%3E%3C/svg%3E")'}} aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0 justify-content-end bg-transparent">
                                    <li className="breadcrumb-item"><a href="/AgentDashboard" className="text-decoration-none text-info" style={{fontFamily:'Calibri', fontSize:"16px", color:"#3A3A3A"}}>Dashboard</a></li>
                                    {/* <li className="breadcrumb-item text-muted" aria-current="page" style={{fontFamily:'Mulish'}}>LMS</li> */}
                                </ol>
                              </nav>
                            </div>
                        </div>
                    </div>

                    <div className="row my-2">
                        <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12 py-2 px-3">
                            <div className="icueBgPrimary p-1 rounded-3 shadow text-white" style={{marginLeft:"0.8rem"}}>
                                <div className="row py-2">
                                    <div className="col-7">
                                        <div className="ps-2">
                                            <h2 className="fs-1 pt-1 " style={{color:"white"}}>{total.totalallLeads}</h2><br/>
                                            <h4 className="fs-5 mb-0 text-capitalize " style={{color:"white"}}>total leads</h4>
                                        </div>
                                    </div>
                                    <div className="col-5 text-end">
                                        <div className="pe-2">
                                            <img src="images/leadsTotal.svg" alt="leadsTotal" className="w-100 shadow rounded-circle" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12 py-2 px-3">
                            <div className="icueBgPrimary p-1 rounded-3 shadow text-white">
                                <div className="row py-2">
                                    <div className="col-7">
                                        <div className="ps-2">
                                            <h2 className="fs-1  pt-1 " style={{color:"white"}}>{total.wonallLeads}</h2><br/>
                                            <h4 className="fs-5 mb-0 text-capitalize" style={{color:"white"}}>won leads</h4>
                                        </div>
                                    </div>
                                    <div className="col-5 text-end">
                                        <div className="pe-2">
                                            <img src="images/leadsWon.svg" alt="leadsWon" className="w-100 shadow rounded-circle" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12 py-2 px-3">
                            <div className="icueBgPrimary p-1 rounded-3 shadow text-white">
                                <div className="row py-2">
                                    <div className="col-7">
                                        <div className="ps-2">
                                            <h2 className="fs-1  pt-1" style={{color:"white"}}>{total.lostallLeads}</h2><br/>
                                            <h4 className="fs-5  mb-0 text-capitalize"style={{color:"white"}}>lost leads</h4>
                                        </div>
                                    </div>
                                    <div className="col-5 text-end">
                                        <div className="pe-2">
                                            <img src="images/leadsLost.svg" alt="leadsLost" className="w-100 shadow rounded-circle" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12 py-2 px-3">
                            <div className="icueBgPrimary p-1 rounded-3 shadow text-white"  style={{marginRight:"0.8rem"}}>
                                <div className="row py-2">
                                    <div className="col-7">
                                        <div className="ps-2">
                                            <h2 className="fs-1 pt-1" style={{color:"white"}}>{total.inProgressallLeads}</h2><br/>
                                            <h4 className="fs-5 mb-0 text-capitalize" style={{color:"white"}}>in progress</h4>
                                        </div>
                                    </div>
                                    <div className="col-5 text-end">
                                        <div className="pe-2">
                                            <img src="images/leadsProgress.svg" alt="leadsProgress" className="w-100 shadow rounded-circle" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row my-2">
                        <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 py-2 px-3">
                            <div className="bg-white p-1 shadow icueBorBottomPrimary"  style={{marginLeft:"0.8rem"}}>
                                <div className="row py-2">
                                    <div className="col-8">
                                        <div className="ps-2">
                                            <h2 className="fs-1 icueTextPrimary">{termleads.totallifeLeads}</h2><br/>
                                            <h4 className="fs-5 mb-0 text-capitalize icueTextLight">life insurance</h4>
                                        </div>
                                    </div>
                                    <div className="col-4 text-end">
                                        <div className="pe-2">
                                            <img src="images/incLife.svg" alt="incLife" className="w-100 shadow rounded-circle"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 py-2 px-3">
                            <div className="bg-white p-1 shadow icueBorBottomPrimary">
                                <div className="row py-2">
                                    <div className="col-8">
                                        <div className="ps-2">
                                            <h2 className="fs-1 icueTextPrimary">{carleads.totalCarLeads}</h2><br/>
                                            <h4 className="fs-5 mb-0 text-capitalize icueTextLight">car insurance</h4>
                                        </div>
                                    </div>
                                    <div className="col-4 text-end">
                                        <div className="pe-2">
                                            <img src="images/incCar.svg" alt="incCar" className="w-100 shadow rounded-circle"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 py-2 px-3">
                            <div className="bg-white p-1 shadow icueBorBottomPrimary"  style={{marginRight:"0.8rem"}}>
                                <div className="row py-2">
                                    <div className="col-8">
                                        <div className="ps-2">
                                            <h2 className="fs-1 icueTextPrimary">{bikeleads.totalBikeLeads}</h2><br/>
                                            <h4 className="fs-5 mb-0 text-capitalize icueTextLight">bike insurance</h4>
                                        </div>
                                    </div>
                                    <div className="col-4 text-end">
                                        <div className="pe-2">
                                            <img src="images/incBike.svg" alt="incBike" className="w-100 shadow rounded-circle"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row my-2">
                        <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 py-2 px-3">
                            <div className="bg-white p-1 shadow icueBorBottomPrimary"  style={{marginLeft:"0.8rem"}}>
                                <div className="row py-2">
                                    <div className="col-8">
                                        <div className="ps-2">
                                            <h2 className="fs-1 icueTextPrimary">6</h2><br/>
                                            <h4 className="fs-5 mb-0 text-capitalize icueTextLight">marine insurance</h4>
                                        </div>
                                    </div>
                                    <div className="col-4 text-end">
                                        <div className="pe-2">
                                            <img src="images/incMarine.svg" alt="incMarine" className="w-100 shadow rounded-circle"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 py-2 px-3">
                            <div className="bg-white p-1 shadow icueBorBottomPrimary">
                                <div className="row py-2">
                                    <div className="col-8">
                                        <div className="ps-2">
                                            <h2 className="fs-1 icueTextPrimary">7</h2><br/>
                                            <h4 className="fs-5 mb-0 text-capitalize icueTextLight">fire insurance</h4>
                                        </div>
                                    </div>
                                    <div className="col-4 text-end">
                                        <div className="pe-2">
                                            <img src="images/incFire.svg" alt="incFire" className="w-100 shadow rounded-circle"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 py-2 px-3">
                            <div className="bg-white p-1 shadow icueBorBottomPrimary"  style={{marginRight:"0.8rem"}}>
                                <div className="row py-2">
                                    <div className="col-8">
                                        <div className="ps-2">
                                            <h2 className="fs-1 icueTextPrimary">7</h2><br/>
                                            <h4 className="fs-5 mb-0 text-capitalize icueTextLight">property insurance</h4>
                                        </div>
                                    </div>
                                    <div className="col-4 text-end">
                                        <div className="pe-2">
                                            <img src="images/incProperty.svg" alt="incProperty" className="w-100 shadow rounded-circle"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row my-2">
                        <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12 py-2 px-3">
                            <div className="bg-white px-4 pt-4 pb-4 rounded-3 shadow-sm" id="icueRfqTable"  style={{marginLeft:"0.8rem"}}>
                                <h4 className="icueTextPrimary pb-1" style={{fontFamily:"ErasDemiITC", fontSize:"28px", fontWeight:"600"}}>Today's RFQ</h4>
                                <table className="table table-striped table-hover  mb-4" id="superadmintable">
                                    <thead>
                                        <tr className="text-uppercase" style={{fontFamily:"Calibri", fontSize:"18px", border:"0"}}>
                                            <th className="py-3">insurance company name</th>
                                            <th className="text-center py-3">total rfq sent</th>
                                            <th className="text-center py-3">total response</th>
                                            <th className="text-center py-3">awaiting response</th>
                                        </tr>
                                    </thead>
                                    <tbody className="text-capitalize" style={{fontFamily:"Calibri", fontSize:"16px"}}>
                                        <tr style={{border:"0"}}>
                                            <td>HDFC Agro</td>
                                            <td className="text-center">5</td>
                                            <td className="text-center">2</td>
                                            <td className="text-center">3</td>
                                        </tr>
                                        <tr style={{border:"0"}}> 
                                            <td>ICICI Term Life</td>
                                            <td className="text-center">7</td>
                                            <td className="text-center">4</td>
                                            <td className="text-center">3</td>
                                        </tr>
                                        <tr style={{border:"0"}}>
                                            <td>royal sundaram</td>
                                            <td className="text-center">5</td>
                                            <td className="text-center">1</td>
                                            <td className="text-center">4</td>
                                        </tr>
                                        <tr style={{border:"0"}}>
                                            <td>sompa</td>
                                            <td className="text-center">3</td>
                                            <td className="text-center">0</td>
                                            <td className="text-center">3</td>
                                        </tr>
                                        <tr>
                                            <td>SBI general</td>
                                            <td className="text-center">10</td>
                                            <td className="text-center">4</td>
                                            <td className="text-center">6</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 py-2 px-3">
                            <div className="bg-white pt-4 pb-2 px-4 rounded-3 shadow-sm border-bottom"  style={{marginRight:"0.8rem"}}>
                                <div className="d-flex justify-content-between">
                                    <h4 className="icueTextPrimary pb-2" style={{fontFamily:"ErasDemiITC", fontSize:"28px", fontWeight:"600"}}>Follow Up</h4>
                                    <div>
                                        <a href="/Followup" type="button" className="btn btn-primary btn-sm" >
                                            <img src="images/add.svg" alt="" className="w-100" /></a>
                                    </div>
                                </div>
                                
                                <div className="d-flex justify-content-between">
                                    <div className="d-flex" >
                                        <h2 className="" style={{fontFamily:"Calibri", fontSize:"44px", color: "#212525"}}>16</h2>
                                        <div className="ps-1">
                                            <div className="text-capitalize  pt-1 text-muted"style={{fontFamily:"Calibri", fontSize:"16px"}}><div>thu</div> <div>sep 2021</div></div>
                                        </div>
                                    </div>
                                    <div className="d-flex fw-bolder align-items-center text-capitalize"><div className="bg-light  icueTextLightGreen px-4 py-1 fs-6 rounded-3" style={{fontFamily:"Calibri", fontSize:"18px"}}>today</div></div>
                                </div>

                                <div className="d-flex justify-content-between pt-3" >
                                    <div className=" text-center">
                                        <div className="day text-uppercase icueTextLightGray"style={{fontFamily:"Calibri", fontSize:"14px", fontWeight:"bold"}}>mo</div>
                                        <div className="date text-uppercase" style={{fontFamily:"Calibri", fontSize:"14px", fontWeight:"bold"}}>13</div>
                                    </div>
                                    <div className=" text-center">
                                        <div className="day text-uppercase icueTextLightGray" style={{fontFamily:"Calibri", fontSize:"14px", fontWeight:"bold"}}>tu</div>
                                        <div className="date text-uppercase" style={{fontFamily:"Calibri", fontSize:"14px", fontWeight:"bold"}}>14</div>
                                    </div>
                                    <div className=" text-center">
                                        <div className="day text-uppercase icueTextLightGray" style={{fontFamily:"Calibri", fontSize:"14px", fontWeight:"bold"}}>we</div>
                                        <div className="date text-uppercase"style={{fontFamily:"Calibri", fontSize:"14px", fontWeight:"bold"}}>15</div>
                                    </div>
                                    <div className="text-center icueActiveCal icueBgInfo text-white px-2 rounded-3">
                                        <div className="day text-uppercase" style={{fontFamily:"Calibri", fontSize:"14px", fontWeight:"bold"}}><small><small>th</small></small></div>
                                        <div className="date text-uppercase "style={{fontFamily:"Calibri", fontSize:"14px", fontWeight:"bold"}}>16</div>
                                    </div>
                                    <div className=" text-center">
                                        <div className="day text-uppercase icueTextLightGray" style={{fontFamily:"Calibri", fontSize:"14px", fontWeight:"bold"}}>fr</div>
                                        <div className="date text-uppercase" style={{fontFamily:"Calibri", fontSize:"14px", fontWeight:"bold"}}>17</div>
                                    </div>
                                    <div className=" text-center">
                                        <div className="day text-uppercase icueTextLightGray" style={{fontFamily:"Calibri", fontSize:"14px", fontWeight:"bold"}}>sa</div>
                                        <div className="date text-uppercase"style={{fontFamily:"Calibri", fontSize:"14px", fontWeight:"bold"}}>18</div>
                                    </div>
                                    <div className=" text-center">
                                        <div className="day text-uppercase icueTextLightGray" style={{fontFamily:"Calibri", fontSize:"14px", fontWeight:"bold"}}>su</div>
                                        <div className="date text-uppercase" style={{fontFamily:"Calibri", fontSize:"14px", fontWeight:"bold"}}>19</div>
                                    </div>
                                </div>
                            </div>
                            <div className="bg-white px-4 pt-3 pb-3 rounded-3 shadow-sm border-bottom"  style={{marginRight:"0.8rem", height:"178px"}}>
                               <a href="/followup"> <button type="button" className="btn icueBgInfo text-start text-white w-100 mb-2 icueRenewBtn "  ><div className="text-uppercase" style={{fontFamily:"Calibri", fontSize:"12px"}}>annie rose
                                {/* <img src="images/Vector.png" class="icueRenewBtn" style={{marginLeft:"9rem"}}/> */}
                                </div>                                                                                        
                                <small className="text-capitalize">car insurance renewal</small></button> </a>
                                <button type="button" className="btn icueBgInfo text-start text-white w-100 icueRenewBtn"><div className="text-uppercase" style={{fontFamily:"Calibri", fontSize:"12px"}}>harri bate
                                {/* <img src="images/Vector.png" class="icueRenewBtn" style={{marginLeft:"9rem"}}/> */}
                                </div><small><small className="text-capitalize">life insurance renewal</small></small></button>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 px-3">
                        </div>
                    </div>

                    
                </main>
            </div>
        </div>
    </div>
    
    
   
   
    </div>)
}