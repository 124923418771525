import React, { Fragment, useState } from 'react';
import './ListMembers.css';
import data from "./mock-data.json";
import ReadOnlyRow from "./ReadOnlyRow";
import EditableRow from "./EditableOnlyRow";

export default function ListMembers() {

    const [contacts, setContacts] = useState(data);
    const [addFormData, setAddFormData] = useState({
        code: "",
        name: "",
        role: "",
        data: "",
        permissions: "",
        status1: "", status2: ""
    });
    const [editFormData, setEditFormData] = useState({
        code: "",
        name: "",
        role: "",
        data: "",
        permissions: "",
        status1: "", status2: ""

    });
    const [editContactId, setEditContactId] = useState(null);

    const handleAddFormChange = (event) => {
        event.preventDefault();

        const fieldName = event.target.getAttribute("name");
        const fieldValue = event.target.value;

        const newFormData = { ...addFormData };
        newFormData[fieldName] = fieldValue;

        setAddFormData(newFormData);
    };
    const handleEditFormChange = (event) => {
        event.preventDefault();

        const fieldName = event.target.getAttribute("name");
        const fieldValue = event.target.value;

        const newFormData = { ...editFormData };
        newFormData[fieldName] = fieldValue;

        setEditFormData(newFormData);
    };
    const handleAddFormSubmit = (event) => {
        event.preventDefault();
        const newContact = {
            code: addFormData.code,
            name: addFormData.name,
            role: addFormData.role,
            date: addFormData.date,
            permissions: addFormData.permissions,
            status1: addFormData.status1,
            status2: addFormData.status2

        };

        const newContacts = [...contacts, newContact];

        setContacts(newContacts);
    };
    const handleEditFormSubmit = (event) => {
        event.preventDefault();

        const editedContact = {
            code: editFormData.code,
            name: editFormData.name,
            role: editFormData.role,
            date: editFormData.date,
            permissions: editFormData.permissions,
            staus1: editFormData.status1,
            status2: editFormData.status2

        };
        const newContacts = [...contacts];

        const index = contacts.findIndex((contact) => contact.id === editContactId);

        newContacts[index] = editedContact;

        setContacts(newContacts);
        setEditContactId(null);
    };

    const handleEditClick = (event, contact) => {
        event.preventDefault();
        setEditContactId(contact.code);

        const formValues = {
            code: contact.code,
            name: contact.name,
            role: contact.role,
            date: contact.date,
            permissions: contact.permissions,
            status1: contact.status1,
            status2: contact.status2
        };

        setEditFormData(formValues);
    };
    const handleCancelClick = () => {
        setEditContactId(null);
    };

    const handleDeleteClick = (contactId) => {
        const newContacts = [...contacts];

        const index = contacts.findIndex((contact) => contact.code === contactId);

        newContacts.splice(index, 1);

        setContacts(newContacts);
    };

    return (
        <div className="ListMembers">
            {/* <!-- Top Bar --> */}
            <div class="container-fluid1 py-2 icueTopBarContainer bg-white">

                <div class="row">
                    <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                        <div class="topBarLfContainer d-flex">
                            <div class="icueLogoContainer">
                                <img src="images/logo.png" alt="ICue Logo" class="icueLogo" />
                            </div>
                            <div class="icueSearchBox w-75 mx-3">
                                <div class="input-group ">
                                    <span class="input-group-text" id="basic-addon1">
                                        <i class="bi bi-search"></i>
                                    </span>
                                    <input type="search" class="form-control icueSearchBoxControl" placeholder="Enter keywords..." aria-label="Username" aria-describedby="basic-addon1" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                        <div class="d-flex flex-row-reverse">
                            <div class="icueProfile px-2">
                                <img src="images/avatar.png" alt="icueProfilePic" class="icueProfilePic border border-dark rounded-circle" />
                            </div>
                            <div class="position-relative px-2">
                                <a href="#" id="icueNotifyBoxHandler">
                                    <i class="bi bi-bell">
                                        <span class="position-absolute icueNofIndic"></span>
                                    </i>
                                </a>
                                <div class="icueNotifyBox" id="icueNotifyBox">
                                    Notifications
                                </div>
                            </div>

                            <div class="icueUserName fs-6 px-2">
                                Viral Patel
                            </div>

                            <div class="icueMail px-2">
                                <i class="bi bi-headset"></i> &nbsp;
                                adminhelpdesk@gmail.com
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- /Top Bar -->

    <!-- Main Body --> */}
            <div class="container-fluid my-2">
                <div class="row">
                    <div class="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12 bg-white">
                        <div class="icueRespSideBar d-xxl-none d-xl-none d-lg-none d-md-block d-sm-block d-block" id="icueRespSideBar">
                            <button type="button" class="btn btn-primary" id="icueRespHumburger">
                                <i class="bi bi-grid-3x3-gap"></i>
                            </button>
                            <div class="icueSideBar" id="icueSideBar">
                                <nav class="navbar icueSideNavContainer">
                                    <ul class="icueNavBarList">
                                        <li class="icueNavBarItem ">
                                            <a href="/Hrdashboard" class="icueNavBarLink" id="">Dashboard</a>
                                        </li>
                                        <li class="icueNavBarItem border-bottom-0">
                                            <a href="#" class="icueNavBarLink" id="">User Management</a>
                                        </li>

                                        <li>
                                            <ul class="icueSubNavBar">
                                                <li class="icueSubNavItem">
                                                    <a href="#" class="icueSubNavLink  text-capitalize">Add Members</a>
                                                </li>
                                                <li

                                                    class="active">
                                                    <ul class="icueSubNavBar">
                                                        <li class="icueSubNavItem" style={{ marginLeft: "-20px" }}>
                                                            <a href="/ListMembers" class="icueSubNavLink text-capitalize">List of Members</a>
                                                        </li>
                                                    </ul>
                                                </li>


                                            </ul>
                                        </li>
                                        <hr></hr>

                                        <li class="icueNavBarItem border-bottom-0">
                                            <a href="#" class="icueNavBarLink" id="">Leave Management</a>
                                        </li>

                                        <li>
                                            <ul class="icueSubNavBar">
                                                <li class="icueSubNavItem">

                                                    <a href="/Leaves" class="icueSubNavLink  text-capitalize">Leaves</a>
                                                </li>
                                                <li class="icueSubNavItem">
                                                    <a href="/LeaveType" class="icueSubNavLink  text-capitalize">Leave Type</a>
                                                </li>
                                            </ul>
                                        </li>
                                        <hr></hr>
                                        <li class="icueNavBarItem border-bottom-1">
                                            <a href="/Holidays" class="icueNavBarLink" id="">Holidays</a>
                                        </li>
                                        <li class="icueNavBarItem border-bottom-1">
                                            <a href="#" class="icueNavBarLink" id="">Attendence</a>
                                        </li>



                                        <li class="icueNavBarItem">
                                            <a href="/Department" class="icueNavBarLink" id="">Department</a>
                                        </li>

                                        <li class="icueNavBarItem">
                                            <a href="/AddRoles" class="icueNavBarLink" id="">Roles</a>
                                        </li>

                                        <li class="icueNavBarItem border-bottom-0">
                                            <a href="#" class="icueNavBarLink" id="">Payroll</a>
                                        </li>
                                        <li>
                                            <ul class="icueSubNavBar">
                                                <li class="icueSubNavItem">
                                                    <a href="/EmpSalary" class="icueSubNavLink  text-capitalize">Employee Salary</a>
                                                </li>
                                                <li class="icueSubNavItem">
                                                    <a href="/Payitems" class="icueSubNavLink  text-capitalize">Payroll Items</a>
                                                </li>
                                            </ul>
                                            <hr></hr>
                                        </li>

                                        <li class="icueNavBarItem border-bottom-0" style={{ marginTop: "-18px" }}>
                                            <a href="#" class="icueNavBarLink" id="">Settings</a>
                                        </li>
                                        <li>
                                            <ul class="icueSubNavBar">
                                                <li class="icueSubNavItem">
                                                    <a href="#" class="icueSubNavLink  text-capitalize">Change Password</a>
                                                </li>
                                                <li class="icueSubNavItem">
                                                    <a href="#" class="icueSubNavLink  text-capitalize">Profile</a>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>

                        <div class="icueNavSideBar d-xxl-block d-xl-block d-lg-block d-md-none d-sm-none d-none" id="icueNavSideBar">
                            <nav>
                                <ul class="icueNavBarList">
                                    <li class="icueNavBarItem">
                                        <a href="/Hrdashboard" class="icueNavBarLink" id="">Dashboard</a>
                                    </li>

                                    <li class="icueNavBarItem border-bottom-0">
                                        <a href="#" class="icueNavBarLink" id="">User Management</a>
                                    </li>

                                    <li>
                                        <ul class="icueSubNavBar">
                                            <li class="icueSubNavItem" style={{ marginTop: "-8px" }}>
                                                <a href="#" class="icueSubNavLink  text-capitalize">Add Member</a>
                                            </li>
                                        </ul>
                                    </li>

                                    <li className="active">
                                        <ul class="icueSubNavBar">
                                            <li class="icueSubNavItem" style={{ marginTop: "2px", marginLeft: "-20px" }}>
                                                <a href="/ListMembers" class="icueSubNavLink  text-capitalize">List Of Members</a>
                                            </li>
                                        </ul>
                                    </li>
                                    <hr></hr>

                                    <li class="icueNavBarItem border-bottom-0" style={{ marginTop: "-18px" }}>
                                        <a href="#" class="icueNavBarLink" id="" >Leave Management</a>
                                    </li>

                                    <li>
                                        <ul class="icueSubNavBar ">
                                            <li class="icueSubNavItem" style={{ marginTop: "-8px" }}>
                                                <a href="/Leaves" class="icueSubNavLink  text-capitalize">Leaves</a>
                                            </li>
                                            <li class="icueSubNavItem">
                                                <a href="/LeaveType" class="icueSubNavLink  text-capitalize">Leave Type</a>
                                            </li>
                                        </ul>
                                        <hr></hr>
                                    </li>

                                    <li class="icueNavBarItem " style={{ marginTop: "-18px" }}>
                                        <a href="/Holidays" class="icueNavBarLink" id="">Holidays</a>
                                    </li>
                                    <li class="icueNavBarItem ">
                                        <a href="#" class="icueNavBarLink" id="">Attendence</a>
                                    </li>




                                    <li class="icueNavBarItem" >
                                        <a href="/Department" class="icueNavBarLink" id="">Department</a>
                                    </li>

                                    <li class="icueNavBarItem">
                                        <a href="/AddRoles" class="icueNavBarLink" id="">Roles</a>
                                    </li>


                                    <li class="icueNavBarItem border-bottom-0">
                                        <a href="#" class="icueNavBarLink" id="">Payroll</a>
                                    </li>
                                    <li>
                                        <ul class="icueSubNavBar">
                                            <li class="icueSubNavItem">
                                                <a href="/EmpSalary" class="icueSubNavLink  text-capitalize">Employee Salary</a>
                                            </li>
                                            <li class="icueSubNavItem">
                                                <a href="/Payitems" class="icueSubNavLink  text-capitalize">Payroll Items</a>
                                            </li>
                                        </ul>
                                        <hr></hr>
                                    </li>

                                    <li class="icueNavBarItem border-bottom-0" style={{ marginTop: "-18px" }}>
                                        <a href="#" class="icueNavBarLink" id="">Settings</a>
                                    </li>
                                    <li>
                                        <ul class="icueSubNavBar">
                                            <li class="icueSubNavItem">
                                                <a href="#" class="icueSubNavLink  text-capitalize">Change Password</a>
                                            </li>
                                            <li class="icueSubNavItem">
                                                <a href="#" class="icueSubNavLink  text-capitalize">Profile</a>
                                            </li>
                                        </ul>
                                        <hr></hr>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>

                    <div class="col-xxl-10 col-xl-10 col-lg-10 col-md-12 col-sm-12 col-12 ">

                        <main class="mainBodyContainer">
                            <div class="row my-2">
                                <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 py-2 px-3">
                                    <div class="d-flex justify-content-between">
                                        <h4 class="text-captalize" style={{ fontFamily: "ErasDemiITC", fontWeight: "bold", marginTop: "1rem", marginLeft: "12px" }}>List of Members</h4>
                                        <div class="bg-transparent p-2  rounded-3">

                                            <nav style={{ bsbreadcrumbdivider: 'url(&#34;"data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="8" height="8"%3E%3Cpath d="M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z" fill="currentColor"/%3E%3C/svg%3E&#34")' }} aria-label="breadcrumb">
                                                <ol class="breadcrumb mb-0 justify-content-end">
                                                    <li class="breadcrumb-item bg-transparent" style={{ fontFamily: "Calibri", fontSize: "bold" }}><a href="/Superdashboard" class="text-decoration-none text-info">Dashboard</a></li>
                                                    <li class="breadcrumb-item bg-transparent" style={{ fontFamily: "Calibri" }}><a href="/lms" class="text-decoration-none text-info">User Mangement</a></li>
                                                    <li class="breadcrumb-item text-muted text-capitalize bg-transparent" aria-current="page" style={{ fontSize: "bold" }}>List of Members</li>
                                                </ol>
                                            </nav>
                                        </div>
                                    </div>
                                </div>

                                <div className="row my-2">
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-12 py-2 px-2">
                                        <div className="bg-white p-2  rounded-3 shadow-sm" style={{ marginLeft: "0.8rem", marginRight: "0.8rem" }}>
                                            <div className="px-1 py-3">
                                                <div class="container1">
                                                    <div className="row">
                                                        <div class=" col-xs-12 bg-white ">
                                                            <div class="col-xl-9 col-lg-12 col-md-12 col-sm-12">
                                                                <div class="icueSearchBox w-85 mx-3" style={{ marginTop: "-20px" }} >
                                                                    <div class="input-group " style={{ marginLeft: "-20px" }}>
                                                                        <input type="search" class="search form-control icueSearchBoxControl" placeholder="Search Users by Name, Email or Date" aria-label="Username" aria-describedby="basic-addon1" style={{ backgroundColor: "#F4F7FC" }} />
                                                                        <div class="col-xl-3 col-lg-12 col-md-12 col-sm-12">
                                                                            <a href="" type="button" class=" icueBgPrimary text-white pt-2 " data-bs-toggle="modal" data-bs-target="#addid" style={{ marginLeft: "12.5rem", width: "7.5rem", textAlign: "center", fontSize: "0.75rem", marginTop: "0.8rem", display: "inline-block", height: "35px" }}><i class="bi bi-plus-circle"></i> &nbsp;Add Member</a>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <form id="icueHrInfoForm">
                                                        <table id="icueHrTable" className="table results table-striped table-hover" style={{ width: "100%", border: "0", marginLeft: "-12px" }}>
                                                            <thead>
                                                                <tr className="text-uppercase" style={{ lineHeight: "15px", fontFamily: "Calibri", fontSize: "12px", backgroundColor: "#F4F7FC" }}>
                                                                    <th class="text-center py-3" >ID</th>
                                                                    <th class="text-center py-3">NAME</th>
                                                                    <th class="text-center py-3" >ROLE</th>
                                                                    <th class="text-center py-3">JOINED ON</th>
                                                                    <th class="text-center py-3">PERMISSIONS</th>
                                                                    <th class="text-center py-3">STATUS</th>
                                                                    <th class="text-center py-3">ACTIONS</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody style={{ lineHeight: "15px", fontFamily: "Calibri", fontSize: "12px", textAlign: "center" }}>

                                                                {contacts.map((contact) => (
                                                                    <Fragment>
                                                                        {editContactId === contact.code ? (
                                                                            <EditableRow
                                                                                editFormData={editFormData}
                                                                                handleEditFormChange={handleEditFormChange}
                                                                                handleCancelClick={handleCancelClick}
                                                                            />
                                                                        ) : (
                                                                            <ReadOnlyRow
                                                                                contact={contact}
                                                                                handleEditClick={handleEditClick}
                                                                                handleDeleteClick={handleDeleteClick}
                                                                            />
                                                                        )}
                                                                    </Fragment>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                {/* <!-- Add Details Modal --> */}
                                <div class="modal" id="addid" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                    <div class="modal-dialog  modal-dialog-centered">
                                        <div class="modal-content rounded-3">
                                            <div class="modal-header d-flex flex-column" id="addModalHeader">
                                                <div class="text-end w-100" >
                                                    <button type="button" class="btn text-muted " data-bs-dismiss="modal" ><i class="bi bi-x-circle" style={{ color: "white" }}></i></button>
                                                </div>
                                                <div class="text-center">
                                                    <h4 class="modal-title">Add Member</h4>
                                                    <p class="text-muted">Please add a valid email</p>
                                                </div>
                                            </div>
                                            <div class="modal-body" id="addModalBody">
                                                <form class="">
                                                    <div class="row">
                                                        <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                                                            <div class="form-group pb-2">
                                                                <input class="form-control" type="text" name="icueAddMemEmail" onChange={handleAddFormChange} placeholder="Enter Email" />
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                                                            <div class="form-group pb-2">
                                                                <input class="form-control" type="text" name="icueAddMemEmpCode" placeholder="Employee Code" />
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                                                            <div class="form-group pb-2">
                                                                <select class="form-select text-muted" aria-label="icueAddDepartment">
                                                                    <option>Department</option>
                                                                    <option value="marketing">Marketing</option>
                                                                    <option value="operations">Operations</option>
                                                                    <option value="finance">Finance</option>
                                                                    <option value="sales">Sales</option>
                                                                    <option value="it">IT</option>
                                                                    <option value="human resource">Human Resource</option>
                                                                    <option value="purchase">Purchase</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                                                            <div class="form-group pb-2">
                                                                <select class="form-select text-muted" aria-label="icueAddRole">
                                                                    <option>Role</option>
                                                                    <option value="1">Member</option>
                                                                    <option value="2">Team Lead</option>
                                                                    <option value="3">Manager</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                                                            <span class="text-muted">Permissions: </span>
                                                        </div>
                                                        <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                                                            <div class="form-check pb-2">
                                                                <input class="form-check-input" type="checkbox" id="icueAddNotifyNewUserByMail" checked />
                                                                <label class="form-check-label text-muted" for="icueNotifyNewUserByMail">
                                                                    Notify new users by email
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                                                            <div class="form-check pb-2">
                                                                <input class="form-check-input" type="checkbox" id="icueAddCanManageUsers" />
                                                                <label class="form-check-label text-muted" for="icueCanManageUsers">
                                                                    Can manage users
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                                                            <div class="form-check pb-2">
                                                                <input class="form-check-input" type="checkbox" id="icueAddClaimManag" />
                                                                <label class="form-check-label text-muted" for="icueClaimManag">
                                                                    Claim management
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                                                            <div class="form-check pb-2">
                                                                <input class="form-check-input" type="checkbox" id="icueAddPolicyManag" />
                                                                <label class="form-check-label text-muted" for="icuePolicyManag">
                                                                    Policy management
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                                                            <div class="form-check pb-2">
                                                                <input class="form-check-input" type="checkbox" id="icueAddAccManag" />
                                                                <label class="form-check-label text-muted" for="icueAccManag">
                                                                    Account management
                                                                </label>
                                                            </div>
                                                        </div>

                                                        <div class="col-12 py-3">
                                                            <div class="d-grid gap-2">
                                                                <button type="button" class="btn btn-primary" data-dismiss="modal">
                                                                    Send Invite
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- /Add Details Modal -->

<!-- Edit Details Modal --> */}
                                <div class="modal fade" id="id" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                    <div class="modal-dialog  modal-dialog-centered">
                                        <div class="modal-content rounded-3">
                                            <div class="modal-header d-flex flex-column" id="EditModalHeader">
                                                <div class="text-end w-100">
                                                    <button type="button" class="btn text-muted" data-bs-dismiss="modal"><i class="bi bi-x-circle"></i></button>
                                                </div>
                                                <div class="text-center">
                                                    <h4 class="modal-title">Edit Member</h4>
                                                    <p class="text-muted"></p>
                                                </div>
                                            </div>
                                            <div class="modal-body" id="editModalBody">
                                                <form class="">
                                                    <div class="row">
                                                        <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                                                            <div class="form-group pb-2">
                                                                <input class="form-control" type="text" name="icueEditMemEmail" placeholder="Enter Email" />
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                                                            <div class="form-group pb-2">
                                                                <input class="form-control" type="text" name="icueEditMemEmpCode" placeholder="Employee Code" />
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                                                            <div class="form-group pb-2">
                                                                <select class="form-select text-muted" aria-label="icueEditDepartment">
                                                                    <option>Department</option>
                                                                    <option value="marketing">Marketing</option>
                                                                    <option value="operations">Operations</option>
                                                                    <option value="finance">Finance</option>
                                                                    <option value="sales">Sales</option>
                                                                    <option value="it">IT</option>
                                                                    <option value="human resource">Human Resource</option>
                                                                    <option value="purchase">Purchase</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                                                            <div class="form-group pb-2">
                                                                <select class="form-select text-muted" aria-label="icueEditRole">
                                                                    <option>Role</option>
                                                                    <option value="1">Member</option>
                                                                    <option value="2">Team Lead</option>
                                                                    <option value="3">Manager</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                                                            <span class="text-muted">Permissions: </span>
                                                        </div>
                                                        <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                                                            <div class="form-check pb-2">
                                                                <input class="form-check-input" type="checkbox" id="icueEditNotifyNewUserByMail" checked />
                                                                <label class="form-check-label text-muted" for="icueEditNotifyNewUserByMail">
                                                                    Notify new users by email
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                                                            <div class="form-check pb-2">
                                                                <input class="form-check-input" type="checkbox" id="icueEditCanManageUsers" />
                                                                <label class="form-check-label text-muted" for="icueEditCanManageUsers">
                                                                    Can manage users
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                                                            <div class="form-check pb-2">
                                                                <input class="form-check-input" type="checkbox" id="icueEditClaimManag" />
                                                                <label class="form-check-label text-muted" for="icueClaimManag">
                                                                    Claim management
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                                                            <div class="form-check pb-2">
                                                                <input class="form-check-input" type="checkbox" id="icueEditPolicyManag" />
                                                                <label class="form-check-label text-muted" for="icuePolicyManag">
                                                                    Policy management
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                                                            <div class="form-check pb-2">
                                                                <input class="form-check-input" type="checkbox" id="icueEditAccManag" />
                                                                <label class="form-check-label text-muted" for="icueAccManag">
                                                                    Account management
                                                                </label>
                                                            </div>
                                                        </div>

                                                        <div class="col-12 py-3">
                                                            <div class="d-grid gap-2">
                                                                <button type="button" class="btn btn-primary btnIcon">
                                                                    Save
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- /Edit Details Modal -->

<!-- Delete Details Modal --> */}
                                <div class="modal fade" id="deleteid" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                    <div class="modal-dialog modal-dialog-centered">
                                        <div class="modal-content  rounded-3">
                                            <div class="modal-header d-flex flex-column" id="deleteModalHeader">
                                                <div class="text-end w-100">
                                                    <button type="button" class="btn text-muted" data-bs-dismiss="modal"><i class="bi bi-x-circle"></i></button>
                                                </div>
                                                <div class="text-center">
                                                    <h4 class="modal-title">Delete Member</h4>
                                                </div>
                                            </div>
                                            <div class="modal-body" id="deleteModalBody">
                                                <form class="">
                                                    <div class="row">
                                                        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                                                            <div class="form-group">
                                                                <h4 class="text-danger text-center">Are you sure, delete this row</h4>
                                                            </div>
                                                        </div>

                                                        <div class="col-12">
                                                            <div class="text-center">
                                                                <button type="button" class="btn btn-danger">Delete</button>
                                                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- /Delete Details Modal --> */}


                        </main>
                    </div>
                </div>
            </div >
        </div >


    )
}