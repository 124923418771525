import React, { useRef, useState, useEffect } from 'react'
import '../../css/new_car_step1.css'
import Footer from '../ReusableComponents/Footer'
import NavBar from '../ReusableComponents/NavBar'
import gimg from '../HomePage/Group 154.png'
import { useHistory } from 'react-router-dom';
import './LifeInsurance.css';
import SubmitQuery from '../../services/insurance-query.service'
import Accordion from 'react-bootstrap/Accordion';
import { Modal } from 'react-bootstrap'
import { Model } from '../ReusableComponents/Modal'
import TermsUsage from '../OtherInsurance/TermsUsage'
import Terms from '../ReusableComponents/Terms'

export default function LifeInsurance() {
    const [checked, setChecked] = useState(true);
    const history = useHistory();
    const [show, setShow] = useState(false);
    const ShowModal = () => setShow(true);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    let car_insurance = useRef();
    let topic1 = useRef();
    let addOn = useRef();
    let faqs = useRef();

    const [showModal, setModalShow] = useState(false);
    const handleCloseModal = () => setModalShow(false);
    const handleShowModal = () => setModalShow(true);

    const sectionContent = {
        textDecoration: "none",
        fontSize: 14,
    }
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
        return () => {
        }
    }, [])

    const initialValues = { firstName: "", email: "", lastName: "", mobile: "", isChecked: true };
    const [formValues, setFormValues] = useState(initialValues);
    const [formErrors, setFormErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setFormErrors(validate(formValues));
        setIsSubmit(true);
        if (true) {
            console.log(formValues);
            SubmitQuery(formValues.firstName, formValues.lastName, formValues.mobile, formValues.email, "termlife/purchaseLifeInsurance")
                .then(response => response.json())
                .then(async response => {
                    console.log(JSON.stringify(response));
                    if (response.status == 1) {
                        await handleShow();
                        setFormValues(initialValues);

                    } else if (response.status == 0) {
                        alert(response.message);
                    }
                });
        }
    };

    useEffect(() => {
        console.log(formErrors);
        if (Object.keys(formErrors).length === 0 && isSubmit) {
            console.log(formValues);
        }
    }, [formErrors]);

    const validate = (values) => {
        const errors = {};
        const email_regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        const fname_regex = /^[a-zA-Z ]+$/;
        const lname_regex = /([a-zA-Z]{1,30})$/;
        const mobile_regex = /^[0-9\b]+$/;


        if (!values.firstName) {
            errors.firstName = "First Name is Required!";
        } else if (!fname_regex.test(values.firstName)) {
            errors.firstName = "Enter Valid First Name!";
        }
        // if (!values.lastName) {
        //   errors.lastName = "Last Name is Required!";
        // } else if (!lname_regex.test(values.lastName)) {
        //   errors.lastName = "Enter Valid Last Name!";
        // }
        if (!values.email) {
            errors.email = "Email is Required!";
        } else if (!email_regex.test(values.email)) {
            errors.email = "Enter Valid Email!";
        }
        if (!values.mobile) {
            errors.mobile = "Mobile No. is Required";
        } else if (values.mobile.length < 10) {
            errors.mobile = "Mobile No. must be 10 Digits";
        }
        else if (!mobile_regex.test(values.mobile)) {
            errors.mobile = "Enter valid Mobile No.";
        }
        if (!values.isChecked) {
            errors.isChecked = "Kindly accepts the terms and conditions to submit";
        }
        return errors;
    };


    return (
        <div className='main-div'>
            <NavBar />
            <br />  
            <br />
            <br />
            <div class='car-insurance-top'>
                <br />
                <div class="container" >
                    <div class="row my-2 form-container">
                        <div class="col-lg-8" >
                            <div class='row' style={{ marginTop: '20px', marginLeft: '1.25rem', paddingTop: '10px' }}>
                                <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 py-1 px-3" >
                                    <div class="bg-white p-2" >
                                        <div class="px-2 py-3">
                                            <div class="container" style={{ Shadow: "0px 30px 50px rgb(0 0 0 / 10%)" }}>
                                                <div class="row" style={{ marginTop: "-30px", marginLeft: "-70px" }}></div>
                                                <form onSubmit={handleSubmit}>
                                                    <h2 class="icueTextPrimary">Term Life Insurance </h2>
                                                    <p>Secure your Life with best Term Life plans offered by insurers.</p> <br />
                                                    <div class="step2-content col-lg-5 col-md-7" style={{marginLeft: '-3rem'}}>
                                                        <div class='col-lg-10'>
                                                            <div class='d-sm-flex'>
                                                                <div class="car3-form-groupp mt-3 mb-2">
                                                                    <input
                                                                        class="car2-form-controll"
                                                                        id="firstName"
                                                                        type="text"
                                                                        name="firstName"
                                                                        value={formValues.firstName}
                                                                        onChange={handleChange}
                                                                        style={{ marginLeft: '20px', marginRight: '20px', textTransform: "capitalize" }}
                                                                    />
                                                                    <label class="ms-3 car2-form-control-placeholderr" for="firstName">First Name</label>
                                                                    <p className='p-error'>{formErrors.firstName}</p>
                                                                </div>
                                                                <div class="car3-form-groupp mt-3 mb-2">

                                                                    <input
                                                                        class="car2-form-controll"
                                                                        id="lastName"
                                                                        type="text"
                                                                        name="lastName"
                                                                        value={formValues.lastName}
                                                                        onChange={handleChange}
                                                                        style={{ marginLeft: '20px', marginRight: '20px', textTransform: "capitalize" }}
                                                                    />
                                                                    <label class="ms-3 car2-form-control-placeholderr" for="lastName">Last Name</label>

                                                                    {/* <p className='p-error'>{formErrors.lastName}</p> */}
                                                                </div>

                                                            </div>
                                                            <div class='d-sm-flex'>
                                                                <div class="car3-form-groupp mt-3 mb-2">

                                                                    <input
                                                                        class="car2-form-controll"
                                                                        id="mobile"
                                                                        type="text"
                                                                        name="mobile"
                                                                        value={formValues.mobile}
                                                                        onChange={handleChange}
                                                                        maxLength={10}
                                                                        style={{ marginLeft: '20px', marginRight: '20px', textTransform: "lowercase" }}
                                                                    />
                                                                    <label class="ms-3 car2-form-control-placeholderr" for="mobile">Mobile</label>

                                                                    <p className='p-error'>{formErrors.mobile}</p>
                                                                </div>
                                                                <div class="car3-form-groupp mt-3 mb-2">
                                                                    <input
                                                                        id="email"
                                                                        class="car2-form-controll"
                                                                        type="text"
                                                                        name="email"
                                                                        value={formValues.email}
                                                                        onChange={handleChange}
                                                                        style={{ marginLeft: '20px', marginRight: '20px', textTransform: "lowercase" }}
                                                                    />
                                                                    <label class="ms-3 car2-form-control-placeholderr" for="email">Email</label>

                                                                    <p className='p-error'>{formErrors.email}</p>
                                                                </div>
                                                            </div>
                                                            {/* <button className="fluid ui button blue">Submit</button> */}
                                                        </div>
                                                    </div>

                                                    <br />

                                                    <div className='privacypolicy-chechbox' >
                                                        <input class='acceptTandC' style={{ width: '20px' }} type='checkbox' defaultChecked={formValues.isChecked} onChange={() => { setChecked(!checked); setFormValues({ ...formValues, 'isChecked': !formValues.isChecked }); }} /><a href="javascript:void(0)"> I/We accept the <span className='link-terms'

                                                            onClick={handleShowModal}

                                                        >Terms and conditions</span> </a>

                                                        <Model show={showModal} onHide={handleCloseModal}>
                                                            <Terms />
                                                        </Model>

                                                        <p className='p-error'>{formErrors.isChecked}</p>
                                                        <br />
                                                        <span style={{
                                                            fontSize: '0.9rem',
                                                            fontFamily: 'Calibri',
                                                            paddingTop: '1rem !important'
                                                        }}>(You authorise PoliciCue Insurance Brokers Pvt Ltd (PoliciCue) to contact you through call, SMS, email, WhatsApp or any other mode in future.
                                                            You hereby override your NDNC registration.)
                                                        </span>
                                                        {/* <input class='acceptTandC' style={{ width: '20px' }} type='checkbox' defaultChecked={checked} onChange={() => setChecked(!checked)} /><a href="/LegalAdminPolicies"> I/We accept the <span className='link-terms' onClick={() => history.push("/LegalAdminPolicies")}>Terms and conditions</span> </a> */}
                                                    </div>
                                                    <div class='step2-car-nextbtn ' style={{ marginLeft: "-55px", lineHeight: "20px", height: "40px" }}><button class='' type='submit'>Submit</button></div>
                                                </form>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4" style={{ backgroundColor: "#F4F7FC", display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                            <img src='./images/GMI2.png' className='car-step1-img' ></img>
                        </div>
                    </div>
                </div>
            </div>

            <div ref={car_insurance} class="car-insurance">
            <div class="container" style={{maxWidth: '80%'}}>
                    <div class="d-sm-flex align-items-center" style={{maxWidth: '90%'}}>
                        <div class="col-lg-9">
                            <div class="text-container">
                                <h2 class="h2-header" >What is Term Life  Insurance ?</h2>
                                <p class="paragraph-header ">Term insurance is a Pure Life insurance which offers high amount of cover at lowest possible rate.It <br /> offers the financial coverage to the Policy holder for specific period against a small premium paid, in <br /> case the death of the insured individual during the policy period the benefit in term of Sum Assured is <br />  paid to the family or nominee under the policy.It helps the family to remain financially independent <br /> and family immediate needs are taken care of by the Sum provided.

                                    <br />
                                    <br />
                                    Due to some unforeseen event with insured before the end of the Policy Term, the relevant Sum <br /> Assured will be paid to the beneficiary under the Policy


                                    <br />
                                    <br />
                                    The insured bears affordable premium rates for the chosen coverage period.The frequency to pay the <br /> premiums (monthly, quarterly or annually) depends on what the insured has opted for.<br />
                                    Under the Term plans there are single life and joint life term plans.In joint term plans, the insured and <br /> his/her spouse are covered and in case of death of either, the death sum assured for the deceased will <br /> be paid to the surviving member.In case of a sudden death of the couple, the entire amount assured <br /> will be paid to the beneficiary mentioned <br />
                                    There are options which allow for return of all premiums paid, in case the insured survives to the end <br /> of coverage term.

                                    <br />
                                    <br />

                                    Insurers now a days along with the Pure Term Insurance has started offering some riders which <br /> increases the benefit under the policy for beneficiaries.

                                    <br />
                                    <br />
                                    <br />


                                    <h2>Riders can be: </h2>
                                </p>

                                <div class="row">

                                    <div class="col-lg-8 col-sm-4">
                                        <p className="paragraph-header">
                                            Critical Illness Cover <br />
                                            Waiver of Premium Cover  <br />
                                            Accidental death benefit  <br />
                                            Survival Benefit  <br />
                                            Better Half Benefit  <br />
                                            To up Benefit  <br />
                                            Life Stage Benefit  <br />
                                            Pay-out Options  <br />

                                        </p>
                                    </div>
                                    <div class="col-lg-4 col-sm-8 mobile">
                                        <img className="textPrimary" style={{ marginTop: -80 }} src='./images/im2 4.png'></img>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-9" style={{ marginLeft: "-120px" }}>
                                <h2 class="h2-header1 textSecondary">Why Term Insurance is needed?</h2>
                            </div>
                            <div class="col-lg-12" style={{ marginLeft: "-120px" }}>
                                <p className="paragraph-header1 textSecondary text " style={{marginLeft:"6rem"}}>
                                    In case of any eventuality or death Insured has many a liability behind to meet towards the family. <br />Term Insurance Plan provide large life insurance cover at affordable prices <br />
                                    Term Insurance Plan helps to protect your loved ones from any unforeseen eventuality <br />
                                    They can cover your financial liabilities Provides supplementary income in case of loss of income due  to accidental disabilities or illness.
                                </p>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
            {/* <!-- end of car-insurance -->
<!-- end of details 2 --> */}


            {/* <!------------------------addons-----------------------------------------> */}
            {/* <!-- Basic --> */}
            <div class="ex-basic-1 pt-5 pb-5">

                <div class="container custom-extend">
                    <div ref={addOn} class="container">

                        <h2 class="h2-header2 ml-8" style={{ marginLeft: "3rem" }}>How to Choose a Term Insurance Plan?</h2>

                        <p className="paragraph-header1" style={{ marginLeft: "3rem" }}>
                            While Term Insurance is the most economical form of Insurance which gives adequate protection in term of financial need at the time of unforeseen <br /> eventuality, it is advisable to take note of some points while buying the Term Insurance.Points to take note of are:

                            <br />
                            <br />
                            Insurer reputation in terms of *Claim Settlement Ratio & *Solvency Ratio<br /><br />
                            Options of Rider & Coverage under the Policy been offered by insurer <br /><br />
                            Stage of life you are in (to ascertain the liability) like- <br /><br />

                            Unmarried<br /><br />
                            Married without Children<br /><br />
                            Married & Having Children<br /><br />
                            Nearing Retirement<br /><br />

                            These stages have your various need and accordingly the Basic Term Plan & Riders can be decided by you according to your need.<br />
                            PoliciCue platform provide the best comparison and offer the policy which suits you the most at your convenience<br />
                        </p>


                        <div ref={topic1} class="d-sm-flex align-items-center textPrimary row">
                            <div class="col-lg-6 col-sm-12" style={{ marginTop: "3rem" }}>
                                <h2 class="h2-header ml-8" style={{ marginLeft: "1.5rem" }}>What is the benefit of buying the Term Insurance Online?</h2>
                                <p className="paragraph-header1" style={{ marginTop: "1rem", marginLeft: '1.5rem' }}>
                                    It is very cost effective as you get the option of comparison <br />
                                    It is most convenient way to buy with ease of your fingertips. <br />
                                    You can customise the benefit of insurance and compare the insurers plan as per your need.<br />
                                    It is environment friendly as you do not use any paper work and prevent paper waste.<br />
                                </p>
                            </div>
                            <div class="col-lg-6 col-sm-12">
                                <img  src="images/Illustration3.png" alt="alternative" style={{width:"100%"}}/>
                            </div>
                        </div>
                       


                    </div>
                </div>
            </div>
            {/* <!-- end of basic -->
<!-- end addons --> */}




            <div ref={faqs} class="faqs">
                <div class="container">
                    <div class="row">
                        <div class="col-align">
                            <h2 class="h2-heading-faqs">Insurance FAQ’s</h2>
                            <p class="p-heading-faqs">If You Are Looking To Buy Insurance You  Ought to Check Below Frequently Asked Questions</p>
                        </div>
                    </div>
                </div>

                <div class="container">
                    <div class="row">
                        <Accordion>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header className='accordion-header-1'>
                                    <img src="./images/Rectangle 22.svg" alt="" />&nbsp;&nbsp;&nbsp;&nbsp; <p className='faqText'>Who all can take Term Insurance?</p></Accordion.Header>
                                <Accordion.Body className='accordion-header-1'>
                                    <p class="p-faqs-para">
                                        As mentioned above at various life stages you can buy the term insurance and people like Parents/Young Professionals/Newly Married/People Nearing Retirement or Retirees can have the benefit of the term insurance.   </p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header className='accordion-header-2'> <img src="./images/Rectangle 22.svg" alt="" />&nbsp;&nbsp;&nbsp;&nbsp; <p className="faqText">How much cover is enough in Term Insurance Plan?</p></Accordion.Header>
                                <Accordion.Body className='accordion-header-2'>
                                    <p class="p-faqs-para">
                                        Usually experts advise to have Sum Assured of 15 to 20 times of your annual income. It is generally understood that you need to calculate your liabilities and your future needs while deciding the Sum Assured. Like you may have your financial liability of Bank, Children Education, Children Marriage and other exigency needs. </p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                                <Accordion.Header className='accordion-header-1'>
                                    <img src="./images/Rectangle 22.svg" alt="" />&nbsp;&nbsp;&nbsp;&nbsp; <p className="faqText">What should be my Period of the Term Insurance Plan?</p></Accordion.Header>
                                <Accordion.Body className='accordion-header-1'>
                                    <p class="p-faqs-para">
                                        It is advisable to choose the longest policy tenure while comparing the policy premium & benefit. After all who don’t want to live the longest.  </p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="3">
                                <Accordion.Header className='accordion-header-2'> <img src="./images/Rectangle 22.svg" alt="" />&nbsp;&nbsp;&nbsp;&nbsp; <p className='faqText'> What is the difference between Term Plan & Traditional Life Insurance Plan?</p></Accordion.Header>
                                <Accordion.Body className='accordion-header-2'>
                                    <p class="p-faqs-para">
                                        Term plans provide financial security to the family in your absence, whereas, traditional plans are investment plans where you get returns against the premium paid. Term plan gives a large sum assured at affordable rates whereas traditional plans gives you limited cover. </p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="4">
                                <Accordion.Header className='accordion-header-1'>
                                    <img src="./images/Rectangle 22.svg" alt="" />&nbsp;&nbsp;&nbsp;&nbsp; <p className="faqText"> Why is the Term Insurance so economic?</p></Accordion.Header>
                                <Accordion.Body className='accordion-header-1'>
                                    <p class="p-faqs-para">
                                        A term plan is economical than a regular endowment plan because there are no returns associated with a term plan. The term plan’s sole purpose is to insure your life and not act as any investment tool. This makes plans more economical than the other kinds of life insurance products. </p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="5">
                                <Accordion.Header className='accordion-header-2'> <img src="./images/Rectangle 22.svg" alt="" />&nbsp;&nbsp;&nbsp;&nbsp; <p className='faqText'>What determines the cost of the term plan?</p></Accordion.Header>
                                <Accordion.Body className='accordion-header-2'>
                                    <p class="p-faqs-para">
                                        The major factors affecting the cost of a term plan are the sum assured and the duration. A plan with a higher sum assured will have a higher premium. Other factors impacting the cost include your age and lifestyle habits. A 2-year-old non-smoker will pay a lower premium for a plan as compared to a 4-year-old smoker. Finally, your health condition also matters. If you have any pre-existing illnesses, like hypertension or diabetes, you will have to pay more for your term plan. </p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="6">
                                <Accordion.Header className='accordion-header-1'>
                                    <img src="./images/Rectangle 22.svg" alt="" />&nbsp;&nbsp;&nbsp;&nbsp; <p className='faqText'> Are there any tax benefits available on term plans?</p></Accordion.Header>
                                <Accordion.Body className='accordion-header-1'>
                                    <p class="p-faqs-para">
                                        Yes. Premiums you pay towards the term plan are tax deductible under Section 80C of the Indian Income Tax Act. Any death benefit received from the policy is also tax deductible under Section 10(10) D. </p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="7">
                                <Accordion.Header className='accordion-header-2'> <img src="./images/Rectangle 22.svg" alt="" />&nbsp;&nbsp;&nbsp;&nbsp; <p className='faqText'>What is nomination? And who is a nominee?</p></Accordion.Header>
                                <Accordion.Body className='accordion-header-2'>
                                    <p class="p-faqs-para">
                                        Nomination is a right conferred on the life insurance policyholder to appoint a person or persons to receive the policy monies in the event of the policy becoming a claim by death. Any policyholder, who is a major and the life insured under a policy, can make a nomination. <br />
                                        A nominee is the person designated by the policyholder to receive the proceeds of an insurance policy, upon the death of the insured. </p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="8">
                                <Accordion.Header className='accordion-header-1'>
                                    <img src="./images/Rectangle 22.svg" alt="" />&nbsp;&nbsp;&nbsp;&nbsp; <p className='faqText'>What is the difference between nomination and assignment?</p></Accordion.Header>
                                <Accordion.Body className='accordion-header-1'>
                                    <p class="p-faqs-para">
                                        While nomination is an authorization to receive the policy monies in the event of death of the life assured, it does not give the nominee an absolute right over the money received to the exclusion of other legal heirs. Further, the nomination can be revoked or cancelled at any time during the lifetime of the policyholder at his will and pleasure or by a subsequent assignment. </p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="9">
                                <Accordion.Header className='accordion-header-2'> <img src="./images/Rectangle 22.svg" alt="" />&nbsp;&nbsp;&nbsp;&nbsp; <p className='faqText'>What is a Claim?</p></Accordion.Header>
                                <Accordion.Body className='accordion-header-2'>
                                    <p class="p-faqs-para">
                                        A claim is the payment made by the insurer to the insured or claimant on the occurrence of the event specified in the contract, in return for the premiums paid for the insured </p>
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="10">
                                <Accordion.Header className='accordion-header-1'> <img src="./images/Rectangle 22.svg" alt="" />&nbsp;&nbsp;&nbsp;&nbsp; <p className='faqText'>Who is entitled to receive the Claim benefit?</p></Accordion.Header>
                                <Accordion.Body className='accordion-header-1'>
                                    <p class="p-faqs-para">
                                        •	The nominee or appointee (in case of minor nominee) last recorded under the Policy in case of Policy on own life. </p>
                                    <p class="p-faqs-para">
                                        •	The proposer in case the Policy is not on own life. </p>
                                    <p class="p-faqs-para">
                                        •	Assignee in case the Policy was assigned.  </p>
                                    <p class="p-faqs-para">
                                        •	Life Assured himself in case of policy on own life for living benefit claims (Eg Critical Illness ride. </p>

                                    <h3 class="h3-faqs">Exclusion:</h3>
                                    <p class="p-faqs-para">
                                        The insurance claim is rejected by the insurance company if the death has occurred under abnormal circumstances. </p>
                                    <p class="p-faqs-para">

                                        In case the policy includes Accidental or Critical illness rider, the insurance claim is rejected if the death has occurred due to: </p>
                                    <p class="p-faqs-para">
                                        a) Accidental death because of driving under the influence of drugs or alcohol </p>
                                    <p class="p-faqs-para">
                                        b) Death as a result of participating in life-threatening activities like racing or trekking </p>
                                    <p class="p-faqs-para">
                                        c) Death due to any pre-existing medical condition not mentioned in the policy term </p>
                                    <p class="p-faqs-para">
                                        d) Death due to any illegal activity</p>
                                    <p class="p-faqs-para">
                                        Other exclusions for term insurance plans include:</p>
                                    <p class="p-faqs-para">
                                        a) Suicide within the first year of term policy tenure </p>
                                    <p class="p-faqs-para">
                                        b) Death due to sexually transmitted disease not mentioned in the policy term </p>
                                    <p class="p-faqs-para">
                                        c) Any self-inflicted injury resulting in death </p>
                                    <p class="p-faqs-para">
                                        d) Death due to the consumption of illegal narcotics or drugs</p>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>

                    </div>
                </div>
            </div>
            <Footer />
            <Modal show={show} onHide={handleClose}>

                <Modal.Body>
                    <div className='modal-block-close-btn'>
                        <button type="button" class="btn text-muted modal-btn-size" onClick={handleClose} ><i class="bi bi-x-circle fas fa-lg modal-btn-icon"></i></button>
                    </div>
                    <div className='modal-block'>
                        <img className="thankyou" src="images/thankyou.png" alt="alternative" style={{ width: "100px" }} />
                        <h2>Thank You!</h2>
                        <div class="mb-4">
                            <div class="d-flex justify-content-between" >
                                <label class="" style={{ textAlign: "center", fontFamily: "Calibri", color: "black" }}>
                                    Your query has been submitted successfully and our executive will contact you shortly.
                                </label>

                            </div>
                        </div>
                    </div>

                </Modal.Body>

            </Modal>
            <div class="modal fade " id="thankyouModal" tabindex="-1" aria-labelledby="thankyouModalLabel" aria-hidden="true" style={{ marginTop: "170px" }}>
                {/* <!-- <div class="modal-dialog modal-dialog-centered"> --> */}
                <div class="modal-dialog modal-sm rounded-circle" >
                    <div class="modal-content" style={{ borderRadius: "12px", width: "130%" }} >
                        {/* <div class="modal-header ">                */}
                        {/* <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" style={{marginLeft:"265px"}}></button> */}
                        <button type="button" class="btn text-muted" data-bs-dismiss="modal" style={{ marginLeft: "305px" }}><i class="bi bi-x-circle fas fa-lg"></i></button>
                        {/* </div> */}
                        <div class="modal-body " style={{ backgroundColor: "white" }}>
                            <div class="row">
                                <div class="col-lg-9 col-md-9 col-sm-12 col-12 mx-auto">
                                    <div class="form-group pb-2">

                                        <img className="thankyou" src="images/thankyou.png" alt="alternative" style={{ width: "108px", marginLeft: "75px" }} />
                                        <h2 style={{ marginLeft: "60px" }}>Thank You!</h2>

                                        {/* <h2 ><i class="bi bi-check-circle fas fa-lg" style={{marginLeft:"80px", color:"#00A0E4"}}></i></h2> */}
                                    </div>
                                </div>
                                <div class="col-lg-9 col-md-9 col-sm-12 col-12 mx-auto" style={{ marginTop: "-15px" }}>
                                    <div class="mb-4">
                                        <div class="d-flex justify-content-between" >
                                            <label class="" style={{ textAlign: "center", fontFamily: "Calibri", color: "black" }}>
                                                Your query had been submitted successfully and our executive will contact you shortly.
                                            </label>

                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}