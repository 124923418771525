import React from "react";

const ReadOnlyRow = ({ contact, handleEditClick , handleDeleteClick }) => {
  return (
    <tr>
      <td>{contact.Date}</td>
      <td class="text-center">{contact.description}</td>
      <td>                                                        
          <button type="button" class="btn btn-sm btn-toggle" data-toggle="button" aria-pressed="false" style={{marginLeft:"110px"}} autocomplete="off" id="toggBtn_1" ><div class="handle"></div></button>
        </td>
     
      <td class="text-center">
        <button class="btn btn-sm btn-transparent " style={{marginLeft:"30px", marginTop:"-15px"}}
          type="button"
          onClick={(event) => handleEditClick(event, contact)}
        >
         <i class='bi bi-pencil'></i>
        </button>
        <button class="btn btn-sm btn-transparent "  style={{ marginLeft:"-20px", marginTop:"-15px"}}
        type="button" onClick={() => handleDeleteClick(contact.id)}>
       <i class='bi bi-trash'></i>
        </button>
      </td>
    </tr>
  );
};

export default ReadOnlyRow;