import React from "react";
import { stringFormat } from "../../utils/methods";
import Select from "react-select";

const PreviousInsurancePolicyAndClaim = ({
  personalchk = void 0,
  addPrevClaims = [],
  formErrors = [],
  errors = [],
  isDisabled = false,
  data = [],
}) => {

  console.log(data, 'data')
  const typesOfAnimals = [
    {
      label: 'Cow',
      value: 1
    },
    {
      label: 'Horse',
      value: 2
    },
    {
      label: 'Goat',
      value: 3
    },
    {
      label: 'Buffalo',
      value: 4
    }
  ]

  const claimSettledIn = [
    {
      label: 'Full',
      value: 1
    },
    {
      label: 'Part',
      value: 2
    },
    {
      label: 'Outstanding',
      value: 3
    },
    {
      label: 'Repudiated',
      value: 4
    }
  ]

  const banks = [
    {
      label: 'Agriculture Insurance Company of India Ltd',
      value: 1
    },
    {
      label: 'Bajaj Allianz General Insurance Co. Ltd',
      value: 2
    },
    {
      label: 'Future Generali India Insurance Co. Ltd',
      value: 3
    },
    {
      label: 'Go Digit General Insurance Limited',
      value: 4
    },
    {
      label: 'HDFC Ergo General Insurance Co. Ltd',
      value: 5
    },
    {
      label: 'ICICI Lombard General Insurance Co. Ltd',
      value: 6
    },
    {
      label: 'IFFCO Tokio General Insurance Co. Ltd',
      value: 7
    },
    {
      label: 'Kotak Mahindra General Insurance Co. Ltd',
      value: 8
    },
    {
      label: 'Liberty General Insurance Limited',
      value: 9
    },
    {
      label: 'Reliance General Insurance Co. Ltd',
      value: 10
    },
    {
      label: 'Royal Sundaram General Insurance Co. Ltd',
      value: 11
    },
    {
      label: 'SBI General Insurance Co. Ltd',
      value: 12
    },
    {
      label: 'Tata AIG General Insurance Co. Ltd',
      value: 13
    },
    {
      label: 'The New India Assurance Co. Ltd',
      value: 14
    },
    {
      label: 'The Oriental Insurance Co. Ltd',
      value: 15
    },
    {
      label: 'United India Insurance Company Limited',
      value: 16
    },
    {
      label: 'National Insurance Company Limited',
      value: 17
    },
  ]

  return (
    <>
          {!isDisabled && addPrevClaims.map((addPrevClaims, i) => (
            <>
              <div className="number" key={i}>
                {i + 1}.
              </div>
              <div className="row row-cols-1 row-cols-lg-3 gy-4 form pt-3 pb-3">
                <div className="col position-relative">
                  <input
                    className="w-100"
                    id="addPrevClaims"
                    type="text"
                    maxLength="4"
                    name={'prev_claims_year'}
                    onChange={(e) => personalchk('prev_claims_year', e, i)}
                    value={
                      !isDisabled
                        ? addPrevClaims[i]?.prev_claims_year
                        : stringFormat(addPrevClaims[i]?.prev_claims_year)
                    }
                    disabled={isDisabled}
                  />
                  <label className="ms-3 position-absolute" htmlFor="Year">
                    Year
                  </label>
                </div>
                <div className="col position-relative">
                  <Select
                    options={banks}
                    isClearable={true}
                    onChange={(e) => personalchk('prev_claims_insurer', e, i)}
                    className="searchable-select"
                    name={addPrevClaims[i]?.prev_claims_insurer}
                    value={addPrevClaims[i]?.prev_claims_insurer}
                  />
                  <label className="ms-3 position-absolute" htmlFor="Insurer Name">
                    Insurer Name
                  </label>
                </div>
                <div className="col position-relative">
                  <Select
                    options={typesOfAnimals}
                    isClearable={true}
                    onChange={(e) => personalchk('prev_claims_animal_type', e, i)}
                    className="searchable-select"
                    name={addPrevClaims[i]?.prev_claims_animal_type}
                    value={addPrevClaims[i]?.prev_claims_animal_type}
                  />
                  <label className="ms-3 position-absolute" htmlFor="landmark">
                    Types of Animals
                  </label>
                </div>
              </div>

              <div className="row row-cols-1 row-cols-lg-3 gy-4 form pt-3 pb-3">
                <div className="col position-relative">
                  <input
                    className="w-100"
                    id="prev_claims_amount"
                    type="number"
                    name={addPrevClaims[i]?.prev_claims_amount}
                    onChange={(e) => personalchk('prev_claims_amount', e, i)}
                    value={
                      !isDisabled ? addPrevClaims[i]?.prev_claims_amount : stringFormat(addPrevClaims[i]?.claimAmount)
                    }
                    disabled={isDisabled}
                  />
                  <label className="ms-3 position-absolute" htmlFor="Claim Amount">
                    Claim Amount
                  </label>
                </div>
                <div className="col position-relative">
                  <Select
                    options={claimSettledIn}
                    isClearable={true}
                    onChange={(e) => personalchk('prev_claims_caim_settled', e, i)}
                    className="searchable-select"
                    name={addPrevClaims[i]?.prev_claims_caim_settled}
                    value={addPrevClaims[i]?.prev_claims_caim_settled}
                  />
                  <label className="ms-3 position-absolute" htmlFor="Whether Claim Settled In">
                    Whether Claim Settled In
                  </label>
                </div>
                <div className="col position-relative">
                  <input
                    className="w-100"
                    id="offers"
                    type="text"
                    name={addPrevClaims[i]?.prev_claims_remarks}
                    onChange={(e) => personalchk('prev_claims_remarks', e, i)}
                    value={
                      !isDisabled ? addPrevClaims[i]?.prev_claims_remarks : stringFormat(addPrevClaims[i]?.prev_claims_remarks)
                    }
                    disabled={isDisabled}
                  />
                  <label className="ms-3 position-absolute" htmlFor="Remarks">
                    Remarks
                  </label>
                </div>
              </div>
              <hr />
            </>
          ))}

          {isDisabled && data?.map((item, i) => (
            <>
              <div className="number" key={i}>
                {i + 1}.
              </div>
              <div className="row row-cols-1 row-cols-lg-3 gy-4 form pt-3 pb-3">
                <div className="col position-relative">
                  <input
                    className="w-100"
                    value={item?.prev_claims_year}
                    disabled={isDisabled}
                  />
                  <label className="ms-3 position-absolute" htmlFor="Year">
                    Year
                  </label>
                </div>
                <div className="col position-relative">
                  <input
                    className="w-100"
                    value={item?.prev_claims_insurer}
                    disabled={isDisabled}
                  />
                  <label className="ms-3 position-absolute" htmlFor="Insurer Name">
                    Insurer Name
                  </label>
                </div>
                <div className="col position-relative">
                  <input
                    className="w-100"
                    value={item?.prev_claims_animal_type}
                    disabled={isDisabled}
                  />
                  <label className="ms-3 position-absolute" htmlFor="Types of Animals">
                    Types of Animals
                  </label>
                </div>
              </div>

              <div className="row row-cols-1 row-cols-lg-3 gy-4 form pt-3 pb-3">
                <div className="col position-relative">
                  <input
                    className="w-100"
                    value={item?.prev_claims_amount}
                    disabled={isDisabled}
                  />
                  <label className="ms-3 position-absolute" htmlFor="Claim Amount">
                    Claim Amount
                  </label>
                </div>
                <div className="col position-relative">
                  <input
                    className="w-100"
                    value={item?.prev_claims_caim_settled}
                    disabled={isDisabled}
                  />
                  <label className="ms-3 position-absolute" htmlFor="Whether Claim Settled In">
                    Whether Claim Settled In
                  </label>
                </div>
                <div className="col position-relative">
                  <input
                    className="w-100"
                    value={item?.prev_claims_remarks}
                    disabled={isDisabled}
                  />
                  <label className="ms-3 position-absolute" htmlFor="Remarks">
                    Remarks
                  </label>
                </div>
              </div>
              <hr />
            </>
          ))}



      {/* <div className="number">
        1.
      </div>
      <div className="row row-cols-1 row-cols-lg-3 gy-4 form pt-3 pb-3">
        <div className="col position-relative">
            <input
              className="w-100"
              id="addPrevClaims"
              type="number"
              name={"addPrevClaims[0].prev_claims_year"}
              onChange={(e) => personalchk(`addPrevClaims[0].prev_claims_year`, e)}
              value={
                !isDisabled
                  ? addPrevClaims[0].prev_claims_year
                  : stringFormat(data.addPrevClaims[0].prev_claims_year)
              }
              disabled={isDisabled}
            />
            <label className="ms-3 position-absolute" htmlFor="Year">
              Year
            </label>
        </div>
        <div className="col position-relative">
            <Select
                options={banks}
                isClearable={true}
                onChange={(e) => (e != null ? personalchk(`${addPrevClaims[0].prev_claims_insurer}`, e.value) : personalchk(`${addPrevClaims[0].prev_claims_insurer}`, ""))}
                className="searchable-select"
                name={addPrevClaims[0].prev_claims_insurer}
                value={addPrevClaims[0].prev_claims_insurer}
            />
            <label className="ms-3 position-absolute" htmlFor="Insurer Name">
              Insurer Name
            </label>
        </div>
        <div className="col position-relative">
            <Select
                options={typesOfAnimals}
                isClearable={true}
                onChange={(e) => (e != null ? personalchk(`${addPrevClaims[0].prev_claims_animal_type}`, e.value) : personalchk(`${addPrevClaims[0].prev_claims_animal_type}`, ""))}
                className="searchable-select"
                name={addPrevClaims[0].prev_claims_animal_type}
                value={addPrevClaims[0].prev_claims_animal_type}
            />
            <label className="ms-3 position-absolute" htmlFor="landmark">
                Types of Animals
            </label>
        </div>
      </div>

      <div className="row row-cols-1 row-cols-lg-3 gy-4 form pt-3 pb-3">
        <div className="col position-relative">
          <input
            className="w-100"
            id="prev_claims_amount"
            type="number"
            name={addPrevClaims[0].prev_claims_amount}
            onChange={(e) => personalchk(`${addPrevClaims[0].prev_claims_amount}`, e)}
            value={
              !isDisabled ? addPrevClaims[0].prev_claims_amount : stringFormat(data.claimAmount)
            }
            disabled={isDisabled}
          />
          <label className="ms-3 position-absolute" htmlFor="Claim Amount">
            Claim Amount
          </label>
        </div>
        <div className="col position-relative">
            <Select
                options={claimSettledIn}
                isClearable={true}
                onChange={(e) => (e != null ? personalchk(`${addPrevClaims[0].prev_claims_caim_settled}`, e.value) : personalchk(`${addPrevClaims[0].prev_claims_caim_settled}`, ""))}
                className="searchable-select"
                name={addPrevClaims[0].prev_claims_caim_settled}
                value={addPrevClaims[0].prev_claims_caim_settled}
            />
            <label className="ms-3 position-absolute" htmlFor="Whether Claim Settled In">
                Whether Claim Settled In
            </label>
        </div>
        <div className="col position-relative">
          <input
            className="w-100"
            id="offers"
            type="text"
            name={addPrevClaims[0].prev_claims_remarks}
            onChange={(e) => personalchk(`${addPrevClaims[0].prev_claims_remarks}`, e)}
            value={
              !isDisabled ? addPrevClaims[0].prev_claims_remarks : stringFormat(data[0].prev_claims_remarks)
            }
            disabled={isDisabled}
          />
          <label className="ms-3 position-absolute" htmlFor="Remarks">
            Remarks
          </label>
        </div>
      </div>

      <hr /> */}

      {/*<div className="number">
        2.
      </div>
      <div className="row row-cols-1 row-cols-lg-3 gy-4 form pt-3 pb-3">
        <div className="col position-relative">
            <input
              className="w-100"
              id="addPrevClaims"
              type="number"
              name={addPrevClaims[1].prev_claims_year}
              onChange={(e) => personalchk(`${addPrevClaims[1].prev_claims_year}`, e)}
              value={
                !isDisabled
                  ? addPrevClaims[1].prev_claims_year
                  : stringFormat(data.addPrevClaims[1].prev_claims_year)
              }
              disabled={isDisabled}
            />
            <label className="ms-3 position-absolute" htmlFor="Year">
              Year
            </label>
        </div>
        <div className="col position-relative">
            <Select
                options={banks}
                isClearable={true}
                onChange={(e) => (e != null ? personalchk(`${addPrevClaims[1].prev_claims_insurer}`, e.value) : personalchk(`${addPrevClaims[1].prev_claims_insurer}`, ""))}
                className="searchable-select"
                name={addPrevClaims[1].prev_claims_insurer}
                value={addPrevClaims[1].prev_claims_insurer}
            />
            <label className="ms-3 position-absolute" htmlFor="Insurer Name">
              Insurer Name
            </label>
        </div>
        <div className="col position-relative">
            <Select
                options={typesOfAnimals}
                isClearable={true}
                onChange={(e) => (e != null ? personalchk(`${addPrevClaims[1].prev_claims_animal_type}`, e.value) : personalchk(`${addPrevClaims[1].prev_claims_animal_type}`, ""))}
                className="searchable-select"
                name={addPrevClaims[1].prev_claims_animal_type}
                value={addPrevClaims[1].prev_claims_animal_type}
            />
            <label className="ms-3 position-absolute" htmlFor="landmark">
                Types of Animals
            </label>
        </div>
      </div>

      <div className="row row-cols-1 row-cols-lg-3 gy-4 form pt-3 pb-3">
        <div className="col position-relative">
          <input
            className="w-100"
            id="prev_claims_amount"
            type="number"
            name={addPrevClaims[1].prev_claims_amount}
            onChange={(e) => personalchk(`${addPrevClaims[1].prev_claims_amount}`, e)}
            value={
              !isDisabled ? addPrevClaims[1].prev_claims_amount : stringFormat(data.claimAmount)
            }
            disabled={isDisabled}
          />
          <label className="ms-3 position-absolute" htmlFor="Claim Amount">
            Claim Amount
          </label>
        </div>
        <div className="col position-relative">
            <Select
                options={claimSettledIn}
                isClearable={true}
                onChange={(e) => (e != null ? personalchk(`${addPrevClaims[1].prev_claims_caim_settled}`, e.value) : personalchk(`${addPrevClaims[1].prev_claims_caim_settled}`, ""))}
                className="searchable-select"
                name={addPrevClaims[1].prev_claims_caim_settled}
                value={addPrevClaims[1].prev_claims_caim_settled}
            />
            <label className="ms-3 position-absolute" htmlFor="Whether Claim Settled In">
                Whether Claim Settled In
            </label>
        </div>
        <div className="col position-relative">
          <input
            className="w-100"
            id="offers"
            type="text"
            name={addPrevClaims[1].prev_claims_remarks}
            onChange={(e) => personalchk(`${addPrevClaims[1].prev_claims_remarks}`, e)}
            value={
              !isDisabled ? addPrevClaims[1].prev_claims_remarks : stringFormat(data[1].prev_claims_remarks)
            }
            disabled={isDisabled}
          />
          <label className="ms-3 position-absolute" htmlFor="Remarks">
            Remarks
          </label>
        </div>
      </div>

      <hr />

      <div className="number">
        3.
      </div>
      <div className="row row-cols-1 row-cols-lg-3 gy-4 form pt-3 pb-3">
        <div className="col position-relative">
            <input
              className="w-100"
              id="addPrevClaims"
              type="number"
              name={addPrevClaims[2].prev_claims_year}
              onChange={(e) => personalchk(`${addPrevClaims[2].prev_claims_year}`, e)}
              value={
                !isDisabled
                  ? addPrevClaims[2].prev_claims_year
                  : stringFormat(data.addPrevClaims[2].prev_claims_year)
              }
              disabled={isDisabled}
            />
            <label className="ms-3 position-absolute" htmlFor="Year">
              Year
            </label>
        </div>
        <div className="col position-relative">
            <Select
                options={banks}
                isClearable={true}
                onChange={(e) => (e != null ? personalchk(`${addPrevClaims[2].prev_claims_insurer}`, e.value) : personalchk(`${addPrevClaims[2].prev_claims_insurer}`, ""))}
                className="searchable-select"
                name={addPrevClaims[2].prev_claims_insurer}
                value={addPrevClaims[2].prev_claims_insurer}
            />
            <label className="ms-3 position-absolute" htmlFor="Insurer Name">
              Insurer Name
            </label>
        </div>
        <div className="col position-relative">
            <Select
                options={typesOfAnimals}
                isClearable={true}
                onChange={(e) => (e != null ? personalchk(`${addPrevClaims[2].prev_claims_animal_type}`, e.value) : personalchk(`${addPrevClaims[2].prev_claims_animal_type}`, ""))}
                className="searchable-select"
                name={addPrevClaims[2].prev_claims_animal_type}
                value={addPrevClaims[2].prev_claims_animal_type}
            />
            <label className="ms-3 position-absolute" htmlFor="landmark">
                Types of Animals
            </label>
        </div>
      </div>

      <div className="row row-cols-1 row-cols-lg-3 gy-4 form pt-3 pb-3">
        <div className="col position-relative">
          <input
            className="w-100"
            id="prev_claims_amount"
            type="number"
            name={addPrevClaims[2].prev_claims_amount}
            onChange={(e) => personalchk(`${addPrevClaims[2].prev_claims_amount}`, e)}
            value={
              !isDisabled ? addPrevClaims[2].prev_claims_amount : stringFormat(data.claimAmount)
            }
            disabled={isDisabled}
          />
          <label className="ms-3 position-absolute" htmlFor="Claim Amount">
            Claim Amount
          </label>
        </div>
        <div className="col position-relative">
            <Select
                options={claimSettledIn}
                isClearable={true}
                onChange={(e) => (e != null ? personalchk(`${addPrevClaims[2].prev_claims_caim_settled}`, e.value) : personalchk(`${addPrevClaims[2].prev_claims_caim_settled}`, ""))}
                className="searchable-select"
                name={addPrevClaims[2].prev_claims_caim_settled}
                value={addPrevClaims[2].prev_claims_caim_settled}
            />
            <label className="ms-3 position-absolute" htmlFor="Whether Claim Settled In">
                Whether Claim Settled In
            </label>
        </div>
        <div className="col position-relative">
          <input
            className="w-100"
            id="offers"
            type="text"
            name={addPrevClaims[2].prev_claims_remarks}
            onChange={(e) => personalchk(`${addPrevClaims[2].prev_claims_remarks}`, e)}
            value={
              !isDisabled ? addPrevClaims[2].prev_claims_remarks : stringFormat(data[2].prev_claims_remarks)
            }
            disabled={isDisabled}
          />
          <label className="ms-3 position-absolute" htmlFor="Remarks">
            Remarks
          </label>
        </div>
      </div> */}
    </>
  );
};

export default PreviousInsurancePolicyAndClaim;
