

import React, { useRef, useState, useEffect } from 'react'
import '../../css/new_car_step1.css'
import Footer from '../ReusableComponents/Footer'
import NavBar from '../ReusableComponents/NavBar'
import gimg from '../HomePage/Group 154.png'
import { useHistory } from 'react-router-dom';
import SubmitQuery from '../../services/insurance-query.service'
import './BikeInsurance.css'
import Accordion from 'react-bootstrap/Accordion'
import { Modal } from 'react-bootstrap'
import { Model } from '../ReusableComponents/Modal'
import TermsUsage from '../OtherInsurance/TermsUsage'
import Terms from '../ReusableComponents/Terms'

export default function BikeInsurance() {

    const [showModal, setModalShow] = useState(false);
    const ShowModal = () => setShow(true);
    const handleCloseModal = () => setModalShow(false);
    const handleShowModal = () => setModalShow(true);

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
        return () => {
        }
    }, [])
    const [checked, setChecked] = useState(true);
    const history = useHistory();
    const sectionContent = {
        textDecoration: "none",
        fontSize: 14,
    }

    let car_insurance = useRef();
    let different_types_car_insurance = useRef();
    let topic1 = useRef();
    let addOn = useRef();
    let faqs = useRef();

    const initialValues = { firstName: "", email: "", lastName: "", mobile: "", isChecked: true };
    const [formValues, setFormValues] = useState(initialValues);
    const [formErrors, setFormErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setFormErrors(validate(formValues));
        setIsSubmit(true);
        if (true) {
            console.log(formValues);
            SubmitQuery(formValues.firstName, formValues.lastName, formValues.mobile, formValues.email, "motor/purchaseBikeInsurance")
                .then(response => response.json())
                .then(async response => {
                    console.log(JSON.stringify(response));
                    if (response.status == 1) {
                        await handleShow();
                        setFormValues(initialValues);

                    } else if (response.status == 0) {
                        alert(response.message);
                    }
                });
        }
    };

    useEffect(() => {
        console.log(formErrors);
        if (Object.keys(formErrors).length === 0 && isSubmit) {
            console.log(formValues);
        }
    }, [formErrors]);

    const validate = (values) => {
        const errors = {};
        const email_regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        const fname_regex = /^[a-zA-Z ]+$/;
        const lname_regex = /([a-zA-Z]{1,30})$/;
        const mobile_regex = /^[0-9\b]+$/;


        if (!values.firstName) {
            errors.firstName = "First Name is Required!";
        } else if (!fname_regex.test(values.firstName)) {
            errors.firstName = "Enter Valid First Name!";
        }
        // if (!values.lastName) {
        //   errors.lastName = "Last Name is Required!";
        // } else if (!lname_regex.test(values.lastName)) {
        //   errors.lastName = "Enter Valid Last Name!";
        // }
        if (!values.email) {
            errors.email = "Email is Required!";
        } else if (!email_regex.test(values.email)) {
            errors.email = "Enter Valid Email!";
        }
        if (!values.mobile) {
            errors.mobile = "Mobile No. is Required";
        } else if (values.mobile.length < 10) {
            errors.mobile = "Mobile No. must be 10 Digits";
        }
        else if (!mobile_regex.test(values.mobile)) {
            errors.mobile = "Enter valid Mobile No.";
        }
        if (!values.isChecked) {
            errors.isChecked = "Kindly accepts the terms and conditions to submit";
        }
        return errors;
    };



    return (
        <div className='main-div'>
        <NavBar />

            <br />
            <br />
            <br />

            <div class='car-insurance-top'>
                <br />
                <div class="container" >
                    <div class="row my-2 form-container">
                        <div class="col-lg-8" >
                            <div class='row' style={{ marginTop: '20px', marginLeft: '1.25rem', paddingTop: '10px' }}>
                                <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 py-1 px-3" >
                                    <div class="bg-white p-2" >
                                        <div class="px-2 py-3">
                                            <div class="container" style={{ Shadow: "0px 30px 50px rgb(0 0 0 / 10%)" }}>
                                                <div class="row" style={{ marginTop: "-30px", marginLeft: "-70px" }}></div>
                                                <form onSubmit={handleSubmit}>
                                                    <h2 class="icueTextPrimary">Two Wheeler Insurance </h2>
                                                    <p>Secure your Two Wheeer with best Two Wheeler Insurance plans offered by insurers.</p> <br />
                                                    <div class="step2-content col-lg-5 col-md-7" style={{marginLeft: '-3rem'}}>
                                                        <div class='col-lg-10'>
                                                            <div class='d-sm-flex'>
                                                                <div class="car3-form-groupp mt-3 mb-2">
                                                                    <input
                                                                        class="car2-form-controll"
                                                                        id="firstName"
                                                                        type="text"
                                                                        name="firstName"
                                                                        value={formValues.firstName}
                                                                        onChange={handleChange}
                                                                        style={{ marginLeft: '20px', marginRight: '20px', textTransform: "capitalize" }}
                                                                    />
                                                                    <label class="ms-3 car2-form-control-placeholderr" for="firstName">First Name</label>
                                                                    <p className='p-error'>{formErrors.firstName}</p>
                                                                </div>
                                                                <div class="car3-form-groupp mt-3 mb-2">

                                                                    <input
                                                                        class="car2-form-controll"
                                                                        id="lastName"
                                                                        type="text"
                                                                        name="lastName"
                                                                        value={formValues.lastName}
                                                                        onChange={handleChange}
                                                                        style={{ marginLeft: '20px', marginRight: '20px', textTransform: "capitalize" }}
                                                                    />
                                                                    <label class="ms-3 car2-form-control-placeholderr" for="lastName">Last Name</label>

                                                                    {/* <p className='p-error'>{formErrors.lastName}</p> */}
                                                                </div>

                                                            </div>
                                                            <div class='d-sm-flex'>
                                                                <div class="car3-form-groupp mt-3 mb-2">

                                                                    <input
                                                                        class="car2-form-controll"
                                                                        id="mobile"
                                                                        type="text"
                                                                        name="mobile"
                                                                        value={formValues.mobile}
                                                                        onChange={handleChange}
                                                                        maxLength={10}
                                                                        style={{ marginLeft: '20px', marginRight: '20px', textTransform: "lowercase" }}
                                                                    />
                                                                    <label class="ms-3 car2-form-control-placeholderr" for="mobile">Mobile</label>

                                                                    <p className='p-error'>{formErrors.mobile}</p>
                                                                </div>
                                                                <div class="car3-form-groupp mt-3 mb-2">
                                                                    <input
                                                                        id="email"
                                                                        class="car2-form-controll"
                                                                        type="text"
                                                                        name="email"
                                                                        value={formValues.email}
                                                                        onChange={handleChange}
                                                                        style={{ marginLeft: '20px', marginRight: '20px', textTransform: "lowercase" }}
                                                                    />
                                                                    <label class="ms-3 car2-form-control-placeholderr" for="email">Email</label>

                                                                    <p className='p-error'>{formErrors.email}</p>
                                                                </div>
                                                            </div>
                                                            {/* <button className="fluid ui button blue">Submit</button> */}
                                                        </div>
                                                    </div>

                                                    <br />

                                                    <div className='privacypolicy-chechbox' >
                                                        <input class='acceptTandC' style={{ width: '20px' }} type='checkbox' defaultChecked={formValues.isChecked} onChange={() => { setChecked(!checked); setFormValues({ ...formValues, 'isChecked': !formValues.isChecked }); }} />
                                                        <a href="javascript:void(0)"> I/We accept the <span className='link-terms'
                                                            onClick={handleShowModal}
                                                        >Terms and conditions</span> </a>

                                                        <Model show={showModal} onHide={handleCloseModal}>
                                                            <Terms />
                                                        </Model>

                                                        <p className='p-error'>{formErrors.isChecked}</p>

                                                        <br />
                                                        <span style={{
                                                            fontSize: '0.9rem',
                                                            fontFamily: 'Calibri',
                                                            paddingTop: '1rem !important'
                                                        }}>(You authorise PoliciCue Insurance Brokers Pvt Ltd (PoliciCue) to contact you through call, SMS, email, WhatsApp or any other mode in future.
                                                            You hereby override your NDNC registration.)
                                                        </span>

                                                        {/* <input class='acceptTandC' style={{ width: '20px' }} type='checkbox' defaultChecked={checked} onChange={() => setChecked(!checked)} /><a href="/LegalAdminPolicies"> I/We accept the <span className='link-terms' onClick={() => history.push("/LegalAdminPolicies")}>Terms and conditions</span> </a> */}
                                                    </div>
                                                    <div class='step2-car-nextbtn ' style={{ marginLeft: "-55px", lineHeight: "20px", height: "40px" }}><button class='' type='submit'>Submit</button></div>
                                                </form>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 text-center" style={{ backgroundColor: "#F4F7FC", display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                            <img src='./images/im2 2.png' className='car-step1-img'  style={{ backgroundColor: "#F4F7FC", display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}></img>
                        </div>
                    </div>
                </div>
                {/* <img src={gimg} style={{ marginTop: '-625px', width: '100%' }}></img> */}
            </div>


            {/* </div> */}
            {/* <div class="container">
                    <div class="row"> */}
            {/* <div class="col-lg-8">
                            <div class='row' style={{ marginTop: '50px', marginLeft: '6.25rem', paddingTop: '50px' }}>
                                <div class="col-lg-4">
                                    <button onClick={() => history.push("/VehicleDetails")} class='step1-car-btn'>Brand New</button>
                                </div>
                                <div class="col-lg-4">
                                    <button onClick={() => history.push("/MoreThanVD1")} class='step1-car-btn'>Less than 3 years of age</button>
                                </div>
                                <div class="col-lg-4">
                                    <button onClick={() => history.push("/MoreThanVD1")} class='step1-car-btn'>More than 3 years of age</button>
                                </div>
                            </div>
                        </div> */}
            {/* <div class="col-lg-4">
                            <img src='./images/im2 2.png' class='car-step1-img'></img>
                        </div>
                    </div>
                </div> */}
            {/* <img src={gimg} style={{ marginTop: '-625px', width: '100%' }}></img> */}
            {/* </div> */}


            <div ref={car_insurance} class="car-insurance">
                <div class="container" style={{ maxWidth: '80%'}}>
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="text-container">
                                <h2 class="h2-header" >What is Two Wheeler Insurance ?</h2>
                                <p class="paragraph-header ">Two Wheeler, Bike or Motor Cycle, Scooty whatever we call it, for most of us,
                                    it is our first grand possession and we all love it to core of our heart. Two wheeler insurance is basically
                                    an insurance plan that provides coverage against different form of damages that may occur to the bike or the
                                    rider because of any accident, theft or natural calamities.</p>

                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="image-container">
                                <img style={{ height: 209 }} class="img-fluid img-details" src="images/im4 2.png" alt="alternative" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- end of car-insurance -->
<!-- end of details 2 --> */}

            {/* <!-- different_types_car_insurance --> */}
            <div ref={different_types_car_insurance} id="different_types_car_insurance" class="different_types_car_insurance">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <h2 class="h2-heading-different_types_car_insurance">What are the different type of Two Wheeler Insurance ? </h2>
                        </div>
                    </div>
                </div>
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6 col-cls">
                            <div class="card diff_car_left">
                                <div class="text-container diff_car_left_text">
                                    <div className="image-container" style={{ marginTop: '-4rem' }}>
                                        <img className='img-cls' src="images/package.png" alt="icon" />
                                    </div>
                                    <h6 className='h6-text-heading'>Package Policy</h6>
                                    <p class="p-diff_car">Covering loss &/or damage to Vehicle and Third Party/Property
                                        This cover provides cover against damage to other vehicles, people and the damage caused to
                                        your vehicle also. The cost of repairs of your motorbike will also be taken care of.
                                        It is recommended that you purchase a comprehensive two wheeler insurance policy.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-cls">
                            <div class="card diff_car_right">
                                <div className="image-container" style={{ marginTop: '-4rem' }}>
                                    <img className='img-cls' src="images/tparty.png" alt="icon" />
                                </div>
                                <div class="text-container">
                                    <h6 className='h6-text-heading'>Third Party Liability </h6>
                                    <p class="p-diff_car">This covers the cost arising due to the damage caused by your vehicle to a third party.
                                        It could another vehicle or another person and this insurance component is mandatory by law. You are not
                                        supposed to ride a bike on the roads if it does not have third party insurance cover.

                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>{/* <!-- end of different_types_car_insurance -->
<!-- end of different_types_car_insurance --> */}

            {/* <!-- topic 1 --> */}
            <div ref={topic1} class="topic-1">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="text-container">
                                <h2 class="h2-topic-1">Can you have Long term Insurance for your Two Wheeler?</h2>
                                <p class="p-topic-1">
                                    There are various long term policies available as below: - For brand new two-wheeler –
                                    As per the Policies available, customer can choose from the mentioned options:
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <!-- topic 2 --> */}
            <div class="topic-2">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="text-container">
                                <h2 class="h2-topic-2">i. Liability only policy for a policy duration of 5 years.  </h2>
                                <p class="p-topic-2">
                                    This policy provides coverage against third party liability in terms of death or
                                    injury or third party property damage
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <!-- topic 3 --> */}
            <div class="topic-3">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="text-container">
                                <h2 class="h2-topic-3">ii. Package policy for a policy duration of 5 years. </h2>
                                <p class="p-topic-3">
                                    This policy provides a comprehensive cover to protect your vehicle against different form
                                    of damages that may occur to the bike because of any accident, theft or natural calamities.
                                    In addition to this, it also provides the mandatory third party liability cover.

                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- topic 4 --> */}
            <div class="topic-3">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="text-container">
                                <h2 class="h2-topic-3">iii	Bundled policy for a policy duration of 5 years. </h2>
                                <p class="p-topic-3">
                                    This policy provides cover to own damage for one year and 5 years for third party liability cover.
                                    <br /><br />
                                    For your old two wheeler – various insurers offers option of Package/Liability policy
                                    for up to 2 or 3 years policy duration.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <!------------------------addons-----------------------------------------> */}
            {/* <!-- Basic --> */}
            <div ref={addOn} class="ex-basic-1 pt-5 pb-5">

                <div class="container custom-extend">

                    {/* <!-- Card --> */}
                    <div class="card card-addons">

                        <div class="card-block">
                            <h2 class="h2-heading-addon">Two-Wheeler Insurance Add -Ons</h2>
                            {/* <!-----------------------row one-----------------------------------------> */}
                            <div class="row addons">

                                <div class="col-lg-4 ">
                                    <div class="card addon card-size-1" >
                                        <div class="card-body addon-content">
                                            <div className="card-heading-row">
                                                <h6 class="card-title h6-heading-addon">Zero Depriciation Cover</h6>
                                                <img src="images/zero.png" />
                                            </div>
                                            <p class="card-text p-addon-text">In case of any damage under insurance plan you need to replace
                                                some parts of your car, the car insurance plan pays you the depreciated value of the part and not
                                                the invoice value of the part. If you have opted for a Depreciation Cover, you would be paid the
                                                actual price of the part and not the depreciated value. This benefit comes with a small increase
                                                in premium.
                                            </p>
                                        </div>
                                    </div>

                                </div>

                                <div class="col-lg-4">
                                    <div class="card addon card-size-2" >
                                        <div class="card-body">
                                            <div className="card-heading-row">
                                                <h6 class="card-title h6-heading-addon">Engine Protect Cover</h6>
                                                <img src="images/engine.png" />
                                            </div>
                                            <p class="card-text p-addon-text">This add on protection gives cover against damage to your car engine and electronic circuit caused due to flooding, or water logging. This cover helps you avoid huge repair costs, as your insurance company will pay off for the damages. It’s quite useful, especially during the monsoon season.</p>

                                        </div>
                                    </div>

                                </div>

                                <div class="col-lg-4  ">
                                    <div class="card addon card-size-3" >
                                        <div class="card-body">
                                            <div className="card-heading-row">
                                                <h6 class="card-title h6-heading-addon">Return to Invoice Cover</h6>
                                                <img src="images/consum.png" />
                                            </div>
                                            <p class="card-text p-addon-text ">Under the normal comprehensive private car policy cost of Engine oil, lubricants, gearbox oil, screw, nut and bolt are not covered. With an add on consumable cover these expenses are made payable under the policy. </p>

                                        </div>
                                    </div>

                                </div>
                            </div>
                            {/* <!--------end row 1-->
                          <!-------------------------------------row two---------------------------------------------------> */}


                            {/* <!------ end row 3 --> */}
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- end of basic -->
<!-- end addons --> */}

            {/* <!-- What is Not Covered under a Basic Car Insurance Plan --> */}
            <div class="topic-4">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="text-container">
                                <h2 class="h2-topic-4">What does Two Wheeler Policy Cover?  </h2>

                                <p class="p-topic-4">
                                    The Policy covers loss or damage against Legal Liability to third party personal injury and property damage
                                    arising out of an accident involving the vehicle as required under Motor Vehicle Act. Along with the damage
                                    to the vehicle insured arising out of:
                                </p>
                                <p class="p-topic-4">
                                    Fire, explosion, self-ignition, lightning, burglary, theft, riot, strike, malicious act, terrorism,
                                    earthquake, flood, typhoon, accidental external means, landslide or rockslide, road/rail/inland
                                    waterway/air transit.
                                </p>
                                <p class="p-topic-4">
                                    The Policy further provides for compulsory Personal Accident cover for owner driver.
                                </p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <!-- Exclusion in Car Insurance --> */}
            <div class="topic-5">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="text-container">
                                <h2 class="h2-topic-5">Policy Exclusion under Two Wheeler Insurance </h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6">

                            <p class="p-topic-5">
                                Any accidents outside the Geographical Limit under the Policy
                            </p>
                            <p class="p-topic-5">
                                Consequential loss, normal wear and tear
                            </p>
                            <p class="p-topic-5">
                                Driving without valid license for the class of vehicle.
                            </p>
                            <p class="p-topic-5">
                                Driving under the influence of liquor/drugs
                            </p>
                            <p class="p-topic-5">
                                Vehicle not being used as per "Limitation as to use" defined in the Policy
                            </p>

                        </div>
                        <div class="col-lg-6">
                            <div class="image-container mobile">
                                <img class="img-fluid img-details" style={{ height: "17rem", marginTop: -100 }} src="images/im4 2.png" alt="alternative" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div ref={faqs} class="faqs">
                <div class="container">
                    <div class="row">
                        <div class="col-align">
                            <h2 class="h2-heading-faqs">Insurance FAQ’s</h2>
                            <p class="p-heading-faqs">If You Are Looking To Buy Insurance You  Ought to Check Below Frequently Asked Questions</p>
                        </div>
                    </div>
                </div>

                <div class="container">
                    <div class="row">
                        <Accordion>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header className='accordion-header-1'>
                                    <img src="./images/Rectangle 22.svg" alt="" />&nbsp;&nbsp;&nbsp;&nbsp; <p className="faqText">How the value of my Two Wheeler/Bike is decided?</p></Accordion.Header>
                                <Accordion.Body className='accordion-header-1'>
                                    <h3 class="h3-faqs">For Own Damage:</h3>
                                    <p class="p-faqs">The Sum Insured under a Motor Insurance policy reflects the value of the motor vehicle determined based on the concept known as Insured's Declared Value.
                                        Insured's Declared Value is the value arrived at based on the Manufacturer's present value and depreciation based on the Age of the Vehicle.
                                    </p>
                                    <div class="row">
                                        <div class="col-lg-5">
                                            <h3 class="h3-faqs">AGE OF THE VEHICLE</h3>
                                            <p class="p-faqs-points">
                                                Not exceeding 6 months
                                            </p>
                                            <p class="p-faqs-points">
                                                Exceeding 6 months but not exceeding 1 year
                                            </p>
                                            <p class="p-faqs-points">
                                                Exceeding 1 year but not exceeding 2 years
                                            </p>
                                            <p class="p-faqs-points">
                                                Exceeding 2 years but not exceeding 3 years
                                            </p>
                                            <p class="p-faqs-points">
                                                Exceeding 3 years but not exceeding 4 years
                                            </p>
                                            <p class="p-faqs-points">
                                                Exceeding 4 years but not exceeding 5 years
                                            </p>
                                        </div>
                                        <div class="col-lg-7">
                                            <h3 class="h3-faqs">% OF DEPRECIATION FOR FIXING IDV</h3>
                                            <p class="p-faqs-points-right">
                                                5%
                                            </p>
                                            <p class="p-faqs-points-right">
                                                15%
                                            </p>
                                            <p class="p-faqs-points-right">
                                                20%
                                            </p>
                                            <p class="p-faqs-points-right">
                                                30%
                                            </p>
                                            <p class="p-faqs-points-right">
                                                40%
                                            </p>
                                            <p class="p-faqs-points-right">
                                                50%
                                            </p>
                                        </div>
                                    </div>
                                    <h3 class="h3-faqs-third-party">
                                        For Third Party:
                                    </h3>
                                    <p class="p-faqs">Coverage is as per requirements of the Motor Vehicles Act, 1988. Compulsory Personal accident cover for
                                        owner-driver is also included. Policy can also be extended to cover various other risks like Personal
                                        Accident to occupants of vehicle, Workmen's Compensation to Driver etc over and above the cover
                                        available to him under statute.
                                    </p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header className='accordion-header-2'>
                                    <img src="./images/Rectangle 22.svg" alt="" />&nbsp;&nbsp;&nbsp;&nbsp; <p className='faqText'>How is the premium of my Two Wheeler determined?</p></Accordion.Header>
                                <Accordion.Body className='accordion-header-2'>
                                    <p class="p-faqs-para">
                                        Many factors determine the premium you will pay. For Own Damage cover different insurance companies charge different premiums for similar coverage. Check various insurers’s quotes at our platform, compare & buy. Do not forget to compare deductibles, coverage and IDV’s as premium may be lesser of one insurer but with higher deductibles, lower coverage and lower IDV, which will adversely impact you in the event of claim settlement. </p>
                                    <p class="p-faqs-para">
                                        With so many Plans & coverage been offered by various Insurance companies it is indeed difficult for you to choose single insurance policy from an insurer. PoliciCue platform gives you an ease to decide while comparing the benefit on offer from various Insurer according to your need in single go and facilitates the opting of an insurance Policy which is best fit to your specific purpose. </p>
                                    <p class="p-faqs-para">
                                        Be prepared to share with us information about the following items that are commonly used to determine your premium: Vehicle registration details with Engine No., Chasis no., Class of vehicle, cubic capacity, seating capacity, etc. (In fact, all relevant details are in the RC book/card and a copy of same may be handed over) Tax paid details; Certificate of fitness, Driver details - age, gender, qualifications, licence validity Previous insurance history, if any.</p>
                                    <p class="p-faqs-para">
                                        The Own Damage coverage is left to be rated by individual insurance companies after duly filing rates with the Insurance Regulatory and Development Authority. The same is determined  on following factors amongst others -- Age of vehicle; Discounts / loadings- Appropriate Bonus / loading/ discounts along with past claims experience are taken into account while calculating premium. IDV (Insured Declared Value). </p>
                                    <p class="p-faqs-para">
                                        Third Party Liability Premium rates are laid down by IRDA.</p>
                                    <p class="p-faqs-para">
                                        In case of break in insurance, vehicle inspection may be required by the insurer. </p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                                <Accordion.Header className='accordion-header-1'>
                                    <img src="./images/Rectangle 22.svg" alt="" />&nbsp;&nbsp;&nbsp;&nbsp; <p className='faqText'> Which policy should I buy , Comprehensive Packed Policy or Third Party?</p></Accordion.Header>
                                <Accordion.Body className='accordion-header-1'>
                                    <p class="p-faqs-para">
                                        As per law, only third party liability only policy is required without which you cannot use the vehicle on road. However, under third party liability only policy, any damage to your vehicle due to accident, fire, theft, earthquake, terrorism etc. is not covered and it could result into a huge financial loss. Therefore, it is recommended to buy a comprehensive cover as it provides financial protection along with protection from third party liability. </p>
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="3">
                                <Accordion.Header className='accordion-header-2'>
                                    <img src="./images/Rectangle 22.svg" alt="" />&nbsp;&nbsp;&nbsp;&nbsp; <p className='faqText'>What is No Claim Bonus (NCB) ?</p></Accordion.Header>
                                <Accordion.Body className='accordion-header-2'>
                                    <p class="p-faqs-para">
                                        No Claim Bonus (NCB) is the benefit accrued to an insured for not making any claims during the previous policy period.  As per current norms in India, it ranges from 20% on the Own Damage premium (and not on Liability premium) and progressively increases to a maximum of 50%. </p>
                                    <br/>
                                    <div class="row">
                                        <div class="col-lg-6">
                                            <h3 class="h3-faqs">All types of Vehicles</h3>
                                            <p class="p-faqs-points">
                                                No claim made or pending during the preceding full year of insurance

                                            </p>
                                            <p class="p-faqs-points">
                                                No claim made or pending during the preceding 2 consecutive years of insurance

                                            </p>
                                            <p class="p-faqs-points">
                                                No claim made or pending during the preceding 3 consecutive years of insurance

                                            </p>
                                            <p class="p-faqs-points">
                                                No claim made or pending during the preceding 4 consecutive years of insurance

                                            </p>
                                            <p class="p-faqs-points">
                                                No claim made or pending during the preceding 5 consecutive years of insurance

                                            </p>

                                        </div>
                                        <div class="col-lg-6">
                                            <h3 class="h3-faqs"style={{marginLeft:"-15px"}}>% of Discount on Own Damage premium</h3>
                                            <p class="p-faqs-points-right">
                                                20%
                                            </p>
                                            <p class="p-faqs-points-right">
                                                25%
                                            </p>
                                            <p class="p-faqs-points-right">
                                                35%
                                            </p>
                                            <p class="p-faqs-points-right">
                                                45%
                                            </p> 
                                            <p class="p-faqs-points-right">
                                                50%
                                            </p>

                                        </div>
                                    </div>
                                    <br/>
                                    <p class="p-faqs-para">
                                        If, however, a claim is lodged, the No Claim Bonus is lost in the subsequent policy period. <br /><br />
                                        NCB is given to the insured and not to the insured vehicle. Hence, on transfer of the vehicle, the insurance policy can be transferred to new owner but not the NCB. The new owner has to pay the difference on account of NCB for the balance policy period. The original owner can, however, use the NCB on a new vehicle purchased by him. </p>
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="4">
                                <Accordion.Header className='accordion-header-1'>
                                    <img src="./images/Rectangle 22.svg" alt="" />&nbsp;&nbsp;&nbsp;&nbsp; <p className='faqText'>What happens to No Claim Bonus (NCB) for an expired policy ?</p></Accordion.Header>
                                <Accordion.Body className='accordion-header-1'>
                                    <p class="p-faqs-para">
                                        No Claim Bonus is valid up to 90 days from the previous policy expiry date. If the policy is not renewed within 90 days, No Claim Bonus will become 0% and no benefit shall be passed on to the renewed policy. </p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="5">
                                <Accordion.Header className='accordion-header-2'>
                                    <img src="./images/Rectangle 22.svg" alt="" />&nbsp;&nbsp;&nbsp;&nbsp; <p className='faqText'>What is deductible ?</p></Accordion.Header>
                                <Accordion.Body className='accordion-header-2'>
                                    <p class="p-faqs-para">
                                        Deductible or “excess” is the amount over and above, which the claim will be payable. There is a normal standard/compulsory excess for most of the vehicles depending upon the cubic capacity/carrying capacity of the vehicle. However, in some cases the insurer may impose additional excess depending upon insurer’s underwriting guideline. </p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="6">
                                <Accordion.Header className='accordion-header-1'>
                                    <img src="./images/Rectangle 22.svg" alt="" />&nbsp;&nbsp;&nbsp;&nbsp; <p className="faqText">What are the documents that are required to be submitted for a Motor Insurance claim ?</p></Accordion.Header>
                                <Accordion.Body className='accordion-header-1'>
                                    <p class="p-faqs-para">
                                        Generally, the following documents are required to be submitted. However, read through your policy to see the complete list—duly filled in claim form, RC copy of the vehicle, original estimate of loss, original repair invoice and payment receipt. In case cashless facility is availed, only repair invoice would need to be submitted and FIR, if required. For theft claims, all keys are to be submitted. Theft claims would also require non-traceable certificate/final report to be submitted. </p>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>

                        {/* <div class="accordion" id="accordionExample">
                            <div class="card card-faqs card-bg-1">
                                <div class="card-header" id="headingOne">
                                    <h5 class="mb-0">
                                        <img src="./images/Rectangle 22.svg" alt="" />
                                        <button class="btn btn-link btn-faqs collapsed" style={sectionContent} type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                            How the value of my Two Wheeler/Bike is decided?
                                        </button>
                                        <img src="images/chevron-bottom.svg" class="img-chevron-down-faqs float-right" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne" />
                                    </h5>
                                </div>

                                <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
                                    <div class="card-body" style={{ background: "#ffffff" }}>
                                        <h3 class="h3-faqs">For Own Damage:</h3>
                                        <p class="p-faqs">The Sum Insured under a Motor Insurance policy reflects the value of the motor vehicle determined based on the concept known as Insured's Declared Value.
                                            Insured's Declared Value is the value arrived at based on the Manufacturer's present value and depreciation based on the Age of the Vehicle.
                                        </p>
                                        <div class="row">
                                            <div class="col-lg-5">
                                                <h3 class="h3-faqs">AGE OF THE VEHICLE</h3>
                                                <p class="p-faqs-points">
                                                    Not exceeding 6 months
                                                </p>
                                                <p class="p-faqs-points">
                                                    Exceeding 6 months but not exceeding 1 year
                                                </p>
                                                <p class="p-faqs-points">
                                                    Exceeding 1 year but not exceeding 2 years
                                                </p>
                                                <p class="p-faqs-points">
                                                    Exceeding 2 years but not exceeding 3 years
                                                </p>
                                                <p class="p-faqs-points">
                                                    Exceeding 3 years but not exceeding 4 years
                                                </p>
                                                <p class="p-faqs-points">
                                                    Exceeding 4 years but not exceeding 5 years
                                                </p>
                                            </div>
                                            <div class="col-lg-7">
                                                <h3 class="h3-faqs">% OF DEPRECIATION FOR FIXING IDV</h3>
                                                <p class="p-faqs-points-right">
                                                    5%
                                                </p>
                                                <p class="p-faqs-points-right">
                                                    15%
                                                </p>
                                                <p class="p-faqs-points-right">
                                                    20%
                                                </p>
                                                <p class="p-faqs-points-right">
                                                    30%
                                                </p>
                                                <p class="p-faqs-points-right">
                                                    40%
                                                </p>
                                                <p class="p-faqs-points-right">
                                                    50%
                                                </p>
                                            </div>
                                        </div>
                                        <h3 class="h3-faqs-third-party">
                                            For Third Party:
                                        </h3>
                                        <p class="p-faqs">Coverage is as per requirements of the Motor Vehicles Act, 1988. Compulsory Personal accident cover for
                                            owner-driver is also included. Policy can also be extended to cover various other risks like Personal
                                            Accident to occupants of vehicle, Workmen's Compensation to Driver etc over and above the cover
                                            available to him under statute.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="card card-faqs card-bg-2">
                                <div class="card-header" id="headingTwo">
                                    <h5 class="mb-0">
                                        <img src="./images/Rectangle 22.svg" alt="" />
                                        <button class="btn btn-link btn-faqs collapsed" style={sectionContent} type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                            How is the premium of my Two Wheeler determined?
                                        </button>
                                        <img src="images/chevron-bottom.svg" class="img-chevron-down-faqs float-right" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo" />
                                    </h5>
                                </div>
                                <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                                    <div class="card-body">
                                        <p class="p-faqs-para">
                                            Many factors determine the premium you will pay. For Own Damage cover different insurance companies charge different premiums for similar coverage. Check various insurers’s quotes at our platform, compare & buy. Do not forget to compare deductibles, coverage and IDV’s as premium may be lesser of one insurer but with higher deductibles, lower coverage and lower IDV, which will adversely impact you in the event of claim settlement. </p>
                                        <p class="p-faqs-para">
                                            With so many Plans & coverage been offered by various Insurance companies it is indeed difficult for you to choose single insurance policy from an insurer. PoliciCue platform gives you an ease to decide while comparing the benefit on offer from various Insurer according to your need in single go and facilitates the opting of an insurance Policy which is best fit to your specific purpose. </p>
                                        <p class="p-faqs-para">
                                            Be prepared to share with us information about the following items that are commonly used to determine your premium: Vehicle registration details with Engine No., Chasis no., Class of vehicle, cubic capacity, seating capacity, etc. (In fact, all relevant details are in the RC book/card and a copy of same may be handed over) Tax paid details; Certificate of fitness, Driver details - age, gender, qualifications, licence validity Previous insurance history, if any.</p>
                                        <p class="p-faqs-para">
                                            The Own Damage coverage is left to be rated by individual insurance companies after duly filing rates with the Insurance Regulatory and Development Authority. The same is determined  on following factors amongst others -- Age of vehicle; Discounts / loadings- Appropriate Bonus / loading/ discounts along with past claims experience are taken into account while calculating premium. IDV (Insured Declared Value). </p>
                                        <p class="p-faqs-para">
                                            Third Party Liability Premium rates are laid down by IRDA.</p>
                                        <p class="p-faqs-para">
                                            In case of break in insurance, vehicle inspection may be required by the insurer. </p>


                                    </div>
                                </div>
                            </div>
                            <div class="card card-faqs card-bg-1">
                                <div class="card-header" id="headingThree">
                                    <h5 class="mb-0">
                                        <img src="./images/Rectangle 22.svg" alt="" />
                                        <button class="btn btn-link btn-faqs collapsed" style={sectionContent} type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                            Which policy should I buy , Comprehensive Packed Policy or Third Party?
                                        </button>
                                        <img src="images/chevron-bottom.svg" class="img-chevron-down-faqs float-right" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree" />
                                    </h5>
                                </div>
                                <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                                    <div class="card-body">
                                        <p class="p-faqs-para">
                                            As per law, only third party liability only policy is required without which you cannot use the vehicle on road. However, under third party liability only policy, any damage to your vehicle due to accident, fire, theft, earthquake, terrorism etc. is not covered and it could result into a huge financial loss. Therefore, it is recommended to buy a comprehensive cover as it provides financial protection along with protection from third party liability. </p>
                                    </div>
                                </div>
                            </div>
                            <div class="card card-faqs card-bg-2">
                                <div class="card-header" id="headingFour">
                                    <h5 class="mb-0">
                                        <img src="./images/Rectangle 22.svg" alt="" />
                                        <button class="btn btn-link btn-faqs collapsed" style={sectionContent} type="button" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                            What is No Claim Bonus (NCB) ?
                                        </button>
                                        <img src="images/chevron-bottom.svg" class="img-chevron-down-faqs float-right" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour" />
                                    </h5>
                                </div>
                                <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordionExample">
                                    <div class="card-body">
                                        <p class="p-faqs-para">
                                            No Claim Bonus (NCB) is the benefit accrued to an insured for not making any claims during the previous policy period.  As per current norms in India, it ranges from 20% on the Own Damage premium (and not on Liability premium) and progressively increases to a maximum of 50%. </p>
                                        <div class="row">
                                            <div class="col-lg-7">
                                                <h3 class="h3-faqs">All types of Vehicles</h3>
                                                <p class="p-faqs-points">
                                                    No claim made or pending during the preceding full year of insurance

                                                </p>
                                                <p class="p-faqs-points">
                                                    No claim made or pending during the preceding 2 consecutive years of insurance

                                                </p>
                                                <p class="p-faqs-points">
                                                    No claim made or pending during the preceding 3 consecutive years of insurance

                                                </p>
                                                <p class="p-faqs-points">
                                                    No claim made or pending during the preceding 4 consecutive years of insurance

                                                </p>
                                                <p class="p-faqs-points">
                                                    No claim made or pending during the preceding 5 consecutive years of insurance

                                                </p>

                                            </div>
                                            <div class="col-lg-5">
                                                <h3 class="h3-faqs">% of Discount on Own Damage premium</h3>
                                                <p class="p-faqs-points-right">
                                                    20%
                                                </p> <br />
                                                <p class="p-faqs-points-right">
                                                    25%
                                                </p> <br />
                                                <p class="p-faqs-points-right">
                                                    35%
                                                </p> <br /> <br />
                                                <p class="p-faqs-points-right">
                                                    45%
                                                </p> <br />
                                                <p class="p-faqs-points-right">
                                                    50%
                                                </p>

                                            </div>
                                        </div>
                                        <p class="p-faqs-para">
                                            If, however, a claim is lodged, the No Claim Bonus is lost in the subsequent policy period. <br /><br />
                                            NCB is given to the insured and not to the insured vehicle. Hence, on transfer of the vehicle, the insurance policy can be transferred to new owner but not the NCB. The new owner has to pay the difference on account of NCB for the balance policy period. The original owner can, however, use the NCB on a new vehicle purchased by him. </p>

                                    </div>
                                </div>
                            </div>
                            <div class="card card-faqs card-bg-1">
                                <div class="card-header" id="headingFive">
                                    <h5 class="mb-0">
                                        <img src="./images/Rectangle 22.svg" alt="" />
                                        <button class="btn btn-link btn-faqs collapsed" style={sectionContent} type="button" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                            What happens to No Claim Bonus (NCB) for an expired policy ?
                                        </button>
                                        <img src="images/chevron-bottom.svg" class="img-chevron-down-faqs float-right" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive" />
                                    </h5>
                                </div>
                                <div id="collapseFive" class="collapse" aria-labelledby="headingFive" data-parent="#accordionExample">
                                    <div class="card-body">
                                        <p class="p-faqs-para">
                                            No Claim Bonus is valid up to 90 days from the previous policy expiry date. If the policy is not renewed within 90 days, No Claim Bonus will become 0% and no benefit shall be passed on to the renewed policy. </p>
                                    </div>
                                </div>
                            </div>
                            <div class="card card-faqs card-bg-2">
                                <div class="card-header" id="headingSix">
                                    <h5 class="mb-0">
                                        <img src="./images/Rectangle 22.svg" alt="" />
                                        <button class="btn btn-link btn-faqs collapsed" style={sectionContent} type="button" data-toggle="collapse" data-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                            What is deductible ?
                                        </button>
                                        <img src="images/chevron-bottom.svg" class="img-chevron-down-faqs float-right" data-toggle="collapse" data-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix" />
                                    </h5>
                                </div>
                                <div id="collapseSix" class="collapse" aria-labelledby="headingSix" data-parent="#accordionExample">
                                    <div class="card-body">
                                        <p class="p-faqs-para">
                                            Deductible or “excess” is the amount over and above, which the claim will be payable. There is a normal standard/compulsory excess for most of the vehicles depending upon the cubic capacity/carrying capacity of the vehicle. However, in some cases the insurer may impose additional excess depending upon insurer’s underwriting guideline. </p>
                                    </div>
                                </div>
                            </div>
                            <div class="card card-faqs card-bg-1">
                                <div class="card-header" id="headingSeven">
                                    <h5 class="mb-0">
                                        <img src="./images/Rectangle 22.svg" alt="" />
                                        <button class="btn btn-link btn-faqs collapsed" style={sectionContent} type="button" data-toggle="collapse" data-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                                            What are the documents that are required to be submitted for a Motor Insurance claim ?
                                        </button>
                                        <img src="images/chevron-bottom.svg" class="img-chevron-down-faqs float-right" data-toggle="collapse" data-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven" />
                                    </h5>
                                </div>
                                <div id="collapseSeven" class="collapse" aria-labelledby="headingSeven" data-parent="#accordionExample">
                                    <div class="card-body">
                                        <p class="p-faqs-para">
                                            Generally, the following documents are required to be submitted. However, read through your policy to see the complete list—duly filled in claim form, RC copy of the vehicle, original estimate of loss, original repair invoice and payment receipt. In case cashless facility is availed, only repair invoice would need to be submitted and FIR, if required. For theft claims, all keys are to be submitted. Theft claims would also require non-traceable certificate/final report to be submitted. </p>
                                    </div>
                                </div>
                            </div>

                        </div> */}
                    </div>
                </div>
            </div>
            <Footer />
            <Modal show={show} onHide={handleClose}>

                <Modal.Body>
                    <div className='modal-block-close-btn'>
                        <button type="button" class="btn text-muted modal-btn-size" onClick={handleClose} ><i class="bi bi-x-circle fas fa-lg modal-btn-icon"></i></button>
                    </div>
                    <div className='modal-block'>
                        <img className="thankyou" src="images/thankyou.png" alt="alternative" style={{ width: "100px" }} />
                        <h2>Thank You!</h2>
                        <div class="mb-4">
                            <div class="d-flex justify-content-between" >
                                <label class="" style={{ textAlign: "center", fontFamily: "Calibri", color: "black" }}>
                                    Your query has been submitted successfully and our executive will contact you shortly.
                                </label>

                            </div>
                        </div>
                    </div>

                </Modal.Body>

            </Modal>
            <div class="modal fade " id="thankyouModal" tabindex="-1" aria-labelledby="thankyouModalLabel" aria-hidden="true" style={{ marginTop: "170px" }}>
                {/* <!-- <div class="modal-dialog modal-dialog-centered"> --> */}
                <div class="modal-dialog modal-sm rounded-circle" >
                    <div class="modal-content" style={{ borderRadius: "12px", width: "130%" }} >
                        {/* <div class="modal-header ">                */}
                        {/* <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" style={{marginLeft:"265px"}}></button> */}
                        <button type="button" class="btn text-muted" data-bs-dismiss="modal" style={{ marginLeft: "305px" }}><i class="bi bi-x-circle fas fa-lg"></i></button>
                        {/* </div> */}
                        <div class="modal-body " style={{ backgroundColor: "white" }}>
                            <div class="row">
                                <div class="col-lg-9 col-md-9 col-sm-12 col-12 mx-auto">
                                    <div class="form-group pb-2">

                                        <img className="thankyou" src="images/thankyou.png" alt="alternative" style={{ width: "108px", marginLeft: "75px" }} />
                                        <h2 style={{ marginLeft: "60px" }}>Thank You!</h2>

                                        {/* <h2 ><i class="bi bi-check-circle fas fa-lg" style={{marginLeft:"80px", color:"#00A0E4"}}></i></h2> */}
                                    </div>
                                </div>
                                <div class="col-lg-9 col-md-9 col-sm-12 col-12 mx-auto" style={{ marginTop: "-15px" }}>
                                    <div class="mb-4">
                                        <div class="d-flex justify-content-between" >
                                            <label class="" style={{ textAlign: "center", fontFamily: "Calibri", color: "black" }}>
                                                Your query had been submitted successfully and our executive will contact you shortly.
                                            </label>

                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
