import React from 'react'
import './Input.css'

function Input({ label, type, name, onChange }) {
    return (
        <div class="form-groupp" >
            <input
                type={type}
                id="icueRegistrationNo"
                class="form-control"
                name={name}
                onChange={onChange}
                required
            />
            <label class="ms-3 form-control-placeholderr" for="icueRegistrationNo">
                {label}
            </label>
        </div>
    )
}

export default Input
