import React, { useState, Fragment } from "react";
import './LeaveType.css';
import { nanoid } from "nanoid";
import data from "./mock-data.json";
import ReadOnlyRow from "./ReadOnlyRow";
import EditableRow from "./EditableRow";
const LeaveType=()=>{
    const [contacts, setContacts] = useState(data);
  const [addFormData, setAddFormData] = useState({
    leaveType: "",
    days:""
    
  });
  const [editFormData, setEditFormData] = useState({
    leaveType: "",
    days:""
    
  });
  const [editContactId, setEditContactId] = useState(null);

  const handleAddFormChange = (event) => {
    event.preventDefault();

    const fieldName = event.target.getAttribute("name");
    const fieldValue = event.target.value;

    const newFormData = { ...addFormData };
    newFormData[fieldName] = fieldValue;

    setAddFormData(newFormData);
  };

  const handleEditFormChange = (event) => {
    event.preventDefault();

    const fieldName = event.target.getAttribute("name");
    const fieldValue = event.target.value;

    const newFormData = { ...editFormData };
    newFormData[fieldName] = fieldValue;

    setEditFormData(newFormData);
  };
  const handleAddFormSubmit = (event) => {
    event.preventDefault();
    const newContact = {
        id: nanoid(),
        leaveType: addFormData.leaveType,
        days: addFormData.days,
       
      };
  
      const newContacts = [...contacts, newContact];
    
      setContacts(newContacts);
    };
    const handleEditFormSubmit = (event) => {
        event.preventDefault();
    
        const editedContact = {
          id: editContactId,
          leaveType: editFormData.leaveType,
          days: editFormData.days,
         
        };
        const newContacts = [...contacts];
    
        const index = contacts.findIndex((contact) => contact.id === editContactId);
    
        newContacts[index] = editedContact;
    
        setContacts(newContacts);
        setEditContactId(null);
      };
    
      const handleEditClick = (event, contact) => {
        event.preventDefault();
        setEditContactId(contact.id);
    
        const formValues = {
          leaveType: contact.leaveType,
          days:contact.days,
         
        };
    
        setEditFormData(formValues);
      };
    
      const handleCancelClick = () => {
        setEditContactId(null);
      };
    
      const handleDeleteClick = (contactId) => {
        const newContacts = [...contacts];
    
        const index = contacts.findIndex((contact) => contact.id === contactId);
    
        newContacts.splice(index, 1);
    
        setContacts(newContacts);
      };
// export default function LeaveType(){
    return(<div className="LeaveType" id="LeaveType">
          {/* <!-- Top Bar --> */}
    <div class="container-fluid1 py-2 icueTopBarContainer bg-white">
        <div class="row">
            <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                <div class="topBarLfContainer d-flex">
                    <div class="icueLogoContainer">
                        <img src="images/logo.png" alt="ICue Logo" class="icueLogo" />
                    </div>
                    <div class="icueSearchBox w-75 mx-3">
                        <div class="input-group ">
                            <span class="input-group-text" id="basic-addon1">
                                <i class="bi bi-search"></i>
                            </span>
                            <input type="search" id="text-search" class="search form-control icueSearchBoxControl" placeholder="Search Users by Name, Email or Date" aria-label="Username" aria-describedby="basic-addon1" style={{backgroundColor:"#F4F7FC"}} />
                          </div>
                    </div>
                </div>
            </div>

            <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                <div class="d-flex flex-row-reverse">
                    <div class="icueProfile px-2">
                        <img src="images/avatar.png" alt="icueProfilePic" class="icueProfilePic border border-dark rounded-circle" />
                    </div>
                    <div class="position-relative px-2">
                        <a href="#" id="icueNotifyBoxHandler">
                            <i class="bi bi-bell">
                                <span class="position-absolute icueNofIndic"></span>
                            </i>
                        </a>
                        <div class="icueNotifyBox" id="icueNotifyBox">
                            Notifications
                        </div>
                    </div>

                    <div class="icueUserName fs-6 px-2">
                        Viral Patel
                    </div>

                    <div class="icueMail px-2">
                        <i class="bi bi-headset"></i> &nbsp;
                        adminhelpdesk@gmail.com
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* <!-- /Top Bar -->

    <!-- Main Body --> */}
    <div class="container-fluid my-2">
        <div class="row">
            <div class="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12 bg-white">
                <div class="icueRespSideBar d-xxl-none d-xl-none d-lg-none d-md-block d-sm-block d-block" id="icueRespSideBar">
                    <button type="button" class="btn btn-primary" id="icueRespHumburger">
                        <i class="bi bi-grid-3x3-gap"></i>
                    </button>
                    <div class="icueSideBar" id="icueSideBar">
                        <nav class="navbar icueSideNavContainer">
                            <ul class="icueNavBarList">
                                <li class="icueNavBarItem ">
                                    <a href="/Hrdashboard" class="icueNavBarLink" id="">Dashboard</a>
                                </li>
                                <li class="icueNavBarItem border-bottom-0">
                                <a href="#" class="icueNavBarLink" id="">User Management</a>
                            </li>

                            <li>
                                <ul class="icueSubNavBar">
                                    <li class="icueSubNavItem">
                                        {/* <!-- <a href="#" data-bs-toggle="modal" data-bs-target="#addid" data-bs-whatever="@getbootstrap" class="icueSubNavLink  text-capitalize">Add Member</a> --> */}
                                        <a href="#" class="icueSubNavLink  text-capitalize">Add Members</a>
                                    </li>
                                    <li class="icueSubNavItem">
                                        <a href="/ListMembers" class="icueSubNavLink  text-capitalize">List of Members</a>
                                    </li>
                                </ul>
                            </li>
                            <hr></hr>

                                <li class="icueNavBarItem border-bottom-0">
                                <a href="#" class="icueNavBarLink" id="">Leave Management</a>
                            </li>

                            <li >
                                <ul class="icueSubNavBar ">
                                    <li class="icueSubNavItem" style={{marginTop:"-8px" }}>
                                       
                                        <a href="/Leaves" class="icueSubNavLink  text-capitalize">Leaves</a>
                                    </li>                                   
                                </ul>                                
                            </li> 
                            <li className="active">
                                <ul>
                                <li class="icueSubNavItem" style={{marginLeft:"-20px"}}>
                                        <a href="/LeaveType" class="icueSubNavLink  text-capitalize">Leave Type</a>
                                    </li>
                                </ul>
                                </li>             
                             <hr/>
                            <li class="icueNavBarItem border-bottom-1">
                                <a href="/Holidays" class="icueNavBarLink" id="">Holidays</a>
                            </li>                            
                            <li class="icueNavBarItem border-bottom-1">
                                <a href="/Attendance" class="icueNavBarLink" id="">Attendance</a>
                            </li>                       
    
                                 
    
                                <li class="icueNavBarItem">
                                    <a href="/Department" class="icueNavBarLink" id="">Department</a>
                                </li>
    
                                <li class="icueNavBarItem">
                                    <a href="/AddRoles" class="icueNavBarLink" id="">Roles</a>
                                </li>
                                
                                <li class="icueNavBarItem border-bottom-0">
                                <a href="#" class="icueNavBarLink" id="">Payroll</a>
                            </li>
                            <li>
                                <ul class="icueSubNavBar">
                                    <li class="icueSubNavItem">
                                        <a href="/EmpSalary" class="icueSubNavLink  text-capitalize">Employee Salary</a>
                                    </li>
                                    <li class="icueSubNavItem">
                                        <a href="/Payitems" class="icueSubNavLink  text-capitalize">Payroll Items</a>
                                    </li>
                                </ul>
                                <hr></hr>
                            </li>
                                
                                <li class="icueNavBarItem border-bottom-0" style={{marginTop:"-18px"}}>
                                    <a href="#" class="icueNavBarLink" id="">Settings</a>
                                </li>
                                <li>
                                    <ul class="icueSubNavBar">
                                        <li class="icueSubNavItem">
                                            <a href="#" class="icueSubNavLink  text-capitalize">Change Password</a>
                                        </li>
                                        <li class="icueSubNavItem">
                                            <a href="#" class="icueSubNavLink  text-capitalize">Profile</a>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>

                <div class="icueNavSideBar d-xxl-block d-xl-block d-lg-block d-md-none d-sm-none d-none" id="icueNavSideBar">
                    <nav>
                        <ul class="icueNavBarList">
                            <li class="icueNavBarItem ">
                                <a href="/Hrdashboard" class="icueNavBarLink" id="">Dashboard</a>
                            </li>

                            <li class="icueNavBarItem border-bottom-0">
                                <a href="#" class="icueNavBarLink" id="">User Management</a>
                            </li>

                            <li>
                                <ul class="icueSubNavBar">
                                    <li class="icueSubNavItem"  style={{marginTop:"-8px"}}>
                                        {/* <!-- <a href="#" data-bs-toggle="modal" data-bs-target="#addid" data-bs-whatever="@getbootstrap" class="icueSubNavLink  text-capitalize">Add Member</a> --> */}
                                        <a href="#" class="icueSubNavLink  text-capitalize">Add Member</a>
                                    </li>
                                    <li class="icueSubNavItem">
                                        <a href="/ListMembers" class="icueSubNavLink  text-capitalize">List of Members</a>
                                    </li>
                                </ul>
                            </li>
                            <hr></hr>

                            <li class="icueNavBarItem border-bottom-0" style={{marginTop:"-18px"}}>
                                <a href="#" class="icueNavBarLink" id="" >Leave Management</a>
                            </li>

                            <li >
                                <ul class="icueSubNavBar ">
                                    <li class="icueSubNavItem" style={{marginTop:"-8px" }}>
                                       
                                        <a href="/Leaves" class="icueSubNavLink  text-capitalize">Leaves</a>
                                    </li>                                   
                                </ul>                                
                            </li> 
                            <li className="active">
                                <ul>
                                <li class="icueSubNavItem" style={{marginLeft:"-20px"}}>
                                        <a href="/LeaveType" class="icueSubNavLink  text-capitalize">Leave Type</a>
                                    </li>
                                </ul>
                                </li>             
                             <hr/>            

                            <li class="icueNavBarItem "style={{marginTop:"-18px"}}>
                                <a href="/Holidays" class="icueNavBarLink" id="">Holidays</a>
                            </li>   
                            <li class="icueNavBarItem ">
                                <a href="/Attendance" class="icueNavBarLink" id="">Attendance</a>
                            </li>   
                            
                            

                            
                            <li class="icueNavBarItem" >
                                <a href="/Department" class="icueNavBarLink" id="">Department</a>
                            </li>

                            <li class="icueNavBarItem">
                                <a href="/AddRoles" class="icueNavBarLink" id="">Roles</a>
                            </li>
                            

                            <li class="icueNavBarItem border-bottom-0">
                                <a href="#" class="icueNavBarLink" id="">Payroll</a>
                            </li>
                            <li>
                                <ul class="icueSubNavBar">
                                    <li class="icueSubNavItem">
                                        <a href="/EmpSalary" class="icueSubNavLink  text-capitalize">Employee Salary</a>
                                    </li>
                                    <li class="icueSubNavItem">
                                        <a href="/Payitems" class="icueSubNavLink  text-capitalize">Payroll Items</a>
                                    </li>
                                </ul>
                                <hr></hr>
                            </li>
                           
                            <li class="icueNavBarItem border-bottom-0"  style={{marginTop:"-18px"}}>
                                <a href="#" class="icueNavBarLink" id="">Settings</a>
                            </li>
                            <li>
                                <ul class="icueSubNavBar">
                                    <li class="icueSubNavItem">
                                        <a href="#" class="icueSubNavLink  text-capitalize">Change Password</a>
                                    </li>
                                    <li class="icueSubNavItem">
                                        <a href="#" class="icueSubNavLink  text-capitalize">Profile</a>
                                    </li>
                                </ul>
                                <hr></hr>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>


            <div class="col-xxl-10 col-xl-10 col-lg-10 col-md-12 col-sm-12 col-12 ">
                <main class="mainBodyContainer">
                <div class="row my-2">
                        <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 py-2 px-3">
                            <div class="d-flex justify-content-between">
                                <h4 class="text-capitalize" style={{fontFamily:"ErasDemiITC", marginLeft:"12px"}}>Leave Type</h4>
                                <div class="bg-transparent p-2  rounded-3">
                                <nav style={{bsbreadcrumbdivider: 'url(&#34;"data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="8" height="8"%3E%3Cpath d="M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z" fill="currentColor"/%3E%3C/svg%3E&#34")'}} aria-label="breadcrumb">
                                    <ol class="breadcrumb mb-0 justify-content-end">
                                        <li class="breadcrumb-item bg-transparent"><a href="/Hrdashboard" class="text-decoration-none text-info">Dashboard</a></li>
                                        <li class="breadcrumb-item bg-transparent" style={{fontFamily:"Calibri"}}><a href="#" class="text-decoration-none text-info">Leave Management</a></li>
                                        <li class="breadcrumb-item text-muted text-capitalize bg-transparent" aria-current="page">Leave Types</li>
                                    </ol>
                                </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row my-2">
                        <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 py-1 px-3" >
                            <div class="bg-white p-2  rounded-3 shadow-sm">
                                <div class="px-2 py-3">                  
                                      <div class="container">
                                      <div class="row">
                                          <br/>
                                             
                                      <div class="addroles col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12" style={{backgroundColor:"#F4F7FC", marginTop:"-24px", marginLeft:"-28px", marginBottom:"-25px"}}>
                                      <label for="icueLmsUpdFormFName" class="form-label icueTextPrimary  text-capitalize" style={{color:"#2E3B52", fontFamily:"Calibri", fontSize:"18px", marginTop:"40px", marginLeft:"20px", fontSize:"bold"}}>Enter Leave Type</label>
                                      <form onSubmit={handleAddFormSubmit}>
                                            <input type="text" name="leaveType" class="form-control bg-white text-capitalize" onChange={handleAddFormChange} style={{backgroundColor:"#EBEFF2", width:"80%", marginLeft:"20px"}}/>
                                            <label for="icueLmsUpdFormFName" class="form-label icueTextPrimary  text-capitalize" style={{color:"#2E3B52", fontFamily:"Calibri", fontSize:"18px", marginTop:"20px", marginLeft:"20px", fontSize:"bold"}}>No. of Days</label>
                                            
                                            <input type="number"  name="days" class="form-control bg-white text-capitalize" onChange={handleAddFormChange} style={{backgroundColor:"#EBEFF2", width:"80%", marginLeft:"20px"}}/> </form>
                                            <br/>
                                            <div class="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12" style={{backgroundColor:"#F4F7FC"}}>
                                                <button class=" btn-sm  text-white" type="submit" data-bs-toggle="modal" onClick={handleAddFormSubmit} data-bs-target="#icueCreateFollowUpModal" style={{backgroundColor:"#3A3285", width:"140%",  marginLeft:"8px"}}><i class="bi bi-plus-circle"></i> Add  Leave Type</button>
                                            </div>
                                          
                                         
                                            </div>
           
                                               <div class="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12  bg-white">
                                                    <div class="tab-content py-1 px-3 px-sm-0" id="myTabContent">
                                                        <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                                          <div class="row">              
                                                               <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12  bg-white">              
                                                               <form onSubmit={handleEditFormSubmit}>
                                            <table id="payrollTable" class="table table-striped table-hover " width="auto" >
                                                <thead>
                                                    <tr class="text-uppercase" style={{lineHeight:"15px", fontFamily:"Calibri", fontStyle:"normal",  fontSize:"12px", backgroundColor:"white"}}>
                                                        <th class="py-3">Leave Type</th>
                                                        <th class="text-center py-3">No. of Days</th>                                                       
                                                        <th  class="text-center  py-3">Action</th>
                                                                                                               
                                                    </tr>
                                                </thead>
                                                <tbody style={{lineHeight:"15px", fontFamily:"Calibri", fontSize:"16px"}}>
                                                    {/* <tr  style={{ border:"0" }}>
                                                        <td >Medical Leave </td>
                                                        <td >2 days </td>
                                                       
                                                        <td class="text-right  py-0" >  <button type='button'class='btn btn-sm btn-transparent editModal text-muted' id='editMod_1'> <a href="#"><i class='bi bi-pencil'></i></a></button> 
                                                        <button type='button' class='btn btn-sm btn-transparent icueDelBtnRow text-muted' id='remMod_1'style={{marginLeft:"-30px"}}><i class='bi bi-trash'></i></button> </td>
                                                                                                            
                                                    </tr>

                                                    <tr >
                                                    <td >Hospitalization </td>
                                                        <td >2 days </td>
                                                        
                                                        <td class="text-right  py-0">  <button type='button'class='btn btn-sm btn-transparent editModal text-muted' id='editMod_1'> <a href="#"><i class='bi bi-pencil'></i></a></button> 
                                                        <button type='button' class='btn btn-sm btn-transparent icueDelBtnRow text-muted' id='remMod_1'style={{marginLeft:"-30px"}}><i class='bi bi-trash'></i></button> </td>
                                                                                                            
                                                    </tr>
                                                    <tr  style={{ border:"0"}}>
                                                    <td >Maternity Leave </td>
                                                        <td >2 days </td>
                                                         
                                                        <td class="text-right  py-0" >  <button type='button'class='btn btn-sm btn-transparent editModal text-muted' id='editMod_1'> <a href="#"><i class='bi bi-pencil'></i></a></button> 
                                                        <button type='button' class='btn btn-sm btn-transparent icueDelBtnRow text-muted' id='remMod_1'style={{marginLeft:"-30px"}}><i class='bi bi-trash'></i></button> </td>
                                                                                                            
                                                    </tr>
                                                    <tr >
                                                    <td >Casual Leave </td>
                                                        <td >2 days </td>
                                                        
                                                        <td class="text-right  py-0">  <button type='button'class='btn btn-sm btn-transparent editModal text-muted' id='editMod_1'> <a href="#"><i class='bi bi-pencil'></i></a></button> 
                                                        <button type='button' class='btn btn-sm btn-transparent icueDelBtnRow text-muted' id='remMod_1'style={{marginLeft:"-30px"}}><i class='bi bi-trash'></i></button> </td>
                                                                                                            
                                                    </tr>
                                                    <tr >
                                                    <td >Paternity Leave </td>
                                                        <td >2 days </td>
                                                        
                                                        <td class="text-right  py-0">  <button type='button'class='btn btn-sm btn-transparent editModal text-muted' id='editMod_1'> <a href="#"><i class='bi bi-pencil'></i></a></button> 
                                                        <button type='button' class='btn btn-sm btn-transparent icueDelBtnRow text-muted' id='remMod_1'style={{marginLeft:"-30px"}}><i class='bi bi-trash'></i></button> </td>
                                                                                                            
                                                    </tr>
                                                    
                                                    <tr>
                                                    <td >Loss of Pay </td>
                                                        <td >1 days </td>
                                                        
                                                        <td class="text-right  py-0">  <button type='button'class='btn btn-sm btn-transparent edit text-muted' data-toggle="tooltip" id='editMod_1'> <a href="#"><i class='bi bi-pencil'></i></a></button> 
                                                        <button type='button' class='btn btn-sm btn-transparent icueDelBtnRow text-muted' id='remMod_1'style={{marginLeft:"-30px"}}><i class='bi bi-trash'></i></button> </td>
                                                                                                            
                                                    </tr> */}
                                                    {contacts.map((contact) => (
              <Fragment>
                {editContactId === contact.id ? (
                  <EditableRow
                    editFormData={editFormData}
                    handleEditFormChange={handleEditFormChange}
                    handleCancelClick={handleCancelClick}
                  />
                ) : (
                  <ReadOnlyRow
                    contact={contact}
                    handleEditClick={handleEditClick}
                    handleDeleteClick={handleDeleteClick}
                  />
                )}
              </Fragment>
            ))}
         
                                                   
                                                </tbody>
                                            </table>
                                            </form>
                                        </div>
                                    </div>   
                                      </div>
                                      
                                </div>
                            </div>
                           
                        </div>
                    </div>
               
                </div>
            </div>
            
            </div>
           
        </div>

        
                </main>
            </div>
        </div>
    </div>
    
    </div>)
}
export default LeaveType;