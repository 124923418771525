import { LazyLoadImage } from "react-lazy-load-image-component";
import X from "../../assets/img/x.png";
import ImagePlaceholder from "../../assets/img/img_placeholder.png";
import { file, stringFormat } from "../../utils/methods";

const PaymentDetailsForm = ({
   updateValue = void 0,
  personalDetail = [],
  bankLoad = false,
  uploadSingleFile = void 0,
  deleteFile = void 0,
  chequeFile = "",
  formErrors = [],
  errors = [],
  isDisabled = false,
  data = [],
}) => {
  function isExistingValue(value) {
    if (isDisabled && data?.[0]?.animal_lost_in_last_three_year === value) {
      return true;
    }
    return false;
  }
  return (
    <>
    <div className="row row-cols-1 row-cols-lg-auto form">
        <div className="col d-flex align-items-center">
          <h6 style={{ marginRight: "2rem", marginBottom: "0", color:"#29166F", fontSize:"18px" }}>
            Animals lost in last 3 years?
          </h6>
          {!isExistingValue("yes") ? (
            <input
              type="radio"
              id="yes"
              name="radio-group"
              className="me-3"
              value="yes"
              onChange={(e) => updateValue("animal_lost_type", e)}
              disabled={isDisabled}
              checked={personalDetail["animal_lost_type"] === "yes" || (isDisabled && data[0].animal_lost_in_last_three_year==1)}
            />
          ) : (
            <i className="bi bi-check-circle-fill text-primary me-2"></i>
          )}
          <span>Yes</span>
        </div>
        <div className="col d-flex align-items-center">
          {!isExistingValue("no") ? (
            <input
              type="radio"
              id="no"
              name="radio-group"
              className="me-3"
              value="no"
              onChange={(e) => updateValue("animal_lost_type", e)}
              checked={personalDetail["animal_lost_type"] === "no" || (isDisabled && data[0].animal_lost_in_last_three_year==0)}
              disabled={isDisabled}
            />
          ) : (
            <i className="bi bi-check-circle-fill text-primary me-2"></i>
          )}
          <span>No</span>
        </div>
      </div>
      {(personalDetail["animal_lost_type"] == "yes" ||  (isDisabled && data[0].animal_lost_in_last_three_year==1 ))&&
        <div className="row row-cols-1 row-cols-lg-4 gy-4 form pt-3 pb-3 mt-1">
          <div className="col position-relative">
            <input
              className="w-100"
              id="lost_in_year"
              type="number"
              name="lost_in_year"
              onChange={(e) => updateValue("lost_in_year-0", e)}
              value={
                !isDisabled
                  ? personalDetail["lost_in_year-0"]
                  : (data?.[0]?.lost_in_year)
              }
              disabled={false}
            />
            <label className="ms-3 position-absolute" htmlFor="Lost In Year">
              Lost In Year
            </label>
          </div>
          <div className="col position-relative">
            <input
              className="w-100"
              id="cause_of_loss"
              type="text"
              name="cause_of_loss"
              onChange={(e) => updateValue("cause_of_loss-0", e)}
              value={
                !isDisabled
                  ? personalDetail["cause_of_loss-0"]
                  : (data?.[0]?.cause_of_loss)
              }
              disabled={isDisabled}
            />
            <label className="ms-3 position-absolute" htmlFor="Cause of Loss">
              Cause of Loss
            </label>
          </div>
          <div className="col position-relative">
            <input
              className="w-100"
              id="no_of_animals_lost"
              type="number"
              name="no_of_animals_lost"
              onChange={(e) => updateValue("no_of_animals_lost-0", e)}
              value={
                !isDisabled
                  ? personalDetail["no_of_animals_lost-0"]
                  : (data?.[0]?.no_of_animals_lost)
              }
              disabled={false}
            />
            <label className="ms-3 position-absolute" htmlFor="No. of Animals Lost">
              No. of Animals Lost
            </label>
          </div>
            <div className="col position-relative">
            <input
              className="w-100"
              id="offers"
              type="text"
              name="identification_details"
              onChange={(e) => updateValue("identification_details-0", e)}
              value={
                !isDisabled
                  ? personalDetail["identification_details-0"]
                  : (data?.[0]?.identification_details)
              }
              disabled={false}
            />
            <label className="ms-3 position-absolute" htmlFor="Identification Details">
              Identification Details
            </label>
          </div>
          <div className="col position-relative">
            <input
              className="w-100"
              id="lost_year2"
              type="number"
              name="lost_year2"
              onChange={(e) => updateValue("lost_in_year-1", e)}
              value={
                !isDisabled
                  ? personalDetail["lost_in_year-1"]
                  : (data?.[1]?.lost_in_year)
              }
              disabled={false}
            />
            <label className="ms-3 position-absolute" htmlFor="Lost In Year">
              Lost In Year
            </label>
          </div>

          <div className="col position-relative">
            <input
              className="w-100"
              id="cause_of_loss2"
              type="text"
              name="cause_of_loss2"
              onChange={(e) => updateValue("cause_of_loss-1", e)}
              value={
                !isDisabled
                  ? personalDetail["cause_of_loss-1"]
                  : (data?.[1]?.cause_of_loss)
              }
              disabled={isDisabled}
            />
            <label className="ms-3 position-absolute" htmlFor="Cause of Loss">
              Cause of Loss
            </label>
          </div>
          <div className="col position-relative">
            <input
              className="w-100"
              id="no_of_animal_lost2"
              type="number"
              name="no_of_animal_lost2"
              onChange={(e) => updateValue("no_of_animals_lost-1", e)}
              value={
                !isDisabled
                  ? personalDetail["no_of_animals_lost-1"]
                  : (data?.[1]?.no_of_animals_lost)
              }
              disabled={false}
            />
            <label className="ms-3 position-absolute" htmlFor="No. of Animals Lost">
              No. of Animals Lost
            </label>
          </div>
           <div className="col position-relative">
            <input
              className="w-100"
              id="offers"
              type="text"
              name="identification_details"
              onChange={(e) => updateValue("identification_details-1", e)}
              value={
                !isDisabled
                  ? personalDetail["identification_details-1"]
                  : (data?.[1]?.identification_details)
              }
              disabled={false}
            />
            <label className="ms-3 position-absolute" htmlFor="Identification Details">
              Identification Details
            </label>
          </div>
          <div className="col position-relative">
            <input
              className="w-100"
              id="lost_year3"
              type="number"
              name="lost_year3"
              onChange={(e) => updateValue("lost_in_year-2", e)}
              value={
                !isDisabled
                  ? personalDetail["lost_in_year-2"]
                  : (data?.[2]?.lost_in_year)
              }
              disabled={false}
            />
            <label className="ms-3 position-absolute" htmlFor="Lost In Year">
              Lost In Year
            </label>
          </div>
          <div className="col position-relative">
            <input
              className="w-100"
              id="cause_of_loss3"
              type="text"
              name="cause_of_loss3"
              onChange={(e) => updateValue("cause_of_loss-2", e)}
              value={
                !isDisabled
                  ? personalDetail["cause_of_loss-2"]
                  : (data?.[2]?.cause_of_loss)
              }
              disabled={isDisabled}
            />
            <label className="ms-3 position-absolute" htmlFor="Cause of Loss">
              Cause of Loss
            </label>
          </div>

          <div className="col position-relative">
            <input
              className="w-100"
              id="offers"
              type="number"
              name="no_of_animal_lost3"
              onChange={(e) => updateValue("no_of_animals_lost-2", e)}
              value={
                !isDisabled
                  ? personalDetail["no_of_animals_lost-2"]
                  : (data?.[2]?.no_of_animals_lost)
              }
              disabled={false}
            />
            <label className="ms-3 position-absolute" htmlFor="No. of Animals Lost3">
              No. of Animals Lost
            </label>
          </div>
        
           
            <div className="col position-relative">
            <input
              className="w-100"
              id="offers"
              type="text"
              name="identification_details"
              onChange={(e) => updateValue("identification_details-2", e)}
              value={
                !isDisabled
                  ? personalDetail["identification_details-2"]
                  : (data?.[2]?.identification_details)
              }
              disabled={false}
            />
            <label className="ms-3 position-absolute" htmlFor="Identification Details">
              Identification Details
            </label>
          </div>
        </div>
      }
     
    </>
  );
};

export default PaymentDetailsForm;
