import React from 'react';
import './ForgotPassword.css';
//import 'bootstrap-icons/font/bootstrap-icons.css';
export default function ForgotPassword(){
    return(<div className="Forgotpassword">
        <div class="container-fluid1" >
        <div class="row justify-content-center align-content-center" style={{height:"100vh"}}>
            <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-7 col-sm-10 col-12 border bg-white p-5 shadow rounded-3  h-auto">
                <div class="text-center">
                    <img src="images/logo.png" class="icueLogo" style={{marginLeft:"1rem"}} />
                    <h3 className="text-capitalize pt-3" style={{fontFamily:"ErasDemiITC"}}>Forgot password</h3>
                    <p class="text-muted">Enter your email to get reset password link</p>
                </div>
                <form id="icueSuperAdminForm" class="">
                    <div class="row">
                        <div class="col-xxl-7 col-xl-7 col-lg-7 col-md-8 col-sm-10 col-10 mx-auto">
                            <div class="row">
                                <div class="col-12 pt-2">
                                    <div class="form-group pb-2">
                                        <input type="email" class="form-control icueResetPassEmail text-capitalize" name="icueResetPassEmail" id="icueResetPassEmail" placeholder="enter email" />
                                    </div>
                                </div>

                                <div class="col-12 py-3">
                                    <div class="d-grid gap-2">
                                        <button type="button" class="btn btn-primary  " style={{fontFamily:"Calibri"}} >
                                            Send
                                            {/* <i class="bi bi-arrow-right-short" ></i> */}
                                        </button>
                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        
        </div>
    </div>
    </div>)
}