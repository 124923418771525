import React from 'react';
import './Renew3.css';
export default function Renew3(){
    return(<div className="RenewPolicy">
          <div class="container-fluid py-2 icueTopBarContainer bg-white">
        <div class="row">
            <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                <div class="topBarLfContainer d-flex">
                    <div class="icueLogoContainer">
                        <img src="images/logo.png" alt="ICue Logo" class="icueLogo" />
                    </div>
                    <div class="icueSearchBox w-75 mx-3">
                        <div class="input-group ">
                            <span class="input-group-text" id="basic-addon1">
                                <i class="bi bi-search"></i>
                            </span>
                            <input type="search" class="form-control icueSearchBoxControl" placeholder="Enter keywords..." aria-label="Username" aria-describedby="basic-addon1"/>
                          </div>
                    </div>
                </div>
            </div>

            <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                <div class="d-flex flex-row-reverse">
                    <div class="icueProfile px-2">
                        <img src="images/avatar.png" alt="icueProfilePic" class="icueProfilePic border border-dark rounded-circle" />
                    </div>
                    <div class="position-relative px-2">
                        <a href="#" id="icueNotifyBoxHandler">
                            <i class="bi bi-bell">
                                <span class="position-absolute icueNofIndic"></span>
                            </i>
                        </a>
                        <div class="icueNotifyBox" id="icueNotifyBox">
                            Notifications
                        </div>
                    </div>

                    <div class="icueUserName fs-6 px-2">
                        Viral Patel
                    </div>

                    <div class="icueMail px-2">
                        <i class="bi bi-headset"></i>&nbsp;
                        adminhelpdesk@gmail.com
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div className="container-fluid my-2">
        <div className="row">
            <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12 bg-white">
                <div className="icueRespSideBar d-xxl-none d-xl-none d-lg-none d-md-block d-sm-block d-block" id="icueRespSideBar">
                    <button type="button" className="btn btn-primary" id="icueRespHumburger">
                        <i className="bi bi-grid-3x3-gap" id="icueOpenIcon"></i>
                        <i className="bi bi-x-lg" id="icueCloseIcon"></i>
                    </button>
                    <div className="icueSideBar" id="icueSideBar">
                        <nav className="navbar icueSideNavContainer">
                            <ul className="icueNavBarList">
                                <li className="icueNavBarItem ">
                                    <a href="/RetailDashboard" className="icueNavBarLink" id="">Dashboard</a>
                                </li>

                                <li className="icueNavBarItem active">
                                    <a href="/Policies" className="icueNavBarLink" id="">Policies</a>
                                </li>

                                {/* <li>
                                    <ul className="icueSubNavBar">
                                        <li className="icueSubNavItem">
                                            <a href="/AgentLMS" className="icueSubNavLink  text-capitalize">All Leads</a>
                                        </li>
                                    </ul>
                                </li> */}
                                 <li className="icueNavBarItem border-bottom-0">
                                    <a href="#" className="icueNavBarLink" id="">Claims <i class="bi bi-star-fill" ></i></a>
                                </li>
                                <hr></hr>
                                <li className="icueNavBarItem border-bottom-0">
                                    <a href="#" className="icueNavBarLink" id="">Settings</a>
                                </li>
                                <li>
                                    <ul className="icueSubNavBar">
                                        <li className="icueSubNavItem">
                                            <a href="#" className="icueSubNavLink  text-capitalize">Change Password</a>
                                        </li>
                                        <li className="icueSubNavItem">
                                            <a href="#" className="icueSubNavLink  text-capitalize">Profile</a>
                                        </li>
                                    </ul>
                                </li>
                                <hr></hr>
                            </ul>
                        </nav>
                    </div>
                </div>

                <div className="icueNavSideBar d-xxl-block d-xl-block d-lg-block d-md-none d-sm-none d-none" id="icueNavSideBar">
                    <nav>
                        <ul className="icueNavBarList">
                            <li className="icueNavBarItem ">
                                <a href="/RetailDashboard" className="icueNavBarLink" id="">Dashboard</a>
                            </li>

                            <li className="icueNavBarItem active">
                                <a href="/Policies" className="icueNavBarLink" id="">Policies</a>
                            </li>

                            {/* <li>
                                <ul className="icueSubNavBar">
                                    <li className="icueSubNavItem" style={{marginTop:"-0.75rem"}}>
                                        <a href="/AgentLMS" className="icueSubNavLink  text-capitalize">All Leads</a>
                                    </li>
                                </ul>
                            </li> */}
                            
                            <li className="icueNavBarItem danger">
                                <a href="#" className="icueNavBarLink" id="">Claims <i class="bi bi-star-fill" ></i></a>
                            </li>
                            <li className="icueNavBarItem border-bottom-0" style={{marginTop:"-0.625rem"}}>
                                <a href="#" className="icueNavBarLink" id="">Settings</a>
                            </li>
                            <li>
                                <ul className="icueSubNavBar" style={{marginTop:"-0.75rem"}}>
                                    <li className="icueSubNavItem">
                                        <a href="#" className="icueSubNavLink  text-capitalize">Change Password</a>
                                    </li>
                                    <li className="icueSubNavItem">
                                        <a href="#" className="icueSubNavLink  text-capitalize">Profile</a>
                                    </li>
                                </ul>
                            </li>
                            <hr></hr>
                        </ul>
                    </nav>
                </div>
            </div>
            <div class="col-xxl-10 col-xl-10 col-lg-10 col-md-12 col-sm-12 col-12 ">
                <main class="mainBodyContainer">
                    <div class="row my-2">
                        <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 py-2 px-3">
                            <div class="d-flex justify-content-between">
                                <h4 class="text" style={{fontFamily:"ErasDemiITC", marginLeft:"12px"}}>Renew Policy</h4>
                                <div class="bg-transparent p-2  rounded-3">
                                <nav style={{bsbreadcrumbdivider: 'url(&#34;"data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="8" height="8"%3E%3Cpath d="M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z" fill="currentColor"/%3E%3C/svg%3E&#34")'}} aria-label="breadcrumb">
                                    <ol class="breadcrumb mb-0 justify-content-end" style={{backgroundColor:"transparent"}}>
                                        <li class="breadcrumb-item"><a href="dashboard.html" class="text-decoration-none text-info" >Dashboard</a></li>
                                        <li class="breadcrumb-item text-muted text-capitalize" aria-current="page">Policies</li>
                                    </ol>
                                </nav>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row my-2">
                        <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 py-2 px-3">
                            <div class="shadow rounded-3 p-5 bg-white">
                                <form id="icueLeadInfoForm" >
                                    <div class="row">
                                        
                                         <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 mb-4"  >
                                            <label for="icueLmsUpdFormFName" class="form-label icueTextPrimary text-capitalize" style={{color:"#2E3B52", fontFamily:"Calibri", fontSize:"18px"}}>policy number</label>
                                            <input type="text" id="icueEditPolicyNumber" name="icueLmsUpdFormFName" class="form-control text-capitalize" value="789151120320323" style={{backgroundColor:"#EBEFF2"}}/>
                                        </div> 

                                        <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 mb-4" style={{marginLeft:"120px"}}>
                                            <label for="icueLmsUpdFormFName" class="form-label icueTextPrimary text-capitalize" style={{color:"#2E3B52", fontFamily:"Calibri", fontSize:"18px"}}>Insurance Company Name</label>
                                            <input type="text" id="icueEditPolicyName" name="icueLmsUpdFormFName" class="form-control text-capitalize" value="TATA AIG" style={{backgroundColor:"#EBEFF2"}}/>
                                        </div> 

                                        <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 mb-4" style={{marginLeft:"120px"}}>
                                            <label for="icueLmsUpdFormLName" class="form-label icueTextPrimary text-capitalize" style={{color:"#2E3B52", fontFamily:"Calibri", fontSize:"18px"}}>insurance type</label>
                                            <input type="text" id="icueEditInsuranceType" name="icueLmsUpdFormLName" class="form-control text-capitalize" value="Car Insurance" style={{backgroundColor:"#EBEFF2"}}/>
                                        </div> 

                                        <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 mb-4">
                                            <label for="icueLmsUpdFormId" class="form-label icueTextPrimary text-capitalize"style={{color:"#2E3B52", fontFamily:"Calibri", fontSize:"18px"}}>sum assured</label>
                                            <input type="text"  class="form-control text-capitalize" value="5,00,000" style={{backgroundColor:"#EBEFF2"}} />
                                        </div>

                                        <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 mb-4" style={{marginLeft:"120px"}}>
                                            <label for="icueLmsUpdFormLoc" class="form-label icueTextPrimary text-capitalize" style={{color:"#2E3B52", fontFamily:"Calibri", fontSize:"18px"}}>Renewal date</label>
                                            <input type="text"  class="form-control text-capitalize" value="8,630" style={{backgroundColor:"#EBEFF2"}}/>
                                        </div>

                                       
                                        <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 mb-4" style={{marginLeft:"120px"}}>
                                            <label for="icueLmsUpdFormLoc" class="form-label icueTextPrimary text-capitalize" style={{color:"#2E3B52", fontFamily:"Calibri", fontSize:"18px"}}>date of purchased</label>
                                            <input type=""  class="form-control text-capitalize" value="27-Jul-2021" style={{backgroundColor:"#EBEFF2"}} />
                                        </div>

                                        
                                       

                                        <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 mb-4">
                                            <label for="icueLmsUpdFormLeadFrom" class="form-label icueTextPrimary text-capitalize" style={{color:"#2E3B52", fontFamily:"Calibri", fontSize:"18px"}}>expiry/maturity date</label>
                                            <input type="" class="form-control text-capitalize" value="27-Jul-2022" style={{backgroundColor:"#EBEFF2"}}/>
                                        </div>

                                        
                                    </div>

                                    <div class="row justify-content-end">
                                        <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 py-2 px-3">
                                            <div class="row justify-content-end">
                                                <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12  bg-white" >                                               
                                                <button type="button" class="btn btn-sm btn-toggle active" data-toggle="button" aria-pressed="false" autocomplete="off" id="toggBtn_1" style={{marginLeft:"650px", marginTop:"1px"}}><div class="handle"></div></button>
                                                {/* <button type="button" class="btn btn-sm btn-toggle " data-toggle="button" aria-pressed="false" autocomplete="off" id="toggBtn_1" style={{marginLeft:"600px", marginTop:"8px"}}><div class="handle"></div></button>  */}
                                                </div>
                                                <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 mb-4" style={{marginTop:"-20px"}}>                                               
                                                <label for="icueLmsUpdFormLeadFrom" class="form-label icueTextPrimary text-capitalize" style={{color:"#2E3B52", fontFamily:"Calibri", fontSize:"14px",marginLeft:"380px",width:"100px",marginTop:"-30px"}}>Auto Renew</label>                                                       
                                                </div>                          
                                                <div class="col-xxl-5 col-xl-5 col-lg-5 col-md-6 col-sm-6 col-12" style={{marginTop:"-12px"}}> 
                                                <a href="/connect" type="button" class="btn icueBgPrimary text-white mb-0 mt-0 " id="icueEditPolicyDetails" style={{fontFamily:"Calibri", color:"white", width:"150px", height:"40px", marginLeft:"250px" }}>Renew Policy</a>
                                              
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <hr></hr>
                                    <div class="row my-2">
                                    <div className="d-flex justify-content-between">
                                    <h4 className="icueTextPrimary pb-2" style={{fontFamily:"ErasDemiITC", fontSize:"20px"}}>More Health Insurance Policies</h4>
                                 </div>                       
                                                                
                           
                        <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 py-2 px-3 ">
                            <div class=" card bg-white p-2  rounded-3 shadow-sm " style={{height:"90px"}}>
                                <div class="d-flex justify-content-around flex-xxl-row flex-xl-row flex-lg-row flex-md-row flex-sm-column flex-column py-2 ">
                                
                                <div className="d-flex justify-content-between text-center" >
                                    <h4 className="icueTextPrimary pb-2" style={{fontFamily:"ErasDemiITC", fontSize:"15px"}}>Aviva <br></br> <small> Base Health Insurance</small></h4>
                                 </div>
                                 <div className="d-flex justify-content-between text-center">
                                    <h4 className="icueTextLight pb-2" style={{fontFamily:"ErasDemiITC", fontSize:"15px"}}>Cover <br></br> <small> <small className="icueTextPrimary">1CR</small></small></h4>
                                 </div> 
                                 <div className="d-flex justify-content-between text-center">
                                    <h4 className="icueTextLight pb-2" style={{fontFamily:"ErasDemiITC", fontSize:"15px"}}>Document <br></br> <small> <i class="bi bi-file-earmark-pdf-fill" style={{color:"#FA5457"}}></i></small></h4>
                                 </div> 
                                 <div className="d-flex justify-content-between text-center">
                                    <h4 className="icueTextPrimary pb-2" style={{fontFamily:"ErasDemiITC", fontSize:"15px"}}>Rs. 4,482/Month <br></br> <small> <small>Rs. 53,781 Annually</small></small></h4>
                                 </div> 
                                 <a href="/connect" type="button" class="btn icueBgPrimary text-white mt-2 "  style={{fontFamily:"Calibri", color:"white", fontSize:"bold", height:"40px"}}> <i class="bi bi-check-circle-fill fas fa-lg" ></i> &nbsp;  &nbsp;  &nbsp;Buy now</a>
                                 {/* <a href="/Followup" type="button" class="btn btn-sm rounded-3 w-50 pb-1 text-capitalize text-white"    style={{lineHeight:"10px", backgroundColor:"#3A3285"}} ><i className="bi bi-search"> </i> &nbsp;  Search</a> */}

                                   </div>
                            </div>
                            
                        </div>

                        <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 py-2 px-3 ">
                            <div class="card bg-white p-2  rounded-3 shadow-sm" style={{height:"110px"}}>
                                <div class="d-flex justify-content-around flex-xxl-row flex-xl-row flex-lg-row flex-md-row flex-sm-column flex-column py-2">
                                
                                <div className="d-flex justify-content-between text-center">
                                    <h4 className="icueTextPrimary pb-2" style={{fontFamily:"ErasDemiITC", fontSize:"15px"}}>ICICI Lombard <br></br> <small style={{lineHeight:"20px"}}> Super Topup Health <br></br> Insurance</small></h4>
                                 </div>
                                 <div className="d-flex justify-content-between text-center">
                                    <h4 className="icueTextLight pb-2" style={{fontFamily:"ErasDemiITC", fontSize:"15px"}}>Cover <br></br> <small> <small className="icueTextPrimary">5L</small></small></h4>
                                 </div> 
                                 <div className="d-flex justify-content-between text-center">
                                    <h4 className="icueTextLight pb-2" style={{fontFamily:"ErasDemiITC", fontSize:"15px"}}>Document <br></br> <small> <i class="bi bi-file-earmark-pdf-fill" style={{color:"#FA5457"}}></i></small></h4>
                                 </div> 
                                 <div className="d-flex justify-content-between text-center">
                                    <h4 className="icueTextPrimary pb-2" style={{fontFamily:"ErasDemiITC", fontSize:"15px"}}>Rs. 2,615/Month <br></br> <small> <small>Rs. 31,375 Annually</small></small></h4>
                                 </div> 
                                 <a href="/connect" type="button" class="btn icueBgPrimary text-white mt-2 "  style={{fontFamily:"Calibri", color:"white", fontSize:"bold", height:"40px"}}> <i class="bi bi-check-circle-fill fas fa-lg" ></i> &nbsp;  &nbsp;  &nbsp;Buy now</a>
                                   </div>
                            </div>
                            
                        </div>

                        <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 py-2 px-3 ">
                            <div class="card bg-white p-2  rounded-3 shadow-sm" style={{height:"90px"}}>
                                <div class="d-flex justify-content-around flex-xxl-row flex-xl-row flex-lg-row flex-md-row flex-sm-column flex-column py-2">
                                
                                <div className="d-flex justify-content-between text-center">
                                    <h4 className="icueTextPrimary pb-2" style={{fontFamily:"ErasDemiITC", fontSize:"15px"}}>Aviva <br></br> <small> Base Health Insurance</small></h4>
                                 </div>
                                 <div className="d-flex justify-content-between text-center">
                                    <h4 className="icueTextLight pb-2" style={{fontFamily:"ErasDemiITC", fontSize:"15px"}}>Cover <br></br> <small> <small className="icueTextPrimary">1CR</small></small></h4>
                                 </div> 
                                 <div className="d-flex justify-content-between text-center">
                                    <h4 className="icueTextLight pb-2" style={{fontFamily:"ErasDemiITC", fontSize:"15px"}}>Document <br></br> <small> <i class="bi bi-file-earmark-pdf-fill" style={{color:"#FA5457"}}></i></small></h4>
                                 </div> 
                                 <div className="d-flex justify-content-between text-center">
                                    <h4 className="icueTextPrimary pb-2" style={{fontFamily:"ErasDemiITC", fontSize:"15px"}}>Rs. 4,482/Month <br></br> <small> <small>Rs. 53,781 Annually</small></small></h4>
                                 </div> 
                                 <a href="/connect" type="button" class="btn icueBgPrimary text-white mt-2 "  style={{fontFamily:"Calibri", color:"white", fontSize:"bold", height:"40px"}}> <i class="bi bi-check-circle-fill fas fa-lg" ></i> &nbsp;  &nbsp;  &nbsp;Buy now</a>
                                   </div>
                            </div>
                            
                        </div>
                        </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 py-2 px-3">
                                    <div className="pe-2" style={{ alignItems:"right"}}>
                                        <img src="images/Help.png" alt="help" className="w-1 rounded-circle" style={{marginLeft:"970px", height:"125px", marginTop:"-85px"}}/>
                                        </div>                                   
                                    </div> 
                    <div class="row my-2">
                        <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 py-2 px-3">
                            <div class="bg-white p-2 rounded-3 shadow-sm">
                                
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    </div>
    </div>)
}